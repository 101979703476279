import { userConstants } from '../_constants/user.constant';
var items = [];
export function users(state = {items}, action) {
  switch (action.type) {

    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.getAll
      };
    case userConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      case userConstants.GETADMIN_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETADMIN_SUCCESS:
      return {
        items: action.getAdmin
      };
    case userConstants.GETADMIN_FAILURE:
      return { 
        error: action.error
      };

      case userConstants.GETTEACHER_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETTEACHER_SUCCESS:
      return {
        items: action.getAdmin
      };
    case userConstants.GETTEACHER_FAILURE:
      return { 
        error: action.error
      };

      case userConstants.GETSTUDENT_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETSTUDENT_SUCCESS:
      return {
        items: action.getAdmin
      };
    case userConstants.GETSTUDENT_FAILURE:
      return { 
        error: action.error
      };

      case userConstants.GETALL_NOACTIVE_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_NOACTIVE_SUCCESS:
      return {
        items: action.getAll
      };
    case userConstants.GETALL_NOACTIVE_FAILURE:
      return { 
        error: action.error
      };


      case userConstants.GETALLDELETED_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALLDELETED_SUCCESS:
      return {
        items: action.getAll
      };
    case userConstants.GETALLDELETED_FAILURE:
      return { 
        error: action.error
      };

      case userConstants.GETINFO_REQUEST:
      return {
        loading: true,
        items: items
      };
    case userConstants.GETINFO_SUCCESS:
      return {
        items: action.userInfo,
        loading: false,
      };
    case userConstants.GETINFO_FAILURE:
      return { 
        error: action.error
      };

      //get info for update
      case userConstants.GETINFOUPDATE_REQUEST:
      return {
        loading: true,
        items: items
      };
    case userConstants.GETINFOUPDATE_SUCCESS:
      return {
        items: action.userInfo
      };
    case userConstants.GETINFOUPDATE_FAILURE:
      return { 
        error: action.error
      };
//AddList
      case userConstants.ADDLIST_REQUEST:
      return {
        loading: true,
        items: items
      };
    case userConstants.ADDLIST_SUCCESS:
      return {
        items: action.list
      };
    case userConstants.ADDLIST_FAILURE:
      return { 
        error: action.error
      };
//GetList
      case userConstants.GETLISTUSER_REQUEST:
      return {
        loading: true,
        items: {}
      };
    case userConstants.GETLISTUSER_SUCCESS:
      return {
        items: action.getList
      };
    case userConstants.GETLISTUSER_FAILURE:
      return { 
        error: action.error
      };

//add user
case userConstants.ADMINADDUSER_REQUEST:
return {
  loading: true,
};
case userConstants.ADMINADDUSER_SUCCESS:
return {
  loading: false,
};
case userConstants.ADMINADDUSER_FAILURE:
return { 
  error: action.error
};
//DELETE USER
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        deleting: true,
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };

       //active user class
  case userConstants.ACTIVE_USER_REQUEST:
    return {
      updating: true,
      ...state,
      items: state.items.map(getUser =>
        getUser.id === action.id
          ? { ...getUser, updating: true }
          : getUser
      )
    };
    case userConstants.ACTIVE_USER_SUCCESS:
    return {
      items: state.items.filter(getUser => getUser.id !== action.id)
    };
  case userConstants.ACTIVE_USER_FAILURE:
    return { 
      ...state,
      items: state.items.map(getUser => {
        if (getUser.id === action.id) {
          const { updating, ...getUserCopy } = getUser;
          return { ...getUserCopy, activeError: action.error };
        }
  
        return getUser;
      })
    };

      //ban user
  case userConstants.BAN_USER_REQUEST:
    return {
      updating: true,
      ...state,
      items: state.items.map(getUser =>
        getUser.id === action.id
          ? { ...getUser, updating: true }
          : getUser
      )
    };
    case userConstants.BAN_USER_SUCCESS:
    return {
      items: state.items.filter(getUser => getUser.id !== action.id)
    };
  case userConstants.BAN_USER_FAILURE:
    return { 
      ...state,
      items: state.items.map(getUser => {
        if (getUser.id === action.id) {
          const { updating, ...getUserCopy } = getUser;
          return { ...getUserCopy, activeError: action.error };
        }
  
        return getUser;
      })
    };


//RESTORE USER
case userConstants.RESTORE_REQUEST:
  return {
    restoring: true,
    ...state,
    items: state.items.map(user =>
      user.id === action.id
        ? { ...user, restoring: true }
        : user
    )
  };
case userConstants.RESTORE_SUCCESS:
// remove deleted user from state
return {
  items: state.items.filter(user => user.id !== action.id)
};
case userConstants.RESTORE_FAILURE:
// remove 'deleting:true' property and add 'deleteError:[error]' property to user 
return {
  ...state,
  items: state.items.map(user => {
    if (user.id === action.id) {
      // make copy of user without 'deleting:true' property
      const { restoring, ...userCopy } = user;
      // return copy of user with 'deleteError:[error]' property
      return { ...userCopy, restoreError: action.error };
    }

    return user;
  })
};

//FORCE DEL USER
case userConstants.FORCEDEL_REQUEST:
  return {
    deleting: true,
    ...state,
    items: state.items.map(user =>
      user.id === action.id
        ? { ...user, deleting: true }
        : user
    )
  };
case userConstants.FORCEDEL_SUCCESS:
// remove deleted user from state
return {
  items: state.items.filter(user => user.id !== action.id)
};
case userConstants.FORCEDEL_FAILURE:
// remove 'deleting:true' property and add 'deleteError:[error]' property to user 
return {
  ...state,
  items: state.items.map(user => {
    if (user.id === action.id) {
      // make copy of user without 'deleting:true' property
      const { deleting, ...userCopy } = user;
      // return copy of user with 'deleteError:[error]' property
      return { ...userCopy, deleteError: action.error };
    }

    return user;
  })
};

    default:
      return state
  }
}
import React from 'react';
import {Link} from 'react-router-dom';
import { isLoged } from '../_services/auth';
import Logo from '../images/logofull.png';
import { AppBar, Drawer, List, ListItem, Divider, ListItemIcon, ListItemText, 
  ListSubheader, Button,  Toolbar, MenuItem, Menu,
  IconButton, Typography, InputBase,  Grid
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Icon from '@material-ui/core/Icon';
import { Videocam, ExpandLess, School, Settings, RecordVoiceOver, Help,
  ExitToApp, PersonOutline, TouchApp
} from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import ScrollToTop from 'react-scroll-up';
import '../App.css'
import { connect } from 'react-redux';
import { userActions } from '../_actions/user.actions';
import { menuActions } from '../_actions/menu.actions';
import UserAvatar from 'react-user-avatar';
import Fab from '@material-ui/core/Fab';
import { history } from '../_helpers/history';
import Tooltip from '@material-ui/core/Tooltip';
import config from "react-global-configuration";


const styles = theme => ({
    root: {
      width: '100%',
    },
    grow: {
      flexGrow: 1,
    },
    logo: {
      height: 30,
      paddingTop:5,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing.unit * 2,
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing.unit * 3,
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing.unit * 5,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 6,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 300,
      },
    },
    sectionMenu:{
      fontSize: '16px!important',
      fontWeight: '500!important',
      marginBottom:10
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    //Draw
    list: {
      width: 250,
    },
    listSubHeader: {
      padding: theme.spacing.unit,
      backgroundColor: '#1565C0',
      color: '#fff',
      height: 55,
    },
    absolute: {
      position: 'absolute',
      bottom: theme.spacing.unit * 2,
      right: theme.spacing.unit * 3,
    },
    footer: {
        color: '#666',
      },
      gray:{
        color: '#666',
      },
      marginTop10:{
        marginTop: 10,
      },
      listInline:{
        display: 'inline-block',
        margin: '0 10px 20px 10px',
      },
      link:{
        color: '#666',
        textDecoration: 'none',
        '&:hover': {
          color: '#d50000',
        }
      },
      backtotop:{
        bottom: -20,
        right: 20,
        transitionDuration: '0.5s',
        transitionTimingFunction: 'easeInQuad',
        transitionDelay: '1s'
      },
      noHover:{
        '&:hover': {
          backgroundColor: 'transparent',
          cursor: 'initial',
        }
      },
      bigAvatar: {
        margin: 10,
        width: 80,
        height: 80,
        marginLeft:'auto',
        marginRight: 'auto',
      },
      listParent:{
        paddingTop:3,
        paddingBottom:3,
        fontWeight:450,
      },
      linkLogo: {
        color: '#666',
        textDecoration: 'none',
        '&:hover':{
          color: '#1e88e5',
        }

      },

  });

 
class TipLayout extends React.Component {
  constructor(props){
    super(props);
    
    this.state = {
        items: [],
        isLoaded: false,
        anchorEl: null,
        searchStr: null,
        loading: true
    }
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  handleSearchChange(e){
    const { searchStr } = this.state;
    const { name, value } = e.target;
    
    this.setState({
      searchStr: {
            ...searchStr,
            [name]: value
        }
    });
  }

  componentDidMount() {
    this.props.dispatch(menuActions.getAll());
    let user = JSON.parse(localStorage.getItem('user'));
    if(user !== null){
      this.props.dispatch(userActions.getInfo());
    }
    this.setState({loading:false})
  }
  handleLogout = (props) => {
    this.props.dispatch(userActions.logout());
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  //For menu
  toggleMenu = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };
 
  handleClick = (e) => {
    this.setState({ [e]: !this.state[e] });
  };
  
  render() {
    const { anchorEl, loading } = this.state;
    const { classes, menu, users } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const uInfo = users.items;
    const uri = (window.location.pathname).split('/');
    // console.log(uri.length)
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
        style={{top:40}}
      >
        <MenuItem style={{height:'auto',padding:0,background:'#fff'}} className={classes.noHover}>
        {users.loading ? (
            ""
        ) : (
          <UserAvatar size="80" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar} className={classes.bigAvatar}/>
        )}
        </MenuItem>
        <MenuItem style={{paddingTop:0}} className={classes.noHover}>
        {uInfo.length !== 0 ? (
          <div style={{marginLeft:'auto', marginRight:'auto'}}><b>{uInfo.name}</b></div>
        ) : ("")}
        </MenuItem>
        <MenuItem style={{height:15,marginBottom:5,background:'#fff'}} className={classes.noHover}>
          <Button component={Link} to="/u/profile" variant="contained" size="small" color="secondary" align="center" style={{marginLeft:'auto', marginRight:'auto',textTransform:'initial'}}>
            Xem thông tin
          </Button>
        </MenuItem>
        <Divider light />
        
        <MenuItem component={Link} to="/lop-hoc-cua-toi" title="Lớp học của tôi" style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <School />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Lớp học của tôi" />
        </MenuItem>
        <MenuItem component={Link} to="/u/edit-profile" style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <Settings />
            </ListItemIcon>
          <ListItemText  classes={{ primary: classes.primary }} inset primary="Thiết lập tài khoản" />
        </MenuItem>
        <MenuItem onClick={this.handleMenuClose} style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <Videocam />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Dạy học" />
        </MenuItem>
        <MenuItem onClick={this.handleMenuClose} style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <RecordVoiceOver />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Mời bạn bè" />
        </MenuItem>
        <MenuItem onClick={this.handleMenuClose} style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <Help />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Trợ giúp" />
        </MenuItem>
        <Divider light />
        <MenuItem style={{height:15}} onClick={this.handleLogout}>
          <ListItemIcon className={classes.icon}>
              <ExitToApp />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Thoát" />
        </MenuItem>
      </Menu>
    );

    const sideList = (
      <div className={classes.list}>
        <div className={classes.toolbar} style={{padding: '8px 0 0 20px'}}>
          <Typography  variant="h5">
            <Link to="/" className={classes.linkLogo} title="Học kỹ năng trực tuyến Learn Skills">Learn Skills</Link>
          </Typography>
          <Typography variant="caption">
            v1.0.0
          </Typography>
        </div>
        <Divider />
        <Typography  variant="body2" style={{padding: '10px 0 0 20px'}}>
            <Link to="/tips" className={classes.listParent} style={{textDecoration:'none',color:'#555'}} title="Mẹo - Thủ thuật">Mẹo - Thủ thuật</Link>
          </Typography>
        <List
        component="nav"
        subheader={<ListSubheader component="div" style={{position:'relative'}}>Các lớp học video</ListSubheader>}
        className={classes.root}
      >
      <ListItem button className={classes.listParent} component={Link} to={'/lop-hoc-video'}>
          <ListItemText inset primary={<Typography variant="subtitle2" gutterBottom>Tất cả</Typography>} style={{paddingLeft:0}}/>
        </ListItem>
        {menu.items.map(val =>(
          val.submenu.length > 0 &&
          <React.Fragment key={val.id}>
          <ListItem button onClick={this.handleClick.bind(this, val.id)} className={classes.listParent}>
            <ListItemText inset primary={<Typography variant="subtitle2" gutterBottom>{val.name}</Typography>} style={{paddingLeft:0}} />
          </ListItem>
          <Collapse in={this.state[val.id]} timeout="auto" unmountOnExit>
          <List component="nav" className={classes.listParent}>
          {val.submenu.length && val.submenu.map(v => (
            <ListItem button className={classes.listParent} key={v.id} component={Link} to={'/sec-video/'+v.slug}>
              <ListItemText inset primary={<Typography variant="body2" gutterBottom>{v.name}</Typography>} style={{paddingLeft:10}}/>
            </ListItem>
          ))}
          </List>
        </Collapse>
        </React.Fragment>
        ))}
      </List>

      <List
        component="nav"
        subheader={<ListSubheader component="div" style={{position:'relative'}}>Các lớp học văn bản</ListSubheader>}
        className={classes.root}
      >
      <ListItem button className={classes.listParent} component={Link} to={'/lop-hoc-van-ban'}>
          <ListItemText inset primary={<Typography variant="subtitle2" gutterBottom>Tất cả</Typography>} style={{paddingLeft:0}}/>
        </ListItem>
        {menu.items.map(val =>(
          val.submenu.length > 0 &&
          <React.Fragment key={val.name}>
          <ListItem button onClick={this.handleClick.bind(this, val.name)} className={classes.listParent}>
            <ListItemText inset primary={<Typography variant="subtitle2" gutterBottom>{val.name}</Typography>} style={{paddingLeft:0}} />
          </ListItem>
          <Collapse in={this.state[val.name]} timeout="auto" unmountOnExit>
          <List component="div" className={classes.listParent}>
          {val.submenu.length && val.submenu.map(v => (
            <ListItem button className={classes.listParent} key={v.name} component={Link} to={'/sec-text/'+v.slug}>
              <ListItemText inset primary={<Typography variant="body2" gutterBottom>{v.name}</Typography>} style={{paddingLeft:10}}/>
            </ListItem>
          ))}
          </List>
        </Collapse>
        </React.Fragment>
        ))}
      </List>
      </div>
    );
    return (
      <div className={classes.root}>
        <AppBar>
          <Toolbar style={{maxHeight:55,minHeight:55}}>
            <IconButton className={classes.sectionMobile} color="inherit" aria-label="Mở menu" onClick={this.toggleMenu('left', true)}>
              <MenuIcon />
            </IconButton>
            {/* open menu */}
            <Drawer open={this.state.left} onClose={this.toggleMenu('left', false)}>
              <div
                tabIndex={0}
                role="button"
                // onClick={this.toggleMenu('left', false)}
                // onKeyDown={this.toggleMenu('left', false)}
              >
                {sideList}
              </div>
            </Drawer>
            {/* end open menu */}
            <Typography className={classes.title} variant="h6" color="inherit" noWrap component={Link} to="/">
              <img src={Logo} alt="Học kỹ năng trực tuyến" className={classes.logo} />
            </Typography>
       
            <div color="inherit" className="dropdown" >
              <Button color="inherit" style={{textTransform:'initial',fontSize:13}}
                className={classes.sectionDesktop}> Video
                <Icon className={classes.rightIcon}>expand_more</Icon>
              </Button>
              <div className="dropdown-content">
                <Grid container spacing={40}>
                  {menu.items.map(item => (
                    item.submenu.length > 0 &&
                    <Grid item md={3} className="section" key={item.slug}>
                    
                      <Link to={'/video/'+item.slug} title={item.name} className={classes.sectionMenu}>{item.name}</Link>
                      {item.submenu.map(subitem => (
                        <Link to={'/sec-video/'+subitem.slug} key={subitem.slug} title={subitem.name}>{subitem.name}</Link>
                      ))}
                    </Grid>
                  ))}
                </Grid>  
              </div>
            </div>
            <div color="inherit" className="dropdown" style={{marginLeft:0}}>
              <Button color="inherit" style={{textTransform:'initial',fontSize:13}}
                className={classes.sectionDesktop}> Văn bản
                <Icon className={classes.rightIcon}>expand_more</Icon>
              </Button>
              <div className="dropdown-content">
                <Grid container spacing={40}>
                  {menu.items.map(item => (
                    item.submenu.length > 0 &&
                    <Grid item md={3} className="section" key={item.slug}>
                      <Link to={'/text/'+item.slug} title={item.name} className={classes.sectionMenu}>{item.name}</Link>
                      {item.submenu.map(subitem => (
                        <Link to={'/sec-text/'+subitem.slug} key={subitem.slug} title={subitem.name}>{subitem.name}</Link>
                      ))}
                    </Grid>
                  ))}
                </Grid>  
              </div>
            </div>
            <div className={classes.search}>
            
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Tìm kiếm lớp học"
                name="search"
                onChange={this.handleSearchChange}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    let str = this.state.searchStr.search.trim();
                    let newStr = str.replace(/\s+/g, '+');
                    history.push('/tim-kiem/'+newStr)
                  }
                }}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
              
            </div>
            <div className={classes.grow} />
            {/* <Tooltip title="Dự án" aria-label="project">
              <IconButton color="inherit" className={classes.sectionDesktop} component={Link} to="/du-an"><Layers /></IconButton>
            </Tooltip> */}
            <Tooltip title="Mẹo - Thủ thuật" aria-label="tip">
              <IconButton color="inherit" className={classes.sectionDesktop} component={Link} to="/tips"><TouchApp /></IconButton>
            </Tooltip>
            
            {/* <Tooltip title="Hỏi - Đáp" aria-label="qa">
              <IconButton color="inherit" className={classes.sectionDesktop} component={Link} to="/hoi-dap"><QuestionAnswer /></IconButton>
            </Tooltip> */}
            <Tooltip title="Trở thành giáo viên" aria-label="teacher">
              <IconButton color="inherit" className={classes.sectionDesktop} component={Link} to="/day-hoc"><School /></IconButton>
            </Tooltip>
            {!isLoged() ? (
              <React.Fragment>
                <Button color="inherit" className={classes.sectionDesktop} style={{textTransform:'initial',fontSize:13}} component={Link} to="/register" title="">Đăng ký</Button>
                <Button variant="contained" className={classes.sectionDesktop} color="secondary" size="small" style={{textTransform:'initial',fontSize:13}} component={Link} to="/login">Đăng nhập</Button>
                <Button variant="contained" className={classes.sectionMobile} color="secondary" size="small" style={{textTransform:'initial',fontSize:13,minWidth:40}} component={Link} to="/login"><PersonOutline /></Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
              <div className={classes.sectionDesktop}>
            <Button color="inherit" size="small" className={classes.sectionDesktop} style={{textTransform:'initial',fontSize:13}} component={Link} to="/lop-hoc-cua-toi" title="Lớp học của tôi">Lớp học</Button>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
                style={{padding:0}}
              >
              {users.loading || loading ? (
                  ""
              ) : (
                <UserAvatar size="40" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar}/>
              )}
                
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleProfileMenuOpen} color="inherit">
              {users.loading || loading ? (
                  ""
              ) : (
                <UserAvatar size="40" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar}/>
              )}
              </IconButton>
            </div>
        
</React.Fragment>
            )}
            
          </Toolbar>
        </AppBar>
        
        {this.props.children}
        {uri.length > 2 ? (
          <Grid container className={classes.footer}>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={10}>
            <Grid container style={{padding:'0 10px'}}>
              <Grid item xs={12}>
                <Divider />
                <Grid container className={classes.marginTop10}>
                <Grid item xs={12} sm={12} md={7}>
                            <Typography variant="caption" gutterBottom className={classes.gray}>
                            <ul className={classes.ListItem}  style={{paddingLeft:0}}>
                                <li className={classes.listInline}>&copy; Learnskill 2018</li>
                                <li className={classes.listInline}>
                                <Link to="/" title="Hướng dẫn" className={classes.link}>Hướng dẫn</Link>
                                </li>
                                <li className={classes.listInline}>
                                <Link to="/" title="Điều khoản" className={classes.link}>Điều khoản</Link>
                                </li>
                                <li className={classes.listInline}>
                                <Link to="/" title="Chính sách" className={classes.link}>Chính sách</Link>
                                </li>
                            </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                        <Typography variant="caption" gutterBottom align="right">
                        <Fab color="primary" size="small" href="/">f</Fab>&nbsp;&nbsp;
                        <Fab color="primary" size="small" href="/">t</Fab>&nbsp;&nbsp;
                        <Fab color="secondary" size="small" href="/"><Videocam /></Fab>
                        </Typography>
                        </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={1}></Grid>

        </Grid>
        ) : (
          <ScrollToTop showUnder={160}>
           <Fab color="secondary" aria-label="Lên đầu trang" className={classes.backtotop}>
            <ExpandLess />
          </Fab>
          </ScrollToTop>
        )}
        

        {renderMenu}

        <div>

        </div>
      </div>
    );
    }
}

function mapStateToProps(state) {
  const { menu, users } = state;
  return {
    menu, users
  };
}

export default connect(mapStateToProps)(withStyles(styles)(TipLayout));
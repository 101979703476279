import React, { Component } from 'react';

class Ads extends Component {

	componentDidMount() {
		(window.adsbygoogle = window.adsbygoogle || []).push({})
	}

	render() {
		const style = {
			display: 'block',
		};

		return(
			<ins className="adsbygoogle"
			     style={style}
			     data-ad-client="ca-pub-1887238973920029"
			     data-ad-slot={this.props.slot}
			     data-ad-format={this.props.format}
                 data-full-width-responsive="true">
			</ins>
		);
	}
}

export default Ads;
import React, { Component } from 'react';
// import ReactGA from 'react-ga';
import { Route } from 'react-router4-with-layouts';
import { Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './_helpers/history';
import { alertActions } from './_actions/alert.actions';
import { PrivateRoute } from './_components/PrivateRoute';
import { Dashboard, User, AddUser, DelUser, UpdateUser, SetRole, AdminList, TeacherLists, StudentList, Menu, MenuAdd,
        MenuUpdate, MenuDeleted, VideoClass, TextClass, VideoClassUpdate, VideoClassAdd,
        TextClassAdd,TextClassUpdate, AddLesson, Lesson, EditLesson, TextClassDeleted, AddQuiz, VideoClassDeleted,
        VideoLesson, AddVideo, EditVideo, MenuTips, MenuTipsAdd, MenuTipsDeleted, MenuTipsUpdate, Tips, TipAdd,
        TipDeleted, TipUpdate, CommentClass, CommentClassPending, UserActivePending
} from './admin';
import { Login, Register, RelatedSkill, profile, editProfile } from './Users';
import { HomeLayout, ClassLayout, AdminLayout, TipLayout } from './Layout';
import { Home, allClass, allClassVideo, allClassFilter, allClassText, allClassTextFilter, classDetail, classTextDetail,
    classTextInfo, classTextLesson, objectiveText, eClass, eClassText, eClassFilter, eClassTextFilter,
    eSecClass, eSecClassFilter, eSecClassText, eSecClassTextFilter, publicUser, searchClass, QuizIntro, QuizClass,
    TeacherList, AllFilter
} from './public';
import { HomeTips, CatTips, subCatTips, TipDetail } from './tips';
import { Teach } from './teacher';
import { HomeMember, MyClass } from './member';

import Error from './Error';


class App extends Component { 
  constructor(props) {
    super(props);
    // Initialize google analytics page view tracking
    // history.listen(location => {
    //   ReactGA.set({ page: location.pathname }); // Update the user's current page
    //   ReactGA.pageview(location.pathname); // Record a pageview for the given page
    // });

    const { dispatch } = this.props;
    history.listen((location, action) => {
        // clear alert on location change
        dispatch(alertActions.clear());
        this.forceUpdate();
    });
}
  render() {
    return (
     
         <div>
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={Home} layout={HomeLayout} />
                    <Route path="/login" component={Login}  />
                    <Route path="/register" component={Register} />
                    <Route path="/u/ky-nang-quan-tam" component={RelatedSkill} layout={HomeLayout} />
                    <Route path="/user/:id/:name" component={publicUser} exact layout={HomeLayout} />
                    <Route path="/tim-kiem/:searchStr" component={searchClass} exact layout={HomeLayout} />

                    <Route exact path="/lop-hoc" component={allClass} layout={ClassLayout} />
                    <Route exact path="/lop-hoc/filter/:filter" component={AllFilter} layout={ClassLayout} />
                    <Route exact path="/lop-hoc-video" component={allClassVideo} layout={ClassLayout} />
                    <Route exact path="/lop-hoc-video/filter/:filter" component={allClassFilter} layout={ClassLayout} />
                    <Route exact path="/video/:slug" component={eClass} layout={ClassLayout} />
                    <Route exact path="/video/:slug/:filter" component={eClassFilter} layout={ClassLayout} />
                    <Route exact path="/sec-video/:slug" component={eSecClass} layout={ClassLayout} />
                    <Route exact path="/sec-video/:secSlug/:filter" component={eSecClassFilter} layout={ClassLayout} />
                    <Route exact path="/video-course/:classSlug" component={classDetail} layout={HomeLayout} />
                    

                    <Route exact path="/lop-hoc-van-ban" component={allClassText} layout={ClassLayout} />
                    <Route path="/lop-hoc-van-ban/filter/:filter" component={allClassTextFilter} layout={ClassLayout} />
                    <Route path="/text/:slug" component={eClassText} exact layout={ClassLayout} />
                    <Route path="/text/:slug/:filter" component={eClassTextFilter} layout={ClassLayout} />
                    <Route exact path="/sec-text/:slug" component={eSecClassText} layout={ClassLayout} />
                    <Route path="/sec-text/:slug/:filter" component={eSecClassTextFilter} layout={ClassLayout} />
                    <Route path="/text-course/:slug" component={classTextDetail} exact layout={ClassLayout} />
                    <Route exact path="/lesson/:lessonSlug/gioi-thieu" component={classTextInfo} layout={ClassLayout} />
                    <Route path="/lesson/:lessonSlug/muc-tieu" component={objectiveText} exact layout={ClassLayout} />
                    <Route path="/lesson/quiz/:slug/intro" component={QuizIntro} exact layout={ClassLayout} />
                    <Route path="/quiz/:slug" component={QuizClass} exact layout={ClassLayout} />
                    <Route path="/lesson/:slugClass/:slugLesson" component={classTextLesson} layout={ClassLayout} />
                    <Route path="/danh-sach-giao-vien/:keyword?" component={TeacherList} layout={HomeLayout} exact/>
                    
                    {/* Tips */}
                    <Route path="/tips" component={HomeTips} layout={TipLayout} exact/>
                    <Route path="/tips/:slug" component={CatTips} layout={TipLayout} exact/>
                    <Route path="/tips/:catSlug/:subCatSlug" component={subCatTips} layout={TipLayout} exact/>
                    <Route path="/tips/:catSlug/:subCatSlug/:tipSlug" component={TipDetail} layout={TipLayout} exact/>
                    
                    {/* User */}
                    <PrivateRoute path="/u/profile" component={profile} exact />
                    <PrivateRoute path="/u/edit-profile" component={editProfile} exact />

                    {/* Member */}
                    <PrivateRoute path="/home" component={HomeMember} exact />
                    <PrivateRoute path="/lop-hoc-cua-toi" component={MyClass} exact />

                    {/* Admin */}
                    <Route path="/dashboard" component={Dashboard} layout={AdminLayout} exact />
                    <Route path="/dashboard/user" component={User} layout={AdminLayout} exact />
                    <Route path="/dashboard/user/not-active" component={UserActivePending} layout={AdminLayout} exact />
                    <Route path="/dashboard/user/add" component={AddUser} layout={AdminLayout} exact />
                    <Route path="/dashboard/user/del" component={DelUser} layout={AdminLayout} exact />
                    <Route path="/dashboard/user-update/:id" component={UpdateUser} layout={AdminLayout} exact />
                    <Route path="/dashboard/user/admin" component={AdminList} layout={AdminLayout} exact />
                    <Route path="/dashboard/user/teacher" component={TeacherLists} layout={AdminLayout} exact />
                    <Route path="/dashboard/user/student" component={StudentList} layout={AdminLayout} exact />
                    <Route path="/dashboard/user/set-role/:id" component={SetRole} layout={AdminLayout} exact />
                    <Route path="/dashboard/menu" component={Menu} layout={AdminLayout} exact />
                    <Route path="/dashboard/menu/del" component={MenuDeleted} layout={AdminLayout} exact />
                    <Route path="/dashboard/menu/add" component={MenuAdd} layout={AdminLayout} exact />
                    <Route path="/dashboard/menu/edit/:id" component={MenuUpdate} layout={AdminLayout} exact />
                    <Route path="/dashboard/video-class" component={VideoClass} layout={AdminLayout} exact />
                    <Route path="/dashboard/video-class/edit/:id" component={VideoClassUpdate} layout={AdminLayout} exact />
                    <Route path="/dashboard/video-class/add" component={VideoClassAdd} layout={AdminLayout} exact />
                    <Route path="/dashboard/video-class/del" component={VideoClassDeleted} layout={AdminLayout} exact />
                    <Route path="/dashboard/video-class/:id" component={VideoLesson} layout={AdminLayout} exact />
                    <Route path="/dashboard/text-class" component={TextClass} layout={AdminLayout} exact />
                    <Route path="/dashboard/video-class/add-video/:id" component={AddVideo} layout={AdminLayout} exact />
                    <Route path="/dashboard/video-class/edit-video/:id" component={EditVideo} layout={AdminLayout} exact />
                    <Route path="/dashboard/text-class/add" component={TextClassAdd} layout={AdminLayout} exact />
                    <Route path="/dashboard/text-class/add-quiz/:id" component={AddQuiz} layout={AdminLayout} exact />
                    <Route path="/dashboard/text-class/del" component={TextClassDeleted} layout={AdminLayout} exact />
                    <Route path="/dashboard/text-class/edit/:id" component={TextClassUpdate} layout={AdminLayout} exact />
                    <Route path="/dashboard/text-class/edit-lesson/:id" component={EditLesson} layout={AdminLayout} exact />
                    <Route path="/dashboard/text-class/:id" component={Lesson} layout={AdminLayout} exact />
                    <Route path="/dashboard/text-class/add-lesson/:id" component={AddLesson} layout={AdminLayout} exact />

                    <Route path="/dashboard/menu-tip" component={MenuTips} layout={AdminLayout} exact />
                    <Route path="/dashboard/menu-tip/del" component={MenuTipsDeleted} layout={AdminLayout} exact />
                    <Route path="/dashboard/menu-tip/add" component={MenuTipsAdd} layout={AdminLayout} exact />
                    <Route path="/dashboard/menu-tip/edit/:id" component={MenuTipsUpdate} layout={AdminLayout} exact />
                    
                    <Route path="/dashboard/tip" component={Tips} layout={AdminLayout} exact />
                    <Route path="/dashboard/tip/add" component={TipAdd} layout={AdminLayout} exact />
                    <Route path="/dashboard/tip/deleted" component={TipDeleted} layout={AdminLayout} exact />
                    <Route path="/dashboard/tip-update/:id" component={TipUpdate} layout={AdminLayout} exact />

                    <Route path="/dashboard/comment/lop-hoc" component={CommentClass} layout={AdminLayout} exact />
                    <Route path="/dashboard/comment/lop-hoc/pending" component={CommentClassPending} layout={AdminLayout} exact />

                    {/* Teacher */}
                    <Route path="/day-hoc" component={Teach} layout={HomeLayout} exact />

                    <Route component={Error} />
                </Switch>
            </Router>
        </div>
  
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
      alert
  };
}

export default connect(mapStateToProps)(App);

import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { classActions } from '../../_actions/class.actions';
import { Button, Typography, CircularProgress, Dialog } from '@material-ui/core';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import ArrowBack from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import red from '@material-ui/core/colors/red';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import green from '@material-ui/core/colors/green';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';

function TabContainer(props) {
    const { children, dir } = props;
  
    return (
      <Typography component="div" dir={dir} style={{ padding: '20px 0' }}>
        {children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
  };

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop:20,
      },
      appBar: {
        position: 'relative',
      },
      flex: {
        flex: 1,
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
      },
      fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[600],
        },
      },
})



  const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit * 2,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      color: theme.palette.grey[500],
    },
  }))(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing.unit * 2,
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles(theme => ({
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit,
    },
  }))(MuiDialogActions);
  
  

class Lesson extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            openDel: false,
            value: 1,
            data: [],
            desc: EditorState.createEmpty(),
            objective: EditorState.createEmpty(),
            project: EditorState.createEmpty(),
            loading: false,
        }
    }
    handleCloseDel = () => {
      this.setState(
        { 
          openDel: false 
        });
    }
    handleClickDelOpen = (id) => {
        this.setState(
          { 
            openDel: true,
            id: id, 
          });
      };

      handleDeleteLesson(id){
        this.setState({ openDel: false });
        this.props.dispatch(classActions.delVideo(id));
      }

    handleChangeTab = (event, value) => {
        this.setState({ value });
      };
    
      handleChangeTabIndex = index => {
        this.setState({ value: index });
      };
    
    componentDidMount() {
        this.setState({loading: true})
        axios.get(config.get("apiDomain")+"class/find/"+this.props.match.params.id)
        .then(res => {
            this.setState({data: res.data});
            if(res.data.description && res.data.description !== null){
                let convert = convertFromRaw(JSON.parse(res.data.description));
                let editor = EditorState.createWithContent(convert);
                this.setState({desc: editor})
            }
            if(res.data.objective && res.data.objective !== null){
                let convert = convertFromRaw(JSON.parse(res.data.objective));
                let editor = EditorState.createWithContent(convert);
                this.setState({objective: editor})
            }
            if(res.data.project && res.data.project !== null){
                let convert = convertFromRaw(JSON.parse(res.data.project));
                let editor = EditorState.createWithContent(convert);
                this.setState({project: editor})
            }
            this.setState({loading: false})
        })
        this.props.dispatch(classActions.allClassVideoAdmin(this.props.match.params.id));
      }
    render(){
        const { classes, theme, lesson  } = this.props;
        const { desc, data, objective, project, loading } = this.state;
       
        return(
            <div className={classes.root}>
                <MetaTags>
                <title>Quản lý bài học - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                {loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                ) : (
                    <React.Fragment>
                      <Button variant="outlined" color="default" size="small" style={{textTransform:'initial',marginBottom:10}}  component={Link} to={"/dashboard/video-class"}>
                          <ArrowBack /> Quay lại quản lý lớp học
                        </Button>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChangeTab}
                            indicatorColor="secondary"
                            textColor="secondary"
                            
                        >
                            <Tab label="Thông tin chung" />
                            <Tab label="Các bài học" />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={this.state.value}
                    onChangeIndex={this.handleChangeTabIndex}
                    
                    >
                    <TabContainer dir={theme.direction} style={{padding:'20px 0!important'}}>
                        <Typography variant="body2" style={{fontWeight:450}}>Tên lớp học:</Typography>
                        <Typography style={{fontSize:18,fontWeight:450}}>{data.title}</Typography>
                        <Typography variant="body2" style={{marginTop:20,fontWeight:450}}>Mô tả lớp học:</Typography>
                        {data.description !== null ? (
                            <Editor toolbarHidden editorState={desc} readOnly={true} />
                        ) : (
                            <div className="box">Không có mô tả cho lớp học này</div>
                        )}
                        
                        <Typography variant="body2" style={{marginTop:20,fontWeight:450}}>Mục tiêu lớp học:</Typography>
                        {data.objective !== null ? (
                            <Editor toolbarHidden editorState={objective} readOnly={true} />
                        ) : (
                            <div className="box">Không có mục tiêu cho lớp học này</div>
                        )}
                        <Typography variant="body2" style={{marginTop:20,fontWeight:450}}>Dự án lớp học:</Typography>
                        {data.project !== null ? (
                            <Editor toolbarHidden editorState={project} readOnly={true} />
                        ) : (
                            <div className="box">Không có dự án cho lớp học này</div>
                        )}
                    </TabContainer>
                    <TabContainer dir={theme.direction}>
                    <Typography style={{fontSize:18,fontWeight:450}}>{data.title}</Typography>
                        <Button variant="contained" color="primary" size="small" style={{textTransform:'initial',marginBottom:10}}  component={Link} to={"/dashboard/video-class/add-video/"+data.id}>
                          <AddIcon /> Thêm video
                        </Button>
                        <Button variant="contained" color="secondary" size="small" style={{textTransform:'initial',marginBottom:10,marginLeft:15}}  component={Link} to={"/dashboard/text-class/add-quiz/"+data.id}>
                          <AddIcon /> Tạo câu hỏi trắc nghiệm
                        </Button>
                        <MaterialTable
                            columns={[
                            { title: 'ID', field: 'id'},
                            { title: 'Tên bài học', field: 'name' },
                            { 
                              title: 'Ảnh', 
                              field: 'thumbnail_custom',
                              render: rowData =>{
                                  return(
                                      <img src={config.get('apiImg')+rowData.thumbnail_custom} width="80px" alt={rowData.title}/>
                                  )
                              }
                            },
                            { title: 'loại video', field: 'type' },
                            { 
                              title: 'Tình trạng', 
                              field: 'actived',
                              render: rowData =>{
                                  return(
                                      rowData.actived === 1 ? <Chip label="Xuất bản" color="primary"/> : <Chip label="Chưa xuất bản" color="secondary"/>
                                  )
                              }
                          },
                            { title: 'Vị trí', field: 'position', defaultSort: 'asc' },
                            // { title: 'Ngày tạo', field: 'created_at' },
                            ]}
                            data={lesson}
                            title="Danh sách các bài học"
                            isLoading = {loading || this.props.deleting}
                            actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Sửa',
                                onClick: (event, rowData) => {
                                this.props.history.push('/dashboard/video-class/edit-video/'+rowData.id);
                                },
                                iconProps: {
                                style: {
                                    fontSize: 20,
                                    color: 'green',
                                },
                                },
                            },
                            
                            {
                                icon: 'delete',
                                tooltip: 'Xóa',
                                onClick: (event, rowData) => {
                                this.handleClickDelOpen(rowData.id)
                                },
                                iconProps: {
                                style: {
                                    fontSize: 20,
                                    color: '#cb3837',
                                },
                                },
                            },
                            ]}
                            
                            options={{
                            columnsButton: true,
                            exportButton: true,
                            actionsColumnIndex: -1,
                            // selection: true,
                            }}
                            localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} trong {count}', // {from}-{to} of {count}
                                labelRowsPerPage: 'Số bản ghi trên trang:', // Rows per page:
                                firstAriaLabel: 'Trang đầu', // First Page
                                firstTooltip: 'Trang đầu', // First Page
                                previousAriaLabel: 'Trang trước', // Previous Page
                                previousTooltip: 'Trang trước', // Previous Page
                                nextAriaLabel: 'Trang tiếp', // Next Page
                                nextTooltip: 'Trang tiếp', // Next Page
                                lastAriaLabel: 'Trang cuối', // Last Page
                                lastTooltip: 'Trang cuối', // Last Page
                            },
                            toolbar: {
                                nRowsSelected: '{0} bản ghi được chọn', // {0} row(s) selected
                                showColumnsTitle: 'Hiển thị cột', // Show Columns
                                showColumnsAriaLabel: 'Hiển thị cột', // Show Columns
                                exportTitle: 'Xuất', // Export
                                exportAriaLabel: 'Xuất', // Export
                                exportName: 'Xuất ra CSV', // Export as CSV
                                searchTooltip: 'Tìm kiếm', // Search
                            },
                            header: {
                                actions: 'Hành động', // Actions
                            },
                            body: {
                                emptyDataSourceMessage: 'Không có bài học nào cho lớp học này ', // No records to display
                                filterRow: {
                                filterTooltip: 'Lọc', // Filter
                                },
                            },
                            }}
                        />
                    </TabContainer>
                    </SwipeableViews>
                                   
                    </React.Fragment>
                )}

                 
        {/* Confirm delete */}
        <Dialog
          onClose={this.handleCloseDel}
          aria-labelledby="customized-dialog-title"
          open={this.state.openDel}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseDel}>
            Xóa video
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
              Bạn chắc chắn muốn xóa video này?
            </Typography>
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleCloseDel} color="primary">
              Bỏ qua
            </Button>
            <Button onClick={this.handleDeleteLesson.bind(this, this.state.id)} variant="contained" color="secondary">
              Xóa
            </Button>
          </DialogActions>
        </Dialog>

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { lesson, deleting } = state.getClass;
    return {
        lesson, deleting
    };
  }
  export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Lesson));
export default function auth(type) {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user && user.access_token){
        let role = user.role;
        if(role.indexOf(type) > -1) return true;
        else return false;
    }else{
        return false;
    }
}
export function isLoged() {
    return localStorage.getItem('user') ? true : false;
}

export function checkRole(type) {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user && user.access_token){
        let role = user.role;
        if(role.indexOf(type) > -1) return true;
        else return false;
    }else{
        return false;
    }
}
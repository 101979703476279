export function getTitle(uri){
    switch (uri) {
        case 'lop-hoc-video':
            return "Lớp học video";
        case 'course':
        case 'text-course':
            return "Chi tiết lớp học";
        case 'lop-hoc-van-ban':
            return "Lớp học văn bản";
        case '/dashboard/user':
            return 'Quản lý người dùng';
        case '/dashboard/user/add':
            return 'Thêm người dùng';
        case '/dashboard/user/del':
            return 'Người dùng đã xóa';
        case '/dashboard/video-class':
            return 'Lớp học video';
        case '/dashboard/video-class/add':
            return 'Tạo lớp học video';
        default:
            return "";
    }
}
import React, { Component } from 'react';
import QuizForm from './QuizForm';
import QuizFormResult from './QuizFormResult';
import { connect } from 'react-redux';
import { classActions } from '../../_actions/class.actions';

class AddQuiz extends Component {
  constructor(props){
  	super(props);
  	this.state = {
  		data : []
  	}
  }

  setResult = (values) =>{
	  this.setState({
          data:{
            quiz : JSON.stringify(values, null, 2)
          }
      })
      const dataQuiz = {quiz : JSON.stringify(values, null, 2)};
      const { dispatch } = this.props;
        dispatch(classActions.editClassText(this.props.match.params.id, dataQuiz, true))

      
  }

  render() {
      
    return (
      <div className="App">
       	<QuizForm onSubmit={this.setResult} />
       	<QuizFormResult result={this.state.data.quiz}/>
         
      </div>
    );
  }
}

function mapStateToProps(state) {
    const { updating } = state.getClass;
    return {
        updating
    };
  }
  
  export default connect(mapStateToProps)(AddQuiz);
import * as React from 'react';
import MetaTags from 'react-meta-tags';
import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { commentActions } from '../../_actions/comment.actions';
import Chip from '@material-ui/core/Chip';
import { convertFromRaw, EditorState } from 'draft-js';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop:100,
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '50%',
        flexShrink: 0,
        fontWeight:450
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },
      subheading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '70%',
        flexShrink: 0,
      },
      subsecondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },
    })

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);


class CommentClassPending extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        open: false,
        expanded: null,
    }
  }
  componentDidMount() {
    this.props.dispatch(commentActions.getAllCommentClassPending());
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
 
  handleClickOpen = (id) => {
    this.setState(
      { 
        open: true,
        id: id, 
      });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDelete(id){
    this.setState({ open: false });
    this.props.dispatch(commentActions.deleteCommentClass(id));
  }

  handleActive(id){
    this.props.dispatch(commentActions.activeCommentClass(id));
  }
//   handleGetSectionName(id){
//     let s = this.props.menu.items.filter(val=>val.id === id);
//     let name='';
//     s.map(val=>(
//         name = val.name
//     ))
//     return name;
//   }
  render() {
    const { getComment } = this.props;
    return(
      <div>
        <MetaTags>
                <title>Quản lý Comment - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
               
    <MaterialTable
    columns={[
      { title: 'id', field: 'id'},
      { title: 'Người comment', field: 'user',
        render: rowData=>{
          return(
              <p>
              {rowData.user === undefined ? '' : rowData.user.name}
              </p>
          )
        }
      },
      { title: 'Nội dung', 
        field: 'body',
        render: rowData=>{
          return(
              EditorState.createWithContent(convertFromRaw(JSON.parse(rowData.body))).getCurrentContent().getBlocksAsArray().map(v=>(
                  v.getText()
              ))
          )
        }
      },
      { title: 'Ngày đăng', field: 'created_at'},
      
    { 
        title: 'Tình trạng', 
        field: 'publish',
        render: rowData =>{
            return(
                rowData.publish === 1 ? <Chip label="đã kích hoạt" color="primary"/> : <Chip label="Chưa kích hoạt" color="secondary"/>
            )
        }
    },
    ]}
    data={getComment.items}
    title="Danh sách comment lớp học chờ duyệt"
    isLoading = {getComment.loading || getComment.deleting || getComment.updating}
    
    actions={[
      {
        icon: 'done',
        tooltip: 'Kích hoạt',
        onClick: (event, rowData) => {
          // alert('You clicked user ' + rowData.id)
          this.handleActive(rowData.id)
        },
        iconProps: {
          style: {
            fontSize: 20,
            color: '#1e88e5',
          },
        },
      },      
      {
        icon: 'delete',
        tooltip: 'Xóa',
        onClick: (event, rowData) => {
          // alert('You clicked user ' + rowData.id)
          this.handleClickOpen(rowData.id)
        },
        iconProps: {
          style: {
            fontSize: 20,
            color: '#cb3837',
          },
        },
      },
    ]}
    
    options={{
      columnsButton: true,
      exportButton: true,
      actionsColumnIndex: -1,
      // selection: true,
    }}
    localization={{
      pagination: {
        labelDisplayedRows: '{from}-{to} trong {count}', // {from}-{to} of {count}
        labelRowsPerPage: 'Số bản ghi trên trang:', // Rows per page:
        firstAriaLabel: 'Trang đầu', // First Page
        firstTooltip: 'Trang đầu', // First Page
        previousAriaLabel: 'Trang trước', // Previous Page
        previousTooltip: 'Trang trước', // Previous Page
        nextAriaLabel: 'Trang tiếp', // Next Page
        nextTooltip: 'Trang tiếp', // Next Page
        lastAriaLabel: 'Trang cuối', // Last Page
        lastTooltip: 'Trang cuối', // Last Page
      },
      toolbar: {
        nRowsSelected: '{0} bản ghi được chọn', // {0} row(s) selected
        showColumnsTitle: 'Hiển thị cột', // Show Columns
        showColumnsAriaLabel: 'Hiển thị cột', // Show Columns
        exportTitle: 'Xuất', // Export
        exportAriaLabel: 'Xuất', // Export
        exportName: 'Xuất ra CSV', // Export as CSV
        searchTooltip: 'Tìm kiếm', // Search
      },
      header: {
        actions: 'Hành động', // Actions
      },
      body: {
        emptyDataSourceMessage: 'Không có dữ liệu', // No records to display
        filterRow: {
          filterTooltip: 'Lọc', // Filter
        },
      },
    }}
  />
  
    
  <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Xóa comment
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
            
              Bạn chắc chắn muốn xóa comment này?
            </Typography>
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleClose} color="primary">
              Bỏ qua
            </Button>
            <Button onClick={this.handleDelete.bind(this, this.state.id)} variant="contained" color="secondary">
              Xóa
            </Button>
          </DialogActions>
        </Dialog>
        </div>
  );
  }
}

function mapStateToProps(state) {
    const { getComment } = state;
    return {
        getComment
    };
  }
  export default connect(mapStateToProps)(withStyles(styles)(CommentClassPending));


export const FilePondLanguage = {
    labelFileProcessing: 'Đang tải',
    labelFileProcessingComplete: 'Đã tải xong',
    labelInvalidField: 'Trường chứa file không hợp lệ',
    labelFileWaitingForSize: 'Chờ thông tin kích thước file',
    labelFileSizeNotAvailable: 'Kích thước file không cho phép',
    labelFileLoadError: 'Lỗi trong quá trình nạp',
    labelFileProcessingAborted: 'Đã hủy tải lên',
    labelFileProcessingError: 'Lỗi trong quá trình tải',
    labelFileProcessingRevertError: 'Lỗi trong quá trình quay lại',
    labelFileRemoveError: 'Lỗi trong quá trình loại bỏ',
    labelTapToCancel: 'Nhấp để hủy',
    labelTapToRetry: 'Nhấn để thử lại',
    labelTapToUndo: 'Nhấn để hoàn tác',
    labelButtonRemoveItem: 'Loại bỏ',
    labelButtonAbortItemLoad: 'Hủy bỏ',
    labelButtonRetryItemLoad: 'Thử lại',
    labelButtonAbortItemProcessing: 'Bỏ qua',
    labelButtonUndoItemProcessing: 'Hoàn tác',
    labelButtonRetryItemProcessing: 'Thử lại',
    labelButtonProcessItem: 'Tải lên'
}
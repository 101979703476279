import * as React from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { classActions } from '../../_actions/class.actions';
import { menuActions } from '../../_actions/menu.actions';
import Chip from '@material-ui/core/Chip';
import config from "react-global-configuration";

const styles = theme => ({
  root: {
      flexGrow: 1,
      width: '100%',
      marginTop:100,
    },
  })

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

class TextClassDeleted extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        open: false,
        id: [],
        isLoaded: false,
    }
  }
  componentDidMount() {
    this.props.dispatch(classActions.classTextDeleted());
    this.props.dispatch(menuActions.getAllAdmin());
  }
  
  handleClickOpen = (id) => {
    this.setState(
      { 
        open: true,
        id: id, 
      });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDelete(id){
    this.setState({ open: false });
    this.props.dispatch(classActions.forceDel(id));
  }
  handleRestore(id){
    this.props.dispatch(classActions.restore(id));
  }
  handleGetSectionName(id){
    let s = this.props.menu.items.filter(val=>val.id === id);
    let name='';
    s.map(val=>(
        name = val.name
    ))
    return name;
  }
  render() {
    const { isLoaded } = this.state;
    const { getClass } = this.props;
    return(
      <div>
        <p>
         <Button  size="small" style={{textTransform:'initial'}} component={Link}  to="/dashboard/text-class" title="Kích hoạt">Kích hoạt</Button>
         &nbsp;&nbsp;
         <Button  size="small" style={{textTransform:'initial'}} component={Link}  to="/dashboard/text-class/del" title="Đã xóa">Đã xóa</Button>
         &nbsp;&nbsp;
        </p>
    <MaterialTable
    //isLoading={true}
    columns={[
      { title: 'Tên lớp học', field: 'title', defaultSort: 'asc',
      render: rowData=>{
        return(
          <Link to={"/dashboard/text-class/"+rowData.id} style={{textDecoration:'none'}}>{rowData.title}</Link>
        )
      }
    },
    { title: 'Giáo viên', field: 'teacher_name',
      render: rowData=>{
        return(
            <p>
            {rowData.user === undefined ? '' : rowData.user.name}
            </p>
        )
      }
    },
    { title: 'Thể loại', 
      field: 'section',
      render: rowData=>{
          return(
              <p>
              {this.handleGetSectionName(rowData.section)} / {this.handleGetSectionName(rowData.category)}
              </p>
          )
      }
   },
    { 
        title: 'Ảnh lớp học', 
        field: 'class_image',
        render: rowData =>{
            return(
              <img src={(rowData.class_image !== null) ? config.get('apiImg')+rowData.class_image : "/no-preview.jpg"} width="100px" alt={rowData.title}/>
            )
        }
  },
  { 
      title: 'Tình trạng', 
      field: 'published',
      render: rowData =>{
          return(
              rowData.published === 1 ? <Chip label="Xuất bản" color="primary"/> : <Chip label="Chưa xuất bản" color="secondary"/>
          )
      }
  },
    ]}
    data={getClass.items}
    title="Danh sách lớp học đã xóa"
    isLoading = {getClass.loading || getClass.restoring || getClass.deleting}
    actions={[
      {
        icon: 'restore',
        tooltip: 'Khôi phục',
        onClick: (event, rowData) => {
            this.handleRestore(rowData.id)
        },
        iconProps: {
          style: {
            fontSize: 30,
            color: 'green',
          },
        },
      },
      {
        icon: 'clear',
        tooltip: 'Xóa vĩnh viễn',
        onClick: (event, rowData) => {
          // alert('You clicked user ' + rowData.id)
          this.handleClickOpen(rowData.id)
        },
        iconProps: {
          style: {
            fontSize: 30,
            color: '#cb3837',
          },
        },
      },
    ]}
    options={{
      columnsButton: true,
      exportButton: true,
      actionsColumnIndex: -1,
    }}
    localization={{
      pagination: {
        labelDisplayedRows: '{from}-{to} trong {count}', // {from}-{to} of {count}
        labelRowsPerPage: 'Số bản ghi trên trang:', // Rows per page:
        firstAriaLabel: 'Trang đầu', // First Page
        firstTooltip: 'Trang đầu', // First Page
        previousAriaLabel: 'Trang trước', // Previous Page
        previousTooltip: 'Trang trước', // Previous Page
        nextAriaLabel: 'Trang tiếp', // Next Page
        nextTooltip: 'Trang tiếp', // Next Page
        lastAriaLabel: 'Trang cuối', // Last Page
        lastTooltip: 'Trang cuối', // Last Page
      },
      toolbar: {
        nRowsSelected: '{0} bản ghi được chọn', // {0} row(s) selected
        showColumnsTitle: 'Hiển thị cột', // Show Columns
        showColumnsAriaLabel: 'Hiển thị cột', // Show Columns
        exportTitle: 'Xuất', // Export
        exportAriaLabel: 'Xuất', // Export
        exportName: 'Xuất ra CSV', // Export as CSV
        searchTooltip: 'Tìm kiếm', // Search
      },
      header: {
        actions: 'Hành động', // Actions
      },
      body: {
        emptyDataSourceMessage: 'Không có dữ liệu', // No records to display
        filterRow: {
          filterTooltip: 'Lọc', // Filter
        },
      },
    }}
    // isLoading = {false}
  />
    
  <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Xóa vĩnh viễn lớp học
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
            {(isLoaded) ? <div className="cssload-loader">
                    <div className="cssload-inner cssload-one"></div>
                    <div className="cssload-inner cssload-two"></div>
                    <div className="cssload-inner cssload-three"></div>
                </div> : ''}
              Việc xóa vĩnh viễn sẽ không khôi phục lại được. Bạn chắc chắn vẫn muốn xóa lớp học này?
            </Typography>
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleClose} color="primary">
              Bỏ qua
            </Button>
            <Button onClick={this.handleDelete.bind(this, this.state.id)} variant="contained" color="secondary">
              Xóa
            </Button>
          </DialogActions>
        </Dialog>
        </div>
  );
  }
}

function mapStateToProps(state) {
  const { getClass, menu } = state;
  return {
    getClass, menu
  };
}
export default connect(mapStateToProps)(withStyles(styles)(TextClassDeleted));


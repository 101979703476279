import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import { HeadPrivate, FooterPrivate } from '../Layout';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Card, CardActionArea, CardMedia, CardContent, CardActions, Button, Chip,
  Tabs, Tab
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { PlayCircleOutline } from '@material-ui/icons';
import { connect } from 'react-redux';
import { authHeader } from '../_helpers/auth-header';
import { classActions } from '../_actions/class.actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Timestamp from 'react-timestamp';

const styles = theme => ({
  root: {
      flexGrow: 1,
      marginTop:100,
    },
    card: {
      maxWidth: 345,
    },
    section:{
      marginBottom: 20,
  },
  media: {
    objectFit: 'cover',
    width: '100%',
    maxHeight:150,
  },
  bigMedia: {
      objectFit: 'cover',
      width: '100%',
      height: 250,
    },
  // chip: {
  //     width:'100%',
  //     color: '#777',
  //     borderRadius: 0,
  //   },
  caption:{
      color: '#777',
  },
  teacherName: {
      color: '#777',
      textDecoration: 'none',
      fontWeight: 450,
      cursor: 'pointer',
      '&:hover': {
          color: 'red',
          textDecoration: 'none',
      },
  },
   
  iconTime: {
    fontSize: 13,
    position: 'relative',
    bottom: -2,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  center:{
      textAlign: 'center',
  },
  menuClass:{
      textAlign: 'right',
      paddingRight: 20,
  },
  btnPlay: {
      position: 'absolute',
      top: '40%',
      left: '45%',
      fontSize: 60,
      '&:hover':{
          color: '#D50000',
      }
  },
 
    linkMenuLeft: {
      color: '#777',
      textDecoration: 'none',
      '&:hover':{
          color: '#1e88e5',
      }
  },
    linkListTeacher: {
        color: '#1565C0',
        textDecoration: 'none',
        marginLeft: 20,
        fontWeight: 'normal',
        '&:hover':{
            color: '#d50000',
        }
    },
    ListItem:{
        listStyleType: 'none',
        margin: 0,
        padding: 0
    },
    listItemText:{
        margin: '7px 0',
    },
    linkSection:{
        color: '#666',
        textDecoration: 'none',
        '&:hover':{
            color: '#d50000',
        }
    },
    inline: {
      display: 'inline',
    },
    titleClass:{
      color: '#444',
      fontSize:'1.1em',
      fontWeight:450,
      textDecoration: 'none',
      marginBottom: 30,
      '&:hover':{
          color: '#1e88e5',
      }
     },
     avatarTeacher:{
      height:60,
      width:60
  },
  chip: {
      fontSize:12,
      height:20,
      borderRadius: 3,
      marginRight:10,
  },
  box2: {
    padding: '35px 0',
    border: '1px dashed #999',
}
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "red" }}
      onClick={onClick}
    />
  );
}

class HomeMember extends React.Component{
  state = {
    value: 0,
    loading: true,
    uInfo: [],
    relatedSkill: [],
    classRelated: [],
  };
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'));
        axios.get(config.get("apiDomain")+'userFrontend/info/'+user.id)
        .then(res => {
            this.setState({
                uInfo: res.data.name,
            });
            if(res.data.skill_related !== null){
                this.setState({
                    relatedSkill: JSON.parse(res.data.skill_related)
                })
            }
        });

        axios.get(config.get("apiDomain")+'user/class-user-related/'+user.id,
        {
          headers: authHeader()
        })
        .then(res => {
            this.setState({
              classRelated: res.data,
              loading: false
            });
        });

    this.props.dispatch(classActions.getAll());
  }

  render(){
    const { classes, getClass } = this.props;
    const classFeature = getClass.items.slice(0,12);
    const classTrending = getClass.items.filter(val=>val.filter === 2).slice(0,12);
    
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 320,
          settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
        },
        {
          breakpoint: 1024,
          settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
        }
      ],
      centerPadding: 30,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    var settings2 = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 320,
          settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
        },
        {
          breakpoint: 1024,
          settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
        }
      ],
      centerPadding: '0',
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return (
      <div className={classes.root}>
        <MetaTags>
          <title>Trang chủ thành viên  - LearnSkill</title>
          <meta name="description" content="Some description." />
          <meta property="og:title" content="MyApp" />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
        <HeadPrivate />
        <Grid container style={{padding:'0 10px'}}>
          <Grid item md={1}>&nbsp;</Grid>
          <Grid item xs={12} sm={12} md={10}>
          {this.state.loading || getClass.loading ? (
              <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
            ) : (
              <React.Fragment>
                <Typography variant="body2" gutterBottom style={{fontSize:22}}>
                  Xin chào <span style={{fontWeight:450,color:'#1e88e5'}}>{this.state.uInfo}</span> Hãy bắt đầu với những bài học đầu tiên
                </Typography>

                <Slider {...settings}>
                {this.state.classRelated.map((val, index)=>(
                  
                    val !== null &&
                      <Paper key={index}>
                    <Grid container>
                      <Grid item xs={12} sm={5} className={classes.videoThumbnail}>
                        <Link to={val.isVideo === 1 ? "/video-course/"+val.slug : "/text-course/"+val.slug}>
                          <img src={(val.class_image !== null) ? config.get('apiImg')+val.class_image : "/no-preview.jpg"} style={{width:'100%'}} alt={val.title}/>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={7} style={{padding:15}}>
                        <Typography variant="h3" style={{fontSize:17,marginBottom:10}} className={classes.titleClass} component={Link} to={val.isVideo === 1 ? "/video-course/"+val.slug : "/text-course/"+val.slug}>
                          {val.title}
                        </Typography>
                        <span style={{color:'#888',fontSize:13}}>Loại lớp học: </span> 
                        <Chip
                          label={val.isVideo === 1 ? 'Video' : 'Văn bản'}
                          className={classes.chip}
                          color={val.isVideo === 1 ? 'secondary' : 'primary'}
                        />
                        <div style={{marginTop:15}}>
                          <Link style={{fontSize:14}} to={"/user/"+val.user.id+'/'+val.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{val.user.name}</Link>
                        </div>
                        
                      </Grid>
                    </Grid>
                   
                  </Paper>
                
                ))}
                    
                    <div className={classes.box2}>
                      <Typography variant="body2" gutterBottom align="center" style={{fontWeight:450,fontSize:18}}>Khi bạn tham gia 1 lớp học, nó sẽ xuất hiện ở đây tại thời điểm tiếp theo bạn ghé thăm Learn Skill</Typography>
                    </div>
                </Slider>
              <Grid container style={{marginTop:50}}>
                <Grid item xs={12} sm={8}>
                <Typography variant="body2" gutterBottom style={{fontSize:26}}>Đề xuất với bạn</Typography>
                </Grid>
                <Grid item xs={12} sm={4} align="right">
                  <Button variant="outlined" size="small" style={{textTransform:'initial'}}>Xem tất cả</Button>
                </Grid>
              </Grid>
              
              <Slider {...settings2}>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography variant="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography variant="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                </Slider>

                <Grid container style={{marginTop:50}}>
                  <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom style={{fontSize:26}}>Lĩnh vực bạn quan tâm</Typography>
                  
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        style={{paddingLeft:5,paddingRight:5}}
                      >
                      {this.state.relatedSkill.map(val=>(
                        <Tab label={val.name} style={{textTransform:'initial'}} key={val.id}/>
                      ))}
                      </Tabs>
                  </Grid>
                </Grid>
              
              <Slider {...settings2}>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography variant="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography variant="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                </Slider>

                <Grid container style={{marginTop:50}}>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body2" gutterBottom style={{fontSize:26}}>Mới nhất trên Learn Skill</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} align="right">
                    <Button variant="outlined" size="small" style={{textTransform:'initial'}}>Xem tất cả</Button>
                  </Grid>
                </Grid>
              
              <Slider {...settings2}>
              {classFeature.map((cf,index) =>(
                  <Card key={index}>
                      <CardActionArea className={classes.videoThumbnail}>
                          <Link to={cf.isVideo === 1 ? "/video-course/"+cf.slug : "/text-course/"+cf.slug} title={cf.title}>
                          {cf.isVideo === 1 &&
                              <PlayCircleOutline color="primary" className={classes.btnPlay} />
                          }
                              <img src={(cf.class_image !== null) ? config.get('apiImg')+cf.class_image : "/no-preview.jpg"} className={classes.media} alt={cf.title}/>
                          </Link>
                      </CardActionArea>
                      <CardActions style={ {padding:'2px'} }>
                          <Grid container>
                              <Grid item xs={6}>
                                  <Typography className={classes.caption} variant="caption" align="left">
                                  {cf.countViews} người học
                                  </Typography>
                              </Grid>
                              {cf.isVideo === 1 &&
                              <Grid item xs={6}>
                                  <Typography className={classes.caption} variant="caption" align="right">
                                  
                                  <Timestamp date={new Date(cf.created_at)} relative autoUpdate />
                                  </Typography>
                              </Grid>
                              }
                              <Grid item xs={12}>
                              <Typography variant="body1" gutterBottom>
                                  <Link to={cf.isVideo === 1 ? "/video-course/"+cf.slug : "/text-course/"+cf.slug} className={classes.titleClass}>{cf.title}</Link>
                              </Typography>
                              <span style={{color:'#888',fontSize:13}}>Loại lớp học: </span>  
                              <Chip
                                  label={cf.isVideo === 1 ? 'Video' : 'Văn bản'}
                                  className={classes.chip}
                                  color={cf.isVideo === 1 ? 'secondary' : 'primary'}
                              />
                              <Typography style={{marginTop:20}}>
                                  <Link to={"/user/"+cf.user.id+'/'+cf.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{cf.user.name}</Link> 
                              </Typography>
                                  
                              </Grid>
                          </Grid>
                      </CardActions>
                  </Card>
              ))}
                </Slider>

                <Grid container style={{marginTop:50}}>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body2" gutterBottom style={{fontSize:26}}>Xu hướng trên Learn Skill</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} align="right">
                    <Button variant="outlined" size="small" style={{textTransform:'initial'}}>Xem tất cả</Button>
                  </Grid>
                </Grid>
              
              <Slider {...settings2}>
              {classTrending.map((cf,index) =>(
                  <Card key={index}>
                      <CardActionArea className={classes.videoThumbnail}>
                          <Link to={cf.isVideo === 1 ? "/video-course/"+cf.slug : "/text-course/"+cf.slug} title={cf.title}>
                          {cf.isVideo === 1 &&
                              <PlayCircleOutline color="primary" className={classes.btnPlay} />
                          }
                              <img src={(cf.class_image !== null) ? config.get('apiImg')+cf.class_image : "/no-preview.jpg"} className={classes.media} alt={cf.title}/>
                          </Link>
                      </CardActionArea>
                      <CardActions style={ {padding:'2px'} }>
                          <Grid container>
                              <Grid item xs={6}>
                                  <Typography className={classes.caption} variant="caption" align="left">
                                  <Timestamp date={new Date(cf.created_at)} relative autoUpdate />
                                  </Typography>
                              </Grid>
                              {cf.isVideo === 1 &&
                              <Grid item xs={6}>
                                  <Typography className={classes.caption} variant="caption" align="right">
                                  
                                  <Icon className={classes.iconTime}>schedule</Icon> 3h30'
                                  </Typography>
                              </Grid>
                              }
                              <Grid item xs={12}>
                              <Typography variant="body1" gutterBottom>
                                  <Link to={cf.isVideo === 1 ? "/video-course/"+cf.slug : "/text-course/"+cf.slug} className={classes.titleClass}>{cf.title}</Link>
                              </Typography>
                              <span style={{color:'#888',fontSize:13}}>Loại lớp học: </span>  
                              <Chip
                                  label={cf.isVideo === 1 ? 'Video' : 'Văn bản'}
                                  className={classes.chip}
                                  color={cf.isVideo === 1 ? 'secondary' : 'primary'}
                              />
                              <Typography style={{marginTop:20}}>
                                  <Link to={"/user/"+cf.user.id+'/'+cf.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{cf.user.name}</Link> 
                              </Typography>
                                  
                              </Grid>
                          </Grid>
                      </CardActions>
                  </Card>
              ))}
                </Slider>

                <Grid container style={{marginTop:50}}>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body2" gutterBottom style={{fontSize:26}}>Tốt nhất tháng này</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} align="right">
                    <Button variant="outlined" size="small" style={{textTransform:'initial'}}>Xem tất cả</Button>
                  </Grid>
                </Grid>
              
              <Slider {...settings2}>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography variant="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography variant="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image="/no-preview.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{padding:10}}>
                      <Typography component="body2" style={{color:'#777',marginBottom:20}}>
                        12 người học
                      </Typography>
                      <Typography gutterBottom variant="h5" style={{fontSize:18}}>
                        Tên lớp học
                      </Typography>
                      
                    </CardContent>
                  <CardActions>
                    <Typography component="p">
                        Tên tác giả
                      </Typography>
                  </CardActions>
                </Card>
                </Slider>

                <Typography variant="h4" style={{marginTop:50,marginBottom:20,fontSize:18}}>
                  Các kỹ năng bạn quan tâm
                  <Button variant="outlined" size="small" style={{textTransform:'initial',marginLeft:20}}>Sửa</Button>
                </Typography>
                  {this.state.relatedSkill.map(val=>(
                    <Chip
                    key={val.id}
                    label={val.name}
                    className={classes.chip}
                    component={Link}
                    to=""
                    clickable
                    style={{
                      fontSize:14,
                      height: 30,
                      padding:5
                    }}
                  />
                  ))}
              </React.Fragment>
            )}
          </Grid>
          <Grid item md={1}>&nbsp;</Grid>
        </Grid>
        <FooterPrivate />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { getClass } = state;
  return {
    getClass
  };
}
export default connect(mapStateToProps)(withStyles(styles)(HomeMember));
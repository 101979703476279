import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tipsActions } from '../../_actions/tips.actions';
import { authHeader } from '../../_helpers/auth-header';
import axios from 'axios';
import config from "react-global-configuration";
import MetaTags from 'react-meta-tags';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { Grid } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../editor.css';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';
import embed from "embed-video";

const styles = theme => ({
    root: {
      position:'relative'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginBottom:10,
      },
      dense: {
        marginTop: 19,
      },
    button: {
      marginTop: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    actionsContainer: {
      marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
      padding: theme.spacing.unit * 3,
    },
    leftIcon: {
      marginRight: theme.spacing.unit,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },
    load: {
      display: 'flex',
      alignItems: 'center',
      flexDirection:'row-reverse',
    },
    wrapper: {
      margin: 5,
      position: 'relative',
    },
    buttonProgress: {
      color: red[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  });
  
  function uploadImageCallBack(file) {
    return new Promise(
      (resolve, reject) => {
        let user = JSON.parse(localStorage.getItem('user'));
        const xhr = new XMLHttpRequest();
        xhr.open('POST', config.get("apiDomain")+"admin/upload-image-tip");
        xhr.setRequestHeader('Authorization', 'Bearer '+user.access_token);
        const data = new FormData();
        data.append('file', file);
        xhr.send(data);
        xhr.addEventListener('load', () => {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        });
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      }
    );
  }
  class TipAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            image:'',
            menuData: [],
            cat: [],
            loading: false,
            submitted: false,
            hasSecError: false,
            hasCatError: false,
            dropCatShow: true,
            imgPreview: '',
            noChange: true,
            content: EditorState.createEmpty(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onContentChange = this.onContentChange.bind(this);
        this.uploadChange = this.uploadChange.bind(this)
    }
    handleChange(event) {
      const { data } = this.state;
      const { name, value } = event.target;
      if(name === 'section'){
        let parent = event.target.value;
        const ca = this.state.menuData.filter(val => val.parent === parseInt(parent));
        this.setState({cat: ca, dropCatShow:false,hasSecError: false})
      
      this.setState({
        noChange: false,
        hasError: false,
          data: {
              ...data,
              [name]: value,
              category: ''
          }
      });
    }else{
      this.setState({
        noChange: false,
        hasError: false,
          data: {
              ...data,
              [name]: value
          }
      });
    }
    // console.log(data)
    
  }
  uploadChange(e) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
      return;
      this.createImage(files[0]);
  }
  createImage(file) {
    const { data } = this.state;
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        imgPreview: e.target.result,
        data: {
          ...data,
          image: e.target.result
      }
      })
    };
    reader.readAsDataURL(file);
  }
    onContentChange = (content) => {
        this.setState({content});
        const { data } = this.state;
        let contentConvert = content.getCurrentContent();
        this.setState({
          noChange: false,
            data: {
                ...data,
                content: JSON.stringify(convertToRaw(contentConvert), null, 4)
            }
        });
    }
  

    handleSubmit(e){
      e.preventDefault();
      this.setState({ submitted: true });
      const { data } = this.state;
      if (!data.section || data.section === "") {
        this.setState({ hasSecError: true, hasCatError: true });
      }else if (!data.category || data.category === "") {
        this.setState({ hasCatError: true, hasSecError: false });
      }else if(!data.title || data.title === ""){
        this.setState({ hasCatError: false, hasSecError: false });
      }
      const { dispatch } = this.props;
      if (data.title && data.section && data.category) {
          dispatch(tipsActions.adminAddTip(data));
      }
    }
    componentDidMount(){
      axios.get(config.get("apiDomain")+"admin/all-menu-tip",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
      .then(res => {
        this.setState({menuData: res.data});
     });
    }
  
    render() {
      const { classes } = this.props;
      const { data, submitted, cat, menuData } = this.state;
      const sec = menuData.filter(val => val.parent === 0);
    
      return (
        <div className={classes.root}>
        <MetaTags>
                <title>Thêm bài mẹo/thủ thuật - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                {this.props.adding &&
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'absolute',top:'48%',left:'48%'}} />
                }
        <form
          ref="form"
          onSubmit={this.handleSubmit}
        >
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={12} align="center">
            <div className={classes.load}>
              <div className={classes.wrapper}>
                
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={this.props.adding || this.state.noChange}
                    className={classes.button}
                    size="small"
                    style={{textTransform:'initial'}}
                  >Thêm bài mẹo/thủ thuật 
                </Button>
                {this.props.adding && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
                <TextField
                    fullWidth
                    className={classes.textField}
                    label="Tên bài mẹo/thủ thuật"
                    name="title"
                    defaultValue=""
                    onChange={this.handleChange}
                    helperText={(submitted && (data.title === undefined || data.title === '')) ? "Tên bài mẹo/thủ thuật không được để trống": ""}
                    error = {(submitted && (data.title === undefined || data.title === '')) ? true : false}
                />
                 <h5>Nội dung bài mẹo/thủ thuật</h5>
                 {submitted && this.state.content.getCurrentContent().hasText() === false ? <Typography style={{color:'red'}}>Nội dung không được để trống</Typography> : ''}
                <Editor
                editorState={this.state.content}
                onEditorStateChange={this.onContentChange}
                ref="editor"
                wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    toolbar={{
                      image: {
                        uploadCallback: uploadImageCallBack,
                        previewImage: true,
                        alt: { present: true, mandatory: true },
                        defaultSize: {height:'100%',width: '100%'}
                      },
                      link: {
                        linkCallback: params => ({ ...params })
                      },
                      embedded: {
                        embedCallback: link => {
                          const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                          return (detectedSrc && detectedSrc[1]) || link;
                        }
                      }
                    }}
                    hashtag={{}}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl style={{marginBottom:10,display:'inherit'}} error={this.state.hasSecError}>
                <InputLabel htmlFor="section-native-helper">Lĩnh vực cha*</InputLabel>
                <NativeSelect
                    fullWidth
                    defaultValue=""
                    onChange={this.handleChange}
                    input={<Input name="section" id="section-native-helper" />}
                >
                    <option value="" />
                    {sec.map(val=>(
                      <option value={val.id} key={val.id}>{val.name}</option>
                    ))}
                    
                    
                </NativeSelect>
                {this.state.hasSecError && <FormHelperText>Bạn phải chọn 1 lĩnh vực cha</FormHelperText>}
            </FormControl>
            <FormControl style={{marginBottom:10,display:'inherit'}} error={this.state.hasCatError}>
                <InputLabel htmlFor="category-native-helper">Lĩnh vực con*</InputLabel>
                <NativeSelect
                    fullWidth
                    defaultValue=""
                    disabled = {this.state.dropCatShow}
                    onChange={this.handleChange}
                    input={<Input name="category" id="category-native-helper" />}
                >
                    <option value="" />
                    {cat.map(val=>(
                      <option value={val.id} key={val.id}>{val.name}</option>
                    ))}
                    
                </NativeSelect>
                {this.state.hasCatError && <FormHelperText>Bạn phải chọn 1 lĩnh vực con</FormHelperText>}
            </FormControl>
            <Typography>Ảnh đại diện lớp học</Typography>
              <input
                accept="image/*"
                className={classes.input}
                id="class-image"
                multiple
                type="file"
                name="class_image"
                onChange={this.uploadChange}
              />
              <label htmlFor="class-image">
              {this.state.imgPreview !== '' &&
                <img src={this.state.imgPreview} alt="preview" style={{marginBottom:10,width:100}}/>
                }
                <br />
                <Button variant="contained" color="secondary" size="small" component="span" className={classes.button} style={{marginBottom:20}}>
                  Upload
                  <CloudUploadIcon className={classes.rightIcon} />
                </Button>
              </label>
            </Grid>
          </Grid>
          </form>
        </div>
      );
    }
  }
  
  TipAdd.propTypes = {
    classes: PropTypes.object,
  };
  function mapStateToProps(state) {
    const { adding} = state.tips;
    return {
        adding
    };
  }
  
  export default connect(mapStateToProps)(withStyles(styles)(TipAdd));
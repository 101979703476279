import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { userActions } from '../../_actions/user.actions';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import MetaTags from 'react-meta-tags';
import { Button, Paper, Grid } from '@material-ui/core';
 import { withStyles } from '@material-ui/core/styles';
 import TextField from '@material-ui/core/TextField';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import Update from '@material-ui/icons/Update';
import red from '@material-ui/core/colors/red';
import { convertFromRaw, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';


 const styles = theme => ({
     root:{
        marginTop:20,
     },
     leftIcon: {
        marginRight: theme.spacing.unit,
      },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginBottom:20,
      },
      dense: {
        marginTop: 19,
      },
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
 });
class UpdateUser extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            data: [],
            loading: false,
            submitted: false,
            showPassword: false,
            noChange: true,
            info: EditorState.createEmpty(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }
    onEditorStateChange = (info) => {
        this.setState({info, noChange:false});
        const { data } = this.state;
        let infoConvert = info.getCurrentContent();
        this.setState({
            data: {
                ...data,
                info: JSON.stringify(convertToRaw(infoConvert), null, 4)
            }
        });
      };
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
      };
    handleChange(event) {
        const { name, value } = event.target;
        const { data } = this.state;
        this.setState({
            noChange:false,
            data: {
                ...data,
                [name]: value
            }
        });
    }
    
      handleSubmit(event) {
        event.preventDefault();
         const { data } = this.state;
            const { dispatch } = this.props;
            dispatch(userActions.editProfile(this.props.match.params.id, data))
      };
    componentDidMount(){
        this.setState({loading: true})
        let uInfo = JSON.parse(localStorage.getItem('user'))
        axios.get(config.get("apiDomain")+'admin/get-user/'+this.props.match.params.id,
        {
          headers: {
              'Authorization': 'Bearer '+uInfo.access_token,
          }
        }
      )
        .then(res => {
          this.setState({user: res.data});
          this.setState({loading: false});
          if(res.data.info !== null){
            let convert = convertFromRaw(JSON.parse(res.data.info));
            let editor = EditorState.createWithContent(convert);
            this.setState({info: editor})
          }
       });
       
      }
    render(){
        const { user, loading, info, submitted } = this.state;
        const { classes, updating } = this.props;
        return(
            <div className={classes.root}>
            <MetaTags>
                <title>Cập nhật người dùng - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                {loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                    ) : (
                
                <form name="form" className={classes.form} onSubmit={this.handleSubmit} autoComplete="off">
                    <Button type="submit" size="small" variant="outlined" color="default" style={{textTransform:'initial',marginBottom:30}} component={Link} to="/dashboard/user">
                    <KeyboardBackspace className={classes.leftIcon} style={{fontSize:18}}/>
                      Quay lại quản lý người dùng
                    </Button>
                    <Paper style={{padding:20}}>
                    <Grid container spacing={32}>
                        <Grid item xs={12} style={{marginRight:10}}>
                            <div className={classes.load}>
                                <div className={classes.wrapper}>
                                <Button
                                    variant="contained" 
                                    color="secondary"
                                    size="small"
                                    disabled={updating || this.state.noChange}
                                    type="submit"
                                    style={{textTransform:'initial'}}
                                >
                                    <Update className={classes.leftIcon} style={{fontSize:18}} />
                                    Cập nhật
                                </Button>
                                {updating && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                label="Họ và tên"
                                name="name"
                                defaultValue={user.name}
                                onChange={this.handleChange}
                                helperText={submitted && !user.name ? "Họ và tên không được để trống": ""}
                                error = {submitted && !user.name ? true : false}
                            />
                            Thông tin bản thân
                            <Editor
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          toolbarOnFocus
                          toolbarClassName="toolbar-class"
                          editorState={info}
                          onEditorStateChange={this.onEditorStateChange}
                          toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
                            inline: {
                              options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                              bold: { className: 'bordered-option-classname' },
                              italic: { className: 'bordered-option-classname' },
                              underline: { className: 'bordered-option-classname' },
                              strikethrough: { className: 'bordered-option-classname' },
                              code: { className: 'bordered-option-classname' },
                            },
                            blockType: {
                              className: 'bordered-option-classname',
                            },
                            fontSize: {
                              className: 'bordered-option-classname',
                            },
                            fontFamily: {
                              className: 'bordered-option-classname',
                            },
                          }}
                        />
                            <TextField
                                fullWidth
                                className={classes.textField}
                                label="Tài khoản Facebook"
                                name="fb_url"
                                defaultValue={user.fb_url}
                                onChange={this.handleChange}
                            />
                            
                            
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                                fullWidth
                                className={classes.textField}
                                label="Tài khoản Google"
                                name="gg_url"
                                defaultValue={user.gg_url}
                                onChange={this.handleChange}
                            />
                            <TextField
                                fullWidth
                                className={classes.textField}
                                label="Tài khoản Youtube"
                                name="yt_url"
                                defaultValue={user.yt_url}
                                onChange={this.handleChange}
                            />
                        <TextField
                                fullWidth
                                className={classes.textField}
                                label="Tài khoản Github"
                                name="gh_url"
                                defaultValue={user.gh_url}
                                onChange={this.handleChange}
                            />
                            <TextField
                                fullWidth
                                className={classes.textField}
                                label="Website cá nhân"
                                name="web_url"
                                defaultValue={user.web_url}
                                onChange={this.handleChange}
                            />
                            
                        </Grid>
                    </Grid>
                    </Paper>
                </form>
            
            )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { users } = state;
    const { updating } = state.editProfile;
    return {
        users, updating
    };
  }
  
  export default connect(mapStateToProps)(withStyles(styles)(UpdateUser));
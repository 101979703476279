import * as React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircle';
import { connect } from 'react-redux';
import { userActions } from '../../_actions/user.actions';
import UserAvatar from 'react-user-avatar';
import { history } from '../../_helpers/history';
import Chip from '@material-ui/core/Chip';
import config from "react-global-configuration";

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop:100,
      },
    })

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);


class User extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        open: false,
    }
  }
  componentDidMount() {
    this.props.dispatch(userActions.getAll());
  }
  handleClickOpen = (id) => {
    this.setState(
      { 
        open: true,
        id: id, 
      });
  };
 
  handleBan = (id) => {
    this.props.dispatch(userActions.banUser(id));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDelete(id){
    this.setState({ open: false });
    this.props.dispatch(userActions.delete(id));
  }

  render() {
    const { isLoaded } = this.state;
    const { users } = this.props;
    return(
      <div>
        <MetaTags>
                <title>Quản lý người dùng - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
        <p align="right">
          <Button  size="small" style={{textTransform:'initial'}}  component={Link} to="/dashboard/user/admin" title="admin">Admin</Button>
          &nbsp;&nbsp;
          <Button  size="small" style={{textTransform:'initial'}}  component={Link} to="/dashboard/user/teacher" title="Giáo viên">Giáo viên</Button>
          &nbsp;&nbsp;
          <Button  size="small" style={{textTransform:'initial'}}  component={Link} to="/dashboard/user/student" title="người học">Người học</Button>
          &nbsp;&nbsp;
        </p>
        <p>
        <Button variant="contained" color="primary" size="small" style={{textTransform:'initial'}}  component={Link} to="/dashboard/user/add">
          <AddIcon /> Thêm người dùng
        </Button>
         &nbsp;&nbsp;
         <Button  size="small" style={{textTransform:'initial'}}  component={Link} to="/dashboard/user" title="Kích hoạt">Kích hoạt</Button>
         &nbsp;&nbsp;
         <Button  size="small" style={{textTransform:'initial'}}  component={Link} to="/dashboard/user/not-active" title="Chưa kích hoạt">Chưa kích hoạt</Button>
         &nbsp;&nbsp;
         <Button  size="small" style={{textTransform:'initial'}}  component={Link} to="/dashboard/user/del" title="Đã xóa">Đã xóa</Button>
         &nbsp;&nbsp;
        </p>
    <MaterialTable
    //isLoading={true}
    columns={[
      { title: 'Họ tên', field: 'name', defaultSort: 'asc',
      render: rowData=>{
        return(
          <Link to={"/user/"+rowData.id+'/'+rowData.name.replace(/\s+/g, '-')} target='_blank' style={{textDecoration:'none'}}>{rowData.name}</Link>
        )
      }
    },
      { title: 'Email', field: 'email' },
      { 
        title: 'Avatar', 
        field: 'avatar', 
        render: rowData =>{
        return(
          <UserAvatar size="50" name={rowData.name} src={config.get('apiImg')+rowData.avatar}/>
        )
      }
    },
    { 
      title: 'Tình trạng', 
      field: 'active',
      render: rowData =>{
          return(
              rowData.active === 1 ? <Chip label="kích hoạt" color="primary"/> : <Chip label="Chưa kích hoạt" color="secondary"/>
          )
      }
  },
    ]}
    data={users.items}
    title="Danh sách người dùng"
    isLoading = {users.loading || users.deleting || users.updating}
    
    actions={[
      {
        icon: 'voice_over_off',
        tooltip: 'Hủy kích hoạt',
        onClick: (event, rowData) => {
          this.handleBan(rowData.id)
        },
        iconProps: {
          style: {
            fontSize: 20,
            color: 'green',
          },
        },
      },
      {
        icon: 'how_to_reg',
        tooltip: 'Phân quyền',
        onClick: (event, rowData) => {
          history.push('/dashboard/user/set-role/'+rowData.id);
        },
        iconProps: {
          style: {
            fontSize: 20,
            color: 'green',
          },
        },
      },
      {
        icon: 'delete',
        tooltip: 'Xóa',
        onClick: (event, rowData) => {
          // alert('You clicked user ' + rowData.id)
          this.handleClickOpen(rowData.id)
        },
        iconProps: {
          style: {
            fontSize: 20,
            color: '#cb3837',
          },
        },
      },
    ]}
    options={{
      columnsButton: true,
      exportButton: true,
      actionsColumnIndex: -1,
    }}
    localization={{
      pagination: {
        labelDisplayedRows: '{from}-{to} trong {count}', // {from}-{to} of {count}
        labelRowsPerPage: 'Số bản ghi trên trang:', // Rows per page:
        firstAriaLabel: 'Trang đầu', // First Page
        firstTooltip: 'Trang đầu', // First Page
        previousAriaLabel: 'Trang trước', // Previous Page
        previousTooltip: 'Trang trước', // Previous Page
        nextAriaLabel: 'Trang tiếp', // Next Page
        nextTooltip: 'Trang tiếp', // Next Page
        lastAriaLabel: 'Trang cuối', // Last Page
        lastTooltip: 'Trang cuối', // Last Page
      },
      toolbar: {
        nRowsSelected: '{0} bản ghi được chọn', // {0} row(s) selected
        showColumnsTitle: 'Hiển thị cột', // Show Columns
        showColumnsAriaLabel: 'Hiển thị cột', // Show Columns
        exportTitle: 'Xuất', // Export
        exportAriaLabel: 'Xuất', // Export
        exportName: 'Xuất ra CSV', // Export as CSV
        searchTooltip: 'Tìm kiếm', // Search
      },
      header: {
        actions: 'Hành động', // Actions
      },
      body: {
        emptyDataSourceMessage: 'Không có dữ liệu', // No records to display
        filterRow: {
          filterTooltip: 'Lọc', // Filter
        },
      },
    }}
    // isLoading = {false}
  />
    
  <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Xóa người dùng
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
            {(isLoaded) ? <div className="cssload-loader">
                    <div className="cssload-inner cssload-one"></div>
                    <div className="cssload-inner cssload-two"></div>
                    <div className="cssload-inner cssload-three"></div>
                </div> : ''}
              Bạn chắc chắn muốn xóa người dùng này?
            </Typography>
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleClose} color="primary">
              Bỏ qua
            </Button>
            <Button onClick={this.handleDelete.bind(this, this.state.id)} variant="contained" color="secondary">
              Xóa
            </Button>
          </DialogActions>
        </Dialog>
        </div>
  );
  }
}

function mapStateToProps(state) {
    const { users } = state;
    return {
      users
    };
}
  export default connect(mapStateToProps)(withStyles(styles)(User));


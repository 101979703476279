import { userConstants } from '../_constants/user.constant';
//var items = [];
export function editProfile(state = {}, action) {
  switch (action.type) {
    case userConstants.EDITUSER_REQUEST:
    return {
      updating: true,
    };
  case userConstants.EDITUSER_SUCCESS:
    return {
      items: {},
    };
  case userConstants.EDITUSER_FAILURE:
    return { 
      error: {}
    };
    default:
      return state
  }
}
import * as React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Button } from '@material-ui/core';
 import { withStyles } from '@material-ui/core/styles';
 import TextField from '@material-ui/core/TextField';
 import FormControl from '@material-ui/core/FormControl';
 import CircularProgress from '@material-ui/core/CircularProgress';
 import { connect } from 'react-redux';
import { menuTipsActions } from '../../_actions/menuTips.actions';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import red from '@material-ui/core/colors/red';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const styles = theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        marginBottom: 20,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
      },
      form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing.unit,
      },
      submit: {
        marginTop: theme.spacing.unit * 3,
      },
      link: {
        color: '#1e88e5',
        textDecoration: 'none',
        marginTop: 20,
        '&:hover':{
          color: '#fd5555',
        }
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
  });

class menuTipsAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        data: [],
        submitted: false,
        noChange:true,
        hasError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const { name, value } = event.target;
    const { data } = this.state;
    this.setState({
      noChange: false,
      hasError: false,
        data: {
            ...data,
            [name]: value
        }
    });
}

componentDidMount() {
    this.props.dispatch(menuTipsActions.getAllAdmin());
  }

handleSubmit(event) {
  event.persist();
    const { data } = this.state;
    this.setState({ hasError: false });
    this.setState({ submitted: true });
    if (data.parent === undefined) {
      this.setState({ hasError: true, submitted: false });
    }
    if (data.name && data.parent !== undefined) {
      const { dispatch } = this.props;
      dispatch(menuTipsActions.adminAddMenu(data));
    }
    
}
    render(){
      const { menuTips, alert, classes, adding  } = this.props;
        const { data, submitted } = this.state;
      const dataStore = menuTips.items;
       return(
         <React.Fragment>
           <MetaTags>
                <title>Thêm Section cho Tips Menu - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                <div>
                    <Button type="submit" size="small" variant="outlined" color="default" style={{textTransform:'initial',marginBottom:30}} component={Link} to="/dashboard/menu-tip">
                        <KeyboardBackspace className={classes.leftIcon} style={{fontSize:18}} />
                        Quay lại quản lý lĩnh vực Tips
                    </Button>
                    <ValidatorForm onSubmit={this.handleSubmit} style={{width:'100%'}} noValidate autoComplete="off">
                        {menuTips.loading &&
                            <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                        }
                        <div className={classes.layout}>
                            <FormControl style={{marginBottom:20,display:'inherit'}}>
                                <TextValidator
                                  fullWidth
                                    id="_name"
                                    variant="outlined"
                                    label="Tên Section"
                                    type="text"
                                    value={data.name || ''}
                                    name="name"
                                    onChange={this.handleChange}
                                    validators={['required','minStringLength:2']}
                                    errorMessages={['Tên lĩnh vực không được để trống','Tên lĩnh vực phải có tối thiểu 2 ký tự']}
                                    
                                />
                            </FormControl> 
                            <FormControl style={{marginBottom:20,display:'inherit'}}>
                                <TextField
                                  fullWidth
                                    id="_desc"
                                    variant="outlined"
                                    label="Mô tả"
                                    type="text"
                                    value={data.description || ''}
                                    name="description"
                                    onChange={this.handleChange}
                                />
                            </FormControl>  
                    
                            
                                <FormControl style={{marginBottom:20,display:'inherit'}} error={this.state.hasError}>
                                    <InputLabel htmlFor="parent-native-helper">Chọn lĩnh vực cha</InputLabel>
                                    <NativeSelect
                                        fullWidth
                                        defaultValue=""
                                        onChange={this.handleChange}
                                        input={<Input name="parent" id="parent-native-helper" />}
                                    >
                                        <option value="" />
                                        <option value="0">Lĩnh vực này là cha</option>
                                        {!submitted && dataStore.map(val=>(
                                            <option key={val.id} value={val.id}>{val.name}</option>
                                        ))}
                                    </NativeSelect>
                                    {this.state.hasError && <FormHelperText>Bạn phải chọn 1 lĩnh vực cha</FormHelperText>}
                                    
                                </FormControl>

                                <FormControl style={{marginBottom:20,display:'inherit'}}>
                                <TextField
                                    id="_pos"
                                    variant="outlined"
                                    type='number'
                                    label="Vị trí"
                                    value={data.position || ''}
                                    name="position"
                                    onChange={this.handleChange}
                                    
                                    />
                                </FormControl>

                                {alert.message &&
                                        <div style={{color:'red',textAlign:'center',padding:5}}>{this.props.alert.message}</div>
                                    }

                                <div className={classes.load}>
                                    <div className={classes.wrapper}>
                                        <Button
                                            variant="contained" 
                                            color="secondary"
                                            size="small"
                                            disabled={adding || this.state.noChange || this.state.hasError}
                                            type="submit"
                                            style={{textTransform:'initial'}}
                                        >
                                            Thêm lĩnh vực
                                        </Button>
                                        {adding && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </div>
                            </div>
                        </ValidatorForm>
                        
                </div>
        </React.Fragment>
       )
    }
}

function mapStateToProps(state) {
  const { menuTips, alert } = state;
  const { adding } = state.menuTips;
  return {
      menuTips, alert, adding
  };
}

export default connect(mapStateToProps)(withStyles(styles)(menuTipsAdd));
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { menuTipsActions } from '../../_actions/menuTips.actions';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import MetaTags from 'react-meta-tags';
import { Button, Paper, Grid } from '@material-ui/core';
 import { withStyles } from '@material-ui/core/styles';
 import TextField from '@material-ui/core/TextField';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import Update from '@material-ui/icons/Update';
import red from '@material-ui/core/colors/red';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';


 const styles = theme => ({
     root:{
        marginTop:20,
     },
     leftIcon: {
        marginRight: theme.spacing.unit,
      },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginBottom:20,
      },
      dense: {
        marginTop: 19,
      },
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
 });
class MenuTipsUpdate extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            menu1: [],
            data: [],
            loading: false,
            submitted: false,
            showPassword: false,
            notChange: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
      };
    handleChange(event) {
        const { name, value } = event.target;
        const { data } = this.state;
        this.setState({
            notChange: false,
            data: {
                ...data,
                [name]: value
            }
        });
        
    }
    
      handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true });
         const { data } = this.state;
            const { dispatch } = this.props;
            dispatch(menuTipsActions.editMenu(this.props.match.params.id, data))
      };
    componentDidMount(){
        this.props.dispatch(menuTipsActions.getAllAdmin());

        this.setState({loading: true})
        const uInfo = JSON.parse(localStorage.getItem('user'))
        axios.get(config.get("apiDomain")+'admin/get-menu-tip/'+this.props.match.params.id,
        {
          headers: {
              'Authorization': 'Bearer '+uInfo.access_token,
          }
        }
      )
        .then(res => {
          this.setState({menu1: res.data});
          this.setState({loading: false});
       });
       
      }
    render(){
        const { menu1, loading, submitted } = this.state;
        const { classes, updating, menuTips } = this.props;
        return(
            <div className={classes.root}>
            <MetaTags>
                <title>Cập nhật Tips Menu - LearnSkill</title>
                <meta name="description" content="Cập nhật Tips menu" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                {loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                    ) : (
                
                <form name="form" className={classes.form} onSubmit={this.handleSubmit} autoComplete="off">
                    <Button type="submit" size="small" variant="outlined" color="default" style={{textTransform:'initial',marginBottom:30}} component={Link} to="/dashboard/menu-tip">
                    <KeyboardBackspace className={classes.leftIcon} style={{fontSize:18}}/>
                      Quay lại quản lý lĩnh vực Tips
                    </Button>
                    <Paper style={{padding:20}}>
                    <Grid container spacing={32}>
                        <Grid item xs={12} style={{marginRight:10}}>
                        <div className={classes.load}>
                            <div className={classes.wrapper}>
                            <Button
                                variant="contained" 
                                color="secondary"
                                size="small"
                                disabled={updating || this.state.notChange}
                                type="submit"
                                style={{textTransform:'initial'}}
                            >
                                <Update className={classes.leftIcon} style={{fontSize:18}} />
                                Cập nhật
                            </Button>
                            {updating && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                label="Tên lĩnh vực"
                                name="name"
                                defaultValue={menu1.name}
                                onChange={this.handleChange}
                                helperText={submitted && !menu1.name ? "Tên lĩnh vực không được để trống": ""}
                                error = {submitted && !menu1.name ? true : false}
                            />
                            <TextField
                                fullWidth
                                className={classes.textField}
                                label="Mô tả lĩnh vực"
                                name="description"
                                defaultValue={menu1.description}
                                onChange={this.handleChange}
                                multiline={true}
                                rows={2}
                                rowsMax={4}
                                variant="outlined"
                            />
                           
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <FormControl style={{marginBottom:20,display:'inherit'}}>
                            <InputLabel htmlFor="parent-native-helper">Lĩnh vực cha</InputLabel>
                            <NativeSelect
                                fullWidth
                                defaultValue={menu1.parent}
                                onChange={this.handleChange}
                                input={<Input name="parent" id="parent-native-helper" />}
                            >
                                <option value="0">Không có lĩnh vực cha</option>
                                {!submitted && menuTips.items.filter(val => val.id !== menu1.id).map(val=>(
                                    <option key={val.id} value={val.id}>{val.name}</option>
                                ))}
                            </NativeSelect>
                            <FormHelperText>Nếu là lĩnh vực gốc chọn "Không có lĩnh vực cha"</FormHelperText>
                        </FormControl>
                        <TextField
                                fullWidth
                                className={classes.textField}
                                label="Thứ tự hiển thị"
                                name="position"
                                type='number'
                                defaultValue={menu1.position}
                                onChange={this.handleChange}
                            />
                            
                        </Grid>
                    </Grid>
                    </Paper>
                </form>
            
            )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { menuTips } = state;
    const { updating } = state.menuTips;
    return {
        menuTips, updating
    };
  }
  
  export default connect(mapStateToProps)(withStyles(styles)(MenuTipsUpdate));
import * as React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Button } from '@material-ui/core';
 import { withStyles } from '@material-ui/core/styles';
 import Visibility from '@material-ui/icons/Visibility';
 import VisibilityOff from '@material-ui/icons/VisibilityOff';
 import FormControl from '@material-ui/core/FormControl';
 import IconButton from '@material-ui/core/IconButton';
 import InputAdornment from '@material-ui/core/InputAdornment';
 import CircularProgress from '@material-ui/core/CircularProgress';
 import { connect } from 'react-redux';
import { userActions } from '../../_actions/user.actions';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import red from '@material-ui/core/colors/red';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';


const styles = theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        marginBottom: 20,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
      },
      form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing.unit,
      },
      submit: {
        marginTop: theme.spacing.unit * 3,
      },
      link: {
        color: '#1e88e5',
        textDecoration: 'none',
        marginTop: 20,
        '&:hover':{
          color: '#fd5555',
        }
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
  });

class AddUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        user: {
            name: '',
            username: '',
            password: ''
        },
        submitted: false,
        showPassword: false,
        noChange: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
        noChange: false,
        user: {
            ...user,
            [name]: value
        }
    });
}

handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    const { user } = this.state;
    const { dispatch } = this.props;
    if (user.name && user.username && user.password) {
        dispatch(userActions.adminAddUser(user));
    }
    
}
    render(){
      const { alert, classes, loading  } = this.props;
        const { user } = this.state;
       return(
         <React.Fragment>
           <MetaTags>
                <title>Thêm người dùng - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                <div>
                    <Button type="submit" size="small" variant="outlined" color="default" style={{textTransform:'initial',marginBottom:30}} component={Link} to="/dashboard/user">
                        <KeyboardBackspace className={classes.leftIcon} style={{fontSize:18}} />
                        Quay lại quản lý người dùng
                    </Button>
                        <ValidatorForm onSubmit={this.handleSubmit} style={{width:'100%'}}>
                        <div className={classes.layout}>
                            <FormControl style={{marginBottom:20,display:'inherit'}}>
                                <TextValidator
                                  fullWidth
                                    id="_name"
                                    variant="outlined"
                                    label="Họ và tên"
                                    type="text"
                                    value={user.name || ''}
                                    name="name"
                                    onChange={this.handleChange}
                                    validators={['required','minStringLength:3']}
                                    errorMessages={['Họ tên không được để trống','Họ tên phải có tối thiểu 3 ký tự']}
                                    
                                />
                            </FormControl> 
                            <FormControl style={{marginBottom:20,display:'inherit'}}>
                                <TextValidator
                                  fullWidth
                                    id="_username"
                                    variant="outlined"
                                    label="Địa chỉ email"
                                    type="mail"
                                    value={user.username || ''}
                                    name="username"
                                    onChange={this.handleChange}
                                    validators={['required', 'isEmail']}
                                    errorMessages={['Email không được để trống', 'Email không hợp lệ']}
                                />
                            </FormControl>  
                    
                            <FormControl style={{marginBottom:20,display:'inherit'}}>
                                <TextValidator
                                  fullWidth
                                    id="_password"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label="Mật khẩu"
                                    value={user.password || ''}
                                    name="password"
                                    onChange={this.handleChange}
                                    validators={['required','minStringLength:6']}
                                    errorMessages={['Mật khẩu không được để trống','Mật khẩu phải có tối thiểu 6 ký tự']}
                                    
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="Ẩn/Hiện mật khẩu"
                                            onClick={this.handleClickShowPassword}
                                            >
                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        ),
                                    }}
                                    />
                                </FormControl>
                    
                                {alert.message &&
                                        <div style={{color:'red',textAlign:'center',padding:5}}>{this.props.alert.message}</div>
                                    }
                                <div className={classes.load}>
                                    <div className={classes.wrapper}>
                                    <Button
                                        variant="contained" 
                                        color="secondary"
                                        size="small"
                                        disabled={loading || this.state.noChange}
                                        type="submit"
                                        style={{textTransform:'initial'}}
                                    >
                                        Thêm người dùng
                                    </Button>
                                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </div>
                            </div>
                        </ValidatorForm>
                        
                </div>
        </React.Fragment>
       )
    }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { loading } = state.users;
  return {
      alert, loading
  };
}

export default connect(mapStateToProps)(withStyles(styles)(AddUser));
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import MetaTags from 'react-meta-tags';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, 
    CardActionArea,  CardActions, CircularProgress, Fade  
} from '@material-ui/core';
// import Icon from '@material-ui/core/Icon';
import { PlayCircleOutline} from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import Timestamp from 'react-timestamp';

const styles = theme => ({
    root: {
      flexGrow: 1,
      marginBottom: 50,
    },
    section: {
        marginBottom:20,
    },
    select:{
        color: '#777',
        fontSize: 14,
    },
    media: {
      objectFit: 'cover',
      width: '100%',
      maxHeight:150,
    },
    card: {
      width: '100%',
    },
    chip: {
        color:'#777',
        fontSize: 10,
        height: 24,
        borderRadius: 0,
      },
    caption:{
        color: '#777',
    },
    filterby:{
        display: 'inline-flex',
        flexDirection: 'column',
        verticalAlign: 'top',
        position: 'relative',
        marginTop: 15,
        marginRight: 20,
        color: '#777',
    },
   
      teacherName: {
          color: '#777',
          textDecoration: 'none',
          cursor: 'pointer',
          '&:hover': {
              color: 'red',
              textDecoration: 'none',
          },
      },
     
    iconTime: {
      fontSize: 13,
      position: 'relative',
      bottom: -2,
    },
    popover: {
      margin: theme.spacing.unit * 2,
      pointerEvents: 'none',
      top:-25,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    button: {
      margin: theme.spacing.unit,
    },
    center:{
        textAlign: 'center',
    },
    menuClass:{
        textAlign: 'right',
        paddingRight: 20,
    },
    btnPlay: {
        position: 'absolute',
        top: '40%',
        left: '45%',
        fontSize: 60,
        '&:hover':{
            color: '#D50000',
        }
    },
    videoThumbnail: {
        display:'inline-flex',
      '&:hover':{
          btnPlay:{
              color: '#D50000',
          } 
      }
      },
      
      linkMenuLeft: {
        color: '#777',
        textDecoration: 'none',
        '&:hover':{
            color: '#1e88e5',
        }
    },
      linkListTeacher: {
          color: '#1565C0',
          textDecoration: 'none',
          marginLeft: 20,
          fontWeight: 'normal',
          '&:hover':{
              color: '#d50000',
          }
      },
      ListItem:{
          listStyleType: 'none',
          margin: 0,
          padding: 0
      },
      listItemText:{
          margin: '7px 0',
      },
      linkSection:{
          color: '#666',
          textDecoration: 'none',
          '&:hover':{
              color: '#d50000',
          }
      },
      inline: {
        display: 'inline',
      },
      formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing.unit * 2,
      },
      titleClass:{
        color: '#444',
        fontSize:'1.1em',
        fontWeight:450,
        textDecoration: 'none',
        marginBottom: 30,
        '&:hover':{
            color: '#1e88e5',
        }
       },
       box:{
        padding: 50,
        border: '1px dashed #999',
    },
    
  });

class AllClassFilter extends React.Component {
   
    state = {
        value: 0,
        data: [],
        filter: 0,
        typeClass: 0,
        loading: true,
        test: [],
      };
        
      handleChange = (event, value) => {
        this.setState({ value });
      };
      getFilter = () =>{
        if(this.props.match.params.filter === 2){
            return 2
        }else if(this.props.match.params.filter === 3){
            return 3
        }else{
            return 0
        }
      }

        componentDidMount(){
            axios.get(config.get("apiDomain")+'class/filter/'+this.getFilter()+'/'+this.state.typeClass)
            .then(res => {
                this.setState({data: res.data});
                this.setState({loading: false})
            });
        }
    
      handleChangeFilter = filter => event => {
        this.setState({ filter: event.target.value });
        this.setState({loading: true})
        axios.get(config.get("apiDomain")+'class/filter/'+event.target.value+'/'+this.state.typeClass)
        .then(res => {
          this.setState({data: res.data});
          this.setState({loading: false})
        });
      };
      handleChangeType = type => event => {
        this.setState({ typeClass: event.target.value });
        this.setState({loading: true})
        axios.get(config.get("apiDomain")+'class/filter/'+this.state.filter+'/'+event.target.value)
        .then(res => {
          this.setState({data: res.data});
          this.setState({loading: false})
        });
      };
    
      render() {
          const { classes } = this.props;
          const {loading } = this.state;
        return(
            <div className={classes.root}>
                <MetaTags>
                    <title>Lọc các lớp học - LearnSkill</title>
                    <meta name="description" content="Danh sách các lớp học mới nhất, đặc trưng nhất, xu hướng nhất trên hệ thống học tập trực tuyến learnskill" />
                    <meta property="og:title" content="Lọc các lớp học - LearnSkill" />
                    <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
                    <meta property="og:url" content={config.get('domain')} />
                    <meta name="twitter:card" content="summary_large_image" />
                </MetaTags>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} align="right">
                                <div className={classes.filterby}>
                                    LỌC THEO:
                                </div>
                                <FormControl className={classes.formControl}>
                                <Select className={classes.select}
                                    native
                                    defaultValue={this.getFilter()}
                                    onChange={this.handleChangeFilter('filter')}
                                    inputProps={{
                                    name: 'filter',
                                    id: 'filter',
                                    }}
                                >
                                    <option value={0}>Mới nhất</option>
                                    <option value={3}>Phổ biến</option>
                                    <option value={2}>Xu hướng</option>
                                </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                <Select className={classes.select}
                                    native
                                    defaultValue = {0}
                                    onChange={this.handleChangeType('type')}
                                    inputProps={{
                                    name: 'typeClass',
                                    id: 'typeClass',
                                    }}
                                >
                                    <option value={0}>Miễn phí</option>
                                    <option value={1}>Mất phí</option>
                                </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        
                        {/* Các lớp học phổ biến */}
                       
                        <Grid container spacing={16} style={{marginTop:10}}>
                        {(loading === false && this.state.data.length === 0) ? (
                            <Grid item xs={12} className={classes.box} align="center">
                                <Typography variant="h6" style={{color:'#999'}}>
                                    Không có dữ liệu
                                </Typography>
                            </Grid>
                        ) : (
                            this.state.data.map((item, index) => (
                                <Grid item xs={12} sm={4} md={4} key={index}>
                                    <Card>
                                        <CardActionArea className={classes.videoThumbnail}>
                                        <Link to={"/video-course/"+item.slug} title={item.title}>
                                            <PlayCircleOutline color="primary" className={classes.btnPlay} />
                                            <img src={(item.class_image !== null) ? config.get('apiImg')+item.class_image : "/no-preview.jpg"} className={classes.media} alt={item.title}/>
                                        </Link>
                                    </CardActionArea>
                                        <CardActions style={ {padding:'2px'} }>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography className={classes.caption} variant="caption" align="left">
                                                    {item.countViews} người học
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography className={classes.caption} variant="caption" align="right">
                                                        <Timestamp date={new Date(item.created_at)} relative autoUpdate />
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" gutterBottom>
                                                        <Link to={"/video-course/"+item.slug} className={classes.titleClass}>{item.title}</Link>
                                                    </Typography>
                                                    <Typography className="margin-top-20">
                                                        <Link to={"/user/"+item.user.id+'/'+item.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{item.user.name}</Link> 
                                                    </Typography>
                                                    
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))
                        )}
                        <div className="placeholder">
                            <Fade
                                in={loading}
                                style={{
                                transitionDelay: loading ? '800ms' : '0ms',
                                }}
                                unmountOnExit
                            >
                                <CircularProgress color="secondary" />
                            </Fade>
                        </div>

                                                        
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { getClass } = state;
    return {
        getClass
    };
  }

export default connect(mapStateToProps)(withStyles(styles)(AllClassFilter));
import { commentConstants } from '../_constants/comment.constants';
var items = [];
export function getComment(state = {items}, action) {
  switch (action.type) {
    case commentConstants.GETALL_COMMENT_CLASS_REQUEST:
    return {
      loading: true,
      items: items
    };
  case commentConstants.GETALL_COMMENT_CLASS_SUCCESS:
    return {
      items: action.getComment
    };
  case commentConstants.GETALL_COMMENT_CLASS_FAILURE:
    return { 
      error: action.error
    };

    //comment pending
      case commentConstants.GETALL_COMMENT_CLASS_PENDING_REQUEST:
      return {
        loading: true,
        items: items
      };
    case commentConstants.GETALL_COMMENT_CLASS_PENDING_SUCCESS:
      return {
        items: action.getComment
      };
    case commentConstants.GETALL_COMMENT_CLASS_PENDING_FAILURE:
      return { 
        error: action.error
      };
//COMENT BY CLASS
    case commentConstants.GETALL_COMMENT_BYCLASS_REQUEST:
    return {
      loading: true,
      items: items
    };
  case commentConstants.GETALL_COMMENT_BYCLASS_SUCCESS:
    return {
      items: action.getComment
    };
  case commentConstants.GETALL_COMMENT_BYCLASS_FAILURE:
    return { 
      error: action.error
    };

    //DELETE COMMENT CLASS
  case commentConstants.DELCOMMENTCLASS_REQUEST:
    return {
      deleting: true,
      ...state,
      items: state.items.map(getComment =>
        getComment.id === action.id
          ? { ...getComment, deleting: true }
          : getComment
      )
    };
  case commentConstants.DELCOMMENTCLASS_SUCCESS:
    return {
      items: state.items.filter(getComment => getComment.id !== action.id)
    };
  case commentConstants.DELCOMMENTCLASS_FAILURE:
    return {
      ...state,
      items: state.items.map(getComment => {
        if (getComment.id === action.id) {
          const { deleting, ...getCommentCopy } = getComment;
          return { ...getCommentCopy, deleteError: action.error };
        }
  
        return getComment;
      })
    };

     //active comment class
  case commentConstants.ACTIVECOMMENTCLASS_REQUEST:
    return {
      updating: true,
      ...state,
      items: state.items.map(getComment =>
        getComment.id === action.id
          ? { ...getComment, updating: true }
          : getComment
      )
    };
    case commentConstants.ACTIVECOMMENTCLASS_SUCCESS:
    return {
      items: state.items.filter(getComment => getComment.id !== action.id)
    };
  case commentConstants.ACTIVECOMMENTCLASS_FAILURE:
    return { 
      ...state,
      items: state.items.map(getComment => {
        if (getComment.id === action.id) {
          const { updating, ...getCommentCopy } = getComment;
          return { ...getCommentCopy, activeError: action.error };
        }
  
        return getComment;
      })
    };

    //add
   case commentConstants.ADD_COMMENT_BYCLASS_REQUEST:
    return {
      adding: true,
    };
    case commentConstants.ADD_COMMENT_BYCLASS_SUCCESS:
    return {
      adding: false,
    };
  case commentConstants.ADD_COMMENT_BYCLASS_FAILURE:
    return { 
      error: action.error
    };

    default:
      return state
  }

}
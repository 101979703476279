import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { menuTipsActions } from '../../_actions/menuTips.actions';
import { tipsActions } from '../../_actions/tips.actions';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import MetaTags from 'react-meta-tags';
import { Button, Paper, Grid, Typography } from '@material-ui/core';
 import { withStyles } from '@material-ui/core/styles';
 import TextField from '@material-ui/core/TextField';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import Update from '@material-ui/icons/Update';
import red from '@material-ui/core/colors/red';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../editor.css';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import embed from "embed-video";


 const styles = theme => ({
     root:{
        marginTop:20,
     },
     input: {
        display: 'none',
      },
     leftIcon: {
        marginRight: theme.spacing.unit,
      },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginBottom:20,
      },
      dense: {
        marginTop: 19,
      },
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
 });
 function uploadImageCallBack(file) {
  return new Promise(
    (resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      const xhr = new XMLHttpRequest();
      xhr.open('POST', config.get("apiDomain")+"admin/upload-image-tip");
      xhr.setRequestHeader('Authorization', 'Bearer '+user.access_token);
      const data = new FormData();
      data.append('file', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    }
  );
}
class TipUpdate extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tip: [],
            data: [],
            loading: false,
            submitted: false,
            showPassword: false,
            notChange: true,
            imgPreview: '',
            content: EditorState.createEmpty(),
        };
        this.uploadChange = this.uploadChange.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onContentChange = this.onContentChange.bind(this);
    }

    onContentChange = (content) => {
        this.setState({content});
        const { data } = this.state;
        let contentConvert = content.getCurrentContent();
        this.setState({
          notChange: false,
            data: {
                ...data,
                content: JSON.stringify(convertToRaw(contentConvert), null, 4)
            }
        });
    }
        
      
    handleChange(event) {
        const { name, value } = event.target;
        const { data } = this.state;
        this.setState({
            notChange: false,
            data: {
                ...data,
                [name]: value
            }
        });
    }
    
      handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        const { data } = this.state;
            const { dispatch } = this.props;
                dispatch(tipsActions.editTip(this.props.match.params.id, data))
        
      };
      uploadChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
          this.createImage(files[0]);
          this.setState({
            notChange: false,
          })
      }
      createImage(file) {
        const { data } = this.state;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({
            imgPreview: e.target.result,
            data: {
              ...data,
              image: e.target.result
          }
          })
        };
        reader.readAsDataURL(file);
      }
    componentDidMount(){
        this.props.dispatch(menuTipsActions.getAllAdmin());

        this.setState({loading: true})
        const uInfo = JSON.parse(localStorage.getItem('user'))
        axios.get(config.get("apiDomain")+'admin/find-tip/'+this.props.match.params.id,
        {
          headers: {
              'Authorization': 'Bearer '+uInfo.access_token,
          }
        }
      )
        .then(res => {
          this.setState({tip: res.data});
          if(res.data.content !== null){
            let convert = convertFromRaw(JSON.parse(res.data.content));
            let editor = EditorState.createWithContent(convert);
            this.setState({content: editor})
        }
          this.setState({loading: false});
       });
       
      }
    render(){
        const { tip, loading, submitted } = this.state;
        const { classes, updating, menuTips } = this.props;
        const cat = menuTips.items.filter(val=>val.parent !== 0);
        return(
            <div className={classes.root}>
            <MetaTags>
                <title>Cập nhật baif mẹo/thủ thuật - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                {loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                    ) : (
                
                <form name="form" className={classes.form} onSubmit={this.handleSubmit} autoComplete="off">
                    <Button type="submit" size="small" variant="outlined" color="default" style={{textTransform:'initial',marginBottom:30}} component={Link} to="/dashboard/tip">
                    <KeyboardBackspace className={classes.leftIcon} style={{fontSize:18}}/>
                      Quay lại quản lý bài mẹo/thủ thuật
                    </Button>
                    <Paper style={{padding:20}}>
                    <Grid container spacing={32}>
                        <Grid item xs={12} style={{marginRight:10}}>
                        <div className={classes.load}>
                            <div className={classes.wrapper}>
                            <Button
                                variant="contained" 
                                color="secondary"
                                size="small"
                                disabled={updating || this.state.notChange}
                                type="submit"
                                style={{textTransform:'initial'}}
                            >
                                <Update className={classes.leftIcon} style={{fontSize:18}} />
                                Cập nhật
                            </Button>
                            {updating && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                label="Tên bài mẹo/thủ thuật"
                                name="title"
                                defaultValue={tip.title}
                                onChange={this.handleChange}
                                helperText={submitted && !tip.title ? "Tên bài mẹo/thủ thuật không được để trống": ""}
                                error = {submitted && !tip.title ? true : false}
                            />
                            <div>
                            <Typography variant="h6">Nội dung</Typography>
                            <Editor
                            editorState={this.state.content}
                            onEditorStateChange={this.onContentChange}
                            ref="editor"
                            wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                toolbar={{
                                  image: {
                                    uploadCallback: uploadImageCallBack,
                                    previewImage: true,
                                    alt: { present: true, mandatory: true },
                                    defaultSize: {height:'100%',width: '100%'}
                                  },
                                  link: {
                                    linkCallback: params => ({ ...params })
                                  },
                                  embedded: {
                                    embedCallback: link => {
                                      const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                      return (detectedSrc && detectedSrc[1]) || link;
                                    }
                                  }
                                }}
                                hashtag={{}}
                            />
                            </div>                         
                           
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <Typography>Ảnh đại diện lớp học</Typography>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="class-image"
                            multiple
                            type="file"
                            name="image"
                            onChange={this.uploadChange}
                        />
                        <label htmlFor="class-image">
                        {this.state.imgPreview === '' ? (
                            <img src={config.get('apiImg')+tip.image} alt="preview" style={{marginBottom:10,width:'100%'}}/>
                        ):(
                            <img src={this.state.imgPreview} alt="preview" style={{marginBottom:10,width:'100%'}}/>
                        )}
                            <br />
                            <Button variant="contained" color="secondary" size="small" component="span" className={classes.button} style={{marginBottom:20}}>
                            Upload
                            <CloudUploadIcon className={classes.rightIcon} />
                            </Button>
                        </label>
                            <FormControl style={{marginBottom:20,display:'inherit'}}>
                                <InputLabel htmlFor="section-native-helper">Chuyên mục</InputLabel>
                                <NativeSelect
                                    fullWidth
                                    defaultValue={tip.section}
                                    onChange={this.handleChange}
                                    input={<Input name="category" id="section-native-helper" />}
                                >
                                    {!submitted && cat.map(val=>(
                                        <option key={val.id} value={val.id}>{val.name}</option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                        
                        <FormControl style={{marginBottom:20,display:'inherit'}}>
                            <InputLabel htmlFor="published-native-helper">Xuất bản</InputLabel>
                            <NativeSelect
                                fullWidth
                                defaultValue={tip.active}
                                onChange={this.handleChange}
                                input={<Input name="active" id="published-native-helper" />}
                            >
                                <option value="0">Chưa xuất bản</option>
                                <option value="1">Đã xuất bản</option>
                            </NativeSelect>
                        </FormControl>
                            
                        </Grid>
                    </Grid>
                    </Paper>
                </form>
            
            )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { menuTips } = state;
    const { updating } = state.tips;
    return {
        menuTips, updating
    };
  }
  
  export default connect(mapStateToProps)(withStyles(styles)(TipUpdate));
import React from 'react';
import MetaTags from 'react-meta-tags';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { classActions } from '../../_actions/class.actions';
import { Button, Grid,  CircularProgress, FormControl, TextField  } from '@material-ui/core';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import red from '@material-ui/core/colors/red';
import embed from "embed-video";
import config from "react-global-configuration";


const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
})
function uploadImageCallBack(file) {
  return new Promise(
    (resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      const xhr = new XMLHttpRequest();
      xhr.open('POST', config.get("apiDomain")+"admin/upload-image-lesson");
      xhr.setRequestHeader('Authorization', 'Bearer '+user.access_token);
      const data = new FormData();
      data.append('file', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    }
  );
}

class AddLesson extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            lessonData: [],
            submitted: false,
            addLesson: EditorState.createEmpty(),
        }
        this.addLessonChange = this.addLessonChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

      handleSubmit(e){
        e.preventDefault();
        this.setState({ submitted: true });
        const { lessonData } = this.state;
        const { dispatch } = this.props;
        if (lessonData.name) {
            dispatch(classActions.adminAddLessonText(lessonData));
      }
    }
      handleChange(event) {
        const { lessonData } = this.state;
        const { name, value } = event.target;
       
        this.setState({
          lessonData: {
                ...lessonData,
                [name]: value,
                class_id: this.props.match.params.id,
            },
            submitted: false,
          hasError: false,
        });
    }
    
    addLessonChange = (addLesson) => {
        this.setState({addLesson});
        const { lessonData } = this.state;
        let addLessonConvert = addLesson.getCurrentContent();
        this.setState({
            lessonData: {
                ...lessonData,
                content: JSON.stringify(convertToRaw(addLessonConvert), null, 4)
            }
        });
    }
 
    render(){
        const { classes } = this.props;
        const { submitted, lessonData } = this.state;

        return(
            <div className={classes.root}>
                <MetaTags>
                <title>Thêm bài học - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                <form ref="form" onSubmit={this.handleSubmit} >
                        
                  <div className={classes.load}>
                      <div className={classes.wrapper}>
                          <Button color="secondary" disabled={this.props.adding} variant="contained" type="submit" size="small" style={{textTransform:'initial'}}>
                              Thêm bài học
                          </Button>
                          {this.props.adding && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                  </div>
                     
                        <div style={{padding:20}}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={10}>
                            <FormControl style={{marginBottom:20,display:'inherit'}}>
                              <TextField
                                fullWidth
                                autoFocus
                                className={classes.textField}
                                label="Tên bài học"
                                name="name"
                                defaultValue=""
                                onChange={this.handleChange}
                                helperText={(submitted && lessonData.name === undefined) ? "Tên bài học không được để trống": ""}
                                error = {(submitted && lessonData.name === undefined) ? true : false}
                              />
                              
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                          <FormControl style={{marginBottom:20,display:'inherit'}}>
                              <TextField
                                fullWidth
                                className={classes.textField}
                                label="Vị trí"
                                name="position"
                                defaultValue=""
                                onChange={this.handleChange}
                                type="number"
                              />
                              
                            </FormControl>
                          </Grid>
                        </Grid>
                        <h5>Nội dung bài học</h5>
                        {submitted && this.state.addLesson.getCurrentContent().hasText() === false ? <p style={{color:'red'}}>Nội dung không được để trống</p> : ''}
                            <Editor
                                editorState={this.state.addLesson}
                                onEditorStateChange={this.addLessonChange}
                                ref="editor"
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                placeholder={"Nhập nội dung bài học..."}
                                toolbar={{
                                  image: {
                                    uploadCallback: uploadImageCallBack,
                                    previewImage: true,
                                    alt: { present: true, mandatory: true },
                                    defaultSize: {height:'100%',width: '100%'}
                                  },
                                  link: {
                                    linkCallback: params => ({ ...params })
                                  },
                                  embedded: {
                                    embedCallback: link => {
                                      const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                      return (detectedSrc && detectedSrc[1]) || link;
                                    }
                                  }
                                }}
                                hashtag={{}}
                            />
                          </div>
                          
                        </form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { lesson, adding } = state.getClass;
    return {
        lesson, adding
    };
  }
  export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(AddLesson));
import React from 'react';
import {Link} from 'react-router-dom';
import { Divider, Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Videocam } from '@material-ui/icons';
import '../App.css'
import Fab from '@material-ui/core/Fab';
const styles = theme => ({
   
  footer: {
      color: '#666',
    },
      gray:{
        color: '#666',
      },
      marginTop10:{
        marginTop: 10,
      },
      listInline:{
        display: 'inline-block',
        margin: '0 10px 30px 10px',
      },
      link:{
        color: '#666',
        textDecoration: 'none',
        '&:hover': {
          color: '#d50000',
        }
      },
      backtotop:{
        bottom: -20,
        right: 20,
        transitionDuration: '0.5s',
        transitionTimingFunction: 'easeInQuad',
        transitionDelay: '1s'
      },
      noHover:{
        '&:hover': {
          backgroundColor: 'transparent',
          cursor: 'initial',
        }
      },
      bigAvatar: {
        margin: 10,
        width: 80,
        height: 80,
        marginLeft:'auto',
        marginRight: 'auto',
      },
      listParent:{
        paddingTop:3,
        paddingBottom:3,
        fontWeight:450,
      }

  });

class FooterPrivate extends React.Component{
    render(){
        const { classes } = this.props;
        return(
            <Grid container className={classes.footer} style={{marginTop:100}}>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={10}>
                    <Grid container>
                    <Grid item xs={12}>
                        <Divider />
                        <Grid container className={classes.marginTop10}>
                        <Grid item xs={7}>
                            <Typography variant="caption" gutterBottom className={classes.gray}>
                            <ul className={classes.ListItem}  style={{paddingLeft:0}}>
                                <li className={classes.listInline}>&copy; Learnskill 2018</li>
                                <li className={classes.listInline}>
                                <Link to="/" title="Hướng dẫn" className={classes.link}>Hướng dẫn</Link>
                                </li>
                                <li className={classes.listInline}>
                                <Link to="/" title="Điều khoản" className={classes.link}>Điều khoản</Link>
                                </li>
                                <li className={classes.listInline}>
                                <Link to="/" title="Chính sách" className={classes.link}>Chính sách</Link>
                                </li>
                            </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                        <Typography variant="caption" gutterBottom align="right">
                        <Fab color="primary" size="small" href="/">f</Fab>&nbsp;&nbsp;
                        <Fab color="primary" size="small" href="/">t</Fab>&nbsp;&nbsp;
                        <Fab color="secondary" size="small" href="/"><Videocam /></Fab>
                        </Typography>
                        </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                    
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                    </Grid>
        )
    }
}
export default withStyles(styles)(FooterPrivate);
export const userConstants = {
    CHECKEMAIL_REQUEST: 'USERS_CHECKEMAIL_REQUEST',
    CHECKEMAIL_SUCCESS: 'USERS_CHECKEMAIL_SUCCESS',
    CHECKEMAIL_FAILURE: 'USERS_CHECKEMAIL_FAILURE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GETADMIN_REQUEST: 'USERS_GETALLADMIN_REQUEST',
    GETADMIN_SUCCESS: 'USERS_GETALLADMIN_SUCCESS',
    GETADMIN_FAILURE: 'USERS_GETALLADMIN_FAILURE',

    GETTEACHER_REQUEST: 'USERS_GETALLTEACHER_REQUEST',
    GETTEACHER_SUCCESS: 'USERS_GETALLTEACHER_SUCCESS',
    GETTEACHER_FAILURE: 'USERS_GETALLTEACHER_FAILURE',

    GETSTUDENT_REQUEST: 'USERS_GETALLSTUDENT_REQUEST',
    GETSTUDENT_SUCCESS: 'USERS_GETALLSTUDENT_SUCCESS',
    GETSTUDENT_FAILURE: 'USERS_GETALLSTUDENT_FAILURE',

    GETALL_NOACTIVE_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_NOACTIVE_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_NOACTIVE_FAILURE: 'USERS_GETALL_FAILURE',

    GETALLDELETED_REQUEST: 'USERS_GETALLDELETED_REQUEST',
    GETALLDELETED_SUCCESS: 'USERS_GETALLDELETED_SUCCESS',
    GETALLDELETED_FAILURE: 'USERS_GETALLDELETED_FAILURE',

    RESTORE_REQUEST: 'USERS_RESTORE_REQUEST',
    RESTORE_SUCCESS: 'USERS_RESTORE_SUCCESS',
    RESTORE_FAILURE: 'USERS_RESTORE_FAILURE',

    FORCEDEL_REQUEST: 'USERS_FORCEDEL_REQUEST',
    FORCEDEL_SUCCESS: 'USERS_FORCEDEL_SUCCESS',
    FORCEDEL_FAILURE: 'USERS_FORCEDEL_FAILURE',

    GETINFO_REQUEST: 'USERS_GETINFO_REQUEST',
    GETINFO_SUCCESS: 'USERS_GETINFO_SUCCESS',
    GETINFO_FAILURE: 'USERS_GETINFO_FAILURE',

    GETINFOUPDATE_REQUEST: 'USERS_GETINFOUPDATE_REQUEST',
    GETINFOUPDATE_SUCCESS: 'USERS_GETINFOUPDATE_SUCCESS',
    GETINFOUPDATE_FAILURE: 'USERS_GETINFOUPDATE_FAILURE',

    SKILLRELATED_REQUEST: 'USERS_SKILLRELATED_REQUEST',
    SKILLRELATED_SUCCESS: 'USERS_SKILLRELATED_SUCCESS',
    SKILLRELATED_FAILURE: 'USERS_SKILLRELATED_FAILURE',

    EDITUSER_REQUEST: 'USERS_EDIT_REQUEST',
    EDITUSER_SUCCESS: 'USERS_EDIT_SUCCESS',
    EDITUSER_FAILURE: 'USERS_EDIT_FAILURE',
    
    ADDLIST_REQUEST: 'USERS_ADDLIST_REQUEST',
    ADDLIST_SUCCESS: 'USERS_ADDLIST_SUCCESS',
    ADDLIST_FAILURE: 'USERS_ADDLIST_FAILURE',

    GETLISTUSER_REQUEST: 'USERS_GETLIST_REQUEST',
    GETLISTUSER_SUCCESS: 'USERS_GETLIST_SUCCESS',
    GETLISTUSER_FAILURE: 'USERS_GETLIST_FAILURE',

    ADMINADDUSER_REQUEST: 'USERS_ADMINADD_REQUEST',
    ADMINADDUSER_SUCCESS: 'USERS_ADMINADD_SUCCESS',
    ADMINADDUSER_FAILURE: 'USERS_ADMINADD_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    
    ACTIVE_USER_SUCCESS: 'ADMIN_ACTIVE_USER_SUCCESS',
    ACTIVE_USER_FAILURE: 'ADMIN_ACTIVE_USER_FAILURE',
    ACTIVE_USER_REQUEST: 'ADMIN_ACTIVE_USER_REQUEST',

    BAN_USER_SUCCESS: 'ADMIN_BAN_USER_SUCCESS',
    BAN_USER_FAILURE: 'ADMIN_BAN_USER_FAILURE',
    BAN_USER_REQUEST: 'ADMIN_BAN_USER_REQUEST',
};
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider, CircularProgress, Card, List, ListItem, ListItemAvatar, 
    ListItemText, CardMedia, CardContent
} from '@material-ui/core';
import axios from 'axios';
import config from "react-global-configuration";
import Avatar from '@material-ui/core/Avatar';
import StickyBox from "react-sticky-box";
import Timestamp from 'react-timestamp';
import Ads from '../_components/Ads';

const styles = theme => ({
    root: {
        marginTop:80,
        marginBottom:30,
        width:'100%'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    linkTips:{
        textDecoration: 'none',
        marginRight:30,
        color:'#444',
    },
    section:{
        marginBottom: 20,
    },
    media: {
      objectFit: 'cover',
      width: '100%',
      maxHeight:150,
    },
    bigMedia: {
        objectFit: 'cover',
        width: '100%',
      },
    card: {
      width: '100%',
    },
    chip: {
        width:'100%',
        color: '#777',
        borderRadius: 0,
      },
    caption:{
        color: '#777',
    },
    teacherName: {
        color: '#777',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            color: 'red',
            textDecoration: 'none',
        },
    },
    gray:{
        color: '#666',
      },
      marginTop10:{
        marginTop: 10,
      },
      listInline:{
        display: 'inline-block',
        margin: '0 10px 50px 10px',
      },
      link:{
        color: '#666',
        textDecoration: 'none',
        '&:hover': {
          color: '#d50000',
        }
      },
    linkSection: {
        color: '#888',
        textDecoration: 'none',
        textTransform: 'uppercase',
        '&:hover': {
            color: 'red',
        },
    },
     
    iconTime: {
      fontSize: 13,
      position: 'relative',
      bottom: -2,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    button: {
      margin: theme.spacing.unit,
    },
    center:{
        textAlign: 'center',
    },
    
      linkListTeacher: {
          color: '#1565C0',
          textDecoration: 'none',
          marginLeft: 20,
          fontWeight: 'normal',
          '&:hover':{
              color: '#d50000',
          }
      },
      titleClass:{
        color: '#444',
        fontSize:'1.1em',
        fontWeight:450,
        textDecoration: 'none',
        marginBottom: 10,
        '&:hover':{
            color: '#1e88e5',
        }
       },
})

class HomeTips extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tipSec: [],
            tipPopular: [],
            loading: true,
            error: false,
            hasMore: true,
            isLoading: false,
            tipAll: [],
            tipTop:[],
            tipLoadmore: [],
            elmStart: 0,
            elmOfset: 10
        };
        window.onscroll = () => {
            const {
              state: {
                error,
                isLoading,
                hasMore,
                elmStart,
                elmOfset
              },
            } = this;
      
            // Bails early if:
            // * there's an error
            // * it's already loading
            // * there's nothing left to load
            if (error || isLoading || !hasMore) return;
      
            // Checks that the page has scrolled to the bottom
            if (
              window.innerHeight + document.documentElement.scrollTop
              === document.documentElement.offsetHeight
            ) {
              this.loadTips();
              this.setState({ elmStart: elmStart+10, elmOfset: elmOfset+10})
            }
          };
        }

        loadTips = () => {
            this.setState({ isLoading: true }, () => {
                axios.get(config.get("apiDomain")+"userFrontend/get-all-tip")
                
                .then(res => {
                    const nextTips = res.data.map(tip => ({
                        id: tip.id,
                        title: tip.title,
                        slug: tip.slug,
                        content: tip.content,
                        section: tip.section,
                        category: tip.category,
                        image: tip.image,
                        userId: tip.user.id,
                        userName: tip.user.name,
                        created_at: tip.created_at
                      }));
                    this.setState({
                        hasMore: (this.state.tipLoadmore.length < this.state.tipAll.length-10),
                        isLoading: false,
                        tipLoadmore: [
                        ...this.state.tipLoadmore,
                        ...nextTips.slice(this.state.elmStart,this.state.elmOfset),
                        ],
                    });
                })
                .catch((err) => {
                    this.setState({
                      error: err.message,
                      isLoading: false,
                     });
                  })
                
            
            });
          }

    componentDidMount() {
      
        axios.get(config.get("apiDomain")+"userFrontend/tipMenuAll/0")
        .then(res => {
            this.setState({tipSec: res.data});
        })
        axios.get(config.get("apiDomain")+"userFrontend/get-all-tip-top")
        .then(res => {
            this.setState({tipTop: res.data, loading:false});
        })
        
        axios.get(config.get("apiDomain")+"userFrontend/tip-popular")
        .then(res => {
            this.setState({tipPopular: res.data});
        })

        this.loadTips();
      axios.get(config.get("apiDomain")+"userFrontend/get-all-tip")
        .then(res => {
            this.setState({tipAll: res.data});
        })
    }
    getCatSlug(id){
        let filter = this.state.tipSec.filter(val => val.id === id);
        let temp = [];
        filter.map(val=>(temp.push(val.slug)))
        return temp[0]
    }
    getCatName(id){
        let filter = this.state.tipSec.filter(val => val.id === id);
        let temp = [];
        filter.map(val=>(temp.push(val.name)))
        return temp[0]
    }
    getSubCatSlug(id, idSub){
        let filter = this.state.tipSec.filter(val => val.id === id);
        let temp = [];
        filter.map(val=>(
            temp = val.submenu
        ))
        let filter2 = temp.filter(val=>(val.id === idSub))
        let temp2 = [];
        filter2.map(val=>(
            temp2.push(val.slug)
        ))
        return temp2[0]
    }
    render(){
        const { classes } = this.props;
        const { tipSec, tipPopular, tipTop,  error,  isLoading, tipLoadmore } = this.state;
        const tipFirst = tipTop.slice(0,1);
        const tipThree = tipTop.slice(1,4);
        // const tipFour = tipTop.slice(4,5);
        
        return (
            <div className={classes.root}>
                <MetaTags>
                    <title>Tổng hợp mẹo, thủ thuật - LearnSkill</title>
                    <meta name="description" content="Tổng hợp các bài hướng dẫn, các mẹo và thủ thuật từ nhiều lĩnh vực trong cuộc sống" />
                    <meta name="keywords" content="mẹo, thủ thuật, học, dạy học, lớp học, dạy học, giáo dục, học trực tuyến, học online, video, lớp học video, các lớp học video" />
                    <meta property="og:title" content='Tổng hợp mẹo, thủ thuật - LearnSkill' />
                    <meta property="og:description" content='Tổng hợp các bài hướng dẫn, các mẹo và thủ thuật từ nhiều lĩnh vực trong cuộc sống' />
                    <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
                    <meta property="og:url" content={config.get('domain')} />
                    <meta name="twitter:card" content="summary_large_image" />
                </MetaTags>
                { this.state.loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                ) : (
                <Grid container>
                    <Grid item md={1}></Grid>
                    <Grid item xs={12} sm={12} md={10} >
                        <Grid container>
                            <Grid item xs={12} style={{padding:'0 10px'}}>
                                <NavLink to={'/tips'} className={classes.linkTips} activeStyle={{fontWeight: 450,color: "#1e88e5",textTransform:'uppercase'}} title="mẹo, thủ thuật">Tips</NavLink>
                                {tipSec.map(val=>(
                                    <NavLink key={val.id} to={'/tips/'+val.slug} className={classes.linkTips} activeStyle={{fontWeight: 450,color: "#1e88e5",textTransform:'uppercase'}} title={val.description}>{val.name}</NavLink>
                                ))}
                            </Grid>
                            <Grid item xs={12} style={{marginTop:30}}>
                                <Grid container>
                                    <Grid item xs={12} sm={6} md={4}>
                                    {tipFirst.map(val =>(
                                        <Card style={{boxShadow:'none'}} key={val.id}>
                                        {/* <CardActionArea> */}
                                          <CardMedia
                                            style={{height:150}}
                                            image={(val.image !== null) ? config.get('apiImg')+val.image : "/no-preview.jpg"}
                                            title={val.title}
                                            component={Link} 
                                            to={"/tips/"+this.getCatSlug(val.section)+'/'+this.getSubCatSlug(val.section, val.category)+'/'+val.slug}
                                          />
                                          <CardContent>
                                            <Typography gutterBottom variant="h5" className={classes.titleClass} component={Link} to={"/tips/"+this.getCatSlug(val.section)+'/'+this.getSubCatSlug(val.section, val.category)+'/'+val.slug}>
                                              {val.title}
                                            </Typography>
                                            <List>
                                                <ListItem style={{paddingLeft:0,paddingTop:20}}>
                                                    <ListItemText
                                                        primary={<Link to={"/user/"+val.user.id+'/'+val.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{val.user.name}</Link>}
                                                        secondary={
                                                          <Timestamp date={new Date(val.created_at)} options={{ includeDay: true, twentyFourHour: true }} />
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                          </CardContent>
                                        {/* </CardActionArea> */}
                                      </Card>
                                    ))}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <List>
                                        {tipThree.map(val=>(
                                        <ListItem alignItems="flex-start" key={val.id} style={{paddingTop:0,paddingBottom:15}}>
                                            <ListItemAvatar component={Link} to={"/tips/"+this.getCatSlug(val.section)+'/'+this.getSubCatSlug(val.section, val.category)+'/'+val.slug}>
                                                <Avatar alt={val.title} src={(val.image !== null) ? config.get('apiImg')+val.image : "/no-preview.jpg"} style={{width:120,height:100,borderRadius:0}} />
                                            </ListItemAvatar>
                                            <ListItemText
                                              primary={<Link to={"/tips/"+this.getCatSlug(val.section)+'/'+this.getSubCatSlug(val.section, val.category)+'/'+val.slug} className={classes.titleClass} >{val.title}</Link>}
                                              secondary={
                                                <React.Fragment>
                                                  {<Link to={"/user/"+val.user.id+'/'+val.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{val.user.name}</Link>}
                                                  <br />
                                                  <span style={{margin:0}}><Timestamp date={new Date(val.created_at)} options={{ includeDay: true, twentyFourHour: true }} /></span>
                                                </React.Fragment>
                                              }
                                            />
                                        </ListItem>
                                        ))}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                    {/* {tipFour.map(val =>(
                                        <Card style={{boxShadow:'none'}} key={val.id}>
                                          <CardMedia
                                            style={{height:150}}
                                            image={(val.image !== null) ? config.get('apiImg')+val.image : "/no-preview.jpg"}
                                            title={val.title}
                                            component={Link} 
                                            to={"/tips/"+this.getCatSlug(val.section)+'/'+this.getSubCatSlug(val.section, val.category)+'/'+val.slug}
                                          />
                                          <CardContent>
                                            <Typography gutterBottom variant="h5" className={classes.titleClass} component={Link} to={"/tips/"+this.getCatSlug(val.section)+'/'+this.getSubCatSlug(val.section, val.category)+'/'+val.slug}>
                                              {val.title}
                                            </Typography>
                                            <List>
                                                <ListItem style={{paddingLeft:0,paddingTop:20}}>
                                                    <ListItemText
                                                        primary={<Link to={"/user/"+val.user.id+'/'+val.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{val.user.name}</Link>}
                                                        secondary={
                                                          <Timestamp date={new Date(val.created_at)} options={{ includeDay: true, twentyFourHour: true }} />
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                          </CardContent>
                                      </Card>
                                    ))} */}
                                    {/* learnskill_top_right_tips */}
                                    {/* <div  className = "ad-menu-header-mobile" >
                                        AdSense mobile code.
                                    </div > 
                                    <div  className = "ad-menu-header-desktop"> */}
                                      <Ads slot="4556294088" format="auto" />
                                    {/* </div> */}
                                    
                                    </Grid>
                                    <Divider style={{width:'100%'}} />
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={8}>
                                                <List>
                                                <ListItem>
                                                  <ListItemText>
                                                    
                                                      <Ads slot="1210879786" format="auto" />
                                                  </ListItemText>
                                                </ListItem>
                                                {tipLoadmore.map((val, index)=>(
                                                <ListItem alignItems="flex-start" key={index} style={{paddingTop:0,paddingBottom:15}}>
                                                    <ListItemText
                                                    primary={<React.Fragment><Link className={classes.linkSection} to={"/tips/"+this.getCatSlug(val.section)}>{this.getCatName(val.section)}</Link><Typography component={Link} to={"/tips/"+this.getCatSlug(val.section)+'/'+this.getSubCatSlug(val.section, val.category)+'/'+val.slug} className={classes.titleClass} >{val.title}</Typography></React.Fragment>}
                                                    secondary={
                                                        <React.Fragment>
                                                        {<Link to={"/user/"+val.userId+'/'+val.userName.replace(/\s+/g, '-')} className={classes.teacherName}>{val.userName}</Link>}
                                                        <br /><span style={{margin:0}}><Timestamp date={new Date(val.created_at)} options={{ includeDay: true, twentyFourHour: true }} /></span>
                                                        </React.Fragment>
                                                    }
                                                    />
                                                    <ListItemAvatar component={Link} to={"/tips/"+this.getCatSlug(val.section)+'/'+this.getSubCatSlug(val.section, val.category)+'/'+val.slug}>
                                                        <Avatar alt={val.title} src={(val.image !== null) ? config.get('apiImg')+val.image : "/no-preview.jpg"} style={{width:150,height:'auto',borderRadius:0}} />
                                                    </ListItemAvatar>
                                                </ListItem>
                                                ))}
                                                </List>
                                                
                                                {error &&
                                                <div style={{ color: '#900' }}>
                                                    {error}
                                                </div>
                                                }
                                                {isLoading &&
                                                <div><CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} /></div>
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} className={classes.sectionDesktop}>
                                            <StickyBox offsetTop={100} offsetBottom={10}>
                                                <Typography variant="h6" className={classes.title} style={{marginTop:30}}>
                                                    Phổ biến trên LearnSkill 
                                                </Typography>
                                                <Divider />
                                                <div className={classes.demo}>
                                                    <List component="nav">
                                                    {tipPopular.map((val, index)=>(
                                                        <ListItem key={index} style={{alignItems: 'start'}}>
                                                            <span style={{width:'auto', fontSize: 25, fontWeight: 600, color: '#888'}}>
                                                            0{index+1}
                                                            </span>
                                                            <ListItemText
                                                            primary={<Link to={"/tips/"+this.getCatSlug(val.section)+'/'+this.getSubCatSlug(val.section, val.category)+'/'+val.slug} className={classes.titleClass} >{val.title}</Link>}
                                                            secondary={
                                                                <React.Fragment>
                                                                <Timestamp date={new Date(val.created_at)} options={{ includeDay: true, twentyFourHour: true }} />
                                                                </React.Fragment>
                                                            }
                                                            />
                                                        </ListItem>
                                                        ))}
                                                    </List>
                                                    {/* <div  className={classes.sectionDesktop} style={{marginLeft:20}}>
                                                      <Ads slot="1887373493" format="auto" />
                                                    </div>
                                                    <div  className={classes.sectionDesktop} style={{marginLeft:20, marginTop:30}}>
                                                      <Ads slot="7003968805" format="link" />
                                                    </div> */}
                                                    <Typography variant="caption" gutterBottom className={classes.gray} style={{marginTop:30}}>
                                                        <ul className={classes.ListItem}>
                                                            <li className={classes.listInline}>
                                                            <Link to="/" title="Hướng dẫn" className={classes.link}>Hướng dẫn</Link>
                                                            </li>
                                                            <li className={classes.listInline}>
                                                            <Link to="/" title="Điều khoản" className={classes.link}>Điều khoản</Link>
                                                            </li>
                                                            <li className={classes.listInline}>
                                                            <Link to="/" title="Chính sách" className={classes.link}>Chính sách</Link>
                                                            </li>
                                                        </ul>
                                                    </Typography>
                                                </div>
                                                </StickyBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                )}
            </div>
        )
    }
}
  export default withStyles(styles)(HomeTips);
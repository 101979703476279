import React from 'react';
import { Link } from 'react-router-dom';
import { isLoged, checkRole } from '../_services/auth';
import { AppBar, Divider, Drawer, Hidden, List, IconButton, ListItem, ListItemIcon,
  Collapse, CssBaseline, Toolbar, Typography, Button, Menu, MenuItem, ListItemText
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { Videocam, ExpandLess, School, Settings, RecordVoiceOver, Help,
ExpandMore, ExitToApp, 
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import ScrollToTop from 'react-scroll-up';
import { connect } from 'react-redux';
import { userActions } from '../_actions/user.actions';
import { getTitle } from '../_services/getTitle';
import UserAvatar from 'react-user-avatar';
import { history } from '../_helpers/history';
import config from "react-global-configuration";
import axios from 'axios';
import { authHeader } from '../_helpers/auth-header';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  linkLogo: {
    color: '#666',
    textDecoration: 'none',
    '&:hover':{
      color: '#1e88e5',
    }

  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
  listParent:{
    paddingTop:3,
    paddingBottom:3,
    fontWeight:450,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  backtotop:{
    bottom: -20,
    right: 20,
    transitionDuration: '0.5s',
    transitionTimingFunction: 'easeInQuad',
    transitionDelay: '1s'
  },
  noHover:{
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'initial',
    }
  },
  bigAvatar: {
    margin: 10,
    width: 80,
    height: 80,
    marginLeft:'auto',
    marginRight: 'auto',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class AdminLayout extends React.Component {
  state = {
    mobileOpen: false,
    expanded: false,
    expandedComment: false,
    uInfo:[],
    uLoad: false,
  };

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user !== null){
      axios.get(config.get("apiDomain")+'user/info/'+user.id,
      {
          headers: authHeader()
      })
      .then(res => {
          this.setState({uInfo: res.data, uLoad: true})
      })
    }
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  handleExpandCommentClick = () => {
    this.setState(state => ({ expandedComment: !state.expandedComment }));
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };
  handleLogout = (props) => {
    this.props.dispatch(userActions.logout());
  }
  handleClick = (e) => {
    this.setState({ [e]: !this.state[e] });
  };
  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    // this.handleMobileMenuClose();
  };

  render() {
    if(!checkRole('admin')){
      history.push('/login');
    }
    const {anchorEl, uInfo } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const { classes, theme} = this.props;
    // const uInfo = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : "";
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
        style={{top:48}}
      >
        <MenuItem style={{height:'auto',padding:0,background:'#fff'}} className={classes.noHover}>
        <UserAvatar size="80" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar} className={classes.bigAvatar}/>
        </MenuItem>
        <MenuItem style={{paddingTop:0}} className={classes.noHover}>
        {uInfo.length !== 0 ? (
          <div style={{marginLeft:'auto', marginRight:'auto'}}><b>{uInfo.name}</b></div>
        ) : ("")}
        </MenuItem>
        <MenuItem style={{height:15,marginBottom:5}} className={classes.noHover}>
          <Button component={Link} to="/u/profile" variant="contained" size="small" color="secondary" align="center" style={{marginLeft:'auto', marginRight:'auto',textTransform:'initial'}}>
            Xem thông tin
          </Button>
        </MenuItem>
        <Divider light />
        
        <MenuItem component={Link} to="/lop-hoc-cua-toi" title="Lớp học của tôi" style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <School />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Lớp học của tôi" />
        </MenuItem>
        <MenuItem component={Link} to="/u/edit-profile" style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <Settings />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Thiết lập tài khoản" />
        </MenuItem>
        <MenuItem onClick={this.handleMenuClose} style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <Videocam />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Dạy học" />
        </MenuItem>
        <MenuItem onClick={this.handleMenuClose} style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <RecordVoiceOver />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Mời bạn bè" />
        </MenuItem>
        <MenuItem onClick={this.handleMenuClose} style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <Help />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Trợ giúp" />
        </MenuItem>
        <Divider light />
        <MenuItem style={{height:15}} onClick={this.handleLogout}>
          <ListItemIcon className={classes.icon}>
              <ExitToApp />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Thoát" />
        </MenuItem>
      </Menu>
    );

    const drawer = (
      <div>
        <div className={classes.toolbar} style={{padding: '8px 0 0 20px'}}>
          <Typography  variant="h5">
            <Link to="/" className={classes.linkLogo} target="_blank" rel="noopener noreferrer" title="Học kỹ năng trực tuyến Learn Skills">Learn Skills</Link>
          </Typography>
          <Typography variant="caption">
            v1.0.0
          </Typography>
        </div>
        <Divider />
        <List
        component="nav"
       >
        <ListItem button className={classes.listParent} component={Link} to={'/dashboard'} >
          <ListItemText inset primary={<Typography variant="body1" gutterBottom>Dashboard</Typography>} style={{paddingLeft:0}}/>
        </ListItem>
        <ListItem button className={classes.listParent} component={Link} to={'/dashboard/user'}>
          <ListItemText inset primary={<Typography variant="body1" gutterBottom>Người dùng</Typography>} style={{paddingLeft:0}}/>
        </ListItem>
        <ListItem button className={classes.listParent} component={Link} to={'/dashboard/menu'}>
          <ListItemText inset primary={<Typography variant="body1" gutterBottom>Lĩnh vực lớp học</Typography>} style={{paddingLeft:0}}/>
        </ListItem>
        <ListItem button className={classes.listParent} onClick={this.handleExpandClick}>
          <ListItemText inset primary={<Typography variant="body1" gutterBottom>Lớp học</Typography>} style={{paddingLeft:0}} />
          {this.state.expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <List component="nav" className={classes.listParent}>
            <ListItem button className={classes.listParent} component={Link} to={'/dashboard/video-class'} >
                <ListItemText inset primary={<Typography variant="body2" gutterBottom>Lớp học Video</Typography>} style={{paddingLeft:10}}/>
            </ListItem>
            <ListItem button className={classes.listParent} component={Link} to={'/dashboard/text-class'} >
                <ListItemText inset primary={<Typography variant="body2" gutterBottom>Lớp học Văn bản</Typography>} style={{paddingLeft:10}}/>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button className={classes.listParent} component={Link} to={'/dashboard/menu-tip'}>
          <ListItemText inset primary={<Typography variant="body1" gutterBottom>Lĩnh vực Tips</Typography>} style={{paddingLeft:0}}/>
        </ListItem>
        <ListItem button className={classes.listParent} component={Link} to={'/dashboard/tip'}>
          <ListItemText inset primary={<Typography variant="body1" gutterBottom>Quản lý Tips</Typography>} style={{paddingLeft:0}}/>
        </ListItem>

        <ListItem button className={classes.listParent} onClick={this.handleExpandCommentClick}>
          <ListItemText inset primary={<Typography variant="body1" gutterBottom>Bình luận</Typography>} style={{paddingLeft:0}} />
          {this.state.expandedComment ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.expandedComment} timeout="auto" unmountOnExit>
          <List component="nav" className={classes.listParent}>
            <ListItem button className={classes.listParent} component={Link} to={'/dashboard/comment/lop-hoc/pending'} >
                <ListItemText inset primary={<Typography variant="body2" gutterBottom>Bình luận lớp học chờ duyệt</Typography>} style={{paddingLeft:10}}/>
            </ListItem>
            <ListItem button className={classes.listParent} component={Link} to={'/dashboard/comment/lop-hoc'} >
                <ListItemText inset primary={<Typography variant="body2" gutterBottom>Bình luận lớp học</Typography>} style={{paddingLeft:10}}/>
            </ListItem>
          </List>
        </Collapse>
      </List>

      
      </div>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              {getTitle(window.location.pathname)}
            </Typography>

            <div className={classes.grow} />
            
            {!isLoged() ? (
              <React.Fragment>
                <Button color="inherit" className={classes.sectionDesktop} style={{textTransform:'initial'}} href="/register" title="">Đăng ký</Button>
                <Button variant="contained" color="secondary" size="small" style={{textTransform:'initial'}} href="/login">Đăng nhập</Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
              <div className={classes.sectionDesktop}>
            <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
                style={{padding:0}}
              >
            {this.state.uLoad &&
              <UserAvatar size="48" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar}/>
            }
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleProfileMenuOpen} color="inherit">
              {this.state.uLoad &&
              <UserAvatar size="48" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar}/>
              }
              </IconButton>
            </div>
        
</React.Fragment>
            )}
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />

          {this.props.children}
          
          {/* scroll to top */}
        <ScrollToTop showUnder={160}>
          <Fab color="secondary" aria-label="Lên đầu trang" className={classes.backtotop}>
            <ExpandLess />
          </Fab>
          </ScrollToTop>
          {renderMenu}
        </main>
        
        
      </div>
    );
  }
}




export default connect()(withStyles(styles, { withTheme: true })(AdminLayout));
import React from 'react';
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import config from "react-global-configuration";
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { classActions } from '../../_actions/class.actions';
import { Button, Grid, CircularProgress, FormControl, TextField, Typography } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import Chip from '@material-ui/core/Chip';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ReactPlayer from 'react-player'
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';


const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      input: {
        display: 'none',
      },
})

class EditVideo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            lessonData: [],
            data: [],
            submitted: false,
            loading:true,
            noChange: true,
            imgPreview: '',
            video:[]
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadChange = this.uploadChange.bind(this)
    }

    uploadChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
          this.createImage(files[0]);
      }
      createImage(file) {
        const { lessonData } = this.state;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({
            imgPreview: e.target.result,
            noChange: false,
            lessonData: {
              ...lessonData,
              thumbnail_custom: e.target.result
          }
          })
        };
        reader.readAsDataURL(file);
      }

      handleSubmit(e){
        e.preventDefault();
        this.setState({ submitted: true });
        const { lessonData } = this.state;
        const { dispatch } = this.props;
        dispatch(classActions.editVideo(this.props.match.params.id, lessonData, this.state.data.class_id));
      
    }
      handleChange(event) {
        const { lessonData } = this.state;
        const { name, value } = event.target;
       
        this.setState({
          lessonData: {
                ...lessonData,
                [name]: value,
            },
            submitted: false,
          hasError: false,
          noChange: false
        });
    }
    componentDidMount(){
      this.setState({loading: true})
        let uInfo = JSON.parse(localStorage.getItem('user'))
        axios.get(config.get("apiDomain")+'admin/find-video/'+this.props.match.params.id,
        {
          headers: {
              'Authorization': 'Bearer '+uInfo.access_token,
          }
        }
      )
        .then(res => {
          this.setState({
            data: res.data,  
            loading: false
          });
          if(res.data.type === 'youtube' || res.data.type === 'vimeo'){
            this.setState({
              lessonData:{
                url: res.data.url
              }
            })
          }
       });
    }
 
    render(){
        const { classes } = this.props;
        const { submitted, data, loading } = this.state;
        return(
            <div className={classes.root}>
                <MetaTags>
                <title>Thêm bài học - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                {loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                    ) : (
                <form ref="form" onSubmit={this.handleSubmit} >
                        
                  <div className={classes.load}>
                      <div className={classes.wrapper}>
                          <Button color="secondary" disabled={this.state.noChange || this.props.updating} variant="contained" type="submit" size="small" style={{textTransform:'initial'}}>
                              Cập nhật video
                          </Button>
                          {this.props.updating && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                  </div>
                     
                        <div style={{padding:20}}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={10}>
                            <FormControl style={{marginBottom:20,display:'inherit'}}>
                              <TextField
                                fullWidth
                                className={classes.textField}
                                label="Tên video"
                                name="name"
                                defaultValue={data.name}
                                onChange={this.handleChange}
                                helperText={(submitted && data.name === undefined) ? "Tên video không được để trống": ""}
                                error = {(submitted && data.name === undefined) ? true : false}
                              />
                              
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                          <FormControl style={{marginBottom:20,display:'inherit'}}>
                              <TextField
                                fullWidth
                                className={classes.textField}
                                label="Vị trí"
                                name="position"
                                defaultValue={data.position}
                                onChange={this.handleChange}
                                type="number"
                              />
                              
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={24}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <div>Loại video: <Chip label={data.type} color={data.type === 'vimeo' ? 'primary' : 'secondary'}/></div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                  <FormControl style={{marginBottom:20,display:'inherit'}}>
                                    <InputLabel htmlFor="published-native-helper">Xuất bản</InputLabel>
                                    <NativeSelect
                                        fullWidth
                                        defaultValue={data.actived}
                                        onChange={this.handleChange}
                                        input={<Input name="actived" id="published-native-helper" />}
                                    >
                                        <option value="0">Chưa xuất bản</option>
                                        <option value="1">Đã xuất bản</option>
                                    </NativeSelect>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                  {data.type !== 'system' &&
                                    <FormControl style={{marginBottom:10,display:'inherit'}}>
                                      <TextField
                                        fullWidth
                                        className={classes.textField}
                                        label="Mã Video"
                                        name="url"
                                        defaultValue={data.url}
                                        onChange={this.handleChange}
                                        helperText={(submitted && data.url === undefined) ? "Mã video Vimeo không được để trống": ""}
                                        error = {(submitted && data.url === undefined) ? true : false}
                                      />
                                    </FormControl>
                                  }
                                    <Typography>Ảnh đại diện video</Typography>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="class-image"
                                      multiple
                                      type="file"
                                      name="thumbnail_video"
                                      onChange={this.uploadChange}
                                    />
                                    <label htmlFor="class-image">
                                    {this.state.imgPreview === '' ? (
                                        <img src={config.get('apiImg')+data.thumbnail_custom} alt="preview" style={{marginBottom:10,width:'100%'}}/>
                                    ):(
                                        <img src={this.state.imgPreview} alt="preview" style={{marginBottom:10,width:'100%'}}/>
                                    )}
                                      <br />
                                      <Button variant="contained" color="secondary" size="small" component="span" className={classes.button} style={{marginBottom:20}}>
                                        Upload
                                        <CloudUploadIcon className={classes.rightIcon} />
                                      </Button>
                                    </label>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                  {data.type === 'youtube' &&
                                  <ReactPlayer 
                                    url={'https://www.youtube.com/watch?v='+this.state.lessonData.url} 
                                    width="100%"
                                    controls
                                    light={this.state.imgPreview}
                                    youtubeConfig={{ playerVars: { showinfo: 0 } }}
                                  />
                                    }
                                    {data.type === 'vimeo' &&
                                  <ReactPlayer 
                                    url={'https://vimeo.com/'+this.state.lessonData.url} 
                                    width="100%"
                                    controls
                                    light={this.state.imgPreview}
                                  />
                                    }
                                 </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        
                          </div>
                          
                        </form>
                    )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { updating } = state.getClass;
    return {
      updating
    };
  }
  export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(EditVideo));
export const menuTipsConstants = {
    GETALL_REQUEST: 'MENUTIPS_GETALL_REQUEST',
    GETALL_SUCCESS: 'MENUTIPS_GETALL_SUCCESS',
    GETALL_FAILURE: 'MENUTIPS_GETALL_FAILURE',

    GETNAME_REQUEST: 'MENUTIPS_GETNAME_REQUEST',
    GETNAME_SUCCESS: 'MENUTIPS_GETNAME_SUCCESS',
    GETNAME_FAILURE: 'MENUTIPS_GETNAME_FAILURE',

    GETMENUBYID_REQUEST: 'MENUTIPS_GETMENUBYID_REQUEST',
    GETMENUBYID_SUCCESS: 'MENUTIPS_GETMENUBYID_SUCCESS',
    GETMENUBYID_FAILURE: 'MENUTIPS_GETMENUBYID_FAILURE',

    GETALLADMIN_REQUEST: 'MENUTIPS_GETALLADMIN_REQUEST',
    GETALLADMIN_SUCCESS: 'MENUTIPS_GETALLADMIN_SUCCESS',
    GETALLADMIN_FAILURE: 'MENUTIPS_GETALLADMIN_FAILURE',

    ADMINADDMENU_REQUEST: 'MENUTIPS_ADMINADD_REQUEST',
    ADMINADDMENU_SUCCESS: 'MENUTIPS_ADMINADD_SUCCESS',
    ADMINADDMENU_FAILURE: 'MENUTIPS_ADMINADD_FAILURE',

    ADMINEDITMENU_REQUEST: 'MENUTIPS_ADMINEDIT_REQUEST',
    ADMINEDITMENU_SUCCESS: 'MENUTIPS_ADMINEDIT_SUCCESS',
    ADMINEDITMENU_FAILURE: 'MENUTIPS_ADMINEDIT_FAILURE',

    DELETE_REQUEST: 'MENUTIPS_DELETE_REQUEST',
    DELETE_SUCCESS: 'MENUTIPS_DELETE_SUCCESS',
    DELETE_FAILURE: 'MENUTIPS_DELETE_FAILURE' ,
    
    GETALLDELETED_REQUEST: 'ADMIN_GETALLDELETED_REQUEST',
    GETALLDELETED_SUCCESS: 'ADMIN_GETALLDELETED_SUCCESS',
    GETALLDELETED_FAILURE: 'ADMIN_GETALLDELETED_FAILURE',

    RESTORE_REQUEST: 'MENUTIPS_RESTORE_REQUEST',
    RESTORE_SUCCESS: 'MENUTIPS_RESTORE_SUCCESS',
    RESTORE_FAILURE: 'MENUTIPS_RESTORE_FAILURE',

    FORCEDEL_REQUEST: 'MENUTIPS_FORCEDEL_REQUEST',
    FORCEDEL_SUCCESS: 'MENUTIPS_FORCEDEL_SUCCESS',
    FORCEDEL_FAILURE: 'MENUTIPS_FORCEDEL_FAILURE',
};
import { tipsConstants } from '../_constants/tips.constants';
var items = [];
export function tips(state = {items}, action) {
  switch (action.type) {
    case tipsConstants.ADMINGETALL_REQUEST:
    return {
      loading: true
    };
  case tipsConstants.ADMINGETALL_SUCCESS:
    return {
      items: action.getTipsAdmin
    };
  case tipsConstants.ADMINGETALL_FAILURE:
    return { 
      error: action.error
    };

   //add
   case tipsConstants.ADMINADDTIPS_REQUEST:
   return {
     adding: true,
   };
   case tipsConstants.ADMINADDTIPS_SUCCESS:
   return {
     adding: false,
   };
 case tipsConstants.ADMINADDTIPS_FAILURE:
   return { 
     error: action.error
   };

  //DELETE TIPS
  case tipsConstants.DELTIPS_REQUEST:
  return {
    deleting: true,
    ...state,
    items: state.items.map(tips =>
      tips.id === action.id
        ? { ...tips, deleting: true }
        : tips
    )
  };
case tipsConstants.DELTIPS_SUCCESS:
  return {
    items: state.items.filter(tips => tips.id !== action.id)
  };
case tipsConstants.DELTIPS_FAILURE:
  return {
    ...state,
    items: state.items.map(tips => {
      if (tips.id === action.id) {
        const { deleting, ...tipsCopy } = tips;
        return { ...tipsCopy, deleteError: action.error };
      }

      return tips;
    })
  };

  //tip deleted
  case tipsConstants.TIPDELETED_REQUEST:
  return {
    loading: true,
  };
case tipsConstants.TIPDELETED_SUCCESS:
  return {
    items: action.tipsDeleted
  };
case tipsConstants.TIPDELETED_FAILURE:
  return { 
    error: action.error
  };

//update tip
case tipsConstants.ADMINEDITTIPS_REQUEST:
return {
  updating: true,
};
case tipsConstants.ADMINEDITTIPS_SUCCESS:
return {
  updating: false,
};
case tipsConstants.ADMINEDITTIPS_FAILURE:
return { 
  error: action.error
};

//RESTORE TIPS
case tipsConstants.RESTORE_REQUEST:
  return {
    restoring: true,
    ...state,
    items: state.items.map(tips =>
      tips.id === action.id
        ? { ...tips, restoring: true }
        : tips
    )
  };
case tipsConstants.RESTORE_SUCCESS:
return {
  items: state.items.filter(tips => tips.id !== action.id)
};
case tipsConstants.RESTORE_FAILURE: 
return {
  ...state,
  items: state.items.map(tips => {
    if (tips.id === action.id) {
      const { restoring, ...tipsCopy } = tips;
      return { ...tipsCopy, restoreError: action.error };
    }

    return tips;
  })
};

//FORCE DEL TIPS
case tipsConstants.FORCEDEL_REQUEST:
  return {
    deleting: true,
    ...state,
    items: state.items.map(tips =>
      tips.id === action.id
        ? { ...tips, deleting: true }
        : tips
    )
  };
case tipsConstants.FORCEDEL_SUCCESS:
return {
  items: state.items.filter(tips => tips.id !== action.id)
};
case tipsConstants.FORCEDEL_FAILURE:
return {
  ...state,
  items: state.items.map(tips => {
    if (tips.id === action.id) {
      const { deleting, ...tipsCopy } = tips;
      return { ...tipsCopy, deleteError: action.error };
    }

    return tips;
  })
};



    default:
      return state
  }
}
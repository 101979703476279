import React from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress, Tabs, Tab
} from '@material-ui/core';
import Pagination from '../_helpers/Pagination'
import Chip from '@material-ui/core/Chip';
import { convertFromRaw, EditorState } from 'draft-js';


const styles = theme => ({
    root: {
      flexGrow: 1,
      marginBottom: 50,
      marginTop:75,
    },
    card: {
        display: 'flex',
        marginBottom:20,
        boxShadow: 'none'
      },
      details: {
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        flex: '1 0 auto',
      },
      cover: {
        width: 151,
      },
      linkTitle: {
        color: '#1a0dab',
        fontSize:18,
        textDecoration: 'none',
        '&:hover':{
          textDecoration: 'underline'
        }
      },
      linkDetail: {
        color: '#006621',
        textDecoration: 'none',
      },
      chip: {
        margin: theme.spacing.unit,
      },
      tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
      },
      tabsIndicator: {
        backgroundColor: '#1890ff',
      },
      tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
          color: '#40a9ff',
          opacity: 1,
        },
        '&$tabSelected': {
          color: '#1890ff',
          fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
          color: '#40a9ff',
        },
      },
      tabSelected: {},
      typography: {
        padding: theme.spacing.unit * 3,
      },
})

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


class SearchClass extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      value: 0,
        loading: false,
        keyword: '',
        // activePage: 1,
        allClass: [],
        currentClass: [],
        currentVideoClass: [],
        curentTextClass: [],
        milisecond: null,
        content: EditorState.createEmpty(),
      }
  }
  
  onPageChanged = data => {
    const { allClass } = this.state;
    const { currentPage, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentClass = allClass.slice(offset, offset + pageLimit);
    const currentVideoClass = allClass.filter(val => val.isVideo === 1).slice(offset, offset + pageLimit);
    const currentTextClass = allClass.filter(val => val.isVideo === 0).slice(offset, offset + pageLimit);

    this.setState({ currentClass, currentVideoClass, currentTextClass });
  };
    componentDidMount(){
        const str = this.props.match.params.searchStr.split('+').join(' ');
        this.setState({loading: true, keyword: str});
        let start = Date.now();
        // this.props.dispatch(searchActions.searchClass(str));
        axios.get(config.get("apiDomain")+"class/search/"+str)
            .then(res => {
              let millis = Date.now() - start;
              let rs = millis/1000;
                this.setState({allClass: res.data, videoClass: res.data.filter(val=>val.isVideo === 1), textClass: res.data.filter(val => val.isVideo===0), loading:false, milisecond:rs});
            })
    }
    componentWillReceiveProps(nextProps) {
        const str = this.props.match.params.searchStr.split('+').join(' ');

        const newStr = nextProps.match.params.searchStr.split('+').join(' ');
        this.setState({keyword: newStr, loading:true})
        let start = Date.now();
        if(str !== newStr){
            // this.props.dispatch(searchActions.searchClass(newStr));
            axios.get(config.get("apiDomain")+"class/search/"+newStr)
            .then(res => {
              let millis = Date.now() - start;
              let rs = millis/1000;
              this.setState({allClass: res.data, videoClass: res.data.filter(val=>val.isVideo === 1), textClass: res.data.filter(val => val.isVideo===0), loading:false, milisecond:rs});
                
            })
        }
    }

    handleChange = (event, value) => {
      this.setState({ value });
    };

    render(){
        const { classes } = this.props;
        const { allClass, videoClass, textClass, keyword, loading, currentClass,currentVideoClass,currentTextClass, milisecond, value } = this.state;
        // const videoClass = allClass.filter(val => val.isVideo === 1);
        // const textClass = allClass.filter(val => val.isVideo === 0);
        return(
            <div className={classes.root}>
                <MetaTags>
                <title>Tìm kiếm lớp học LearnSkill</title>
                <meta name="description" content={'Kết quả tìm kiếm cho từ khóa '+keyword+' trên hệ thống học kỹ năng trực tuyến learnskill'} />
              <meta name="keywords" content="học, dạy học, lớp học, dạy học, giáo dục, học trực tuyến, học online, video, lớp học video, các lớp học video" />
              <meta property="og:title" content="Tìm kiếm lớp học LearnSkill" />
              <meta property="og:description" content={'Kết quả tìm kiếm cho từ khóa '+keyword+' trên hệ thống học kỹ năng trực tuyến learnskill'} />
              <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
              <meta property="og:url" content={config.get('domain')} />
              <meta name="twitter:card" content="summary_large_image" />
                </MetaTags>
                {loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'absolute',top:'48%',left:'48%'}} />
                    ) : (
                <Grid container>
                    <Grid item md={2}></Grid>
                    <Grid item xs={12} sm={12} md={6}>
                    <Tabs
                      value={value}
                      onChange={this.handleChange}
                      classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                    >
                      <Tab
                        disableRipple
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label="Tất cả"
                      />
                      <Tab
                        disableRipple
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label="Video"
                      />
                      <Tab
                        disableRipple
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label="Văn bản"
                      />
                    </Tabs>
                    {value === 0 && <TabContainer>
                      {allClass.length === 0 ?(
                      <Typography variant="body1" gutterBottom>
                      Không có lớp học nào cho từ khóa <b>{keyword}</b>
                    </Typography>
                    ):(
                      <React.Fragment>
                        <Typography variant="body1" gutterBottom>
                      Kết quả tìm kiếm lớp học cho từ khóa <b>{keyword}</b>
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{marginBottom:30}}>
                      Khoảng {allClass.length} kết quả ({milisecond+' giây'})
                    </Typography>
                        {currentClass.map(val=>(
                            val.isVideo === 1 ? (
                              <React.Fragment key={val.id}>
                              <Typography component={Link} to={'/video-course/'+val.slug} className={classes.linkTitle}>
                                {val.title}
                              </Typography>
                              <Typography component={Link} to={'/video-course/'+val.slug} className={classes.linkDetail}>
                                {window.location.protocol + "//" + window.location.host+'/video-course/'+val.slug}
                              </Typography>
                              <Grid container spacing={16} style={{marginBottom:20}}>
                                <Grid item xs={12} sm={3} md={3}>
                                  <img style={{width:'100%'}} src={(val.class_image !== null) ? val.class_image : "/no-preview.jpg"} alt={val.title} />
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                  <Typography variant="body2" color="textSecondary">
                                    {val.description !== null &&
                                      EditorState.createWithContent(convertFromRaw(JSON.parse(val.description))).getCurrentContent().getBlocksAsArray().map(v=>(
                                        v.getText().length > 160 ? v.getText().substring(0,160)+"..." :v.getText()
                                      ))
                                    }
                                    </Typography>
                                    <div style={{color:'#777',fontSize:14}}>
                                      Loại lớp học <Chip label="Video" color ="primary" className={classes.chip} style={{height:20,fontSize:12}}/>
                                    </div>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                            ):(
                              <React.Fragment key={val.id}>
                                <div style={{marginBottom:20}}>
                                  <Typography component={Link} to={'/text-course/'+val.slug} className={classes.linkTitle}>
                                    {val.title}
                                  </Typography>
                                  <Typography component={Link} to={'/text-course/'+val.slug} className={classes.linkDetail}>
                                    {window.location.protocol + "//" + window.location.host+'/text-course/'+val.slug}
                                  </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                    {val.description !== null &&
                                      EditorState.createWithContent(convertFromRaw(JSON.parse(val.description))).getCurrentContent().getBlocksAsArray().slice(0, 1).map(v=>(
                                        v.getText().length > 160 ? v.getText().substring(0,160)+"..." : v.getText()
                                      ))
                                    }
                                    </Typography>
                                    <div style={{color:'#777',fontSize:14}}>
                                      Loại lớp học <Chip label="Văn bản" color ="secondary" className={classes.chip} style={{height:20,fontSize:12}}/>
                                    </div>
                                </div>
                              </React.Fragment>
                            )
                        ))}
                      </React.Fragment>
                    )}
                    <Pagination
                      totalRecords={allClass.length}
                      pageLimit={10}
                      pageNeighbours={10}
                      onPageChanged={this.onPageChanged}
                    />
                    </TabContainer>}
                    {value === 1 && <TabContainer>
                      {allClass.length === 0 ?(
                      <Typography variant="body1" gutterBottom>
                      Không có lớp học nào cho từ khóa <b>{keyword}</b>
                    </Typography>
                    ):(
                      <React.Fragment>
                        <Typography variant="body1" gutterBottom>
                      Kết quả tìm kiếm lớp học cho từ khóa <b>{keyword}</b>
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{marginBottom:30}}>
                      Khoảng {videoClass.length} kết quả ({milisecond+' giây'})
                    </Typography>
                        {currentVideoClass.map(val=>(
                              <React.Fragment key={val.id}>
                              <Typography component={Link} to={'/video-course/'+val.slug} className={classes.linkTitle}>
                                {val.title}
                              </Typography>
                              <Typography component={Link} to={'/video-course/'+val.slug} className={classes.linkDetail}>
                                {window.location.protocol + "//" + window.location.host+'/video-course/'+val.slug}
                              </Typography>
                              <Grid container spacing={16} style={{marginBottom:20}}>
                                <Grid item xs={12} sm={3} md={3}>
                                  <img style={{width:'100%'}} src={(val.class_image !== null) ? val.class_image : "/no-preview.jpg"} alt={val.title} />
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                  <Typography variant="body2" color="textSecondary">
                                    {val.description !== null &&
                                      EditorState.createWithContent(convertFromRaw(JSON.parse(val.description))).getCurrentContent().getBlocksAsArray().map(v=>(
                                        v.getText().length > 160 ? v.getText().substring(0,160)+"..." :v.getText()
                                      ))
                                    }
                                    </Typography>
                                    <div style={{color:'#777',fontSize:14}}>
                                      Loại lớp học <Chip label="Video" color ="primary" className={classes.chip} style={{height:20,fontSize:12}}/>
                                    </div>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                            
                        ))}
                      </React.Fragment>
                    )}
                    <Pagination
                      totalRecords={videoClass.length}
                      pageLimit={10}
                      pageNeighbours={10}
                      onPageChanged={this.onPageChanged}
                    />
                    </TabContainer>}
                    {value === 2 && <TabContainer>
                      {allClass.length === 0 ?(
                      <Typography variant="body1" gutterBottom>
                      Không có lớp học nào cho từ khóa <b>{keyword}</b>
                    </Typography>
                    ):(
                      <React.Fragment>
                        <Typography variant="body1" gutterBottom>
                      Kết quả tìm kiếm lớp học cho từ khóa <b>{keyword}</b>
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{marginBottom:30}}>
                      Khoảng {textClass.length} kết quả ({milisecond+' giây'})
                    </Typography>
                        {currentTextClass.map(val=>(
                       
                              <React.Fragment key={val.id}>
                                <div style={{marginBottom:20}}>
                                  <Typography component={Link} to={'/text-course/'+val.slug} className={classes.linkTitle}>
                                    {val.title}
                                  </Typography>
                                  <Typography component={Link} to={'/text-course/'+val.slug} className={classes.linkDetail}>
                                    {window.location.protocol + "//" + window.location.host+'/text-course/'+val.slug}
                                  </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                    {val.description !== null &&
                                      EditorState.createWithContent(convertFromRaw(JSON.parse(val.description))).getCurrentContent().getBlocksAsArray().slice(0, 1).map(v=>(
                                        v.getText().length > 160 ? v.getText().substring(0,160)+"..." : v.getText()
                                      ))
                                    }
                                    </Typography>
                                    <div style={{color:'#777',fontSize:14}}>
                                      Loại lớp học <Chip label="Văn bản" color ="secondary" className={classes.chip} style={{height:20,fontSize:12}}/>
                                    </div>
                                </div>
                              </React.Fragment>
                        ))}
                      </React.Fragment>
                    )}
                    <Pagination
                      totalRecords={textClass.length}
                      pageLimit={10}
                      pageNeighbours={10}
                      onPageChanged={this.onPageChanged}
                    />
                    </TabContainer>}

                    
                    </Grid>
                </Grid>
                )}
            </div>
        )
    }
}

  export default withStyles(styles)(SearchClass); 
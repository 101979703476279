export const commentConstants = {
    GETALL_COMMENT_CLASS_REQUEST: 'COMMENT_CLASS_GETALL_REQUEST',
    GETALL_COMMENT_CLASS_SUCCESS: 'COMMENT_CLASS_GETALL_SUCCESS',
    GETALL_COMMENT_CLASS_FAILURE: 'COMMENT_CLASS_GETALL_FAILURE', 

    GETALL_COMMENT_CLASS_PENDING_REQUEST: 'COMMENT_CLASS_PENDING_GETALL_REQUEST',
    GETALL_COMMENT_CLASS_PENDING_SUCCESS: 'COMMENT_CLASS_PENDING_GETALL_SUCCESS',
    GETALL_COMMENT_CLASS_PENDING_FAILURE: 'COMMENT_CLASS_PENDING_GETALL_FAILURE',

    GETALL_COMMENT_BYCLASS_REQUEST: 'COMMENT_BYCLASS_GETALL_REQUEST',
    GETALL_COMMENT_BYCLASS_SUCCESS: 'COMMENT_BYCLASS_GETALL_SUCCESS',
    GETALL_COMMENT_BYCLASS_FAILURE: 'COMMENT_BYCLASS_GETALL_FAILURE',

    ADD_COMMENT_BYCLASS_SUCCESS: 'COMMENT_BYCLASS_ADD_SUCCESS',
    ADD_COMMENT_BYCLASS_FAILURE: 'COMMENT_BYCLASS_ADD_FAILURE',
    ADD_COMMENT_BYCLASS_REQUEST: 'COMMENT_BYCLASS_ADD_REQUEST',

    DELCOMMENTCLASS_SUCCESS: 'ADMIN_DELCOMMENTCLASS_SUCCESS',
    DELCOMMENTCLASS_FAILURE: 'ADMIN_DELCOMMENTCLASS_FAILURE',
    DELCOMMENTCLASS_REQUEST: 'ADMIN_DELCOMMENTCLASS_REQUEST',

    ACTIVECOMMENTCLASS_SUCCESS: 'ADMIN_ACTIVECOMMENTCLASS_SUCCESS',
    ACTIVECOMMENTCLASS_FAILURE: 'ADMIN_ACTIVECOMMENTCLASS_FAILURE',
    ACTIVECOMMENTCLASS_REQUEST: 'ADMIN_ACTIVECOMMENTCLASS_REQUEST',

}
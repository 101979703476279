import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, CircularProgress   
} from '@material-ui/core';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";

const styles = theme => ({
    root: {
        width:'100%',
        textAlign: 'center',
        marginTop:50
    },
})

class QuizIntro extends React.Component{
    state = {
        introData: [],
        loading:true
      };
    componentDidMount(){
        axios.get(config.get("apiDomain")+'class/detail/'+this.props.match.params.slug)
        .then(res => {
            this.setState({
                introData: res.data,
                loading:false
            });
            
        })
    }
    render(){
        const { classes } = this.props;
        
        return(
            <div className={classes.root}>
                <MetaTags>
                    <title>Giới thiệu bài kiểm tra {this.state.introData.title} - LearnSkill</title>
                    <meta name="description" content={'Thực hiện làm bài kiểm tra đánh giá khóa học '+this.state.introData.title+' trên hệ thống học trực tuyến learnskill'} />
                    <meta property="og:title" content={'Kiểm tra đánh giá khóa học '+this.state.introData.title} />
                    <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
                    <meta property="og:url" content={config.get('domain')} />
                    <meta name="twitter:card" content="summary_large_image" />
                </MetaTags>
                {this.state.loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'absolute',top:'48%',left:'48%'}} />
                ) : (
                    this.state.introData.quiz !== '' ? (
                <React.Fragment>
                <Typography variant="h2" gutterBottom style={{fontSize:34}}>
                    Kiểm tra đánh giá khóa học
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Bạn đã hoàn thành khóa học <span style={{fontWeight:450,color:'#1e88e5'}}>{this.state.introData.title}</span>? Hãy làm bài kiểm tra để kiểm tra kiến thức và kỹ năng sau khi học xong.
                </Typography>
                <Button variant="contained" size="large" color="secondary" component={Link} to={'/quiz/'+this.state.introData.slug} style={{textTransform:'initial',margin:'20px 0 50px 0'}}>Làm bài kiểm tra</Button>
                <Typography variant="body1" gutterBottom>
                    Nếu bạn chưa hoàn thành khóa học <span style={{fontWeight:450,color:'#1e88e5'}}>{this.state.introData.title}</span>? Hãy quay lại học hết khóa học trước khi làm bài kiểm tra.
                </Typography>
                <Button variant="outlined" color="primary" component={Link} to={'/text-course/'+this.state.introData.slug} style={{textTransform:'initial'}}>Quay lại lớp học</Button>
            </React.Fragment>
            ) : (
                <Typography variant="h5">Không có bài kiểm tra cho lớp học này</Typography>
              )
            )}
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(QuizIntro);
import { classConstants } from '../_constants/class.constants';
var items = [];
export function getClass(state = {items}, action) {
  switch (action.type) {
    case classConstants.GETALL_REQUEST:
    return {
      loading: true,
      items: items
    };
  case classConstants.GETALL_SUCCESS:
    return {
      items: action.getClass
    };
  case classConstants.GETALL_FAILURE:
    return { 
      error: action.error
    };
//text deleted
    case classConstants.GETTEXTDELETED_REQUEST:
    return {
      loading: true,
    };
  case classConstants.GETTEXTDELETED_SUCCESS:
    return {
      items: action.textDeleted
    };
  case classConstants.GETTEXTDELETED_FAILURE:
    return { 
      error: action.error
    };

    //video deleted
    case classConstants.GETVIDEODELETED_REQUEST:
    return {
      loading: true,
    };
  case classConstants.GETVIDEODELETED_SUCCESS:
    return {
      items: action.videoDeleted
    };
  case classConstants.GETVIDEODELETED_FAILURE:
    return { 
      error: action.error
    };

    //Get class by id
    case classConstants.GETCLASSBYID_REQUEST:
    return {
      loading: true,
      items: items
    };
  case classConstants.GETCLASSBYID_SUCCESS:
    return {
      items: action.idClass
    };
  case classConstants.GETCLASSBYID_FAILURE:
    return { 
      error: action.error
    };

    //get all admin
    case classConstants.ADMINGETALL_REQUEST:
    return {
      loading: true,
      items: items
    };
  case classConstants.ADMINGETALL_SUCCESS:
    return {
      items: action.getClassAdmin
    };
  case classConstants.ADMINGETALL_FAILURE:
    return { 
      error: action.error
    };

    //get all class text admin
    case classConstants.ADMINGETALLCLASSTEXT_REQUEST:
    return {
      loading: true,
      items: items
    };
  case classConstants.ADMINGETALLCLASSTEXT_SUCCESS:
    return {
      items: action.getClassAdmin
    };
  case classConstants.ADMINGETALLCLASSTEXT_FAILURE:
    return { 
      error: action.error
    };

  //DELETE CLASS
  case classConstants.DELCLASS_REQUEST:
  return {
    deleting: true,
    ...state,
    items: state.items.map(getClass =>
      getClass.id === action.id
        ? { ...getClass, deleting: true }
        : getClass
    )
  };
case classConstants.DELCLASS_SUCCESS:
  return {
    items: state.items.filter(getClass => getClass.id !== action.id)
  };
case classConstants.DELCLASS_FAILURE:
  return {
    ...state,
    items: state.items.map(getClass => {
      if (getClass.id === action.id) {
        const { deleting, ...getClassCopy } = getClass;
        return { ...getClassCopy, deleteError: action.error };
      }

      return getClass;
    })
  };

  //DELETE lesson
  case classConstants.DELLESSON_REQUEST:
  return {
    deleting: true,
    ...state,
    lesson: state.lesson.map(getClass =>
      getClass.id === action.id
        ? { ...getClass, deleting: true }
        : getClass
    )
  };
case classConstants.DELLESSON_SUCCESS:
  return {
    lesson: state.lesson.filter(getClass => getClass.id !== action.id)
  };
case classConstants.DELLESSON_FAILURE:
  return {
    ...state,
    lesson: state.lesson.map(getClass => {
      if (getClass.id === action.id) {
        const { deleting, ...getClassCopy } = getClass;
        return { ...getClassCopy, deleteError: action.error };
      }

      return getClass;
    })
  };

  //DELETE video
  case classConstants.DELVIDEO_REQUEST:
  return {
    deleting: true,
    ...state,
    lesson: state.lesson.map(getClass =>
      getClass.id === action.id
        ? { ...getClass, deleting: true }
        : getClass
    )
  };
case classConstants.DELVIDEO_SUCCESS:
  return {
    lesson: state.lesson.filter(getClass => getClass.id !== action.id)
  };
case classConstants.DELVIDEO_FAILURE:
  return {
    ...state,
    lesson: state.lesson.map(getClass => {
      if (getClass.id === action.id) {
        const { deleting, ...getClassCopy } = getClass;
        return { ...getClassCopy, deleteError: action.error };
      }

      return getClass;
    })
  };



  //update
  case classConstants.ADMINEDITCLASS_REQUEST:
  return {
    updating: true,
  };
  case classConstants.ADMINEDITCLASS_SUCCESS:
  return {
    updating: false,
  };
case classConstants.ADMINEDITCLASS_FAILURE:
  return { 
    error: action.error
  };

  //update lesson
  case classConstants.ADMINEDITLESSON_REQUEST:
  return {
    updating: true,
  };
  case classConstants.ADMINEDITLESSON_SUCCESS:
  return {
    updating: false,
  };
case classConstants.ADMINEDITLESSON_FAILURE:
  return { 
    error: action.error
  };

  //update video
  case classConstants.ADMINEDITVIDEO_REQUEST:
  return {
    updating: true,
  };
  case classConstants.ADMINEDITVIDEO_SUCCESS:
  return {
    updating: false,
  };
case classConstants.ADMINEDITVIDEO_FAILURE:
  return { 
    error: action.error
  };

   //add
   case classConstants.ADMINADDCLASS_REQUEST:
   return {
     adding: true,
   };
   case classConstants.ADMINADDCLASS_SUCCESS:
   return {
     adding: false,
   };
 case classConstants.ADMINADDCLASS_FAILURE:
   return { 
     error: action.error
   };

   //add lesson text
   case classConstants.ADMINADDLESSONTEXT_REQUEST:
   return {
     adding: true,
   };
   case classConstants.ADMINADDLESSONTEXT_SUCCESS:
   return {
     adding: false,
     added: true,
     lesson: state.lesson
   };
 case classConstants.ADMINADDLESSONTEXT_FAILURE:
   return { 
     error: action.error
   };

    //add lesson video
    case classConstants.ADMINADDLESSONVIDEO_REQUEST:
    return {
      adding: true,
    };
    case classConstants.ADMINADDLESSONVIDEO_SUCCESS:
    return {
      adding: false,
      added: true,
      lesson: state.video
    };
  case classConstants.ADMINADDLESSONVIDEO_FAILURE:
    return { 
      error: action.error
    };

   //GET lesson text by classId
   case classConstants.GETALLLESSONBYCLASS_REQUEST:
   return {
     loading: true,
   };
   case classConstants.GETALLLESSONBYCLASS_SUCCESS:
   return {
     lesson: action.getLesson
   };
 case classConstants.GETALLLESSONBYCLASS_FAILURE:
   return { 
     error: action.error
   };

    //GET lesson text admin by classId
    case classConstants.GETALLLESSONADMINBYCLASS_REQUEST:
        return {
          loading: true,
        };
        case classConstants.GETALLLESSONADMINBYCLASS_SUCCESS:
        return {
          lesson: action.getLesson
        };
      case classConstants.GETALLLESSONADMINBYCLASS_FAILURE:
        return { 
          error: action.error
        };

    //GET lesson video by classId
    case classConstants.GETALLLVIDEOBYCLASS_REQUEST:
    return {
      loading: true,
    };
    case classConstants.GETALLLVIDEOBYCLASS_SUCCESS:
    return {
      lesson: action.getVideo
    };
  case classConstants.GETALLLVIDEOBYCLASS_FAILURE:
    return { 
      error: action.error
    };

    //GET lesson video admin by classId
    case classConstants.GETALLLVIDEOADMINBYCLASS_REQUEST:
    return {
      loading: true,
    };
    case classConstants.GETALLLVIDEOADMINBYCLASS_SUCCESS:
    return {
      lesson: action.getVideo
    };
  case classConstants.GETALLLVIDEOADMINBYCLASS_FAILURE:
    return { 
      error: action.error
    };


//RESTORE CLASS
case classConstants.RESTORE_REQUEST:
  return {
    restoring: true,
    ...state,
    items: state.items.map(eclass =>
      eclass.id === action.id
        ? { ...eclass, restoring: true }
        : eclass
    )
  };
case classConstants.RESTORE_SUCCESS:
return {
  items: state.items.filter(eclass => eclass.id !== action.id)
};
case classConstants.RESTORE_FAILURE: 
return {
  ...state,
  items: state.items.map(eclass => {
    if (eclass.id === action.id) {
      const { restoring, ...eclassCopy } = eclass;
      return { ...eclassCopy, restoreError: action.error };
    }

    return eclass;
  })
};

//FORCE DEL CLASS
case classConstants.FORCEDEL_REQUEST:
  return {
    deleting: true,
    ...state,
    items: state.items.map(eclass =>
      eclass.id === action.id
        ? { ...eclass, deleting: true }
        : eclass
    )
  };
case classConstants.FORCEDEL_SUCCESS:
return {
  items: state.items.filter(eclass => eclass.id !== action.id)
};
case classConstants.FORCEDEL_FAILURE:
return {
  ...state,
  items: state.items.map(eclass => {
    if (eclass.id === action.id) {
      const { deleting, ...eclassCopy } = eclass;
      return { ...eclassCopy, deleteError: action.error };
    }

    return eclass;
  })
};


    default:
      return state
  }
}
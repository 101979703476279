import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, Stepper, Step, StepLabel, StepContent, Button, Paper, Typography,
    FormControl, Input } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { menuActions } from '../_actions/menu.actions';
import { userActions } from '../_actions/user.actions';
import axios from 'axios';
import config from "react-global-configuration";
import { FacebookShareButton, TwitterShareButton, FacebookIcon,
    TwitterIcon } from 'react-share';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop:50,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
    image: {
        position: 'relative',
        height: 200,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
            opacity: 0.15,
            },
            '& $imageMarked': {
            opacity: 0,
            },
            '& $imageTitle': {
            border: '4px solid currentColor',
        },
    },
    },
    focusVisible: { },
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px ${theme.spacing.unit + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
})
    function getSteps() {
    return ['Chọn 1 hoặc nhiều lĩnh vực để tùy chỉnh đề xuất cho bạn.', 'Lưu 1 hoặc nhiều lớp học cho kế hoạch học của bạn', 'Create an ad'];
    }
    
   
class RelatedSkill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            data: [],
            dataClass: [],
            eClass: [],
            loading: false,
            hasError: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClickClass = this.handleClickClass.bind(this);
    }
    componentDidMount() {
        this.props.dispatch(menuActions.getAll());
      }

    handleClick(item) {
        const { data, eClass } = this.state;
        const found = data.some(el => el.name === item.name);
        var index = data.findIndex(val => val.name === item.name);
        if(!found){
            this.setState({loading:true, hasError:false});
            axios.get(config.get("apiDomain")+"class/find-all/"+item.id)
            .then(res => {
                this.setState({
                    loading: false,
                    eClass: [
                        ...eClass,
                        res.data
                    ]
                })
            })
            this.setState({
                data: [
                    ...data,
                    {
                        id: item.id,
                        name: item.name,
                    }
                ]
            });
        }else if (found && index !== -1){
            this.setState({
                data: data.filter((_, i) => i !== index),
                eClass: eClass.filter((_, i) => i !== index)
              });
        }
    }
    handleClickClass(item) {
        const { dataClass } = this.state;
        const found = dataClass.some(el => el === item.id);
        var index = dataClass.findIndex(val => val === item.id);
        if(!found){
            this.setState({
                dataClass: [
                    ...dataClass,
                    {id: item.id}
                ]
            });
        }else if (found && index !== -1){
            this.setState({
                dataClass: dataClass.filter((_, i) => i !== index)
              });
        }
    }

  handleNext = () => {
    const { data } = this.state;
    if(data.length === 0){
        this.setState({hasError:true})
    }else{
        let userInfo = JSON.parse(localStorage.getItem('user'));
        let dataSkill = {
            skill_related: JSON.stringify(data)
        }
        this.props.dispatch(userActions.editProfile(userInfo.id, dataSkill));
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
        
    }
  };
  handleNextClass = () => {
    const { dataClass } = this.state;
        let userInfo = JSON.parse(localStorage.getItem('user'));
        const dataClassUpdate = {
            class_related: JSON.stringify(dataClass)
        }
        if(dataClassUpdate.length !== 0){
            this.props.dispatch(userActions.editProfile(userInfo.id, dataClassUpdate));
            this.setState(state => ({
                activeStep: state.activeStep + 1,
            }));
        }
  };
  handleNextFinish = () => {
    this.setState(state => ({
        activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const { classes, menu } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;
    const { handleClick } = this;
    const { handleClickClass } = this;
    return (
        <div>
            <MetaTags>
                    <title>Chọn kỹ năng quan tâm - LearnSkill</title>
                    <meta name="description" content="Chọn kỹ năng quan tâm trên hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                    <meta property="og:title" content="Chọn kỹ năng quan tâm - LearnSkill" />
                    <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
                    <meta property="og:url" content={config.get('domain')} />
                    <meta name="twitter:card" content="summary_large_image" />
                </MetaTags>
        <Grid container className={classes.root}>
            <Grid item xs={12} align="center">
                <Typography variant="h2"  style={{paddingTop: 50,paddingBottom:10,fontSize:30}}>
                    Bạn quan tâm những lĩnh vực nào?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item md={1}></Grid>
                    <Grid item xs={12} sm={12} md={10}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                    
                        <Step key={0}>
                        <StepLabel>Chọn 1 hoặc nhiều lĩnh vực để tùy chỉnh đề xuất cho bạn.</StepLabel>
                        <StepContent>
                        {menu.loading ? (
                            <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                        ) : (
                            <Typography>
                            {menu.items.map(val => (
                                val.submenu.map(item=>(
                                <ButtonBase
                                focusRipple
                                key={item.id}
                                className={classes.image}
                                focusVisibleClassName={classes.focusVisible}
                                onClick={() => handleClick(item)}
                                style={{
                                    width: '22%',
                                    height: 125,
                                    margin: 10,
                                }}
                                id={item.id}
                                name={item.name}
                                >
                                <span
                                    className={classes.imageSrc}
                                    style={{
                                    backgroundImage: `url(/images/${item.slug}.png)`,
                                    }}
                                />
                                <span className={classes.imageBackdrop} />
                                <span className={classes.imageButton}>
                                <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                className={classes.imageTitle}
                                >
                                    {item.name}
                                    <span className={classes.imageMarked} />
                                    </Typography>
                                </span>
                                </ButtonBase>
                                ))
                            ))}
                            </Typography>
                        )}
                            
                            <div className={classes.actionsContainer}>
                            {this.props.updating &&
                            <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                            }
                            {this.state.hasError ? <div style={{color:'red'}}>Bạn phải chọn ít nhất 1 lĩnh vực quan tâm</div> : '' }
                            <div>
                                <Button
                                disabled={activeStep === 0}
                                onClick={this.handleBack}
                                className={classes.button}
                                >
                                Quay lại
                                </Button>
                                <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleNext}
                                className={classes.button}
                                >
                                {activeStep === steps.length - 1 ? 'Hoàn thành' : 'Tiếp theo'}
                                </Button>
                            </div>
                            </div>
                        </StepContent>
                        </Step>

                        <Step key={1}>
                        <StepLabel>Lưu 1 hoặc nhiều lớp học cho kế hoạch học của bạn</StepLabel>
                        <StepContent>
                            {this.state.loading ? (
                                <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                            ) : (
                                <Typography>
                                    
                            {this.state.eClass.map((val, index) => (
                                val.map((item, index) => (
                                <ButtonBase
                                focusRipple
                                key={index}
                                className={classes.image}
                                focusVisibleClassName={classes.focusVisible}
                                onClick={() => handleClickClass(item)}
                                style={{
                                    width: '22%',
                                    height: 125,
                                    margin: 10,
                                }}
                                >
                                <span
                                    className={classes.imageSrc}
                                    style={{
                                    backgroundImage: `url(${config.get('apiImg')+item.class_image})`,
                                    }}
                                />
                                <span className={classes.imageBackdrop} />
                                <span className={classes.imageButton}>
                                <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                className={classes.imageTitle}
                                >
                                    {item.title}
                                    <span className={classes.imageMarked} />
                                    </Typography>
                                </span>
                                </ButtonBase>
                                ))
                            ))}
                            </Typography>
                            )}
                            
                            <div className={classes.actionsContainer}>
                            
                            <div>
                                <Button
                                disabled={activeStep === 0}
                                onClick={this.handleBack}
                                className={classes.button}
                                >
                                Quay lại
                                </Button>
                                <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleNextClass}
                                className={classes.button}
                                >
                                {activeStep === steps.length - 1 ? 'Hoàn thành' : 'Tiếp theo'}
                                </Button>
                            </div>
                            </div>
                        </StepContent>
                        </Step>

                        <Step key={2}>
                        <StepLabel>Phát triển cộng đồng Learn Skill</StepLabel>
                        <StepContent>
                            <div style={{marginBottom:20}}>
                            Giúp phát triển cộng đồng học tập bằng cách chia sẻ Learn Skill trên mạng xã hội của bạn.
                            <br /> 
                            <FormControl className={classes.formControl} disabled style={{marginTop:20}}>
                                <Input id="component-disabled" value="bit.ly/2YVexjb" />
                            </FormControl>
                            <div className="network" style={{marginTop:20, marginLeft:30}}>
                                <FacebookShareButton
                                    url="http://bit.ly/2YVexjb"
                                    quote="Học kỹ năng trực tuyến Learn Skill"
                                    className="network__share-button">
                                    <FacebookIcon
                                    size={32}
                                    round 
                                    />
                                </FacebookShareButton>
                                </div>
                                <div className="network" style={{marginTop:20}}>
                                    <TwitterShareButton
                                        url="http://bit.ly/2YVexjb"
                                        title="Học kỹ năng trực tuyến Learn Skill"
                                        className="network__share-button">
                                        <TwitterIcon
                                        size={32}
                                        round />
                                    </TwitterShareButton>
                                </div>
                            </div>
                            <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                disabled={activeStep === 0}
                                onClick={this.handleBack}
                                className={classes.button}
                                >
                                Quay lại
                                </Button>
                                <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleNextFinish}
                                className={classes.button}
                                >
                                {activeStep === steps.length - 1 ? 'Hoàn thành' : 'Tiếp theo'}
                                </Button>
                            </div>
                            </div>
                        </StepContent>
                        </Step>
                    
                    </Stepper>
                    {activeStep === steps.length && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                        <Typography>Bạn đã hoàn thành các bước</Typography>
                        <Button component={Link} to="/home" title="Trang chủ thành viên" className={classes.button} variant="contained"
                                color="secondary">
                        Vào trang chủ của bạn
                        </Button>
                    </Paper>
                    )}
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </Grid>
        </Grid>
        </div>
    );
  }
}

RelatedSkill.propTypes = {
  classes: PropTypes.object,
};

function mapStateToProps(state) {
    const { menu } = state;
    const { updating } = state.editProfile;
    return {
      menu, updating
    };
  }
  
  export default connect(mapStateToProps)(withStyles(styles)(RelatedSkill));
import { menuTipsConstants } from '../_constants/menuTips.constant';
var items = [];
export function menuTips(state = {items}, action) {
  switch (action.type) {
    case menuTipsConstants.GETALL_REQUEST:
    return {
      loading: true,
      items: items
    };
  case menuTipsConstants.GETALL_SUCCESS:
    return {
      items: action.menu
    };
  case menuTipsConstants.GETALL_FAILURE:
    return { 
      error: action.error
    };

    //Get menu name
    case menuTipsConstants.GETNAME_REQUEST:
    return {
      loading: true,
    };
  case menuTipsConstants.GETNAME_SUCCESS:
    return {
      items: action.menuName
    };
  case menuTipsConstants.GETNAME_FAILURE:
    return { 
      error: action.error
    };

    //Get menu by id
    case menuTipsConstants.GETMENUBYID_REQUEST:
    return {
      loading: true,
    };
  case menuTipsConstants.GETMENUBYID_SUCCESS:
    return {
      catMenu: action.menuid
    };
  case menuTipsConstants.GETMENUBYID_FAILURE:
    return { 
      error: action.error
    };

    //GET ALL ADMIN
    case menuTipsConstants.GETALLADMIN_REQUEST:
    return {
      loading: true,
      items: items
    };
  case menuTipsConstants.GETALLADMIN_SUCCESS:
    return {
      items: action.menuAdmin
    };
  case menuTipsConstants.GETALLADMIN_FAILURE:
    return { 
      error: action.error
    };

    //update
    case menuTipsConstants.ADMINEDITMENU_REQUEST:
    return {
      updating: true,
    };
    case menuTipsConstants.ADMINEDITMENU_SUCCESS:
    return {
      updating: false,
    };
  case menuTipsConstants.ADMINEDITMENU_FAILURE:
    return { 
      error: action.error
    };

    //add
    case menuTipsConstants.ADMINADDMENU_REQUEST:
    return {
      adding: true,
    };
    case menuTipsConstants.ADMINADDMENU_SUCCESS:
    return {
      adding: false,
    };
  case menuTipsConstants.ADMINADDMENU_FAILURE:
    return { 
      error: action.error
    };

//DELETE USER
case menuTipsConstants.DELETE_REQUEST:
// add 'deleting:true' property to user being deleted
return {
  deleting: true,
  ...state,
  items: state.items.map(menu =>
    menu.id === action.id
      ? { ...menu, deleting: true }
      : menu
  )
};
case menuTipsConstants.DELETE_SUCCESS:
// remove deleted user from state
return {
  items: state.items.filter(menu => menu.id !== action.id)
};
case menuTipsConstants.DELETE_FAILURE:
// remove 'deleting:true' property and add 'deleteError:[error]' property to user 
return {
  ...state,
  items: state.items.map(menu => {
    if (menu.id === action.id) {
      // make copy of user without 'deleting:true' property
      const { deleting, ...menuCopy } = menu;
      // return copy of user with 'deleteError:[error]' property
      return { ...menuCopy, deleteError: action.error };
    }

    return menu;
  })
};

//get all deleted
    case menuTipsConstants.GETALLDELETED_REQUEST:
      return {
        loading: true
      };
    case menuTipsConstants.GETALLDELETED_SUCCESS:
      return {
        items: action.getAll
      };
    case menuTipsConstants.GETALLDELETED_FAILURE:
      return { 
        error: action.error
      };

//RESTORE MENU
case menuTipsConstants.RESTORE_REQUEST:
  return {
    restoring: true,
    ...state,
    items: state.items.map(menu =>
      menu.id === action.id
        ? { ...menu, restoring: true }
        : menu
    )
  };
case menuTipsConstants.RESTORE_SUCCESS:
return {
  items: state.items.filter(menu => menu.id !== action.id)
};
case menuTipsConstants.RESTORE_FAILURE: 
return {
  ...state,
  items: state.items.map(menu => {
    if (menu.id === action.id) {
      const { restoring, ...menuCopy } = menu;
      return { ...menuCopy, restoreError: action.error };
    }

    return menu;
  })
};

//FORCE DEL MENU
case menuTipsConstants.FORCEDEL_REQUEST:
  return {
    deleting: true,
    ...state,
    items: state.items.map(menu =>
      menu.id === action.id
        ? { ...menu, deleting: true }
        : menu
    )
  };
case menuTipsConstants.FORCEDEL_SUCCESS:
return {
  items: state.items.filter(menu => menu.id !== action.id)
};
case menuTipsConstants.FORCEDEL_FAILURE:
return {
  ...state,
  items: state.items.map(menu => {
    if (menu.id === action.id) {
      const { deleting, ...menuCopy } = menu;
      return { ...menuCopy, deleteError: action.error };
    }

    return menu;
  })
};



    default:
      return state
  }
}
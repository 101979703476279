import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, Tabs, Tab, Card, 
    CardActionArea,  CardActions, CircularProgress, Chip   
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import { PlayCircleOutline, PeopleOutline, TouchApp, TrendingUp } from '@material-ui/icons';
import Dataslider from './Dataslider';
import Slider from 'react-animated-slider';
import './home.css'
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Timestamp from 'react-timestamp';
import axios from 'axios';
import config from "react-global-configuration";

const styles = theme => ({
    root: {
    //   width: '100%',
    },
    section:{
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
        marginBottom: 30,
      },
      tabItem:{
          fontSize: '1em',
          textTransform: 'capitalize',
          color: '#424242',
          minWidth: 80,
          minHeight: 35,
      },
      media:{
        height: 120,
        objectFit: 'cover',
        width: '100%',
      },
      card:{
        width: '100%',
      },
      caption:{
          color: '#777',
      },
     
        teacherName:{
            color: '#777',
            textDecoration: 'none',
            cursor: 'pointer',
            '&:hover':{
                color: 'red',
                textDecoration: 'none',
            }
        },
      iconTime:{
        fontSize: 13,
        position: 'relative',
        bottom: -2,
      },
      center:{
          textAlign: 'center',
      },
      btnPlay:{
          position: 'absolute',
          top: '30%',
          left: '40%',
          fontSize: 50,
          '&:hover':{
            color: '#D50000',
          }
          
      },
        whyChoose:{
            background: '#E3F2FD',
            marginTop: 50,
            paddingBottom: 50,
        },
        mobile:{
            background: '#282c34 url("../../images/mobile.jpg")',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'relative',
            height: 420,
            color: '#fff',
            padding: '80px 0 50px 0',
        },
        amazing:{
            background: '#fff url("../../images/desktop-2-rows.png")',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'relative',
            height: 350,
            margin: '80px 0 50px 0',
        },
        listTeacher:{
            background: '#282c34 url("../../images/teachers-hero.jpg")',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'relative',
            height: 420,
            margin: '30px 0 20px 0',
            padding: '100px 0',
            color: '#fff',
        },
        gray:{
            color: '#666',
        },
        linkListTeacher:{
            color: '#1565C0',
            textDecoration: 'none',
            marginLeft: 20,
            fontWeight: 'normal',
            '&:hover':{
                color: '#d50000',
            }
        },
        titleClass:{
            color: '#444',
            fontSize: '1.1em',
            fontWeight: 450,
            textDecoration: 'none',
            marginBottom: 30,
            '&:hover':{
                color: '#1e88e5',
            }
        },
        ListItem:{
            listStyleType: 'none',
            margin: 0,
            padding: 0,
        },
        listItemText:{
            margin: '7px 0',
        },
        linkSection:{
            color: '#666',
            textDecoration: 'none',
            '&:hover':{
                color: '#d50000'
            }
        },
        placeholder:{
            position: 'absolute',
            top:'48%',
            left: '48%',
        },
        box:{
            padding: 50,
            border: '1px dashed #999',
        },
        rightIcon: {
            marginLeft:10,
        },
        chip: {
            fontSize:12,
            height:20,
            borderRadius: 3,
            
        },
});

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 5 * 2 }}>
        {props.children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };

class Home extends React.Component {
    state = {
      value: 0,
      anchorEl: null,
      allClass: [],
      catIT: [],
      catLife: [],
      loading: true
    };
  
    handleChange = (event, value) => {
      this.setState({ value });
    };
    componentDidMount() {
        axios.get(config.get("apiDomain")+"class/all-home")
        .then(res => {
            this.setState({allClass: res.data})
        })

        axios.get(config.get("apiDomain")+"class/section/home/1")
        .then(res => {
            this.setState({catIT: res.data})
        })
        axios.get(config.get("apiDomain")+"class/section/home/44")
        .then(res => {
            this.setState({catLife: res.data, loading: false})
        })
    }
  
    render() {
        const { value, allClass, catIT, catLife } = this.state;
        const { classes} = this.props;
    return(
        <div>
            <Helmet>
                <style>{'body { background-color: #f1f1f1; }'}</style>
            </Helmet>
            <Slider autoplay={5000}>
            {Dataslider.map((item, index) => (
                <div
                    key={index}
                    className="sliderContent"
                    style={{ background: `url('${item.image}') no-repeat center center`,marginTop:55 }}
                >
                    <div className="inner">
                        <h1>{item.title}</h1>
                        <p>{item.description}</p>
                        <Button variant="contained" color="secondary" size="large" component={Link} to={item.url}>
                            {item.button}
                        </Button>
                    </div>
                </div>
            ))}
			</Slider>

            <Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" color="primary" className={classes.section}>
                        Tăng kỹ năng, tăng lương, tăng cơ hội thăng tiến
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab className={classes.tabItem} label="Tất cả" />
                        <Tab className={classes.tabItem} label="Công nghệ thông tin" />
                        <Tab className={classes.tabItem} label="Cuộc sống" />
                        <Tab className={classes.tabItem} label="Thiết kế" />
                        <Tab className={classes.tabItem} label="Phim ảnh" />
                        <Tab className={classes.tabItem} label="làm đẹp" />
                    </Tabs>
                    {value === 0 && <TabContainer>
                        <Grid container>
                            <Grid item md={1}></Grid>
                            <Grid item xs={12} sm={12} md={10}>
                                <Grid container spacing = {16} style={{position:'relative'}}>
                                
                                {this.state.loading ? (
                                    <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                                ) : (
                                    allClass.length === 0 ? (
                                        <Grid item xs={12}>
                                            <Typography variant="h6" align="center" className={classes.box}>
                                            Không có lớp học cho lĩnh vực này
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        allClass.map(item => (
                                            <Grid item xs={12} sm={6} md={3} key={item.id}>
                                            <Card>
                                                <CardActionArea className={classes.videoThumbnail}>
                                                    <Link to={item.isVideo === 1 ? "/video-course/"+item.slug : "/text-course/"+item.slug}>
                                                    {item.isVideo === 1 &&
                                                        <PlayCircleOutline color="primary" className={classes.btnPlay} />
                                                    }
                                                    <img src={(item.class_image !== null) ? config.get('apiImg')+item.class_image : "/no-preview.jpg"} className={classes.media} alt={item.title}/>
                                                    </Link>
                                                </CardActionArea>
                                                <CardActions style={ {padding:'2px'} }>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography className={classes.caption} variant="caption" align="left">
                                                                {/* {item.student === null ? "0" : JSON.parse(item.student).length} người học */}
                                                                {item.countViews} lượt xem
                                                            </Typography>
                                                        </Grid>
                                                        
                                                        <Grid item xs={6}>
                                                            <Typography className={classes.caption} variant="caption" align="right">
                                                            
                                                            <Timestamp date={new Date(item.created_at)} relative autoUpdate />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" gutterBottom>
                                                                <Link to={item.isVideo === 1 ? "/video-course/"+item.slug : "/text-course/"+item.slug} className={classes.titleClass}>{item.title}</Link>
                                                            </Typography>
                                                            Loại lớp học: <Chip
                                                                label={item.isVideo === 1 ? 'Video' : 'Văn bản'}
                                                                className={classes.chip}
                                                                color={item.isVideo === 1 ? 'secondary' : 'primary'}
                                                            />
                                                            <Typography style={{marginTop:20}}>
                                                                <Link to={"/user/"+item.user.id+'/'+item.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{item.user.name}</Link> 
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )
                                    
                                    ))
                                )}
                                </Grid>
                            </Grid>
                            <Grid item md={1}></Grid>
                        </Grid>
                    </TabContainer>}
                    {value === 1 && <TabContainer>
                        <Grid container>
                            <Grid item md={1}></Grid>
                                <Grid item xs={12} sm={12} md={10}>
                                    <Grid container spacing = {16} style={{position:'relative'}}>
                                    {catIT.length === 0 ? (
                                        <Grid item xs={12}>
                                            <Typography variant="h6" align="center" className={classes.box}>
                                            Không có lớp học cho lĩnh vực này
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        catIT.map(item => (
                                            <Grid item xs={6} sm={4} md={3} key={item.id}>
                                            <Card>
                                                <CardActionArea className={classes.videoThumbnail}>
                                                    <Link to={item.isVideo === 1 ? "/video-course/"+item.slug : "/text-course/"+item.slug}>
                                                    {item.isVideo === 1 &&
                                                        <PlayCircleOutline color="primary" className={classes.btnPlay} />
                                                    }
                                                    <img src={(item.class_image !== null) ? config.get('apiImg')+item.class_image : "/no-preview.jpg"} className={classes.media} alt={item.title}/>
                                                    </Link>
                                                </CardActionArea>
                                                <CardActions style={ {padding:'2px'} }>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography className={classes.caption} variant="caption" align="left">
                                                            {item.countViews} lượt xem
                                                            </Typography>
                                                        </Grid>
                                                        {item.isVideo === 1 &&
                                                        <Grid item xs={6}>
                                                            <Typography className={classes.caption} variant="caption" align="right">
                                                            
                                                            <Icon className={classes.iconTime}>schedule</Icon> 3h30'
                                                            </Typography>
                                                        </Grid>
                                                        }
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" gutterBottom>
                                                                <Link to={item.isVideo === 1 ? "/video-course/"+item.slug : "/text-course/"+item.slug} className={classes.titleClass}>{item.title}</Link>
                                                            </Typography>
                                                            Loại lớp học: <Chip
                                                                label={item.isVideo === 1 ? 'Video' : 'Văn bản'}
                                                                className={classes.chip}
                                                                color={item.isVideo === 1 ? 'secondary' : 'primary'}
                                                            />
                                                            <Typography style={{marginTop:20}}>
                                                                <Link to={"/user/"+item.user.id+'/'+item.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{item.user.name}</Link> 
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )
                                    )
                                )}
                                    </Grid>
                                </Grid>
                            <Grid item md={1}></Grid>
                        </Grid>
                    </TabContainer>}
                    {value === 2 && <TabContainer>
                        <Grid container>
                            <Grid item md={1}></Grid>
                            <Grid item xs={12} sm={12} md={10}>
                                <Grid container spacing = {16} style={{position:'relative'}}>
                                {catLife.length === 0 ? (
                                        <Grid item xs={12}>
                                            <Typography variant="h6" align="center" className={classes.box}>
                                            Không có lớp học cho lĩnh vực này
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        catLife.map(item => (
                                            <Grid item xs={6} sm={4} md={3} key={item.id}>
                                            <Card>
                                                <CardActionArea className={classes.videoThumbnail}>
                                                    <Link to={item.isVideo === 1 ? "/video-course/"+item.slug : "/text-course/"+item.slug}>
                                                    {item.isVideo === 1 &&
                                                        <PlayCircleOutline color="primary" className={classes.btnPlay} />
                                                    }
                                                    <img src={(item.class_image !== null) ? config.get('apiImg')+item.class_image : "/no-preview.jpg"} className={classes.media} alt={item.title}/>
                                                    </Link>
                                                </CardActionArea>
                                                <CardActions style={ {padding:'2px'} }>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography className={classes.caption} variant="caption" align="left">
                                                            {item.countViews} lượt xem
                                                            </Typography>
                                                        </Grid>
                                                        {/* {item.isVideo === 1 &&
                                                        <Grid item xs={6}>
                                                            <Typography className={classes.caption} variant="caption" align="right">
                                                            
                                                            <Icon className={classes.iconTime}>schedule</Icon> 3h30'
                                                            </Typography>
                                                        </Grid>
                                                        } */}
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" gutterBottom>
                                                                <Link to={item.isVideo === 1 ? "/video-course/"+item.slug : "/text-course/"+item.slug} className={classes.titleClass}>{item.title}</Link>
                                                            </Typography>
                                                            Loại lớp học: <Chip
                                                                label={item.isVideo === 1 ? 'Video' : 'Văn bản'}
                                                                className={classes.chip}
                                                                color={item.isVideo === 1 ? 'secondary' : 'primary'}
                                                            />
                                                            <Typography style={{marginTop:20}}>
                                                                <Link to={"/user/"+item.user.id+'/'+item.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{item.user.name}</Link> 
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )
                                    )
                                )}
                                </Grid>
                            </Grid>
                            <Grid item md={1}></Grid>
                        </Grid>
                    </TabContainer>}
                    {value === 3 && <TabContainer>
                        <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center" className={classes.box}>
                            Không có lớp học cho lĩnh vực này
                            </Typography>
                        </Grid>
                        </Grid>
                    </TabContainer>}
                    {value === 4 && <TabContainer>
                        <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center" className={classes.box}>
                            Không có lớp học cho lĩnh vực này
                            </Typography>
                        </Grid>
                        </Grid>
                    </TabContainer>}
                    {value === 5 && <TabContainer>
                        <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center" className={classes.box}>
                            Không có lớp học cho lĩnh vực này
                            </Typography>
                        </Grid>
                        </Grid>
                    </TabContainer>}
                </Grid>
                
                <Grid item xs={12} className={classes.center} style={{marginTop:30}}>
                    <Button variant="contained" size="large" color="primary" className={classes.button} component={Link} to="/lop-hoc" title="Khám phá các lớp học">
                        Khám phá các lớp học 
                        <Icon className={classes.rightIcon}>send</Icon>
                    </Button>
                </Grid>

                {/* Why choose */}
                <Grid item xs={12} className={classes.whyChoose} style={{padding: '0 10px'}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h4" color="primary" className={classes.section}>
                                Tại sao Learnskill?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} align="center" style={{paddingBottom:50, paddingTop:10}}>
                            <PeopleOutline style={{fontSize:80}} color="primary"/>
                            <Typography variant="h6">
                                Học với mọi người
                            </Typography>
                            <Typography variant="body1">
                            Chia sẻ công việc và thông tin với cộng đồng của chúng tôi.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} align="center" style={{paddingBottom:50, paddingTop:10}}>
                            <TouchApp style={{fontSize:80}} color="primary"/>
                            <Typography variant="h6">
                                Học song hành với làm
                            </Typography>
                            <Typography variant="body1">
                            Thực hành cái bạn học bằng việc thực hiện các dự án trong mỗi bài học.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} align="center" style={{paddingBottom:50, paddingTop:10}}>
                            <TrendingUp style={{fontSize:80}} color="primary" />
                            <Typography variant="h6">
                                Làm chủ tương lai
                            </Typography>
                            <Typography variant="body1">
                            Các lớp học được giảng dạy bởi các chuyên gia về các xu hướng và công nghệ mới.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container className={classes.mobile} style={{paddingLeft:10,paddingRight:10}}>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h4" color="inherit">
                            Học bất cứ đâu.
                            </Typography> <br />
                            <Typography variant="body1" color="inherit" style={{fontSize:20}}>
                            Học tập với ứng dụng Learnskill tại bất cứ đâu - trên máy bay, xe buýt, công viên hoặc bất cứ đâu bạn muốn.
                            </Typography>
                            <br />
                            <Link to="/" title="learnskill ios app">
                                <img src="images/app-store-black.svg" alt="learnskill ios app" />
                            </Link>&nbsp;&nbsp;
                            <Link to="/" title="learnskill ios app">
                                <img src="images/android-footer-black.svg" alt="learnskill android app" />
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6}></Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container className={classes.amazing} style={{padding:'0 10px'}}>
                        <Grid item xs={12} md={6}></Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h4" style={{marginTop:30}} color="primary">
                            Làm một điều gì đó tuyệt vời
                            </Typography> <br />
                            <Typography variant="body1" className={classes.gray} style={{fontSize:20}}>
                            Học tập với ứng dụng Learnskill tại bất cứ đâu - trên máy bay, xe buýt, công viên hoặc bất cứ đâu bạn muốn.
                            </Typography>
                            <br />
                            <Button variant="contained" size="large" color="primary" className={classes.button} href="/" title="Khám phá các lớp học">
                                Xem các dự án 
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container className={classes.listTeacher} style={{padding:"0 10px"}}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={6} className={classes.center}>
                            <Typography variant="h4" color="inherit">
                            Dạy học trên Learnskill
                            </Typography> <br />
                            <Typography variant="body1" color="inherit" style={{fontSize:20}}>
                            Chia sẻ kiến thức chuyên môn của bạn, xây dựng cộng đồng giáo dục.
                            </Typography>
                            <br />
                            <Button variant="contained" size="large" color="secondary" className={classes.button} href="/" title="Khám phá các lớp học">
                                Tìm hiểu thêm
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>                
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.center} style={{marginBottom:50, padding: '0 10px'}}>
                    <Typography variant="h6" className={classes.gray} style={{wordWrap: 'break-word'}} >
                        DS giáo viên:
                        <Link to="/danh-sach-giao-vien/A" title="A" className={classes.linkListTeacher}>A</Link> 
                        <Link to="/danh-sach-giao-vien/B" title="B" className={classes.linkListTeacher}>B</Link>
                        <Link to="/danh-sach-giao-vien/C" title="C" className={classes.linkListTeacher}>C</Link>
                        <Link to="/danh-sach-giao-vien/D" title="D" className={classes.linkListTeacher}>D</Link>
                        <Link to="/danh-sach-giao-vien/E" title="E" className={classes.linkListTeacher}>E</Link>
                        <Link to="/danh-sach-giao-vien/G" title="G" className={classes.linkListTeacher}>G</Link>
                        <Link to="/danh-sach-giao-vien/H" title="H" className={classes.linkListTeacher}>H</Link>
                        <Link to="/danh-sach-giao-vien/K" title="K" className={classes.linkListTeacher}>K</Link>
                        <Link to="/danh-sach-giao-vien/L" title="L" className={classes.linkListTeacher}>L</Link>
                        <Link to="/danh-sach-giao-vien/M" title="M" className={classes.linkListTeacher}>M</Link>
                        <Link to="/danh-sach-giao-vien/N" title="N" className={classes.linkListTeacher}>N</Link>
                        <Link to="/danh-sach-giao-vien/O" title="O" className={classes.linkListTeacher}>O</Link>
                        <Link to="/danh-sach-giao-vien/P" title="P" className={classes.linkListTeacher}>P</Link>
                        <Link to="/danh-sach-giao-vien/Q" title="Q" className={classes.linkListTeacher}>Q</Link>
                        <Link to="/danh-sach-giao-vien/R" title="R" className={classes.linkListTeacher}>R</Link>
                        <Link to="/danh-sach-giao-vien/S" title="S" className={classes.linkListTeacher}>S</Link>
                        <Link to="/danh-sach-giao-vien/T" title="T" className={classes.linkListTeacher}>T</Link>
                        <Link to="/danh-sach-giao-vien/U" title="U" className={classes.linkListTeacher}>U</Link>
                        <Link to="/danh-sach-giao-vien/V" title="V" className={classes.linkListTeacher}>V</Link>
                        <Link to="/danh-sach-giao-vien/X" title="X" className={classes.linkListTeacher}>X</Link>
                        <Link to="/danh-sach-giao-vien/Y" title="Y" className={classes.linkListTeacher}>Y</Link>                     
                    </Typography>
                </Grid>

            </Grid>

            {/* <Grid container className={classes.whyChoose} style={{marginTop:20}}>
                <Grid item xs={12} md={2}></Grid>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12} sm ={6} md={3}>
                            <Typography variant="h6" style={{marginTop:30, textTransform:'uppercase',fontSize:16}} className={classes.gray}>
                            Sáng tạo
                            </Typography>
                            <ul className={classes.ListItem}>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}>
                                    <Typography variant="button" gutterBottom className={classes.gray}>
                                        <Link className={classes.linkSection} to="/" title="Xem thêm">Thêm...</Link>
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm ={6} md={3}>
                            <Typography variant="h6" style={{marginTop:30, textTransform:'uppercase',fontSize:16}} className={classes.gray}>
                            Công nghệ thông tin 
                            </Typography>
                            <ul className={classes.ListItem}>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}>
                                    <Typography variant="button" gutterBottom className={classes.gray}>
                                        <Link className={classes.linkSection} to="/" title="Xem thêm">Thêm...</Link>
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm ={6} md={3}>
                            <Typography variant="h6" style={{marginTop:30, textTransform:'uppercase',fontSize:16}} className={classes.gray}>
                            Kinh doanh 
                            </Typography>
                            <ul className={classes.ListItem}>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}>
                                <Typography variant="button" gutterBottom className={classes.gray}>
                                        <Link className={classes.linkSection} to="/" title="Xem thêm">Thêm...</Link>
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm ={6} md={3}>
                        <Typography variant="h6" style={{marginTop:30, textTransform:'uppercase',fontSize:16}} className={classes.gray}>
                            Cuộc sống
                            </Typography>
                            <ul className={classes.ListItem}>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}><Link className={classes.linkSection} to="/" title="tiêu đề">Hội họa</Link></li>
                                <li className={classes.listItemText}>
                                    <Typography variant="button" gutterBottom className={classes.gray}>
                                        <Link className={classes.linkSection} to="/" title="Xem thêm">Thêm...</Link>
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={2}></Grid>
            </Grid>*/}
        {/* </Grid>  */}
    </div>
    )
}
}

export default withStyles(styles)(Home);
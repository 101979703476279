import { commentConstants } from '../_constants/comment.constants';
import axios from 'axios';
import config from "react-global-configuration";
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux'
import { authHeader } from '../_helpers/auth-header';
import { alertActions } from './alert.actions';
// import { history } from '../_helpers/history';

export const commentActions = {
    getAllCommentClass, deleteCommentClass, activeCommentClass, getCommentByClass, addComment, getAllCommentClassPending
};

function getAllCommentClass() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"admin/get-all-comment",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu comment'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request() { return { type: commentConstants.GETALL_COMMENT_CLASS_REQUEST } }
    function success(getComment) { return { type: commentConstants.GETALL_COMMENT_CLASS_SUCCESS, getComment } }
    function failure(error) { return { type: commentConstants.GETALL_COMMENT_CLASS_FAILURE, error } }
}

function getAllCommentClassPending() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"admin/get-all-comment-pending",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu comment'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request() { return { type: commentConstants.GETALL_COMMENT_CLASS_PENDING_REQUEST } }
    function success(getComment) { return { type: commentConstants.GETALL_COMMENT_CLASS_PENDING_SUCCESS, getComment } }
    function failure(error) { return { type: commentConstants.GETALL_COMMENT_CLASS_PENDING_FAILURE, error } }
}

function getCommentByClass(id, parent) {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"class/getComment-by-class/"+id+"/"+parent)
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu comment'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request() { return { type: commentConstants.GETALL_COMMENT_BYCLASS_REQUEST } }
    function success(getComment) { return { type: commentConstants.GETALL_COMMENT_BYCLASS_SUCCESS, getComment } }
    function failure(error) { return { type: commentConstants.GETALL_COMMENT_BYCLASS_FAILURE, error } }
}

function addComment(data) {
    return dispatch => {
        dispatch(request(data));

        axios.post(config.get("apiDomain")+"user/comment/add", data,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data));
                dispatch(snackbar.show({
                    message: 'Tạo bình luận thành công',
                  }))
            })
            .catch(error => {
                dispatch(failure('lỗi thêm comment'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request(data) { return { type: commentConstants.ADD_COMMENT_BYCLASS_REQUEST, data } }
    function success(data) { return { type: commentConstants.ADD_COMMENT_BYCLASS_SUCCESS, data } }
    function failure(error) { return { type: commentConstants.ADD_COMMENT_BYCLASS_FAILURE, error } }
}

function activeCommentClass(id) {
    return dispatch => {
        dispatch(request(id));
        
        axios.get(config.get("apiDomain")+'admin/active-comment-class/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Kích hoạt comment thành công',
                })) 
            
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi kết nối server'))
        })
}

    function request(id) { return { type: commentConstants.ACTIVECOMMENTCLASS_REQUEST, id } }
    function success(id) { return { type: commentConstants.ACTIVECOMMENTCLASS_SUCCESS, id } }
    function failure(error) { return { type: commentConstants.ACTIVECOMMENTCLASS_FAILURE, error } }
}



function deleteCommentClass(id) {
    return dispatch => {
        dispatch(request(id));

        axios.delete(config.get("apiDomain")+'admin/delete-comment-class/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa lớp comment thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa comment',
        }))
    });
    }
    function success(id) { return { type: commentConstants.DELCOMMENTCLASS_SUCCESS, id } }
    function request(id) { return { type: commentConstants.DELCOMMENTCLASS_REQUEST, id } }
    function failure(id, error) { return { type: commentConstants.DELCOMMENTCLASS_FAILURE, id, error } }
}
import { tipsConstants } from '../_constants/tips.constants';
import axios from 'axios';
import config from "react-global-configuration";
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux'
import { authHeader } from '../_helpers/auth-header';
import { alertActions } from './alert.actions';
import { history } from '../_helpers/history';

export const tipsActions = {
    allTips, adminAddTip, delete: _delete, tipsDeleted, restore, forceDel, editTip
};

function allTips() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"admin/tip-all",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu lớp học'));
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
            }))
            })
    };

    function request() { return { type: tipsConstants.ADMINGETALL_REQUEST } }
    function success(getTipsAdmin) { return { type: tipsConstants.ADMINGETALL_SUCCESS, getTipsAdmin } }
    function failure(error) { return { type: tipsConstants.ADMINGETALL_FAILURE, error } }
}

//admin add tip
function adminAddTip(tipData) {
    return dispatch => {
        dispatch(request(tipData));
        axios.post(config.get("apiDomain")+'admin/add-tip', tipData,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/tip');
            dispatch(snackbar.show({
                message: 'Thêm bài mẹo/thủ thuật thành công',
            })) 
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình thêm mẹo/thủ thuật'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình thêm mẹo/thủ thuật',
        }))
    });
}

    function request(addTip) { return { type: tipsConstants.ADMINADDTIPS_REQUEST, addTip } }
    function success(addTip) { return { type: tipsConstants.ADMINADDTIPS_SUCCESS, addTip } }
    function failure(error) { return { type: tipsConstants.ADMINADDTIPS_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        axios.delete(config.get("apiDomain")+'admin/delete-tip/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa bài mẹo/thủ thuật thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa bài mẹo/thủ thuật',
        }))
    });
}

    function request(id) { return { type: tipsConstants.DELTIPS_REQUEST, id } }
    function success(id) { return { type: tipsConstants.DELTIPS_SUCCESS, id } }
    function failure(id, error) { return { type: tipsConstants.DELTIPS_FAILURE, id, error } }
}

function editTip(id, tip) {
    return dispatch => {
        dispatch(request(tip));
        
        axios.put(config.get("apiDomain")+'admin/update-tip/'+id, tip,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/tip');
            dispatch(snackbar.show({
                message: 'Sửa bài mẹo/thủ thuật thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(snackbar.show({
                message: 'Lỗi kết nối server',
              })) 
        })
}

    function request(tip) { return { type: tipsConstants.ADMINEDITTIPS_REQUEST, tip } }
    function success(tip) { return { type: tipsConstants.ADMINEDITTIPS_SUCCESS, tip } }
    function failure(error) { return { type: tipsConstants.ADMINEDITTIPS_FAILURE, error } }
}


function tipsDeleted() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"admin/tip/deleted",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu mẹo/thủ thuật'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request() { return { type: tipsConstants.TIPDELETED_REQUEST } }
    function success(tipsDeleted) { return { type: tipsConstants.TIPDELETED_SUCCESS, tipsDeleted } }
    function failure(error) { return { type: tipsConstants.TIPDELETED_FAILURE, error } }
}

function restore(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+'admin/restore-tip/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Khôi phục bài mẹo/thủ thuật thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình khôi phục bài mẹo/thủ thuật người dùng',
        }))
    });
}

    function request(id) { return { type: tipsConstants.RESTORE_REQUEST, id } }
    function success(id) { return { type: tipsConstants.RESTORE_SUCCESS, id } }
    function failure(id, error) { return { type: tipsConstants.RESTORE_FAILURE, id, error } }
}

function forceDel(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+'admin/force-del-tip/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa vĩnh viễn bài mẹo/thủ thuật thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa vĩnh viễn bài học/thủ thuật',
        }))
    });
}

    function request(id) { return { type: tipsConstants.FORCEDEL_REQUEST, id } }
    function success(id) { return { type: tipsConstants.FORCEDEL_SUCCESS, id } }
    function failure(id, error) { return { type: tipsConstants.FORCEDEL_FAILURE, id, error } }
}
import { menuConstants } from '../_constants/menu.constant';
import axios from 'axios';
import config from "react-global-configuration";
import { authHeader } from '../_helpers/auth-header';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux'
import { alertActions } from './alert.actions';
import { history } from '../_helpers/history';

export const menuActions = {
    getAll, adminAddMenu, delete:_delete, editMenu, getAllAdmin, getAllDeleted, restore, forceDel
};

function getAll() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"menu/all/0")
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu menu'))
            })
    };

    function request() { return { type: menuConstants.GETALL_REQUEST } }
    function success(menu) { return { type: menuConstants.GETALL_SUCCESS, menu } }
    function failure(error) { return { type: menuConstants.GETALL_FAILURE, error } }
}

function getAllAdmin() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"admin/all-menu",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu menu'))
            })
    };

    function request() { return { type: menuConstants.GETALLADMIN_REQUEST } }
    function success(menuAdmin) { return { type: menuConstants.GETALLADMIN_SUCCESS, menuAdmin } }
    function failure(error) { return { type: menuConstants.GETALLADMIN_FAILURE, error } }
}



//admin add menu
function adminAddMenu(menu) {
    return dispatch => {
        dispatch(request(menu));
        const m = {
            name: menu.name,
            description: menu.description || '',
            position: menu.position || '',
            parent: menu.parent || ''
        }
        axios.post(config.get("apiDomain")+'admin/add-menu', m,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/menu');
            dispatch(snackbar.show({
                message: 'Thêm lĩnh vực thành công',
            })) 
            
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình thêm lĩnh vực'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình thêm lĩnh vực',
        }))
    });
}

    function request(addsection) { return { type: menuConstants.ADMINADDMENU_REQUEST, addsection } }
    function success(addsection) { return { type: menuConstants.ADMINADDMENU_SUCCESS, addsection } }
    function failure(error) { return { type: menuConstants.ADMINADDMENU_FAILURE, error } }
}

function editMenu(id, menu) {
    return dispatch => {
        dispatch(request(menu));
        
        axios.put(config.get("apiDomain")+'admin/update-menu/'+id, menu,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/menu');
            dispatch(snackbar.show({
                message: 'Sửa lĩnh vực thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình sửa lĩnh vực'))
        })
}

    function request(menu) { return { type: menuConstants.ADMINEDITMENU_REQUEST, menu } }
    function success(menu) { return { type: menuConstants.ADMINEDITMENU_SUCCESS, menu } }
    function failure(error) { return { type: menuConstants.ADMINEDITMENU_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        axios.delete(config.get("apiDomain")+'admin/delete-menu/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa lĩnh vực thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa lĩnh vực',
        }))
    });
}

    function request(id) { return { type: menuConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: menuConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: menuConstants.DELETE_FAILURE, id, error } }
}

function getAllDeleted() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+'admin/menu/del',
        {
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'))
        })
    };

    function request() { return { type: menuConstants.GETALLDELETED_REQUEST } }
    function success(getAll) { return { type: menuConstants.GETALLDELETED_SUCCESS, getAll } }
    function failure(error) { return { type: menuConstants.GETALLDELETED_FAILURE, error } }
}

function restore(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+'admin/restore-menu/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Khôi phục lĩnh vực thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình khôi phục lĩnh vực người dùng',
        }))
    });
}

    function request(id) { return { type: menuConstants.RESTORE_REQUEST, id } }
    function success(id) { return { type: menuConstants.RESTORE_SUCCESS, id } }
    function failure(id, error) { return { type: menuConstants.RESTORE_FAILURE, id, error } }
}

function forceDel(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+'admin/force-del-menu/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa vĩnh viễn lĩnh vực thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa vĩnh viễn lĩnh vực',
        }))
    });
}

    function request(id) { return { type: menuConstants.FORCEDEL_REQUEST, id } }
    function success(id) { return { type: menuConstants.FORCEDEL_SUCCESS, id } }
    function failure(id, error) { return { type: menuConstants.FORCEDEL_FAILURE, id, error } }
}
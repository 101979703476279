import { menuTipsConstants } from '../_constants/menuTips.constant';
import axios from 'axios';
import config from "react-global-configuration";
import { authHeader } from '../_helpers/auth-header';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux'
import { alertActions } from './alert.actions';
import { history } from '../_helpers/history';

export const menuTipsActions = {
    getAll, adminAddMenu, delete:_delete, editMenu, getAllAdmin, getAllDeleted, restore, forceDel, getNameMenu,
    getCatMenu
};

function getAll() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"userFrontend/tipMenuAll/0")
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu menu'))
            })
    };

    function request() { return { type: menuTipsConstants.GETALL_REQUEST } }
    function success(menu) { return { type: menuTipsConstants.GETALL_SUCCESS, menu } }
    function failure(error) { return { type: menuTipsConstants.GETALL_FAILURE, error } }
}
function getNameMenu(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+"userFrontend/getMenuName/"+id)
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu menu'));
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối serve',
                }))
            })
    };

    function request() { return { type: menuTipsConstants.GETNAME_REQUEST } }
    function success(menuName) { return { type: menuTipsConstants.GETNAME_SUCCESS, menuName } }
    function failure(error) { return { type: menuTipsConstants.GETNAME_FAILURE, error } }
}

function getCatMenu(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+"userFrontend/tipMenuById/"+id)
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu menu'));
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối serve',
                }))
            })
    };

    function request() { return { type: menuTipsConstants.GETMENUBYID_REQUEST } }
    function success(menuid) { return { type: menuTipsConstants.GETMENUBYID_SUCCESS, menuid } }
    function failure(error) { return { type: menuTipsConstants.GETMENUBYID_FAILURE, error } }
}

function getAllAdmin() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"admin/all-menu-tip",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu menu'))
            })
    };

    function request() { return { type: menuTipsConstants.GETALLADMIN_REQUEST } }
    function success(menuAdmin) { return { type: menuTipsConstants.GETALLADMIN_SUCCESS, menuAdmin } }
    function failure(error) { return { type: menuTipsConstants.GETALLADMIN_FAILURE, error } }
}



//admin add menu
function adminAddMenu(menu) {
    return dispatch => {
        dispatch(request(menu));
        const m = {
            name: menu.name,
            description: menu.description || '',
            position: menu.position || '',
            parent: menu.parent || ''
        }
        axios.post(config.get("apiDomain")+'admin/add-menu-tip', m,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/menu-tip');
            dispatch(snackbar.show({
                message: 'Thêm lĩnh vực thành công',
            })) 
            
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình thêm lĩnh vực'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình thêm lĩnh vực',
            }))
    });
}

    function request(addsection) { return { type: menuTipsConstants.ADMINADDMENU_REQUEST, addsection } }
    function success(addsection) { return { type: menuTipsConstants.ADMINADDMENU_SUCCESS, addsection } }
    function failure(error) { return { type: menuTipsConstants.ADMINADDMENU_FAILURE, error } }
}

function editMenu(id, menu) {
    return dispatch => {
        dispatch(request(menu));
        
        axios.put(config.get("apiDomain")+'admin/update-menu-tip/'+id, menu,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/menu-tip');
            dispatch(snackbar.show({
                message: 'Sửa lĩnh vực thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình sửa lĩnh vực'))
        })
}

    function request(menu) { return { type: menuTipsConstants.ADMINEDITMENU_REQUEST, menu } }
    function success(menu) { return { type: menuTipsConstants.ADMINEDITMENU_SUCCESS, menu } }
    function failure(error) { return { type: menuTipsConstants.ADMINEDITMENU_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        axios.delete(config.get("apiDomain")+'admin/delete-menu-tip/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa lĩnh vực thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa lĩnh vực',
        }))
    });
}

    function request(id) { return { type: menuTipsConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: menuTipsConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: menuTipsConstants.DELETE_FAILURE, id, error } }
}

function getAllDeleted() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+'admin/menu-tip/del',
        {
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'))
        })
    };

    function request() { return { type: menuTipsConstants.GETALLDELETED_REQUEST } }
    function success(getAll) { return { type: menuTipsConstants.GETALLDELETED_SUCCESS, getAll } }
    function failure(error) { return { type: menuTipsConstants.GETALLDELETED_FAILURE, error } }
}

function restore(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+'admin/restore-menu-tip/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Khôi phục lĩnh vực thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình khôi phục lĩnh vực người dùng',
        }))
    });
}

    function request(id) { return { type: menuTipsConstants.RESTORE_REQUEST, id } }
    function success(id) { return { type: menuTipsConstants.RESTORE_SUCCESS, id } }
    function failure(id, error) { return { type: menuTipsConstants.RESTORE_FAILURE, id, error } }
}

function forceDel(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+'admin/force-del-menu-tip/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa vĩnh viễn lĩnh vực thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa vĩnh viễn lĩnh vực',
        }))
    });
}

    function request(id) { return { type: menuTipsConstants.FORCEDEL_REQUEST, id } }
    function success(id) { return { type: menuTipsConstants.FORCEDEL_SUCCESS, id } }
    function failure(id, error) { return { type: menuTipsConstants.FORCEDEL_FAILURE, id, error } }
}
import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { skillRelated } from './skillrelated.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { menu } from './menu.reducer';
import { menuTips } from './menuTips.reducer';
import { getClass } from './getClass.reducer';
import { editProfile } from './editProfile.reducer';
import { tips } from './tips.reducer';
import { search } from './search.reducer';
import { getComment } from './getComment.reducer';
import { snackbarReducer } from 'material-ui-snackbar-redux'
import { reducer as formReducer } from "redux-form";
const snackbar = snackbarReducer;
const rootReducer = combineReducers({
  authentication, registration, users, alert, menu, snackbar, getClass, 
  skillRelated, editProfile, search,form: formReducer, menuTips, tips, getComment
});

export default rootReducer;
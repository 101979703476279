import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { classActions } from '../../_actions/class.actions';
import { Button, Grid,  CircularProgress, FormControl, TextField, Typography  } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ArrowBack from '@material-ui/icons/ArrowBack';
import axios from 'axios';
import config from "react-global-configuration";
import { FilePond, registerPlugin } from 'react-filepond';
import { FilePondLanguage } from '../../_helpers/langFilepond';
// Import FilePond styles
import '../../filepond.min.css';
import '../../filepond-plugin-image-preview.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);


const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      input: {
        display: 'none',
      },
      group: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
      },
})

class AddVideo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          // type: 'system',
            lessonData: {
              type: 'system',
              name: ''
            },
            submitted: false,
            imgPreview: '',
            files: [],
            noChange: true,
            insertId: ''
        }
        this.handleChange = this.handleChange.bind(this);
        // this.handleChangeType = this.handleChangeType.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadChange = this.uploadChange.bind(this)
    }
    handleInit() {
      console.log("Khởi tạo trình upload", this.pond);
    }
    uploadChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
        this.createImage(files[0]);
    }
    createImage(file) {
      const { lessonData } = this.state;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          imgPreview: e.target.result,
          lessonData: {
            ...lessonData,
            thumbnail_custom: e.target.result
        }
        })
      };
      reader.readAsDataURL(file);
    }

      handleSubmit(e){
        e.preventDefault();
        this.setState({ submitted: true });
        const { lessonData } = this.state;
        const { dispatch } = this.props;
        if (lessonData.name) {
          if(lessonData.type === 'system'){
            dispatch(classActions.editVideo(this.state.insertId,lessonData, lessonData.class_id));
          }else{
            dispatch(classActions.adminAddLessonVideo(lessonData));
          }
        }
    }
      handleChange(event) {
        const { lessonData } = this.state;
        const { name, value } = event.target;
       
        this.setState({
          type: value,
          lessonData: {
                ...lessonData,
                [name]: value,
                class_id: this.props.match.params.id,
            },
            submitted: false,
          hasError: false,
          noChange: false
        });
    }
 
    render(){
        const { classes } = this.props;
        const { submitted, lessonData } = this.state;
        return(
            <div className={classes.root}>
                <MetaTags>
                <title>Thêm bài học - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                <Button variant="outlined" color="default" size="small" style={{textTransform:'initial',marginBottom:10}}  component={Link} to={"/dashboard/video-class/"+this.props.match.params.id}>
                  <ArrowBack /> Quay lại danh sách video
                </Button>
                <form ref="form" onSubmit={this.handleSubmit} >
                  <div className={classes.load}>
                      <div className={classes.wrapper}>
                          <Button color="secondary" disabled={this.state.noChange} variant="contained" type="submit" size="small" style={{textTransform:'initial'}}>
                              Cập nhật 
                          </Button>
                          {this.props.adding && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                  </div>
                  <div>
                    <Grid container spacing={24}>
                      <Grid item xs={12}>
                        <FormControl component="fieldset" className={classes.formControl}>
                          <FormLabel component="legend">Lựa chọn cách thức tải video</FormLabel>
                          <RadioGroup
                            name="type"
                            className={classes.group}
                            value={lessonData.type}
                            onChange={this.handleChange}
                          >
                            <FormControlLabel value="system" control={<Radio />} label="Tải video từ máy" />
                            <FormControlLabel value="vimeo" control={<Radio />} label="Nhúng video từ Vimeo" />
                            <FormControlLabel value="youtube" control={<Radio />} label="Nhúng video từ Youtube" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {lessonData.type === 'system' &&
                        <Grid item xs={12}>
                          <FilePond
                            {...FilePondLanguage}
                            labelIdle = 'Kéo và Thả video vào đây hoặc <span class="filepond--label-action">Chọn video</span> để upload.'
                            // instantUpload={false}
                                    name="file"
                                    required={true}
                                    allowMultiple={false}
                                    // maxFiles={3}
                                    allowFileTypeValidation={true}
                                    // acceptedFileTypes={['application/pdf','image/*']}
                                    // labelFileTypeNotAllowed='File không hợp lệ'
                                    // fileValidateTypeLabelExpectedTypes='Chỉ chấp nhận Ảnh hoặc PDF'
                                    // onupdatefiles={(fileItems) => {
                                    //     this.setState({
                                    //         file: fileItems && fileItems[0].file
                                    //     }) 
                                    // }}
                                    server={
                                        {
                                            timeout: 99999,
                                            process: (fieldName, file, metadata, load, error, progress, abort) => {
                                              let user = JSON.parse(localStorage.getItem('user'));
                                                const formData = new FormData()
                                                formData.append('file', file, file.name)
                                                formData.append('userId', user.id)
                                                formData.append('classId', this.props.match.params.id)
                                                formData.append('name', file.name)
                                                // aborting the request
                                                const CancelToken = axios.CancelToken
                                                const source = CancelToken.source()

                                                axios({
                                                    method: 'POST',
                                                    url: config.get('apiDomain')+'admin/upload',
                                                    data: formData,
                                                    // cancelToken: source.token,
                                                      headers: {
                                                        'Content-Type': 'multipart/form-data',
                                                        'Authorization': 'Bearer ' + user.access_token
                                                      },
                                                    onUploadProgress: (e) => {
                                                        // updating progress indicator
                                                        progress(e.lengthComputable, e.loaded, e.total)
                                                    }
                                                }).then(response => {
                                                    // passing the file id to FilePond
                                                    // console.log(response)
                                                    load(response.file)
                                                    if(this.state.lessonData.name === ''){
                                                    this.setState({
                                                      lessonData: {
                                                            ...lessonData,
                                                            name: response.data.name
                                                        },
                                                        insertId: response.data.id
                                                    });
                                                  }else{
                                                    this.setState({
                                                        insertId: response.data.id
                                                    });
                                                  }
                                                  
                                              }).catch((thrown) => {
                                                  if (axios.isCancel(thrown)) {
                                                      console.log('Request canceled', thrown.message)
                                                  } else {
                                                      // handle error
                                                  }
                                              })
                                              // Setup abort interface
                                              return {
                                                  abort: () => {
                                                      source.cancel('Operation canceled by the user.')
                                                      abort()
                                                  }
                                              }
                                          },
                                          revert: (uniqueFileId, load, error) => {
                                            console.log(uniqueFileId);
                                            // Should remove the earlier created temp file here
                                            // ...
                                
                                            // Can call the error method if something is wrong, should exit after
                                            error('oh my goodness');
                  
                                            // Should call the load method when done, no parameters required
                                            load();
                                        }

                                      }

                                      
                                  }
                              />
                            </Grid>
                            }
                      
                            <Grid item xs={12} sm={10} md={10}>
                              <FormControl style={{marginBottom:10,display:'inherit'}}>
                                <TextField
                                  fullWidth
                                  className={classes.textField}
                                  label="Tên bài học"
                                  name="name"
                                  value={lessonData.name}
                                  onChange={this.handleChange}
                                  helperText={(submitted && lessonData.name === "") ? "Tên bài học không được để trống": ""}
                                  error = {(submitted && lessonData.name === "") ? true : false}
                                />     
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                              <FormControl style={{marginBottom:10,display:'inherit'}}>
                                <TextField
                                  fullWidth
                                  className={classes.textField}
                                  label="Vị trí"
                                  name="position"
                                  defaultValue=""
                                  onChange={this.handleChange}
                                  type="number"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          {lessonData.type === 'vimeo' &&
                          <Grid item xs={12}>
                            <Grid container spacing={24}>
                              <Grid item xs={12} sm={6} md={5}>
                                <FormControl style={{marginBottom:10,display:'inherit'}}>
                                  <TextField
                                    fullWidth
                                    className={classes.textField}
                                    label="Mã Video Vimeo"
                                    name="url"
                                    defaultValue=""
                                    onChange={this.handleChange}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography>Hướng dẫn</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          }
                          {lessonData.type === 'youtube' &&
                          <Grid item xs={12}>
                            <Grid container spacing={24}>
                              <Grid item xs={12} sm={6} md={5}>
                                <FormControl style={{marginBottom:10,display:'inherit'}}>
                                  <TextField
                                    fullWidth
                                    className={classes.textField}
                                    label="Mã Video Youtube"
                                    name="url"
                                    defaultValue=""
                                    onChange={this.handleChange}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography>Hướng dẫn</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          }
                          <Grid item xs={12} style={{marginTop:20}}>
                            <Typography>Ảnh đại diện bài học</Typography>
                            <input
                              accept="image/*"
                              className={classes.input}
                              id="class-image"
                              multiple
                              type="file"
                              name="class_image"
                              onChange={this.uploadChange}
                            />
                            <label htmlFor="class-image">
                            {this.state.imgPreview !== '' &&
                              <img src={this.state.imgPreview} alt="preview" style={{marginBottom:10,width:320}}/>
                              }
                              <br />
                              <Button variant="contained" color="secondary" size="small" component="span" className={classes.button} style={{marginBottom:20}}>
                                Upload
                                <CloudUploadIcon className={classes.rightIcon} />
                              </Button>
                            </label>
                            
                          </Grid>
                      </div>
                    </form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { lesson, adding } = state.getClass;
    return {
        lesson, adding
    };
  }
  export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(AddVideo));
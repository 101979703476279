export const classConstants = {
    GETALL_REQUEST: 'HOME_GETALL_REQUEST',
    GETALL_SUCCESS: 'HOME_GETALL_SUCCESS',
    GETALL_FAILURE: 'HOME_GETALL_FAILURE',  

    GETTEXTDELETED_REQUEST: 'TEXTCLASS_DELETED_REQUEST',
    GETTEXTDELETED_SUCCESS: 'TEXTCLASS_DELETED_SUCCESS',
    GETTEXTDELETED_FAILURE: 'TEXTCLASS_DELETED_FAILURE',

    GETVIDEODELETED_REQUEST: 'VIDEOCLASS_DELETED_REQUEST',
    GETVIDEODELETED_SUCCESS: 'VIDEOCLASS_DELETED_SUCCESS',
    GETVIDEODELETED_FAILURE: 'VIDEOCLASS_DELETED_FAILURE',

    GETALLLESSONBYCLASS_REQUEST: 'CLASS_GETALLLESSON_REQUEST',
    GETALLLESSONBYCLASS_SUCCESS: 'CLASS_GETALLLESSON_SUCCESS',
    GETALLLESSONBYCLASS_FAILURE: 'CLASS_GETALLLESSON_FAILURE',

    GETALLLESSONADMINBYCLASS_REQUEST: 'CLASS_GETALLLESSONADMIN_REQUEST',
    GETALLLESSONADMINBYCLASS_SUCCESS: 'CLASS_GETALLLESSONADMIN_SUCCESS',
    GETALLLESSONADMINBYCLASS_FAILURE: 'CLASS_GETALLLESSONADMIN_FAILURE',

    GETALLLVIDEOBYCLASS_REQUEST: 'CLASS_GETALLVIDEO_REQUEST',
    GETALLLVIDEOBYCLASS_SUCCESS: 'CLASS_GETALLVIDEO_SUCCESS',
    GETALLLVIDEOBYCLASS_FAILURE: 'CLASS_GETALLVIDEO_FAILURE',

    GETALLLVIDEOADMINBYCLASS_REQUEST: 'CLASS_GETALLVIDEOADMIN_REQUEST',
    GETALLLVIDEOADMINBYCLASS_SUCCESS: 'CLASS_GETALLVIDEOADMIN_SUCCESS',
    GETALLLVIDEOADMINBYCLASS_FAILURE: 'CLASS_GETALLVIDEOADMIN_FAILURE',

    GETCLASSBYID_REQUEST: 'CLASS_GETBYID_REQUEST',
    GETCLASSBYID_SUCCESS: 'CLASS_GETBYID_SUCCESS',
    GETCLASSBYID_FAILURE: 'CLASS_GETBYID_FAILURE',

    ADMINGETALL_REQUEST: 'ADMIN_GETALLCLASS_REQUEST',
    ADMINGETALL_SUCCESS: 'ADMIN_GETALLCLASS_SUCCESS',
    ADMINGETALL_FAILURE: 'ADMIN_GETALLCLASS_FAILURE',

    ADMINGETALLCLASSTEXT_REQUEST: 'ADMIN_GETALLCLASSTEXT_REQUEST',
    ADMINGETALLCLASSTEXT_SUCCESS: 'ADMIN_GETALLCLASSTEXT_SUCCESS',
    ADMINGETALLCLASSTEXT_FAILURE: 'ADMIN_GETALLCLASSTEXT_FAILURE',
    

    ADMINADDLESSONTEXT_REQUEST: 'ADMIN_ADDLESSONTEXT_REQUEST',
    ADMINADDLESSONTEXT_SUCCESS: 'ADMIN_ADDLESSONTEXT_SUCCESS',
    ADMINADDLESSONTEXT_FAILURE: 'ADMIN_ADDLESSONTEXT_FAILURE',

    ADMINADDLESSONVIDEO_REQUEST: 'ADMIN_ADDLESSONVIDEO_REQUEST',
    ADMINADDLESSONVIDEO_SUCCESS: 'ADMIN_ADDLESSONVIDEO_SUCCESS',
    ADMINADDLESSONVIDEO_FAILURE: 'ADMIN_ADDLESSONVIDEO_FAILURE',
    
    DELCLASS_REQUEST: 'ADMIN_DELCLASS_REQUEST',
    DELCLASS_SUCCESS: 'ADMIN_DELCLASS_SUCCESS',
    DELCLASS_FAILURE: 'ADMIN_DELCLASS_FAILURE', 

    DELLESSON_REQUEST: 'ADMIN_DELLESSON_REQUEST',
    DELLESSON_SUCCESS: 'ADMIN_DELLESSON_SUCCESS',
    DELLESSON_FAILURE: 'ADMIN_DELLESSON_FAILURE',

    DELVIDEO_REQUEST: 'ADMIN_DELVIDEO_REQUEST',
    DELVIDEO_SUCCESS: 'ADMIN_DELVIDEO_SUCCESS',
    DELVIDEO_FAILURE: 'ADMIN_DELVIDEO_FAILURE',

    ADMINEDITCLASS_REQUEST: 'CLASS_ADMINEDIT_REQUEST',
    ADMINEDITCLASS_SUCCESS: 'CLASS_ADMINEDIT_SUCCESS',
    ADMINEDITCLASS_FAILURE: 'CLASS_ADMINEDIT_FAILURE',

    ADMINEDITLESSON_REQUEST: 'LESSON_ADMINEDIT_REQUEST',
    ADMINEDITLESSON_SUCCESS: 'LESSON_ADMINEDIT_SUCCESS',
    ADMINEDITLESSON_FAILURE: 'LESSON_ADMINEDIT_FAILURE',

    ADMINEDITVIDEO_REQUEST: 'VIDEO_ADMINEDIT_REQUEST',
    ADMINEDITVIDEO_SUCCESS: 'VIDEO_ADMINEDIT_SUCCESS',
    ADMINEDITVIDEO_FAILURE: 'VIDEO_ADMINEDIT_FAILURE',

    ADMINADDCLASS_REQUEST: 'CLASS_ADMINADD_REQUEST',
    ADMINADDCLASS_SUCCESS: 'CLASS_ADMINADD_SUCCESS',
    ADMINADDCLASS_FAILURE: 'CLASS_ADMINADD_FAILURE',

    RESTORE_REQUEST: 'CLASS_RESTORE_REQUEST',
    RESTORE_SUCCESS: 'CLASS_RESTORE_SUCCESS',
    RESTORE_FAILURE: 'CLASS_RESTORE_FAILURE',

    FORCEDEL_REQUEST: 'CLASS_FORCEDEL_REQUEST',
    FORCEDEL_SUCCESS: 'CLASS_FORCEDEL_SUCCESS',
    FORCEDEL_FAILURE: 'CLASS_FORCEDEL_FAILURE',
};
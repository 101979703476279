import React from 'react';
import {Link} from 'react-router-dom';
import { isLoged } from '../_services/auth';
import { AppBar, Divider, Drawer, Hidden, List, IconButton, ListItem, ListItemIcon,
  ListSubheader, Collapse, CssBaseline, Toolbar, Typography, Button,  Menu, MenuItem, ListItemText,
  InputBase, Grid
} from '@material-ui/core';
import { Videocam, ExpandLess, School, Settings, RecordVoiceOver, Help,
  ExitToApp, TouchApp, PersonOutline
} from '@material-ui/icons';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import ScrollToTop from 'react-scroll-up';
import { connect } from 'react-redux';
import { userActions } from '../_actions/user.actions';
import { menuActions } from '../_actions/menu.actions';
import { getTitle } from '../_services/getTitle';
import UserAvatar from 'react-user-avatar';
import { history } from '../_helpers/history';
import SearchIcon from '@material-ui/icons/Search';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Helmet } from 'react-helmet';
import Tooltip from '@material-ui/core/Tooltip';
import config from "react-global-configuration";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  linkLogo: {
    color: '#666',
    textDecoration: 'none',
    '&:hover':{
      color: '#1e88e5',
    }

  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
  listParent:{
    paddingTop:3,
    paddingBottom:3,
    fontWeight:450,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  backtotop:{
    bottom: -20,
    right: 20,
    transitionDuration: '0.5s',
    transitionTimingFunction: 'easeInQuad',
    transitionDelay: '1s'
  },
  noHover:{
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'initial',
    }
  },
  bigAvatar: {
    margin: 10,
    width: 80,
    height: 80,
    marginLeft:'auto',
    marginRight: 'auto',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 5,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 6,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
  footer: {
      color: '#666',
    },
  gray:{
    color: '#666',
  },
  marginTop10:{
    marginTop: 10,
  },
  listInline:{
    display: 'inline-block',
    margin: '0 10px 30px 10px',
  },
  link:{
    color: '#666',
    textDecoration: 'none',
    '&:hover': {
      color: '#d50000',
    }
  },
});

class ClassLayout extends React.Component {
  constructor(props){
    super(props);
    
    this.state = {
        mobileOpen: false,
        searchStr: null,
        loading: true
    }
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  handleSearchChange(e){
    const { searchStr } = this.state;
    const { name, value } = e.target;
    
    this.setState({
      searchStr: {
            ...searchStr,
            [name]: value
        }
    });
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };
  componentDidMount() {
    this.props.dispatch(menuActions.getAll());
    let user = JSON.parse(localStorage.getItem('user'));
    if(user !== null){
      this.props.dispatch(userActions.getInfo());
    }
    this.setState({loading:false})
  }
  handleLogout = (props) => {
    this.props.dispatch(userActions.logout());
  }
  handleClick = (e) => {
    this.setState({ [e]: !this.state[e] });
  };
  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    // this.handleMobileMenuClose();
  };

  render() {
    const { anchorEl, loading } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const { classes, theme, menu, users } = this.props;
    const uInfo = users.items;
    let uri = (window.location.pathname).split('/')
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
        style={{top:48}}
      >
        <MenuItem style={{height:'auto',padding:0}} className={classes.noHover}>
        {users.loading ? (
            ""
        ) : (
          <UserAvatar size="80" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar} className={classes.bigAvatar}/>
        )}
        </MenuItem>
        <MenuItem style={{paddingTop:0}} className={classes.noHover}>
        {uInfo.length !== 0 ? (
          <div style={{marginLeft:'auto', marginRight:'auto'}}><b>{uInfo.name}</b></div>
        ) : ("")}
        </MenuItem>
        <MenuItem style={{height:15,marginBottom:5}} className={classes.noHover}>
          <Button component={Link} to="/u/profile" variant="contained" size="small" color="secondary" align="center" style={{marginLeft:'auto', marginRight:'auto',textTransform:'initial'}}>
            Xem thông tin
          </Button>
        </MenuItem>
        <Divider light />
        
        <MenuItem component={Link} to="/lop-hoc-cua-toi" title="Lớp học của tôi" style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <School />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Lớp học của tôi" />
        </MenuItem>
        <MenuItem component={Link} to="/u/edit-profile" style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <Settings />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Thiết lập tài khoản" />
        </MenuItem>
        <MenuItem onClick={this.handleMenuClose} style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <Videocam />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Dạy học" />
        </MenuItem>
        <MenuItem onClick={this.handleMenuClose} style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <RecordVoiceOver />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Mời bạn bè" />
        </MenuItem>
        <MenuItem onClick={this.handleMenuClose} style={{height:15}}>
          <ListItemIcon className={classes.icon}>
              <Help />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Trợ giúp" />
        </MenuItem>
        <Divider light />
        <MenuItem style={{height:15}} onClick={this.handleLogout}>
          <ListItemIcon className={classes.icon}>
              <ExitToApp />
            </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Thoát" />
        </MenuItem>
      </Menu>
    );

    const drawer = (
      <div>
        <div className={classes.toolbar} style={{padding: '8px 0 0 20px'}}>
          <Typography  variant="h5">
            <Link to="/" className={classes.linkLogo} title="Học kỹ năng trực tuyến Learn Skills">Learn Skills</Link>
          </Typography>
          <Typography variant="caption">
            v1.0.0
          </Typography>
        </div>
        <Divider />
        <Typography  variant="body2" style={{padding: '10px 0 0 20px'}}>
            <Link to="/tips" className={classes.listParent} style={{textDecoration:'none',color:'#555'}} title="Mẹo - Thủ thuật">Mẹo - Thủ thuật</Link>
          </Typography>
        <List
        component="nav"
        subheader={<ListSubheader component="div" style={{position:'relative'}}>Các lớp học video</ListSubheader>}
      >
        <ListItem button className={classes.listParent} component={Link} to={'/lop-hoc-video'}>
          <ListItemText inset primary={<Typography variant="subtitle2" gutterBottom>Tất cả</Typography>} style={{paddingLeft:0}}/>
        </ListItem>
        {menu.items.map(val =>(
          val.submenu.length > 0 &&
          <div key={val.id}>
          <ListItem button onClick={this.handleClick.bind(this, val.id)} className={classes.listParent}>
            <ListItemText inset primary={<Typography variant="subtitle2" gutterBottom>{val.name}</Typography>} style={{paddingLeft:0}} />
          </ListItem>
          <Collapse in={this.state[val.id]} timeout="auto" unmountOnExit>
          <List component="nav" className={classes.listParent}>
          {val.submenu.length && val.submenu.map(v => (
            <ListItem button className={classes.listParent} key={v.id} component={Link} to={'/sec-video/'+v.slug} >
              <ListItemText inset primary={<Typography variant="body2" gutterBottom>{v.name}</Typography>} style={{paddingLeft:10}}/>
            </ListItem>
          ))}
          </List>
        </Collapse>
        </div>
        ))}
      </List>

      <List
        component="nav"
        subheader={<ListSubheader component="div" style={{position:'relative'}}>Các lớp học văn bản</ListSubheader>}
      >
      <ListItem button className={classes.listParent} component={Link} to={'/lop-hoc-van-ban'} >
          <ListItemText inset primary={<Typography variant="subtitle2" gutterBottom>Tất cả</Typography>} style={{paddingLeft:0}}/>
        </ListItem>
        {menu.items.map(val =>(
          val.submenu.length > 0 &&
          <React.Fragment key={val.name}>
          <ListItem button onClick={this.handleClick.bind(this, val.name)} className={classes.listParent}>
            <ListItemText inset primary={<Typography variant="subtitle2" gutterBottom>{val.name}</Typography>} style={{paddingLeft:0}} />
          </ListItem>
          <Collapse in={this.state[val.name]} timeout="auto" unmountOnExit>
          <List component="nav" className={classes.listParent}>
          {val.submenu.length && val.submenu.map(v => (
            <ListItem button className={classes.listParent} key={v.name} component={Link} to={'/sec-text/'+v.slug}>
              <ListItemText inset primary={<Typography variant="body2" gutterBottom>{v.name}</Typography>} style={{paddingLeft:10}}/>
            </ListItem>
          ))}
          </List>
        </Collapse>
        </React.Fragment>
        ))}
      </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Helmet>
            <style>{'body { background-color: #ffffff; }'}</style>
        </Helmet>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar  style={{maxHeight:55,minHeight:55}}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              {getTitle(uri[1])}
            </Typography>

            <div className={classes.grow} />
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Tìm kiếm lớp học"
                name="search"
                onChange={this.handleSearchChange}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    let str = this.state.searchStr.search.trim();
                    let newStr = str.replace(/\s+/g, '+');
                    history.push('/tim-kiem/'+newStr)
                  }
                }}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
              
            </div>
            {/* <Tooltip title="Dự án" aria-label="project">
              <IconButton color="inherit" className={classes.sectionDesktop} component={Link} to="/du-an" title=""><Layers /></IconButton>
            </Tooltip> */}
            <Tooltip title="Mẹo - Thủ thuật" aria-label="tip">
              <IconButton color="inherit" className={classes.sectionDesktop} component={Link} to="/tips" title=""><TouchApp /></IconButton>
            </Tooltip>
            
            {/* <Tooltip title="Hỏi - Đáp" aria-label="qa">
              <IconButton color="inherit" className={classes.sectionDesktop} component={Link} to="/hoi-dap" title=""><QuestionAnswer /></IconButton>
            </Tooltip> */}
            <Tooltip title="Trở thành giáo viên" aria-label="teacher">
              <IconButton color="inherit" className={classes.sectionDesktop} component={Link} to="/day-hoc" title=""><School /></IconButton>
            </Tooltip>
            {!isLoged() ? (
              <React.Fragment>
                <Button color="inherit" className={classes.sectionDesktop} style={{textTransform:'initial',fontSize:13}} component={Link} to="/register" title="">Đăng ký</Button>
                <Button variant="contained" className={classes.sectionDesktop} color="secondary" size="small" style={{textTransform:'initial',fontSize:13}} component={Link} to="/login">Đăng nhập</Button>
                <Button variant="contained" className={classes.sectionMobile} color="secondary" size="small" style={{textTransform:'initial',fontSize:13,minWidth:40}} component={Link} to="/login"><PersonOutline /></Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
              <div className={classes.sectionDesktop}>
            <Button color="inherit" className={classes.sectionDesktop} style={{textTransform:'initial'}} component={Link} to="/lop-hoc-cua-toi" title="Lớp học của tôi">Lớp học</Button>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
                style={{padding:0}}
              >
              {users.loading || loading ? (
                  ""
              ) : (
                <UserAvatar size="40" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar}/>
              )}
                
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleProfileMenuOpen} color="inherit">
              {users.loading || loading ? (
                  ""
              ) : (
                <UserAvatar size="40" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar}/>
              )}
              </IconButton>
            </div>
        
</React.Fragment>
            )}
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />

          {this.props.children}

          <Grid container className={classes.footer} style={{marginTop:100}}>
                <Grid item xs={12}>
                    <Grid container>
                    <Grid item xs={12}>
                        <Divider />
                        <Grid container className={classes.marginTop10}>
                        <Grid item xs={12} sm={12} md={7}>
                            <Typography variant="caption" gutterBottom className={classes.gray}>
                            <ul className={classes.ListItem}  style={{paddingLeft:0}}>
                                <li className={classes.listInline}>&copy; Learnskill 2018</li>
                                <li className={classes.listInline}>
                                <Link to="/" title="Hướng dẫn" className={classes.link}>Hướng dẫn</Link>
                                </li>
                                <li className={classes.listInline}>
                                <Link to="/" title="Điều khoản" className={classes.link}>Điều khoản</Link>
                                </li>
                                <li className={classes.listInline}>
                                <Link to="/" title="Chính sách" className={classes.link}>Chính sách</Link>
                                </li>
                            </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                        <Typography variant="caption" gutterBottom align="right">
                        <Fab color="primary" size="small" href="/">f</Fab>&nbsp;&nbsp;
                        <Fab color="primary" size="small" href="/">t</Fab>&nbsp;&nbsp;
                        <Fab color="secondary" size="small" href="/"><Videocam /></Fab>
                        </Typography>
                        </Grid>
                        </Grid>
                    </Grid>
                  </Grid>
                    
              </Grid>
            </Grid>
          
          {/* scroll to top */}
        <ScrollToTop showUnder={160}>
          <Fab color="secondary" aria-label="Lên đầu trang" className={classes.backtotop}>
            <ExpandLess />
          </Fab>
          </ScrollToTop>
          {renderMenu}
        </main>
        
        
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { menu, users } = state;
  return {
    menu, users
  };
}


export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(ClassLayout));
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider, CircularProgress, List, ListItem, ListItemAvatar, Fab,
    ListItemText, CardHeader, CardMedia, CardContent, Card
} from '@material-ui/core';
import axios from 'axios';
import config from "react-global-configuration";
import { ThumbUp } from '@material-ui/icons';
import UserAvatar from 'react-user-avatar';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { FacebookShareButton, TwitterShareButton, FacebookIcon,
    TwitterIcon } from 'react-share';
import Avatar from '@material-ui/core/Avatar';
import Timestamp from 'react-timestamp';
import AdArticle from '../_components/AdArticle';

const styles = theme => ({
    root: {
        marginTop:80,
        width:'100%'
    },
    linkTips:{
        textDecoration: 'none',
        marginRight:30,
        color:'#444',
    },
    section:{
        marginBottom: 20,
    },
    media: {
      objectFit: 'cover',
      width: '100%',
      maxHeight:150,
    },
    bigMedia: {
        objectFit: 'cover',
        width: '100%',
      },
    card: {
      width: '100%',
    },
    chip: {
        width:'100%',
        color: '#777',
        borderRadius: 0,
      },
    caption:{
        color: '#777',
    },
    teacherName: {
        color: '#777',
        textDecoration: 'none',
        fontWeight: 450,
        cursor: 'pointer',
        '&:hover': {
            color: 'red',
            textDecoration: 'none',
        },
    },
     
    iconTime: {
      fontSize: 13,
      position: 'relative',
      bottom: -2,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    button: {
      margin: theme.spacing.unit,
    },
    center:{
        textAlign: 'center',
    },
    
      linkListTeacher: {
          color: '#1565C0',
          textDecoration: 'none',
          marginLeft: 20,
          fontWeight: 'normal',
          '&:hover':{
              color: '#d50000',
          }
      },
      titleClass:{
        color: '#444',
        fontSize:'1.1em',
        fontWeight:450,
        textDecoration: 'none',
        marginBottom: 30,
        '&:hover':{
            color: '#1e88e5',
        }
       },
})

class TipDetail extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            catName: [],
            catMenu: [],
            data:{
                user:{
                    id: 1,
                    name: 'a'
                }
            },
            dataRelated: [],
            content: EditorState.createEmpty(),
            loading: true
        };
    }
    componentWillMount() {
        axios.get(config.get("apiDomain")+"userFrontend/tipMenuById/"+this.props.match.params.catSlug)
            .then(res => {
                this.setState({catMenu: res.data});
            })
        axios.get(config.get("apiDomain")+"userFrontend/getMenuName/"+this.props.match.params.catSlug)
            .then(res => {
                this.setState({catName: res.data});
            })
            axios.get(config.get("apiDomain")+"userFrontend/tip-content/"+this.props.match.params.tipSlug)
            .then(res => {
                this.setState({data: res.data});
                if(res.data.content !== null){
                    let convert = convertFromRaw(JSON.parse(res.data.content));
                    let editor = EditorState.createWithContent(convert);
                    this.setState({content: editor})
                }
               
            })
            axios.get(config.get("apiDomain")+'userFrontend/update-view-tip/'+this.props.match.params.tipSlug);
            axios.get(config.get("apiDomain")+"userFrontend/tip-related/"+this.props.match.params.subCatSlug)
            .then(res => {
                this.setState({dataRelated: res.data, loading: false});
            })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({loading:true})
        const catSlug = this.props.match.params.catSlug;
        const newCatSlug = nextProps.match.params.catSlug;
        const slug = this.props.match.params.tipSlug;
        const newSlug = nextProps.match.params.tipSlug;
        const subCat = this.props.match.params.subCatSlug;
        const newSubCat = nextProps.match.params.subCatSlug;
        if(catSlug !== newCatSlug){
            axios.get(config.get("apiDomain")+"userFrontend/tipMenuById/"+newCatSlug)
            .then(res => {
                this.setState({catMenu: res.data});
            })
            axios.get(config.get("apiDomain")+"userFrontend/getMenuName/"+newCatSlug)
            .then(res => {
                this.setState({catName: res.data});
            })
        }
        if(slug !== newSlug){
            axios.get(config.get("apiDomain")+"userFrontend/tip-content/"+newSlug)
            .then(res => {
                this.setState({data: res.data, loading: false});
                if(res.data.content !== null){
                    let convert = convertFromRaw(JSON.parse(res.data.content));
                    let editor = EditorState.createWithContent(convert);
                    this.setState({content: editor})
                }
            })
            axios.get(config.get("apiDomain")+'userFrontend/update-view-tip/'+newSlug);
        }
        if(subCat !== newSubCat){
            axios.get(config.get("apiDomain")+"userFrontend/tip-related/"+newSubCat)
            .then(res => {
                this.setState({dataRelated: res.data});
            })
        }
    }
    getSubCatSlug(id){
        let filter = this.state.catMenu.filter(val => val.id === id);
        let temp = [];
        filter.map(val=>(temp.push(val.slug)))
        return temp[0]
    }
    getSubCatName(id){
        let filter = this.state.catMenu.filter(val => val.id === id);
        let temp = [];
        filter.map(val=>(temp.push(val.name)))
        return temp[0]
    }
    
    render(){
        const { classes } = this.props;
        const { catName, catMenu, data, dataRelated, content } = this.state;
        const tipRelated = dataRelated.filter(val =>(val.id !== data.id))
      
        return (
            <div className={classes.root}>
                <MetaTags>
                    <title>{data.title+' - LearnSkill'}</title>
                    <meta name="description" content={'Mẹo, thủ thuật, hướng dẫn về '+data.title+' trên hệ thống học kỹ năng trực tuyến learnskill'} />
                    <meta name="keywords" content="mẹo, thủ thuật, học, dạy học, lớp học, dạy học, giáo dục, học trực tuyến, học online, video, lớp học video, các lớp học video" />
                    <meta property="og:title" content={data.title+' - LearnSkill'} />
                    <meta property="og:description" content={'Mẹo, thủ thuật, hướng dẫn về '+data.title+' trên hệ thống học kỹ năng trực tuyến learnskill'} />
                    <meta property="og:image" content={config.get('apiImg')+data.image} />
                    <meta property="og:url" content={config.get('domain')} />
                    <meta name="twitter:card" content="summary_large_image" />
                </MetaTags>
                { this.state.loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                ) : (
                <React.Fragment>
                <Grid container style={{padding:'0 10px'}}>
                    <Grid item md={1}></Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <Grid container spacing={16}>
                            <Grid item xs={12}>
                            <NavLink to={'/tips'} className={classes.linkTips} activeStyle={{fontWeight: 500,color: "#1e88e5"}} title="Mẹo, thủ thuật">TIPS</NavLink>
                            <NavLink to={'/tips/'+catName.slug} className={classes.linkTips} style={{fontSize:25,fontWeight: 500}} activeStyle={{fontSize:25,fontWeight: 500,color: "#1e88e5"}} title="Mẹo, thủ thuật">{catName.name}</NavLink>
                            {catMenu.map(val=>(
                                <NavLink key={val.id} to={'/tips/'+catName.slug+'/'+val.slug} className={classes.linkTips} activeStyle={{fontWeight: 450,color: "#1e88e5"}} title={val.description}>{val.name}</NavLink>
                            ))}
                            <Divider style={{marginTop:5}} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container style={{marginTop:50,padding:'0 10px'}} >
                    <Grid item md={2}></Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <Typography variant="h2" style={{fontSize:25,fontWeight:500}}>{data.title}</Typography>
                        
                                               
                        <List>
                            <ListItem style={{paddingLeft:0}}>
                                <ListItemAvatar component={Link} to={"/user/"+data.user.id+'/'+data.user.name.replace(/\s+/g, '-')} title={data.user.name} className={classes.teacherName}>
                                <UserAvatar size="40" name={data.user.name} src={config.get('apiImg')+data.user.avatar} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Link to={"/user/"+data.user.id+'/'+data.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{data.user.name}</Link>}
                                    secondary={
                                        <Typography component="span" className={classes.caption} color="textPrimary">
                                        <Timestamp date={new Date(data.created_at)} options={{ includeDay: true, twentyFourHour: true }} />
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                        <AdArticle slot="2588730000" />
                        <Editor editorState={content} readOnly={true} toolbarHidden />

                        <Grid container style={{marginTop:20, padding:'0 10px'}}>
                            <Grid item xs={6}>
                                <Fab color="secondary" size="small">
                                    <ThumbUp />
                                </Fab>
                                <span style={{marginLeft:10,color:'#666'}}>123 luot thich</span>
                            </Grid>
                            <Grid item xs={6} align="right">
                                <div className="network">
                                    <FacebookShareButton
                                        url={config.get('domain')+window.location.pathname}
                                        quote={data.title}
                                        className="network__share-button">
                                        <FacebookIcon
                                        size={32}
                                        round 
                                        />
                                    </FacebookShareButton>
                                </div>
                                <div className="network">
                                    <TwitterShareButton
                                        url={config.get('domain')+window.location.pathname}
                                        title={data.title}
                                        className="network__share-button">
                                        <TwitterIcon
                                        size={32}
                                        round />
                                    </TwitterShareButton>
                                </div>
                            </Grid>
                            <Divider style={{width:'100%',marginTop:10}} />
                            <Grid item xs={12}>
                                <List>
                                    <ListItem style={{paddingLeft:0}}>
                                        <ListItemAvatar component={Link} to={"/user/"+data.user.id+'/'+data.user.name.replace(/\s+/g, '-')} title={data.user.name} className={classes.teacherName}>
                                        <UserAvatar size="60" name={data.user.name} src={config.get('apiImg')+data.user.avatar} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Link to={"/user/"+data.user.id+'/'+data.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{data.user.name}</Link>}
                                            secondary={
                                                <Typography component="span" className={classes.caption} color="textPrimary">
                                                Thành viên LearnSkill từ {<Timestamp date={new Date(data.user.created_at)} options={{ includeDay: true, twentyFourHour: true }} />}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                            
                        </Grid>

                    </Grid>
                    <Grid item md={3}></Grid>
                    <Grid item xs={12}>
                        <Grid container style={{background:'#fafafa',marginTop:50,padding:'30px 0'}}>
                            <Grid item md={1}></Grid>
                            <Grid item xs={12} sm={12} md={10}>
                                <Grid container spacing={16}>
                                {tipRelated.map(val=>(
                                    <Grid item xs={12} sm={6} md={4} key={val.id}>
                                        <Card className={classes.card}>
                                            
                                            <CardMedia
                                            style={{height:150}}
                                            image={(val.image !== null) ? config.get('apiImg')+val.image : "/no-preview.jpg"}
                                            title={val.title}
                                            component={Link} 
                                            to={"/tips/"+catName.slug+'/'+this.getSubCatSlug(val.category)+'/'+val.slug}
                                            />
                                            <CardContent style={{paddingTop:10,paddingBottom:5}}>
                                                <Typography component="p" style={{color:'#888',fontSize:12,marginBottom:10}}>
                                                Xem thêm trong <Link style={{color:'#888',fontWeight:500,textDecoration:'none'}} to={'/tips/'+catName.slug+'/'+this.props.match.params.subCatSlug}>{this.getSubCatName(val.category)}</Link> thuộc <Link style={{color:'#888',fontWeight:500,textDecoration:'none'}} to={'/tips/'+catName.slug}>{catName.name}</Link>
                                                </Typography>
                                                <Typography gutterBottom variant="h5" className={classes.titleClass} style={{marginBottom:10}} component={Link} to={"/tips/"+catName.slug+'/'+this.props.match.params.subCatSlug+'/'+val.slug}>
                                                    {val.title}
                                                </Typography>
                                                
                                            </CardContent>
                                            <CardHeader
                                            avatar={
                                                <Avatar aria-label={val.user.name} className={classes.avatar}>
                                                <img src={config.get('apiImg')+val.user.avatar} alt={val.user.name} style={{width:40}} />
                                                </Avatar>
                                            }
                                            title={<Link to={"/user/"+val.user.id+'/'+val.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{val.user.name}</Link>}
                                            subheader={<Timestamp date={new Date(val.created_at)} options={{ includeDay: true, twentyFourHour: true }} />}
                                            style={{padding:'0 15px 10px 15px'}}
                                            />
                                            
                                        </Card>
                                    </Grid>
                                ))}
                                </Grid>
                            </Grid>
                                
                        </Grid>
                    </Grid>
                </Grid>
                </React.Fragment>
                )}
            </div>
        )
    }
}
export default withStyles(styles)(TipDetail);
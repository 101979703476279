import React, { Component } from 'react';

class AdArticle extends Component {

	componentDidMount() {
		(window.adsbygoogle = window.adsbygoogle || []).push({})
	}

	render() {
		const style = {
            display: 'block',
            textAlign:'center'
		};

		return(
			<ins className="adsbygoogle"
			     style={style}
			     data-ad-client="ca-pub-1887238973920029"
			     data-ad-slot={this.props.slot}
			     data-ad-layout="in-article"
                data-ad-format="fluid">
			</ins>
		);
	}
}

export default AdArticle;
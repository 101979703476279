import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { isLoged } from '../_services/auth';
import axios from 'axios';
import config from "react-global-configuration";
import { connect } from 'react-redux';
import { authHeader } from '../_helpers/auth-header';
import { Grid, CircularProgress, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar,
    Tabs, Tab, Divider, Card, CardActionArea, CardActions, Icon, Button, Fab, Paper, CardContent, CardHeader
  } from '@material-ui/core';
  import { PlayCircleOutline } from '@material-ui/icons';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import UserAvatar from 'react-user-avatar';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import embed from "embed-video";
import '../editor.css';
import PersonOutline from '@material-ui/icons/PersonOutline';
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import Assessment from '@material-ui/icons/Assessment';
import ThumbUpAlt from '@material-ui/icons/ThumbUpAlt';
import Tooltip from '@material-ui/core/Tooltip';
import red from '@material-ui/core/colors/red';
import Timestamp from 'react-timestamp';

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
  } from 'react-share';

  const styles = theme => ({
    root: {
      flexGrow: 1,
      marginTop: 80,
    },
    videoList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 400,
    },
    videoListBox: {
        background: '#eee',
    },
    inline:{
        float:'left',
        marginRight: 20,
    },
    btnPlay:{
        position: 'absolute',
        top: '30%',
        left: '40%',
        fontSize: 50,
        '&:hover':{
          color: '#D50000',
        }
        
    },
    media:{
        height: 120,
        objectFit: 'cover',
        width: '100%',
      },
      caption:{
        color: '#777',
    },
   
      teacherName:{
          color: '#777',
          textDecoration: 'none',
          cursor: 'pointer',
          '&:hover':{
              color: 'red',
              textDecoration: 'none',
          }
      },

      userComment:{
        color: '#1e88e5',
        textDecoration: 'none',
        fontWeight:500
    },
    iconTime:{
      fontSize: 13,
      position: 'relative',
      bottom: -2,
    },
    titleClass:{
        color: '#444',
        fontSize: '1.1em',
        fontWeight: 450,
        textDecoration: 'none',
        marginBottom: 30,
        '&:hover':{
            color: '#1e88e5',
        }
    },
    load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
})

function TabContainer({ children}) {
    return (
      <Typography component="div" style={{ padding: '24px 0' }}>
        {children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };

  function uploadImageCallBack(file) {
    return new Promise(
      (resolve, reject) => {
        const reader = new FileReader(); // eslint-disable-line no-undef
        reader.onload = e => resolve({ data: { link: e.target.result } });
        reader.onerror = e => reject(e);
        reader.readAsDataURL(file);
      });
  }

class ClassDetail extends React.Component{
    player = {}
    constructor(props){
        super(props);
    
        this.state = {
            value: 0, 
            loading: true,
            videos: [],
            selectedVideo: [],
            getClassName: [],
            comment:[],
            teacherInfo:
                {
                    id:'',
                    name: 'abc',
                    avatar: '',
                    skill_related: {"id":3,"name":"abc"}
                },
            desc: EditorState.createEmpty(),
            objective: EditorState.createEmpty(),
            project: EditorState.createEmpty(),
            info: EditorState.createEmpty(),
            contentComment: EditorState.createEmpty(),
            contentComment2: EditorState.createEmpty(),
            // contentReply: EditorState.createEmpty(),
            dataComment:[],
            dataComment2:[],
            // dataReply:[],
            videoRelated: [],
            countViews: 0,
            likes: 124,
            updatedLike: false,
            noChange: true,
            submitted: false,
            commentNotif: '',
            allUser: [],
            visible: 5,
        };
        this.updateLikes = this.updateLikes.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onContentCommentChange = this.onContentCommentChange.bind(this);
        this.onContentCommentChange2 = this.onContentCommentChange2.bind(this);
        // this.onContentReplyChange = this.onContentReplyChange.bind(this);
        this.handleSubmitComment = this.handleSubmitComment.bind(this);
        this.handleSubmitComment2 = this.handleSubmitComment2.bind(this);
        // this.handleSubmitReply = this.handleSubmitReply.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
        this.setState((prev) => {
          return {visible: prev.visible + 5};
        });
      }

    onContentCommentChange = (contentComment) => {
        this.setState({contentComment});
        const { dataComment, getClassName } = this.state;
        let contentConvert = contentComment.getCurrentContent();
        let userInfo = JSON.parse(localStorage.getItem('user'));
        this.setState({
            noChange: false,
            dataComment: {
                ...dataComment,
                user_id: userInfo.id,
                class_id: getClassName.id,
                body: JSON.stringify(convertToRaw(contentConvert), null, 4)
            }
        }); 
    }
    onContentCommentChange2 = (contentComment2) => {
        this.setState({contentComment2});
        const { dataComment2, getClassName } = this.state;
        let contentConvert = contentComment2.getCurrentContent();
        let userInfo = JSON.parse(localStorage.getItem('user'));
        this.setState({
            noChange: false,
            dataComment2: {
                ...dataComment2,
                user_id: userInfo.id,
                class_id: getClassName.id,
                body: JSON.stringify(convertToRaw(contentConvert), null, 4)
            }
        }); 
    }
    // onContentReplyChange = (contentReply) => {
    //     this.setState({contentReply});
    //     const { dataReply, getClassName } = this.state;
    //     let contentConvert = contentReply.getCurrentContent();
    //     let userInfo = JSON.parse(localStorage.getItem('user'));
    //     this.setState({
    //         noChange: false,
    //         dataReply: {
    //             ...dataReply,
    //             user_id: userInfo.id,
    //             class_id: getClassName.id,
    //             body: JSON.stringify(convertToRaw(contentConvert), null, 4)
    //         }
    //     }); 
    // }
    handleSubmitComment(e){
        e.preventDefault();
        this.setState({ submitted: true });
        const { dataComment, contentComment } = this.state;
        if (contentComment.getCurrentContent().hasText() === true) {
            axios.post(config.get("apiDomain")+"user/comment/add", dataComment,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                this.setState({
                    commentNotif: 'Bình luận của bạn đã được tạo và chờ kiểm duyệt từ hệ thống.',
                    contentComment: EditorState.createEmpty(),
                    submitted: false
                })
                this.turnOffRedTimeout = setTimeout(() => { 
                    this.setState(() => ({commentNotif: ''}))
                  }, 5000);
            })
            .catch(error => {
                this.setState({
                    commentNotif: 'Lỗi tạo bình luận',
                    submitted: false
                })
            })
        }
      }

      handleSubmitComment2(e){
        e.preventDefault();
        this.setState({ submitted: true });
        const { dataComment2, contentComment2 } = this.state;
        if (contentComment2.getCurrentContent().hasText() === true) {
            axios.post(config.get("apiDomain")+"user/comment/add", dataComment2,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                this.setState({
                    commentNotif: 'Bình luận của bạn đã được tạo và chờ kiểm duyệt từ hệ thống.',
                    contentComment2: EditorState.createEmpty(),
                    submitted: false
                })
                this.turnOffRedTimeout = setTimeout(() => { 
                    this.setState(() => ({commentNotif: ''}))
                  }, 5000);
            })
            .catch(error => {
                this.setState({
                    commentNotif: 'Lỗi tạo bình luận',
                    submitted: false
                })
            })
        }
      }

    //   handleSubmitReply(id){
    //     alert(id);
    //   }

    updateLikes() {
        if(!this.state.updated) {
          this.setState((prevState, props) => {
            return {
              likes: prevState.likes + 1,
              updatedLike: true
            };
          });
        } else {
    
          this.setState((prevState, props) => {
            return {
              likes: prevState.likes - 1,
              updatedLike: false
            };
          });
        }
    }
    handleClick(item){
        
        this.setState({
            selectedVideo: [
                {
                    id: item.id,
                    name: item.name,
                    url: item.url,
                    type: item.type,
                }
            ]
        })
    }
    handleChange = (event, value) => {
        this.setState({ value });
      };

    componentDidMount(){
        axios.get(config.get("apiDomain")+'class/content-video/'+this.props.match.params.classSlug)
        .then(res => {
            this.setState({
                videos: res.data,
                selectedVideo: res.data.slice(0,1),
            });
        });
        axios.get(config.get("apiDomain")+'class/userInfo/'+this.props.match.params.classSlug)
        .then(res => {
            this.setState({
                teacherInfo: {
                    id: res.data.id,
                    name: res.data.name,
                    avatar: res.data.avatar,
                    skill_related: JSON.parse(res.data.skill_related)
                }
            });
            
           
        });

        axios.get(config.get("apiDomain")+'class/getName/'+this.props.match.params.classSlug)
        .then(res => {
            this.setState({
                getClassName: res.data,
                countViews: res.data.countViews + 1
            });
            if(res.data.description !== null){
                let convert = convertFromRaw(JSON.parse(res.data.description));
                let editor = EditorState.createWithContent(convert);
                this.setState({desc: editor})
            }
            if(res.data.objective !== null){
                let convert = convertFromRaw(JSON.parse(res.data.objective));
                let editor = EditorState.createWithContent(convert);
                this.setState({objective: editor})
            }
            if(res.data.project !== null){
                let convert = convertFromRaw(JSON.parse(res.data.project));
                let editor = EditorState.createWithContent(convert);
                this.setState({project: editor})
            }
            if(res.data.user.info !== null){
                let convert = convertFromRaw(JSON.parse(res.data.user.info));
                let editor = EditorState.createWithContent(convert);
                this.setState({info: editor})
            }

            axios.get(config.get("apiDomain")+"class/getComment-by-class/"+res.data.id+"/0")
            .then(res => {
                this.setState({
                    comment: res.data,
                });
            });

        });

        axios.get(config.get("apiDomain")+'class/video-related/'+this.props.match.params.classSlug)
        .then(res => {
            this.setState({
                videoRelated: res.data,
            });
        });
        axios.get(config.get("apiDomain")+'class/update-view/'+this.props.match.params.classSlug);
        axios.get(config.get("apiDomain")+'userFrontend/all-user')
        .then(res => {
            this.setState({
                allUser: res.data.map(val=>(
                    {'text': val.name, 'value':val.name, 'url':'/'+val.id+'/'+val.name.replace(/\s+/g, '-')}
                )),
                loading: false
            });
        });
    }
    componentWillReceiveProps(nextProps) {
        this.setState({loading:true})
        const slug = this.props.match.params.classSlug;
        const newSlug = nextProps.match.params.classSlug;
        if(slug !== newSlug){
            axios.get(config.get("apiDomain")+'class/content-video/'+newSlug)
        .then(res => {
            this.setState({
                videos: res.data,
                selectedVideo: res.data.slice(0,1)
            });
        });

        axios.get(config.get("apiDomain")+'class/userInfo/'+newSlug)
        .then(res => {
            this.setState({
                teacherInfo: {
                    id: res.data.id,
                    name: res.data.name,
                    avatar: res.data.avatar,
                    skill_related: JSON.parse(res.data.skill_related)
                }
            });  
        });

        axios.get(config.get("apiDomain")+'class/getName/'+newSlug)
        .then(res => {
            this.setState({
                getClassName: res.data,
                countViews: res.data.countViews + 1
            });
            if(res.data.description !== null){
                let convert = convertFromRaw(JSON.parse(res.data.description));
                let editor = EditorState.createWithContent(convert);
                this.setState({desc: editor})
            }
            if(res.data.objective !== null){
                let convert = convertFromRaw(JSON.parse(res.data.objective));
                let editor = EditorState.createWithContent(convert);
                this.setState({objective: editor})
            }
            if(res.data.project !== null){
                let convert = convertFromRaw(JSON.parse(res.data.project));
                let editor = EditorState.createWithContent(convert);
                this.setState({project: editor})
            }
            if(res.data.user.info !== null){
                let convert = convertFromRaw(JSON.parse(res.data.user.info));
                let editor = EditorState.createWithContent(convert);
                this.setState({info: editor})
            }

            axios.get(config.get("apiDomain")+"class/getComment-by-class/"+res.data.id+"/0")
            .then(res => {
                this.setState({
                    comment: res.data,
                });
            });

        });

        axios.get(config.get("apiDomain")+'class/video-related/'+newSlug)
        .then(res => {
            this.setState({
                videoRelated: res.data,
            });
        });
        axios.get(config.get("apiDomain")+'class/update-view/'+newSlug);
        axios.get(config.get("apiDomain")+'userFrontend/all-user')
        .then(res => {
            this.setState({
                allUser: res.data.map(val=>(
                    {'text': val.name, 'value':val.name, 'url':'/'+val.id+'/'+val.name.replace(/\s+/g, '-')}
                )),
                loading: false
            });
        });
        }
    }
    render(){
        const { classes } = this.props;
        const { videos, selectedVideo, getClassName, comment, teacherInfo, value, desc, objective, project, info, videoRelated } = this.state;
        const { handleClick } = this;
        var description = desc.getCurrentContent().getBlocksAsArray();
        var obj = objective.getCurrentContent().getBlocksAsArray();
        var proj = project.getCurrentContent().getBlocksAsArray();
        var a = info.getCurrentContent().getBlocksAsArray();
        let userInfo = JSON.parse(localStorage.getItem('user')); 
        return(
            <div className={classes.root}>
                <MetaTags>
                    <title>{'Lớp học video '+getClassName.title+' - LearnSkill'}</title>
                    <meta name="description" content={'Học kỹ năng '+getClassName.title+' trên hệ thống học trực tuyến learnskill'} />
                    <meta name="keywords" content="học, dạy học, lớp học, dạy học, giáo dục, học trực tuyến, học online, video, lớp học video, các lớp học video" />
                    <meta property="og:title" content={'Lớp học video '+getClassName.title+' - LearnSkill'} />
                    <meta property="og:description" content={'Học kỹ năng '+getClassName.title+' trên hệ thống học trực tuyến learnskill'} />
                    <meta property="og:image" content={config.get('apiImg')+getClassName.class_image} />
                    <meta property="og:url" content={config.get('domain')} />
                    <meta name="twitter:card" content="summary_large_image" />
                </MetaTags>
            {this.state.loading ? (
              <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
            ) : (
                // videos.length !== 0 ? (
                <React.Fragment>
                <Grid container style={{padding:'0 10px'}}>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={8}>
                            
                            {selectedVideo.map(val=>(
                                <React.Fragment key={val.id}>
                                    {val.type === 'youtube' &&
                                        <div className='player-wrapper'>
                                        <ReactPlayer 
                                            className='react-player'
                                            url={'https://www.youtube.com/watch?v='+val.url}
                                            playing 
                                            width='100%'
                                            height='100%'
                                            controls ={true}
                                            config={{
                                                youtube: {
                                                playerVars: { modestbranding: 1, rel:0, showinfo:0, fs:1 }
                                                }
                                            }}
                                        />
                                        </div>
                                    }
                                    {val.type === 'vimeo' &&
                                        <div className='player-wrapper'>
                                        <ReactPlayer 
                                            className='react-player'
                                            url={'https://vimeo.com/'+val.url}
                                            playing 
                                            width='100%'
                                            height='100%'
                                            controls ={true}
                                        />
                                        </div>
                                    }
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" style={{fontSize:20,marginTop:15}}>{getClassName.title}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" style={{marginTop:10,color:'#888'}}>{this.state.countViews} lượt xem</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body2">
                                                <Tooltip title="Thích khóa học này">
                                                    <Button style={{color:'#888'}} onClick={this.updateLikes}>
                                                        <ThumbUpAlt /> {this.state.likes}  
                                                    </Button>
                                                </Tooltip>
                                                <span style={{marginLeft:20,marginRight:5}}>Chia sẻ:</span> 
                                                <Fab style={{color:'#888',height:'auto',width:'auto',minHeight:'auto'}} >
                                                <Tooltip title="Chia sẻ lên Facebook">
                                                    <FacebookShareButton
                                                        url={config.get('domain')+'video-course/'+this.props.match.params.classSlug}
                                                        quote={'Khóa học '+getClassName.title}
                                                        >
                                                        <FacebookIcon
                                                        size={32}
                                                        round />
                                                    </FacebookShareButton>
                                                </Tooltip>
                                                </Fab>
                                                <Fab style={{color:'#888',height:'auto',width:'auto',minHeight:'auto',marginLeft:5}} >
                                                <Tooltip title="Chia sẻ lên Twitter">
                                                <TwitterShareButton
                                                    url={config.get('domain')+'video-course/'+this.props.match.params.classSlug}
                                                    quote={'Khóa học '+getClassName.title}
                                                    >
                                                    <TwitterIcon
                                                    size={32}
                                                    round />
                                                </TwitterShareButton>
                                                </Tooltip>
                                                </Fab>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </Grid>
                            
                                </React.Fragment>
                            ))}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={classes.videoListBox}>
                                <div style={{padding: '15px 20px'}}>
                                    <Typography style={{fontWeight:450,fontSize:16}}>{getClassName.title}</Typography>
                                    <Typography>{videos.length} bài học</Typography>
                                </div>
                                <div className={classes.videoList}style={{paddingLeft:10}}>
                                    <List dense>
                                        {videos.map(value => (
                                        <ListItem key={value.id} button selected={value.id === selectedVideo.id ? true : false} onClick={() => handleClick(value)} style={{paddingLeft:0}}>
                                            <ListItemAvatar>
                                            <Avatar
                                                alt={value.name}
                                                src={value.thumbnail_custom !== null ? config.get('apiImg')+value.thumbnail_custom : '/no-preview.jpg'}
                                                style={{width:100, height:65,borderRadius:0}}
                                            />
                                            </ListItemAvatar>
                                            <ListItemText primary={<Typography style={{fontWeight:500, fontSize:16}}>{value.name}</Typography>} style={{paddingLeft:5}} />
                                        </ListItem>
                                        ))}
                                    </List>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    indicatorColor="secondary"
                                    textColor="secondary"
                                    style={{paddingLeft:5,paddingRight:5}}
                                >
                                    <Tab label="Giới thiệu" style={{textTransform:'initial',fontSize:16,minWidth:100}}/>
                                    <Tab label={"Thảo luận ("+comment.length+")"} style={{textTransform:'initial',fontSize:16,minWidth:100}}/>
                                    <Tab label="Dự án lớp học" style={{textTransform:'initial',fontSize:16,minWidth:100}}/>
                                </Tabs>
                                {value === 0 && <TabContainer>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} sm={12} md={7}>
                                        <Typography variant="h6" color="primary">Giới thiệu lớp học</Typography>
                                        {getClassName.class_image !== null &&
                                            <img src={config.get('apiImg')+getClassName.class_image} alt={getClassName.title} style={{width:'100%'}} />
                                        }
                                        {desc.getCurrentContent().hasText() ? (
                                        description.map((val,index)=>(
                                            <div key={index} style={{marginBottom:15,textAlign:'justify'}}>{val.getText()}</div>
                                        ))
                                        ) : (
                                        <div className="box">
                                            Không có mô tả cho lớp học này
                                        </div>
                                        )}
                                        <Typography variant="h6"  color="primary" style={{marginTop:20}}>
                                        Mục tiêu khóa học
                                        </Typography>
                                        {objective.getCurrentContent().hasText() ? (
                                        <Typography component="div" className={classes.caption} color="textPrimary">
                                            Sau khi học xong khóa học này, người học sẽ có thể:
                                            {
                                            obj.map((val,index)=>(
                                            <div key={index} style={{marginBottom:15,textAlign:'justify'}}>{val.getText()}</div>
                                            ))
                                            }
                                        </Typography>
                                        ) : (
                                        <div className="box">
                                            Không có mục tiêu cho lớp học này
                                        </div>
                                        )}
                                        <Typography variant="h6"  color="primary" style={{marginTop:20}}>
                                        Dự án khóa học
                                        </Typography>
                                        {project.getCurrentContent().hasText() ? (
                                            proj.map((val,index)=>(
                                            <div key={index} style={{marginBottom:15,textAlign:'justify'}}>{val.getText()}</div>
                                            ))
                                        ) : (
                                        <div className="box">
                                            Không có dự án cho lớp học này
                                        </div>
                                        )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5}>
                                            <div>
                                                <div className={classes.inline}>
                                                    <PersonOutline /> <span style={{position: 'relative', top: -5}}>{this.state.countViews}</span>
                                                    <Typography>Lượt xem</Typography>
                                                </div>
                                                <div className={classes.inline}>
                                                    <AddToPhotos /> <span style={{position: 'relative', top: -5}}>0</span>
                                                    <Typography>Dự án</Typography>
                                                </div>
                                                <div className={classes.inline}>
                                                    <Assessment /> <span style={{position: 'relative', top: -5}}>
                                                    {getClassName.level === 0 && 'Dễ'}
                                                    {getClassName.level === 1 && 'Khó'}
                                                    {getClassName.level === 2 && 'Nâng cao'}
                                                    </span>
                                                    <Typography>Mức độ</Typography>
                                                </div>
                                            </div>
                                            <div style={{clear:'both'}}></div>
                                            <Divider style={{margin:'10px 0'}} />
                            
                                            <List style={{paddingTop:0}}>
                                                <ListItem style={{paddingLeft:0}}>
                                                    <ListItemAvatar className={classes.teacherName}>
                                                        {teacherInfo.avatar !== '' ? (
                                                            <UserAvatar size="50" name={teacherInfo.name} src={config.get('apiImg')+teacherInfo.avatar}/>
                                                        ) : (
                                                            <UserAvatar size="50" name={teacherInfo.name}/>
                                                        )}
                                                    </ListItemAvatar>
                                                        <ListItemText
                                                            primary={<Link to={"/user/"+teacherInfo.id+'/'+teacherInfo.name.replace(/\s+/g, '-')} className={classes.teacherName}>{teacherInfo.name}</Link>}
                                                            secondary={
                                                            <React.Fragment>
                                                                <Typography component="span" className={classes.caption} color="textPrimary">
                                                                    {teacherInfo.skill_related.map((rs, i, arr)=>(
                                                                        <span key={i}>{rs.name}{arr.length-1 === i ? "": ", "}</span>
                                                                    ))}
                                                                    </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                                <Typography variant="body1" gutterBottom>
                                                    Xin chào, tôi là {teacherInfo.name}
                                                </Typography>
                                                {
                                                    a.map((val,index)=>(
                                                    <div key={index} style={{marginBottom:15,textAlign:'justify'}}>{val.getText()}</div>
                                                    ))
                                                }
                                        </Grid>
                                    </Grid>
                                    
                                </TabContainer>}
                                {value === 1 && <TabContainer>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={8}>
                                        {!isLoged() ? (
                                            <Paper style={{padding:"20px 10px"}}>
                                                Vui lòng <Link to = "/login" className={classes.userComment}>Đăng nhập</Link> hoặc <Link to = "/register" className={classes.userComment}>Đăng ký</Link> để bình luận
                                            </Paper>
                                        ) : (
                                            <React.Fragment>
                                                <Card className={classes.card} style={{overflow: 'initial'}}>
                                                    <CardHeader
                                                        style={{background:'#f5f6f7',padding:'3px 10px',borderBottom: '1px solid #dddfe2',borderRadius: '2px 2px 0 0'}}
                                                        title={<span style={{fontSize:17,fontWeight:500}}>Bình luận</span>}
                                                        subheader={<span style={{color:'red'}} className="fade-in">{this.state.commentNotif}</span>}
                                                    />
                                                    <CardContent style={{paddingBottom:0}}>
                                                        <List style={{paddingTop:0}}>
                                                            <ListItem style={{paddingLeft:0}}>
                                                                <ListItemAvatar className={classes.teacherName}>
                                                                    {userInfo.avatar !== '' ? (
                                                                        <UserAvatar size="50" name={userInfo.name} src={config.get('apiImg')+userInfo.avatar}/>
                                                                    ) : (
                                                                        <UserAvatar size="50" name={userInfo.name}/>
                                                                    )}
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    style={{position:'relative',top:-20}}
                                                                    primary={
                                                                        <React.Fragment>
                                                                        
                                                                        <Editor
                                                                            wrapperClassName="demo-wrapper"
                                                                            editorClassName="demo-editor"
                                                                            toolbarOnFocus
                                                                            toolbarClassName="toolbar-class"
                                                                            editorState={this.state.contentComment}
                                                                            onEditorStateChange={this.onContentCommentChange}
                                                                            placeholder="Viết trao đổi..."
                                                                            hashtag={{}}
                                                                            mention={{
                                                                                separator: ' ',
                                                                                trigger: '@',
                                                                                suggestions:this.state.allUser,
                                                                              }}
                                                                            toolbar={{
                                                                                options: ['image', 'embedded'],
                                                                                // emoji: {
                                                                                //     icon: emoji,
                                                                                //     className: undefined,
                                                                                //     component: undefined,
                                                                                //     popupClassName: undefined,
                                                                                //     emojis: [
                                                                                //       '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                                                //       '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                                                //       '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                                                //       '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                                                //       '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                                                //       '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                                                //       '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                                                //       '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                                                //       '✅', '❎', '💯',
                                                                                //     ],
                                                                                //   },
                                                                                  image: {
                                                                                    uploadCallback: uploadImageCallBack,
                                                                                    previewImage: true,
                                                                                  },
                                                                                  link: {
                                                                                    linkCallback: params => ({ ...params })
                                                                                  },
                                                                                  embedded: {
                                                                                    embedCallback: link => {
                                                                                      const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                                                                      return (detectedSrc && detectedSrc[1]) || link;
                                                                                    }
                                                                                  }
                                                                            }}
                                                                        />
                                                                        {this.state.submitted && this.state.contentComment.getCurrentContent().hasText() === false ? <Typography style={{color:'red'}}>Nội dung không được để trống</Typography> : ''}
                                                                        </React.Fragment>
                                                                    }
                                                                        
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </CardContent>
                                                    <CardActions style={{justifyContent: 'flex-end',borderTop: '1px solid #dddfe2'}}>
                                                        <div className={classes.load}>
                                                            <div className={classes.wrapper}>
                                                                <Button onClick = {this.handleSubmitComment} style={{textTransform:'initial'}} variant="contained" color="primary" size="small" disabled={this.state.submitted || this.state.noChange}>Đăng</Button>
                                                                {this.state.submitted && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                            </div>
                                                        </div>
                                                    </CardActions>
                                                </Card>
                                            </React.Fragment>
                                        )}
                                        {comment.slice(0, this.state.visible).map((val, index) =>(
                                            <React.Fragment key={index}>
                                            <List className="fade-in">
                                                
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                    <UserAvatar size="50" name={val.username} src={config.get('apiImg')+val.avatar}/>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Link to={"/user/"+val.userId+'/'+val.username.replace(/\s+/g, '-')} className={classes.userComment}>{val.username}</Link> 
                                                            <Editor editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(val.body)))} readOnly={true} toolbarHidden />
                                                            
                                                        </React.Fragment>}
                                                    secondary={
                                                        <React.Fragment>
                                                        {isLoged() ? (
                                                                <Typography component="span" style={{fontSize:13,color:'#888'}}>
                                                                {/* {val.clike} <Button onClick={this.updateLikeComment} style={{textTransform:'initial', color:'#888'}}>thích</Button>  */}
                                                                {/* <Link to="#" style={{color:'#888',textDecoration:'none',margin:'0 20px 0 0'}}>Trả lời</Link>  */}
                                                                <Timestamp date={new Date(val.created_at.date)} relative autoUpdate />
                                                                </Typography>
                                                            ) : (
                                                                <Timestamp date={new Date(val.created_at.date)} relative autoUpdate />
                                                            )}
                                                        </React.Fragment>
                                                    }
                                                    />
                                                </ListItem>
                                            </List>
                                            {/* {val.subcomment.map((v, idx)=>(
                                                <List key={idx} style={{marginLeft:50,borderLeft:'2px solid #1e88e5', padding:0}}>
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar>
                                                            <UserAvatar size="35" name={v.username} src={v.avatar}/>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                        primary={
                                                            <React.Fragment>
                                                                <Link to={"/user/"+v.userId+'/'+v.username.replace(/\s+/g, '-')} className={classes.userComment}>{v.username}</Link> 
                                                                <Editor editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(val.body)))} readOnly={true} toolbarHidden />
                                                            
                                                                </React.Fragment>}
                                                        secondary={
                                                            <React.Fragment>
                                                            {isLoged() ? (
                                                                <Typography component="span" style={{fontSize:13,color:'#888'}}>
                                                                {v.clike} <Link to="#" style={{color:'#888',textDecoration:'none'}}>thích</Link> 
                                                                <Link to="#" style={{color:'#888',textDecoration:'none',margin:'0 20px 0 0'}}>Trả lời</Link> 
                                                                <Timestamp date={new Date(val.created_at.date)} relative autoUpdate />
                                                                </Typography>
                                                            ) : (
                                                                <Timestamp date={new Date(val.created_at.date)} relative autoUpdate />
                                                            )}
                                                            </React.Fragment>
                                                        }
                                                        />
                                                    </ListItem>
                                                </List>

                                                
                                            ))} */}
                                            {/* <React.Fragment>
                                            <List style={{marginLeft:50,padding:0}}>
                                                            <ListItem style={{padding:0}}>
                                                                <ListItemAvatar className={classes.teacherName}>
                                                                    {userInfo.avatar !== '' ? (
                                                                        <UserAvatar size="35" name={userInfo.name} src={userInfo.avatar}/>
                                                                    ) : (
                                                                        <UserAvatar size="35" name={userInfo.name}/>
                                                                    )}
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    style={{position:'relative',top:-20,paddingRight:0}}
                                                                    primary={
                                                                        <React.Fragment>
                                                                        
                                                                        <Editor
                                                                            wrapperClassName="demo-wrapper"
                                                                            editorClassName="demo2-editor"
                                                                            toolbarOnFocus
                                                                            toolbarClassName="toolbar-class"
                                                                            editorState={this.state.contentReply}
                                                                            onEditorStateChange={this.onContentReplyChange}
                                                                            placeholder="Viết trả lời..."
                                                                            hashtag={{}}
                                                                            mention={{
                                                                                separator: ' ',
                                                                                trigger: '@',
                                                                                suggestions:this.state.allUser,
                                                                              }}
                                                                            toolbar={{
                                                                                options: ['image', 'embedded'],
                                                                                // emoji: {
                                                                                //     icon: emoji,
                                                                                //     className: undefined,
                                                                                //     component: undefined,
                                                                                //     popupClassName: undefined,
                                                                                //     emojis: [
                                                                                //       '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                                                //       '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                                                //       '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                                                //       '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                                                //       '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                                                //       '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                                                //       '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                                                //       '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                                                //       '✅', '❎', '💯',
                                                                                //     ],
                                                                                //   },
                                                                                  image: {
                                                                                    uploadCallback: uploadImageCallBack,
                                                                                    previewImage: true,
                                                                                  },
                                                                                  link: {
                                                                                    linkCallback: params => ({ ...params })
                                                                                  },
                                                                                  embedded: {
                                                                                    embedCallback: link => {
                                                                                      const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                                                                      return (detectedSrc && detectedSrc[1]) || link;
                                                                                    }
                                                                                  }
                                                                            }}
                                                                        />
                                                                        {this.state.submitted && this.state.contentComment2.getCurrentContent().hasText() === false ? <Typography style={{color:'red'}}>Nội dung không được để trống</Typography> : ''}
                                                                        </React.Fragment>
                                                                    }
                                                                        
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        <div align="right" style={{position:'relative',top:-38}}>
                                                            <p style={{color:'red'}}>{this.state.commentNotif}</p>
                                                            <div className={classes.load}>
                                                                <div className={classes.wrapper}>
                                                                    <Button id="1" onClick={e => this.handleSubmitReply(e.target.id)}style={{textTransform:'initial'}} variant="contained" color="primary" size="small" disabled={this.state.submitted || this.state.noChange}>Đăng</Button>
                                                                    {this.state.submitted && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </React.Fragment> */}
                                            </React.Fragment>
                                        ))}
                                        {this.state.visible < this.state.comment.length &&
                                            <Button onClick={this.loadMore} size="small" variant="outlined" color="primary" style={{textTransform:'initial'}}>Xem thêm</Button>
                                         }
                                         {((this.state.visible > this.state.comment.length) &&  (this.state.comment.length > 5)) &&
                                            
                                            <React.Fragment>
                                            <List style={{paddingTop:0,height:'auto'}}>
                                                            <ListItem style={{padding:0}}>
                                                                <ListItemAvatar className={classes.teacherName}>
                                                                    {userInfo.avatar !== '' ? (
                                                                        <UserAvatar size="50" name={userInfo.name} src={userInfo.avatar}/>
                                                                    ) : (
                                                                        <UserAvatar size="50" name={userInfo.name}/>
                                                                    )}
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    style={{position:'relative',top:-20,paddingRight:0}}
                                                                    primary={
                                                                        <React.Fragment>
                                                                        
                                                                        <Editor
                                                                            wrapperClassName="demo-wrapper"
                                                                            editorClassName="demo2-editor"
                                                                            toolbarOnFocus
                                                                            toolbarClassName="toolbar-class"
                                                                            editorState={this.state.contentComment2}
                                                                            onEditorStateChange={this.onContentCommentChange2}
                                                                            placeholder="Viết trao đổi..."
                                                                            hashtag={{}}
                                                                            mention={{
                                                                                separator: ' ',
                                                                                trigger: '@',
                                                                                suggestions:this.state.allUser,
                                                                              }}
                                                                            toolbar={{
                                                                                options: ['image', 'embedded'],
                                                                                // emoji: {
                                                                                //     icon: emoji,
                                                                                //     className: undefined,
                                                                                //     component: undefined,
                                                                                //     popupClassName: undefined,
                                                                                //     emojis: [
                                                                                //       '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                                                //       '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                                                //       '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                                                //       '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                                                //       '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                                                //       '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                                                //       '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                                                //       '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                                                //       '✅', '❎', '💯',
                                                                                //     ],
                                                                                //   },
                                                                                  image: {
                                                                                    uploadCallback: uploadImageCallBack,
                                                                                    previewImage: true,
                                                                                  },
                                                                                  link: {
                                                                                    linkCallback: params => ({ ...params })
                                                                                  },
                                                                                  embedded: {
                                                                                    embedCallback: link => {
                                                                                      const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                                                                      return (detectedSrc && detectedSrc[1]) || link;
                                                                                    }
                                                                                  }
                                                                            }}
                                                                        />
                                                                        {this.state.submitted && this.state.contentComment2.getCurrentContent().hasText() === false ? <Typography style={{color:'red'}}>Nội dung không được để trống</Typography> : ''}
                                                                        </React.Fragment>
                                                                    }
                                                                        
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        <div align="right" style={{position:'relative',top:-38}}>
                                                            <p style={{color:'red'}}>{this.state.commentNotif}</p>
                                                            <div className={classes.load}>
                                                                <div className={classes.wrapper}>
                                                                    <Button onClick = {this.handleSubmitComment2} style={{textTransform:'initial'}} variant="contained" color="primary" size="small" disabled={this.state.submitted || this.state.noChange}>Đăng</Button>
                                                                    {this.state.submitted && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </React.Fragment>
                                        }
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>

                                        </Grid>
                                    </Grid>
                                </TabContainer>}
                                {value === 3 && <TabContainer>
                                    Chức năng này chưa sẵn có
                                </TabContainer>}
                                <Typography variant="body2" style={{fontSize:22,fontWeight:450,marginTop:30,marginBottom:15}}>Các lớp học liên quan</Typography>
                                
                            </Grid>
                                
                        </Grid>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
                <div style={{background:'#eee',padding:'35px 0'}}>
                    <Grid container style={{padding:'0 10px'}}>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <Grid container spacing={16}>
                            {videoRelated.map(val=>(
                                <Grid item xs={12} sm={6} md={3} key={val.id}>
                                    <Card>
                                        <CardActionArea className={classes.videoThumbnail}>
                                            <Link to={"/video-course/"+val.slug}>
                                                <PlayCircleOutline color="primary" className={classes.btnPlay} />
                                                <img src={(val.class_image !== null) ? config.get('apiImg')+val.class_image : "/no-preview.jpg"} className={classes.media} alt={val.title}/>
                                            </Link>
                                        </CardActionArea>
                                        <CardActions style={ {padding:'2px'} }>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography className={classes.caption} variant="caption" align="left">
                                                        {val.countViews} người học
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography className={classes.caption} variant="caption" align="right">
                                                    
                                                    <Icon className={classes.iconTime}>schedule</Icon> <Timestamp date={new Date(val.created_at)} relative autoUpdate />
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" gutterBottom>
                                                        <Link to={"/video-course/"+val.slug} className={classes.titleClass}>{val.title}</Link>
                                                    </Typography>
                                                    <Typography style={{marginTop:20}}>
                                                        <Link to={"/user/"+val.user.id+'/'+val.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{val.user.name}</Link> 
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                </React.Fragment>
            )}
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { adding} = state.getComment;
    return {
        adding
    };
  }
export default connect(mapStateToProps)(withStyles(styles)(ClassDetail));


import { userConstants } from '../_constants/user.constant';
import { alertActions } from './alert.actions';
import { history } from '../_helpers/history';
import axios from 'axios';
import config from "react-global-configuration";
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux'
import { authHeader } from '../_helpers/auth-header';

export const userActions = {
    checkEmail, login, logout, register, skillRelated, getInfo, editProfile, getList, addList, getAll, getAllDeleted,
    adminAddUser,delete: _delete, restore, forceDel, getInfoUser, getAllNoActive, activeUser, banUser, getAllAdmin,
    getAllTeacher, getAllStudent
};

function checkEmail(email) {
    return dispatch => {
        dispatch(request({ email }));
        axios.get(config.get("apiDomain")+'auth/checkEmail/'+ email,
          {
            headers: {
                'Content-Type': 'application/json',
            }
          }
        )
        .then(res => 
            {
                let responseJson = res.data;
              if(responseJson.id){  
                dispatch(success(res.data));
              }else{
                dispatch(failure('loi'));
                dispatch(alertActions.error('Không tồn tại email bạn nhập trong hệ thống'));
              }
    });
}
function request(email) { return { type: userConstants.CHECKEMAIL_REQUEST, email } }
function success(email) { return { type: userConstants.CHECKEMAIL_SUCCESS, email } }
function failure(error) { return { type: userConstants.CHECKEMAIL_FAILURE, error } }
}

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));
        const data = {
            email: email,
            password: password
        }
        axios.post(config.get("apiDomain")+'auth/login', data,
          {
            headers: {
                'Content-Type': 'application/json',
            }
          }
        )
        .then(res => 
            {
              let responseJson = res.data;
              if(responseJson.access_token){  
                dispatch(success(responseJson));
                dispatch(snackbar.show({
                    message: 'Đăng nhập thành công',
                    action: 'Undo',
                    handleAction: () => {/* do something... */} 
                  }))
                localStorage.setItem('user', JSON.stringify(responseJson));
                history.push('/home');
              }else{
                dispatch(failure('loi'));
                dispatch(alertActions.error('Mật khẩu không chính xác'));
                dispatch(snackbar.show({
                    message: 'Lỗi đăng nhập! Mật khẩu không chính xác',
                    action: 'Undo',
                    handleAction: () => {/* do something... */} 
                  }))
              }
    });
}

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    return dispatch => {
        dispatch(request());
        let user = JSON.parse(localStorage.getItem('user'));
        axios.get(config.get("apiDomain")+'auth/logout',
        {
          headers: {
              'Authorization': 'Bearer '+user.access_token,
          }
        }
      )
      .then(res => 
        {
            let responseJson = res.data;
            if(responseJson.message === 1){ 
                dispatch(success(responseJson));
                dispatch(snackbar.show({
                    message: 'Đăng xuất thành công',
                  })) 
                localStorage.removeItem('user');
                history.push('/login');
            }else{
                dispatch(failure('loi'));
                dispatch(alertActions.error('Lỗi đăng xuất'));
                dispatch(snackbar.show({
                    message: 'Lỗi đăng xuất',
                  }))
              }
    });
}
    function request() { return { type: userConstants.LOGOUT_REQUEST  } }
    function success() { return { type: userConstants.LOGOUT_SUCCESS } }
    function failure(error) { return { type: userConstants.LOGOUT_FAILURE, error } }
}

function register(name, email, password) {
    return dispatch => {
        dispatch(request(name));
        const data = 
        {
            name: name,
            email: email,
            password: password
        }
        axios.post(config.get("apiDomain")+'auth/signup', data,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => 
        {
            let responseJson = res.data;
            if(responseJson.access_token){  
            dispatch(success(responseJson));
            localStorage.setItem('user', JSON.stringify(responseJson));
            dispatch(snackbar.show({
                message: 'Đăng ký tài khoản thành công',
              })) 
            history.push('/u/ky-nang-quan-tam');
            }else{
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình đăng ký hoặc địa chỉ email đã tồn tại'));
            }
    });
}

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getInfo() {
    let user = JSON.parse(localStorage.getItem('user'));
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+'user/info/'+user.id,
        {
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'))
        })
    };

    function request() { return { type: userConstants.GETINFO_REQUEST } }
    function success(userInfo) { return { type: userConstants.GETINFO_SUCCESS, userInfo } }
    function failure(error) { return { type: userConstants.GETINFO_FAILURE, error } }
}

//Info user for update
function getInfoUser(id) {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+'admin/get-user/'+id,
        {
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'))
        })
    };

    function request() { return { type: userConstants.GETINFOUPDATE_REQUEST } }
    function success(userInfo) { return { type: userConstants.GETINFOUPDATE_SUCCESS, userInfo } }
    function failure(error) { return { type: userConstants.GETINFOUPDATE_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+'admin/user-all',
        {
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'))
        })
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(getAll) { return { type: userConstants.GETALL_SUCCESS, getAll } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function getAllAdmin() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+'auth/get-admin',
        {
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'))
        })
    };

    function request() { return { type: userConstants.GETADMIN_REQUEST } }
    function success(getAdmin) { return { type: userConstants.GETADMIN_SUCCESS, getAdmin } }
    function failure(error) { return { type: userConstants.GETADMIN_FAILURE, error } }
}

function getAllTeacher() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+'auth/get-teacher',
        {
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'))
        })
    };

    function request() { return { type: userConstants.GETTEACHER_REQUEST } }
    function success(getAdmin) { return { type: userConstants.GETTEACHER_SUCCESS, getAdmin } }
    function failure(error) { return { type: userConstants.GETTEACHER_FAILURE, error } }
}

function getAllStudent() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+'auth/get-student',
        {
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'))
        })
    };

    function request() { return { type: userConstants.GETSTUDENT_REQUEST } }
    function success(getAdmin) { return { type: userConstants.GETSTUDENT_SUCCESS, getAdmin } }
    function failure(error) { return { type: userConstants.GETSTUDENT_FAILURE, error } }
}


function getAllNoActive() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+'admin/user/not-active',
        {
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'))
        })
    };

    function request() { return { type: userConstants.GETALL_NOACTIVE_REQUEST } }
    function success(getAll) { return { type: userConstants.GETALL_NOACTIVE_SUCCESS, getAll } }
    function failure(error) { return { type: userConstants.GETALL_NOACTIVE_FAILURE, error } }
}

function activeUser(id) {
    return dispatch => {
        dispatch(request(id));
        
        axios.get(config.get("apiDomain")+'admin/user/active/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Kích hoạt người dùng thành công',
                })) 
            
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi kết nối server'))
        })
}

    function request(id) { return { type: userConstants.ACTIVE_USER_REQUEST, id } }
    function success(id) { return { type: userConstants.ACTIVE_USER_SUCCESS, id } }
    function failure(error) { return { type: userConstants.ACTIVE_USER_FAILURE, error } }
}

function banUser(id) {
    return dispatch => {
        dispatch(request(id));
        
        axios.get(config.get("apiDomain")+'admin/user/ban/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Hủy kích hoạt người dùng thành công',
                })) 
            
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi kết nối server'))
        })
}

    function request(id) { return { type: userConstants.BAN_USER_REQUEST, id } }
    function success(id) { return { type: userConstants.BAN_USER_SUCCESS, id } }
    function failure(error) { return { type: userConstants.BAN_USER_FAILURE, error } }
}

function getAllDeleted() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+'admin/user/del',
        {
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'))
        })
    };

    function request() { return { type: userConstants.GETALLDELETED_REQUEST } }
    function success(getAll) { return { type: userConstants.GETALLDELETED_SUCCESS, getAll } }
    function failure(error) { return { type: userConstants.GETALLDELETED_FAILURE, error } }
}


function getList() {
    let user = JSON.parse(localStorage.getItem('user'));
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+'user/getClassList/'+user.id,
        {
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'))
        })
    };

    function request() { return { type: userConstants.GETLISTUSER_REQUEST } }
    function success(getList) { return { type: userConstants.GETLISTUSER_SUCCESS, getList } }
    function failure(error) { return { type: userConstants.GETLISTUSER_FAILURE, error } }
}


function skillRelated(id) {
    return dispatch => {
        dispatch(request(id));
        axios.post(config.get("apiDomain")+'userFrontend/info/'+id,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => 
        {
            if(res.data.length > 0){
                dispatch(success(JSON.parse(res.data.skill_related)));
            }else{
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình nạp dữ liệu'));
            }
        });
    }
    function request() { return { type: userConstants.SKILLRELATED_REQUEST } }
    function success(users) { return { type: userConstants.SKILLRELATED_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SKILLRELATED_FAILURE, error } }
}

function editProfile(id, user) {
    return dispatch => {
        dispatch(request(user));
        
        axios.put(config.get("apiDomain")+'user/edit/'+id, user,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            dispatch(snackbar.show({
                message: 'Sửa thông tin thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình sửa thông tin'))
        })
}

    function request(user) { return { type: userConstants.EDITUSER_REQUEST, user } }
    function success(user) { return { type: userConstants.EDITUSER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.EDITUSER_FAILURE, error } }
}
function addList(id, list) {
    return dispatch => {
        dispatch(request(list));
        
        axios.post(config.get("apiDomain")+'user/addList/'+id, list,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            dispatch(snackbar.show({
                message: 'Thêm danh sách thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình thêm danh sách'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình thêm danh sách',
              }))
        })
}

    function request(list) { return { type: userConstants.ADDLIST_REQUEST, list } }
    function success(list) { return { type: userConstants.ADDLIST_SUCCESS, list } }
    function failure(error) { return { type: userConstants.ADDLIST_FAILURE, error } }
}

//admin add user
function adminAddUser(user) {
    return dispatch => {
        dispatch(request(user));
        const data = 
        {
            name: user.name,
            email: user.username,
            password: user.password
        }
        axios.post(config.get("apiDomain")+'admin/add-user', data,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            dispatch(snackbar.show({
                message: 'Thêm người dùng thành công',
            })) 
            
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình thêm người dùng'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình thêm người dùng',
        }))
    });
}

    function request(adduser) { return { type: userConstants.ADMINADDUSER_REQUEST, adduser } }
    function success(adduser) { return { type: userConstants.ADMINADDUSER_SUCCESS, adduser } }
    function failure(error) { return { type: userConstants.ADMINADDUSER_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        axios.delete(config.get("apiDomain")+'admin/delete-user/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa người dùng thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa người dùng',
        }))
    });
}

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}

function restore(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+'admin/restore-user/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Khôi phục người dùng thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình khôi phục người dùng',
        }))
    });
}

    function request(id) { return { type: userConstants.RESTORE_REQUEST, id } }
    function success(id) { return { type: userConstants.RESTORE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.RESTORE_FAILURE, id, error } }
}

function forceDel(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+'admin/force-del-user/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa hẳn người dùng thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa hẳn người dùng',
        }))
    });
}

    function request(id) { return { type: userConstants.FORCEDEL_REQUEST, id } }
    function success(id) { return { type: userConstants.FORCEDEL_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.FORCEDEL_FAILURE, id, error } }
}
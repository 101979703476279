import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider, CircularProgress, Paper, Card, CardActionArea, List, ListItem, ListItemAvatar, 
    ListItemText, CardHeader, CardMedia, CardContent
} from '@material-ui/core';
import axios from 'axios';
import config from "react-global-configuration";
import Icon from '@material-ui/core/Icon';
import UserAvatar from 'react-user-avatar';
import { convertFromRaw, EditorState } from 'draft-js';
import Avatar from '@material-ui/core/Avatar';
import Timestamp from 'react-timestamp';

const styles = theme => ({
    root: {
        marginTop:80,
        marginBottom:30,
        width:'100%'
    },
    linkTips:{
        textDecoration: 'none',
        marginRight:30,
        color:'#444',
    },
    section:{
        marginBottom: 20,
    },
    media: {
      objectFit: 'cover',
      width: '100%',
      maxHeight:150,
    },
    bigMedia: {
        objectFit: 'cover',
        width: '100%',
      },
    card: {
      width: '100%',
    },
    chip: {
        width:'100%',
        color: '#777',
        borderRadius: 0,
      },
    caption:{
        color: '#777',
    },
    teacherName: {
        color: '#777',
        textDecoration: 'none',
        fontWeight: 450,
        cursor: 'pointer',
        '&:hover': {
            color: 'red',
            textDecoration: 'none',
        },
    },
     
    iconTime: {
      fontSize: 13,
      position: 'relative',
      bottom: -2,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    button: {
      margin: theme.spacing.unit,
    },
    center:{
        textAlign: 'center',
    },
    
      linkListTeacher: {
          color: '#1565C0',
          textDecoration: 'none',
          marginLeft: 20,
          fontWeight: 'normal',
          '&:hover':{
              color: '#d50000',
          }
      },
      titleClass:{
        color: '#444',
        fontSize:'1.1em',
        fontWeight:450,
        textDecoration: 'none',
        marginBottom: 30,
        '&:hover':{
            color: '#1e88e5',
        }
       },
})

class subCatTips extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        tipSec: [],
        catName: [],
        catMenu: [],
        data: [],
        catSlug: '',
        subCatFirst: [],
        content: EditorState.createEmpty(),
        loading: true,
        error: false,
        hasMore: true,
        isLoading: false,
        tipLoadmore: [],
        elmStart: 0,
        elmOfset: 12
      };
      window.onscroll = () => {
        const {
          state: {
            error,
            isLoading,
            hasMore,
            elmStart,
            elmOfset
          },
        } = this;
 
        if (error || isLoading || !hasMore) return;
        if (
          window.innerHeight + document.documentElement.scrollTop
          === document.documentElement.offsetHeight
        ) {
           
          this.loadTips(this.props.match.params.subCatSlug);
          this.setState({ elmStart: elmStart+12, elmOfset: elmOfset+12})
        }
      };
    }

    loadTips = (id) => {
        this.setState({ isLoading: true }, () => {
            axios.get(config.get("apiDomain")+"userFrontend/get-all-subcat-tip/"+id)
            
            .then(res => {
                const nextTips = res.data.slice(1).map(tip => ({
                    id: tip.id,
                    title: tip.title,
                    slug: tip.slug,
                    section: tip.section,
                    category: tip.category,
                    image: tip.image,
                    userId: tip.user.id,
                    userName: tip.user.name,
                    avatar: tip.user.avatar,
                    created_at: tip.created_at
                  }));
                this.setState({
                    hasMore: (this.state.tipLoadmore.length < this.state.data.length-1),
                    isLoading: false,
                    tipLoadmore: [
                    ...this.state.tipLoadmore,
                    ...nextTips.slice(this.state.elmStart,this.state.elmOfset),
                    ],
                });
            })
            .catch((err) => {
                this.setState({
                  error: err.message,
                  isLoading: false,
                 });
              })
            
        
        });
      }
      
    componentDidMount() {
        this.loadTips(this.props.match.params.subCatSlug);
        axios.get(config.get("apiDomain")+"userFrontend/tipMenuAll/0")
        .then(res => {
            this.setState({tipSec: res.data});
        })
        axios.get(config.get("apiDomain")+"userFrontend/tipMenuById/"+this.props.match.params.catSlug)
            .then(res => {
                this.setState({catMenu: res.data});
            })
        axios.get(config.get("apiDomain")+"userFrontend/getMenuName/"+this.props.match.params.catSlug)
            .then(res => {
                this.setState({catName: res.data});
            })
            axios.get(config.get("apiDomain")+"userFrontend/sub-cat-tip-first/"+this.props.match.params.subCatSlug)
            .then(res => {
                this.setState({subCatFirst: res.data});
            })
            axios.get(config.get("apiDomain")+"userFrontend/get-all-subcat-tip/"+this.props.match.params.subCatSlug)
            .then(res => {
                this.setState({data: res.data, loading: false});
            })
    }
    componentWillReceiveProps(nextProps) {
        const currProps = this.props.match.params.subCatSlug;

        const newProps = nextProps.match.params.subCatSlug;
        // const newProps2 = nextProps.match.params.subCatSlug;
        if(currProps !== newProps){
            this.setState({loading:true})
            this.loadTips(nextProps.match.params.subCatSlug);
            axios.get(config.get("apiDomain")+"userFrontend/tipMenuById/"+newProps)
            .then(res => {
                this.setState({catMenu: res.data});
            })
            axios.get(config.get("apiDomain")+"userFrontend/getMenuName/"+newProps)
            .then(res => {
                this.setState({catName: res.data})
            })
            axios.get(config.get("apiDomain")+"userFrontend/sub-cat-tip-first/"+newProps)
            .then(res => {
                this.setState({subCatFirst: res.data});
            })
            axios.get(config.get("apiDomain")+"userFrontend/get-all-subcat-tip/"+newProps)
            .then(res => {
                this.setState({data: res.data, loading: false});
            })
        }
    }
    getSubCatName(id){
        let filter = this.state.catMenu.filter(val => val.id === id);
        let temp = [];
        filter.map(val=>(temp.push(val.slug)))
        return temp[0]
    }
    render(){
        const { classes } = this.props;
        const { catName, catMenu, data, tipSec, tipLoadmore, subCatFirst, error, isLoading } = this.state;
        
        return (
            <div className={classes.root}>
                <MetaTags>
                    <title>{catName.name+' - LearnSkill'}</title>
                    <meta name="description" content="Tổng hợp các bài hướng dẫn, các mẹo và thủ thuật từ nhiều lĩnh vực trong cuộc sống" />
                    <meta name="keywords" content="mẹo, thủ thuật, học, dạy học, lớp học, dạy học, giáo dục, học trực tuyến, học online, video, lớp học video, các lớp học video" />
                    <meta property="og:title" content={catName.name+' - LearnSkill'} />
                    <meta property="og:description" content='Tổng hợp các bài hướng dẫn, các mẹo và thủ thuật từ nhiều lĩnh vực trong cuộc sống' />
                    <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
                    <meta property="og:url" content={config.get('domain')} />
                    <meta name="twitter:card" content="summary_large_image" />
                </MetaTags>
                { this.state.loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                ) : (
                <Grid container style={{padding:'0 10px'}}>
                    <Grid item md={1}></Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <Grid container spacing={16}>
                            <Grid item xs={12}>
                                <NavLink to={'/tips'} className={classes.linkTips} activeStyle={{fontWeight: 450,color: "#1e88e5"}} title="mẹo, thủ thuật">Tips</NavLink>
                                {tipSec.map(val=>(
                                    <NavLink key={val.id} to={'/tips/'+val.slug} className={classes.linkTips} activeStyle={{fontWeight: 450,color: "#1e88e5"}} title={val.description}>{val.name}</NavLink>
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h2" style={{fontSize:50}}>{catName.name}</Typography>
                                <Typography variant="body2" style={{fontSize:20}}>bởi LearnSkill</Typography>
                            </Grid>
                            
                            <Grid item xs={12}>
                            {catMenu.map(val=>(
                                <NavLink key={val.id} to={'/tips/'+catName.slug+'/'+val.slug} className={classes.linkTips} activeStyle={{fontWeight: 450,color: "#1e88e5"}} title={val.description}>{val.name}</NavLink>
                            ))}
                            <Divider style={{marginTop:5}} />
                            </Grid>
                            {data.length === 0 ? (
                            <Grid item xs={12} className="box">Chưa có bài mẹo/thủ thuật nào cho lĩnh vực này</Grid>
                            ) : (
                            <React.Fragment>
                            <Grid item xs={12}>
                            {subCatFirst.map(val=>(
                                <Paper key={val.id}>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Card style={{boxShadow: 'none',borderRadius:0}}>
                                            <CardActionArea className={classes.videoThumbnail}>
                                                <Link to={"/tips/"+catName.slug+'/'+this.getSubCatName(val.category)+'/'+val.slug}>
                                                <img style={{height:300}} src={(val.image !== null) ? config.get('apiImg')+val.image : "/no-preview.jpg"} className={classes.bigMedia} alt={val.title}/>
                                                </Link>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} style={{paddingLeft: 20, position:'relative'}}>
                                        <Typography variant="h6" gutterBottom align="left" style={{marginTop:10}}>
                                            <Link to={"/tips/"+catName.slug+'/'+this.getSubCatName(val.category)+'/'+val.slug} className={classes.titleClass}>{val.title}</Link>
                                        </Typography>
                                        <Typography variant="body2">
                                        {val.content !== null &&
                                        EditorState.createWithContent(convertFromRaw(JSON.parse(val.content))).getCurrentContent().getPlainText().length > 300 ? EditorState.createWithContent(convertFromRaw(JSON.parse(val.content))).getCurrentContent().getPlainText().substring(0,300)+"..." : EditorState.createWithContent(convertFromRaw(JSON.parse(val.content))).getCurrentContent().getPlainText()
                                        }
                                        </Typography>
                                        <List>
                                            <ListItem style={{paddingLeft:0,paddingTop:20}}>
                                                <ListItemAvatar component={Link} to={"/user/"+val.user.id+'/'+val.user.name.replace(/\s+/g, '-')} title={val.user.name} className={classes.teacherName}>
                                                {val.user.avatar !== '' ?(
                                                        <UserAvatar size="60" name={val.user.name} src={config.get('apiImg')+val.user.avatar}/>
                                                        ) : (
                                                        <UserAvatar size="60" name={val.user.name} />
                                                        )} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={<Link to={"/user/"+val.user.id+'/'+val.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{val.user.name}</Link>}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography component="span" className={classes.caption} color="textPrimary">
                                                            {val.user.skill_related !== null && JSON.parse(val.user.skill_related).map((rs, i, arr)=>(
                                                                <span key={i}>{rs.name}{arr.length-1 === i ? "": ", "}</span>
                                                            ))}
                                                            </Typography>
                                                            
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                            <Typography className={classes.caption} style={{position:'absolute',bottom:0}} variant="caption">
                                                <Icon className={classes.iconTime}>schedule</Icon> <Timestamp date={new Date(val.created_at)} options={{ includeDay: true, twentyFourHour: true }} />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={16}>
                                {data.length > 0 &&
                                tipLoadmore.map((val,index)=>(
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Card className={classes.card}>
                                            <CardHeader
                                            style={{padding:10}}
                                            avatar={
                                                <Avatar aria-label={val.userName} className={classes.avatar}>
                                                <img src={config.get('apiImg')+val.avatar} alt={val.userName} style={{width:40}} />
                                                </Avatar>
                                            }
                                            title={<Link to={"/user/"+val.userId+'/'+val.userName.replace(/\s+/g, '-')} className={classes.teacherName}>{val.userName}</Link>}
                                            subheader={<Timestamp date={new Date(val.created_at)} options={{ includeDay: true, twentyFourHour: true }} />}
                                            />
                                            <CardMedia
                                            style={{height:200}}
                                            image={(val.image !== null) ? config.get('apiImg')+val.image : "/no-preview.jpg"}
                                            title={val.title}
                                            component={Link} 
                                            to={"/tips/"+catName.slug+'/'+this.getSubCatName(val.category)+'/'+val.slug}
                                            />
                                            <CardContent style={{padding:10}}>
                                                <Typography gutterBottom variant="h5" style={{marginBottom:10}} className={classes.titleClass} component={Link} to={"/tips/"+catName.slug+'/'+this.getSubCatName(val.category)+'/'+val.slug}>
                                                    {val.title}
                                                </Typography>
                                            </CardContent>
                                            
                                        </Card>
                                    </Grid>
                                ))}
                                </Grid>
                                {error &&
                                <div style={{ color: '#900' }}>
                                    {error}
                                </div>
                                }
                                {isLoading &&
                                <div><CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} /></div>
                                }
                            </Grid>
                            </React.Fragment>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                )}
            </div>
        )
    }
}
  export default withStyles(styles)(subCatTips);
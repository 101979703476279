import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { menuActions } from '../../_actions/menu.actions';
import { classActions } from '../../_actions/class.actions';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import MetaTags from 'react-meta-tags';
import { Button, Paper, Grid, Typography } from '@material-ui/core';
 import { withStyles } from '@material-ui/core/styles';
 import TextField from '@material-ui/core/TextField';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import Update from '@material-ui/icons/Update';
import red from '@material-ui/core/colors/red';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../editor.css';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


 const styles = theme => ({
     root:{
        marginTop:20,
     },
     input: {
        display: 'none',
      },
     leftIcon: {
        marginRight: theme.spacing.unit,
      },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginBottom:20,
      },
      dense: {
        marginTop: 19,
      },
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
 });
class TextClassUpdate extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            eclass: [],
            data: [],
            loading: false,
            submitted: false,
            showPassword: false,
            notChange: true,
            imgPreview: '',
            descClass: EditorState.createEmpty(),
            objClass: EditorState.createEmpty(),
            projectClass: EditorState.createEmpty(),
        };
        this.uploadChange = this.uploadChange.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDesClassChange = this.onDesClassChange.bind(this);
        this.onObjClassChange = this.onObjClassChange.bind(this);
        this.onProjectClassChange = this.onProjectClassChange.bind(this);
    }

    onDesClassChange = (descClass) => {
        this.setState({descClass, notChange: false});
        const { data } = this.state;
        let descClassConvert = descClass.getCurrentContent();
        this.setState({
            data: {
                ...data,
                description: JSON.stringify(convertToRaw(descClassConvert), null, 4)
            }
        });
    }
    onObjClassChange = (objClass) => {
        this.setState({objClass, notChange: false});
        const { data } = this.state;
        let objClassConvert = objClass.getCurrentContent();
        this.setState({
            data: {
                ...data,
                objective: JSON.stringify(convertToRaw(objClassConvert), null, 4)
            }
        });
    }

    onProjectClassChange = (projectClass) => {
        this.setState({projectClass, notChange: false});
        const { data } = this.state;
        let projectClassConvert = projectClass.getCurrentContent();
        this.setState({
            data: {
                ...data,
                project: JSON.stringify(convertToRaw(projectClassConvert), null, 4)
            }
        });
    }
        
      
    handleChange(event) {
        const { name, value } = event.target;
        const { data } = this.state;
        this.setState({
            notChange: false,
            data: {
                ...data,
                [name]: value
            }
        });
    }
    
      handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        const { data } = this.state;
            const { dispatch } = this.props;
                dispatch(classActions.editClassText(this.props.match.params.id, data, false))
        
      };
      uploadChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
          this.createImage(files[0]);
          this.setState({
            notChange: false,
          })
      }
      createImage(file) {
        const { data } = this.state;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({
            imgPreview: e.target.result,
            data: {
              ...data,
              class_image: e.target.result
          }
          })
        };
        reader.readAsDataURL(file);
      }
    componentDidMount(){
        this.props.dispatch(menuActions.getAllAdmin());

        this.setState({loading: true})
        const uInfo = JSON.parse(localStorage.getItem('user'))
        axios.get(config.get("apiDomain")+'admin/find-class/'+this.props.match.params.id,
        {
          headers: {
              'Authorization': 'Bearer '+uInfo.access_token,
          }
        }
      )
        .then(res => {
          this.setState({eclass: res.data});
          if(res.data.description !== null){
            let convert = convertFromRaw(JSON.parse(res.data.description));
            let editor = EditorState.createWithContent(convert);
            this.setState({descClass: editor})
        }
        if(res.data.objective !== null){
            let convert = convertFromRaw(JSON.parse(res.data.objective));
            let editor = EditorState.createWithContent(convert);
            this.setState({objClass: editor})
        }
        if(res.data.project !== null){
            let convert = convertFromRaw(JSON.parse(res.data.project));
            let editor = EditorState.createWithContent(convert);
            this.setState({projectClass: editor})
        }
          this.setState({loading: false});
       });
       
      }
    render(){
        const { eclass, loading, submitted } = this.state;
        const { classes, updating, menu } = this.props;
        const cat = menu.items.filter(val=>val.parent !== 0);
        return(
            <div className={classes.root}>
            <MetaTags>
                <title>Cập nhật lớp học - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                {loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                    ) : (
                
                <form name="form" className={classes.form} onSubmit={this.handleSubmit} autoComplete="off">
                    <Button type="submit" size="small" variant="outlined" color="default" style={{textTransform:'initial',marginBottom:30}} component={Link} to="/dashboard/text-class">
                    <KeyboardBackspace className={classes.leftIcon} style={{fontSize:18}}/>
                      Quay lại quản lý lớp học văn bản
                    </Button>
                    <Paper style={{padding:20}}>
                    <Grid container spacing={32}>
                        <Grid item xs={12} style={{marginRight:10}}>
                        <div className={classes.load}>
                            <div className={classes.wrapper}>
                            <Button
                                variant="contained" 
                                color="secondary"
                                size="small"
                                disabled={updating || this.state.notChange}
                                type="submit"
                                style={{textTransform:'initial'}}
                            >
                                <Update className={classes.leftIcon} style={{fontSize:18}} />
                                Cập nhật
                            </Button>
                            {updating && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                label="Tên lớp học"
                                name="title"
                                defaultValue={eclass.title}
                                onChange={this.handleChange}
                                helperText={submitted && !eclass.title ? "Tên lớp học không được để trống": ""}
                                error = {submitted && !eclass.title ? true : false}
                            />
                            <div>
                            <Typography variant="h6">Mô tả lớp học</Typography>
                            <Editor
                            editorState={this.state.descClass}
                            onEditorStateChange={this.onDesClassChange}
                            ref="editor"
                            wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                }}
                                hashtag={{}}
                            />
                            </div>
                            <div>
                            <Typography variant="h6">Mục tiêu lớp học</Typography>
                            <Editor
                            editorState={this.state.objClass}
                            onEditorStateChange={this.onObjClassChange}
                            ref="editor"
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                                toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                }}
                                hashtag={{}}
                            />
                            </div>
                            <div>
                            <Typography variant="h6">Dự án lớp học</Typography>
                            <Editor
                            editorState={this.state.projectClass}
                            onEditorStateChange={this.onProjectClassChange}
                            ref="editor"
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                                toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                }}
                                hashtag={{}}
                            />
                            </div>
                            
                           
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <Typography>Ảnh đại diện lớp học</Typography>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="class-image"
                            multiple
                            type="file"
                            name="class_image"
                            onChange={this.uploadChange}
                        />
                        <label htmlFor="class-image">
                        {this.state.imgPreview === '' ? (
                            <img src={config.get('apiImg')+eclass.class_image} alt="preview" style={{marginBottom:10,width:'100%'}}/>
                        ):(
                            <img src={this.state.imgPreview} alt="preview" style={{marginBottom:10,width:'100%'}}/>
                        )}
                            <br />
                            <Button variant="contained" color="secondary" size="small" component="span" className={classes.button} style={{marginBottom:20}}>
                            Upload
                            <CloudUploadIcon className={classes.rightIcon} />
                            </Button>
                        </label>
                            <FormControl style={{marginBottom:20,display:'inherit'}}>
                                <InputLabel htmlFor="section-native-helper">Chuyên mục</InputLabel>
                                <NativeSelect
                                    fullWidth
                                    defaultValue={eclass.section}
                                    onChange={this.handleChange}
                                    input={<Input name="category" id="section-native-helper" />}
                                >
                                    {!submitted && cat.map(val=>(
                                        <option key={val.id} value={val.id}>{val.name}</option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                        <FormControl style={{marginBottom:20,display:'inherit'}}>
                            <InputLabel htmlFor="level-native-helper">Loại lớp học</InputLabel>
                            <NativeSelect
                                fullWidth
                                defaultValue={eclass.level}
                                onChange={this.handleChange}
                                input={<Input name="level" id="level-native-helper" />}
                            >
                                <option value="0">Miễn phí</option>
                                <option value="1">Mất phí</option>
                            </NativeSelect>
                        </FormControl>
                        <FormControl style={{marginBottom:20,display:'inherit'}}>
                            <InputLabel htmlFor="filter-native-helper">Lọc hiển thị</InputLabel>
                            <NativeSelect
                                fullWidth
                                defaultValue={eclass.filter}
                                onChange={this.handleChange}
                                input={<Input name="filter" id="filter-native-helper" />}
                            >
                                <option value="0">Lớp học thường</option>
                                <option value="1">Lớp học tiêu biểu</option>
                                <option value="2">Lớp học xu hướng</option>
                                <option value="3">Lớp học phổ biến</option>
                            </NativeSelect>
                        </FormControl>
                        <FormControl style={{marginBottom:20,display:'inherit'}}>
                            <InputLabel htmlFor="published-native-helper">Xuất bản</InputLabel>
                            <NativeSelect
                                fullWidth
                                defaultValue={eclass.published}
                                onChange={this.handleChange}
                                input={<Input name="published" id="published-native-helper" />}
                            >
                                <option value="0">Chưa xuất bản</option>
                                <option value="1">Đã xuất bản</option>
                            </NativeSelect>
                        </FormControl>
                            
                        </Grid>
                    </Grid>
                    </Paper>
                </form>
            
            )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { menu } = state;
    const { updating } = state.getClass;
    return {
        menu, updating
    };
  }
  
  export default connect(mapStateToProps)(withStyles(styles)(TextClassUpdate));
import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import config from "react-global-configuration";
import { connect } from 'react-redux';
import {Typography, Paper, Grid} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { userActions } from '../_actions/user.actions';
import {Helmet} from 'react-helmet';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const styles = theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        marginTop:100,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      paper: {
        marginTop: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position:'relative',
      },
      avatar: {
        margin: theme.spacing.unit,
        backgroundColor: '#1A2040',
      },
      form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing.unit,
      },
      submit: {
        marginTop: theme.spacing.unit * 3,
      },
      link: {
        color: '#1e88e5',
        textDecoration: 'none',
        marginTop: 20,
        '&:hover':{
          color: '#fd5555',
        }
      },
      link2: {
        color: '#fff',
        textDecoration: 'none',
        marginTop: 20,
        '&:hover':{
          color: 'yellow',
        }
      },
      textField: {
        width:'100%',
        marginBottom:20,
      },
      progress:{
          borderRadius: '3px 3px 0 0',
      }
  });

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            name:'',
            email: '',
            password:'',
            disabled: true,
            submitted: false,
            showPassword: false,
            loading: false,
            msgErr: '',
        };
        this.renderStep = this.renderStep.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submit = this.submit.bind(this);
        // this.prevStep = this.prevStep.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.validatorListener = this.validatorListener.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    submit() {
        this.refs.form.submit();
    }

    handleSubmit(e) {
        const { name, email, password } = this.state;
        const { dispatch } = this.props;
        if (email && password) {
            dispatch(userActions.register(name, email, password));
        }
    }

    // prevStep() {
    //     let step = this.state.step;
    //     if (step > 1) {
    //         step--;
    //     }
    //     this.setState({ step }, () => this.refs.form.walk(this.refs.form.childs));
    // }
   
    nextStep() {
        if (this.refs.form.walk(this.refs.form.childs)) {
            if (this.state.name && ! this.state.email) {
                this.setState({loading: true, submitted:true});
                let step = this.state.step;
                if (step < 3) {
                    step++;
                }
                this.setState({ step }, () => this.refs.form.walk(this.refs.form.childs));
                this.setState({loading: false, submitted:false});
            }
            else if (this.state.name && this.state.email) {
                this.setState({loading: true, submitted:true});
                axios.get(config.get("apiDomain")+'auth/checkEmail/'+ this.state.email,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(res =>{
                    let responseJson = res.data;
                    if(responseJson.id){  
                        this.setState({msgErr: 'Email bạn nhập đã được đăng ký'})
                    }else{
                        let step = this.state.step;
                        if (step < 3) {
                            step++;
                        }
                        this.setState({ step }, () => this.refs.form.walk(this.refs.form.childs));
                        this.setState({msgErr: ''})
                    }
                    this.setState({loading: false, submitted:false});
                });
            }
            
        }
    }

    validatorListener(result) {
        this.setState({ disabled: !result });
    }

    renderStep() {
        const { step } = this.state;
        let content = null;
        switch (step) {
            case 1:
                content = (
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.nextStep}
                        instantValidate
                        autoComplete="off"
                    >
                        <FormControl style={{width:'100%'}}>
                            <TextValidator
                                key={1}
                                autoFocus
                                fullWidth
                                name="name"
                                label="Họ và tên"
                                variant="outlined"
                                validators={['required', 'minStringLength:3']}
                                errorMessages={['Họ và tên không được để trống', 'Họ và tên phải có tối thiểu 3 ký tự']}
                                value={this.state.name}
                                onChange={this.onChange}
                                validatorListener={this.validatorListener}
                            />
                        </FormControl>
                    </ValidatorForm>);
                break;
            case 2:
                content = (
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.nextStep}
                        instantValidate
                        autoComplete="off"
                    >
                        <FormControl style={{width:'100%'}}>
                            <TextValidator
                                key={2}
                                autoFocus
                                fullWidth
                                name="email"
                                label="Địa chỉ email"
                                variant="outlined"
                                validators={['required', 'isEmail']}
                                errorMessages={['Email không được để trống', 'Email không hợp lệ']}
                                value={this.state.email}
                                onChange={this.onChange}
                                validatorListener={this.validatorListener}
                            />
                        </FormControl>
                    </ValidatorForm>);
                break;
            case 3:
                content = (
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        instantValidate
                        autoComplete="off"
                    >
                        <FormControl style={{width:'100%'}}>
                            <TextValidator
                                key={3}
                                fullWidth
                                autoFocus
                                variant="outlined"
                                type={this.state.showPassword ? 'text' : 'password'}
                                label="Mật khẩu"
                                value={this.state.password}
                                name="password"
                                onChange={this.onChange}
                                validatorListener={this.validatorListener}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="Ẩn/Hiện mật khẩu"
                                        onClick={this.handleClickShowPassword}
                                        >
                                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                                validators={['minStringLength:6']}
                                errorMessages={['Mật khẩu phải có tối thiểu 6 ký tự']}
                            />
                        </FormControl>
                    </ValidatorForm>);
                break;

                default:
                return ;
        }
        return content;
    }

    render() {
        const { step, submitted } = this.state;
        const { registering, classes, alert} = this.props;
        return (
            <div>
                <MetaTags>
                    <title>Đăng ký hệ thống - LearnSkill</title>
                    <meta name="description" content="Đăng ký hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                    <meta property="og:title" content="Đăng ký hệ thống - LearnSkill" />
                    <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
                    <meta property="og:url" content={config.get('domain')} />
                    <meta name="twitter:card" content="summary_large_image" />
                </MetaTags>
                <Helmet>
                    <style>{'body { background-color: #1e88e5; }'}</style>
                </Helmet>
                <div className={classes.layout}>
                    <Paper className={classes.paper} style={{display:'block'}}>
                        {(registering || this.state.loading) &&
                            <LinearProgress color="secondary" className={classes.progress}/>
                        }
                        <div style={{padding:'50px 20px 30px 20px'}}>
                            <div align="center" style={{marginBottom:40}}>
                                <Typography variant="h5" style={{marginBottom:10}} component={Link} to="/">
                                    <img src="/images/logofull.png" alt="Học kỹ năng trực tuyến"/>
                                </Typography>
                                <Typography variant="h5" style={{marginBottom:10}}>Đăng ký tài khoản LearnSkill</Typography>
                            </div>
                            <Grid container>
                                <Grid item xs={12}>
                                {this.renderStep()}
                                </Grid>   
                            
                                <Grid item xs={12} style={{marginTop:30}} align="right">
                                    {alert.message &&
                                        <div style={{color:'red',textAlign:'center',padding:5}}>{this.props.alert.message}</div>
                                    }
                                    {this.state.msgErr !== '' &&
                                        <div style={{color:'red',textAlign:'center',padding:5}}>{this.state.msgErr}</div>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Link to="/login" title="Đăng nhập tài khoản" className={classes.link} style={{marginLeft:15}}>Đăng nhập</Link>
                                </Grid>
                                <Grid item xs={12} sm={6} align="right">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={step < 3 ? this.nextStep : this.submit}
                                        disabled={submitted}
                                    >
                                        {
                                            // (submitted && 'Your form is submitted!') ||
                                            (step < 3 ? 'Tiếp tục' : 'Đăng ký')
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        {/* <Typography variant="caption" style={{textAlign:'center',marginTop:10}}>
                            HOẶC
                        </Typography>
                        <div style={{margin:'0 20px 20px 20px'}}>
                            <Button variant="outlined" fullWidth color="primary" className={classes.button}>
                                Đăng nhập với Facebook
                            </Button>
                        </div> */}
                    </Paper>
                    <Typography variant="body1" style={{textAlign:'center',marginTop:30}}>
                        <Link to="/login" title="Không thể đăng nhập" className={classes.link2}>Không thể đăng nhập?</Link>
                        
                    </Typography>
                    <Typography variant="body2" style={{textAlign:'center',marginTop:30}}>
                        <Link to="/" title="Điều khoản" className={classes.link2}>Điều khoản</Link>
                        <Link to="/" title="Chính sách" className={classes.link2} style={{marginLeft:20}}>Chính sách</Link>
                    </Typography>
                </div>
             </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering } = state.registration;
    const { alert } = state;
    return {
        registering, alert
    };
}
Register.propTypes = {
    classes: PropTypes.object.isRequired,
  };


export default connect(mapStateToProps)(withStyles(styles)(Register));
import { userConstants } from '../_constants/user.constant';

const initialState = {};

export function skillRelated(state = initialState, action) {
  switch (action.type) {
    case userConstants.SKILLRELATED_REQUEST:
      return {
        loading: true,
        reSkill: action.users
      };
    case userConstants.SKILLRELATED_SUCCESS:
      return {
        reSkill: action.users
      };
    case userConstants.SKILLRELATED_FAILURE:
      return {};
    
    default:
      return state
  }
}
import React from 'react';
import Quiz from 'react-quiz-component';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import MetaTags from 'react-meta-tags';
import Chip from '@material-ui/core/Chip';
import axios from 'axios';
import config from "react-global-configuration";

const styles = theme => ({
  root: {
      width:'100%',
      textAlign: 'center',
      marginTop:50
  },
})
class QuizClass extends React.Component{
  state = {
    introData: [],
    loading:true
  };
  componentDidMount(){
    axios.get(config.get("apiDomain")+'class/detail/'+this.props.match.params.slug)
    .then(res => {
        this.setState({
            introData: res.data,
            loading:false
        });
        
    })
}
    render(){
      const { introData } =this.state; 
      const { classes } = this.props;
      const onCompleteAction = (obj) => {
        
        const rs = (obj.numberOfCorrectAnswers/obj.numberOfQuestions)*100;

        return(
          <Grid container>
            <Grid item xs={12} align="center">
              <Typography variant="h2">Kết quả</Typography>
              <Typography variant="body2">Tổng câu hỏi: <span style={{fontWeight: 450,fontSize:20}}>{obj.numberOfQuestions}</span></Typography>
              <Typography variant="body2">Số câu trả lời đúng: <span style={{fontWeight: 450,fontSize:20}}>{obj.numberOfCorrectAnswers}</span></Typography>
              <Typography variant="body2">Số câu trả lời sai: <span style={{fontWeight: 450,fontSize:20}}>{obj.numberOfIncorrectAnswers}</span></Typography>
              <Typography variant="body2">Tỷ lệ đúng: <span style={{fontWeight: 450,fontSize:24}}>{rs} %</span></Typography>
              {rs > 75 ? (
                 <Chip
                 label="Làm chủ kỹ năng"
                 color="primary"
               />
              ) : (
                <Chip
                label="Không làm chủ kỹ năng"
                color="secondary"
              />
              )}
            </Grid>
            {/* <Grid item xs={12}>
                <Typography variant="h4">Các câu hỏi bạn đã trả lời</Typography>
                {obj.questions.map((val, index)=>(
                  <div key={index}>
                    <Typography variant="button">Câu hỏi {index+1}:</Typography> 
                    <Typography variant="body2">{val.question}</Typography> 
                    <Typography variant="body2">{val.question}</Typography>
                  </div>
                ) 
                )}
            </Grid> */}
          </Grid>
        )
      }
        return(
          <div>
            <MetaTags>
                <title>{'Kiểm tra đánh giá khóa học '+introData.title}</title>
                <meta name="description" content={'Thực hiện làm bài kiểm tra đánh giá khóa học '+introData.title+' trên hệ thống học trực tuyến learnskill'} />
                <meta property="og:title" content={'Kiểm tra đánh giá khóa học '+introData.title} />
                <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
                <meta property="og:url" content={config.get('domain')} />
                <meta name="twitter:card" content="summary_large_image" />
            </MetaTags>
            {this.state.loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'absolute',top:'48%',left:'48%'}} />
                ) : (
                  introData.quiz !== '' ? (
                    <Grid container spacing={32}>
                      <Grid item xs={12} sm={12} md={9}>
                      <Quiz quiz={JSON.parse(introData.quiz)} shuffle={true} showDefaultResult={false} onComplete={onCompleteAction}/>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography variant="h5">Không có bài kiểm tra cho lớp học này</Typography>
                  )
                  
            
                )}
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(QuizClass);
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { classActions } from '../../_actions/class.actions';
import { authHeader } from '../../_helpers/auth-header';
import axios from 'axios';
import config from "react-global-configuration";
import MetaTags from 'react-meta-tags';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { Grid } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../editor.css';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
    root: {
      width: '90%',
      position:'relative'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginBottom:10,
      },
      dense: {
        marginTop: 19,
      },
    button: {
      marginTop: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    actionsContainer: {
      marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
      padding: theme.spacing.unit * 3,
    },
    leftIcon: {
      marginRight: theme.spacing.unit,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },
    load: {
      display: 'flex',
      alignItems: 'center',
      flexDirection:'row-reverse',
    },
    wrapper: {
      margin: 5,
      position: 'relative',
    },
    buttonProgress: {
      color: red[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  });
  
  
  class VideoClassAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            data: [],
            class_image:'',
            menuData: [],
            cat: [],
            loading: false,
            submitted: false,
            hasSecError: false,
            hasCatError: false,
            dropCatShow: true,
            imgPreview: '',
            descClass: EditorState.createEmpty(),
            objClass: EditorState.createEmpty(),
            projectClass: EditorState.createEmpty(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDesClassChange = this.onDesClassChange.bind(this);
        this.onObjClassChange = this.onObjClassChange.bind(this);
        this.onProjectClassChange = this.onProjectClassChange.bind(this);
        this.uploadChange = this.uploadChange.bind(this)
    }
    handleChange(event) {
      const { data } = this.state;
      const { name, value } = event.target;
      if(name === 'section'){
        let parent = event.target.value;
        const ca = this.state.menuData.filter(val => val.parent === parseInt(parent));
        this.setState({cat: ca, dropCatShow:false,hasSecError: false})
      
      this.setState({
        noChange: false,
        hasError: false,
          data: {
              ...data,
              [name]: value,
              category: '',
              isVideo: 1
          }
      });
    }else{
      this.setState({
        noChange: false,
        hasError: false,
          data: {
              ...data,
              [name]: value,
              isVideo: 1
          }
      });
    }
    // console.log(data)
    
  }
  uploadChange(e) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
      return;
      this.createImage(files[0]);
  }
  createImage(file) {
    const { data } = this.state;
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        imgPreview: e.target.result,
        data: {
          ...data,
          class_image: e.target.result
      }
      })
    };
    reader.readAsDataURL(file);
  }
    onDesClassChange = (descClass) => {
        this.setState({descClass});
        const { data } = this.state;
        let descClassConvert = descClass.getCurrentContent();
        this.setState({
            data: {
                ...data,
                description: JSON.stringify(convertToRaw(descClassConvert), null, 4)
            }
        });
    }
    onObjClassChange = (objClass) => {
        this.setState({objClass});
        const { data } = this.state;
        let objClassConvert = objClass.getCurrentContent();
        this.setState({
            data: {
                ...data,
                objective: JSON.stringify(convertToRaw(objClassConvert), null, 4)
            }
        });
    }

    onProjectClassChange = (projectClass) => {
        this.setState({projectClass});
        const { data } = this.state;
        let projectClassConvert = projectClass.getCurrentContent();
        this.setState({
            data: {
                ...data,
                project: JSON.stringify(convertToRaw(projectClassConvert), null, 4)
            }
        });
    }
  
    handleNext = () => {
      const { data } = this.state;
     
      this.setState({submitted:true})
      if (!data.section || data.section === "") {
        this.setState({ hasSecError: true, hasCatError: true });
      }else if (!data.category || data.category === "") {
        this.setState({ hasCatError: true, hasSecError: false });
      }else if(!data.title || data.title === ""){
        this.setState({ hasCatError: false, hasSecError: false });
      }else{
        this.setState(state => ({
          activeStep: state.activeStep + 1,
        }));
      }
    };
  
    handleBack = () => {
      this.setState({submitted:false})
      this.setState(state => ({
        activeStep: state.activeStep - 1,
      }));
    };
  
    handleReset = () => {
      this.setState({
        activeStep: 0,
      });
    };

    handleSubmit(e){
      e.preventDefault();
      this.setState({ submitted: true });
      const { data } = this.state;
      const { dispatch } = this.props;
      if (data.title && data.section && data.category) {
          dispatch(classActions.adminAddClass(data));
      }
    }
    componentDidMount(){
      axios.get(config.get("apiDomain")+"admin/all-menu",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
      .then(res => {
        this.setState({menuData: res.data});
     });
    }
  
    render() {
      const { classes } = this.props;
      const { activeStep, data, submitted, cat, menuData } = this.state;
      const sec = menuData.filter(val => val.parent === 0);
      return (
        <div className={classes.root}>
        <MetaTags>
                <title>Thêm lớp học - LearnSkill</title>
                <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
                <meta property="og:title" content="MyApp" />
                <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                {this.props.adding &&
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'absolute',top:'48%',left:'48%'}} />
                }
        <form
          ref="form"
          onSubmit={this.handleSubmit}
        >
          <Stepper activeStep={activeStep} orientation="vertical">
            {/* {steps.map((label, index) => ( */}
              <Step key={0}>
                <StepLabel>Thông tin chung lớp học</StepLabel>
                <StepContent>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            className={classes.textField}
                            label="Tên lớp học"
                            name="title"
                            defaultValue={data.title || ''}
                            onChange={this.handleChange}
                            helperText={(submitted && data.title === undefined) ? "Tên lớp học không được để trống": ""}
                            error = {(submitted && data.title === undefined) ? true : false}
                       />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl style={{marginBottom:10,display:'inherit'}} error={this.state.hasSecError}>
                            <InputLabel htmlFor="section-native-helper">Lĩnh vực cha*</InputLabel>
                            <NativeSelect
                                fullWidth
                                defaultValue=""
                                onChange={this.handleChange}
                                input={<Input name="section" id="section-native-helper" />}
                            >
                                <option value="" />
                                {sec.map(val=>(
                                  <option value={val.id} key={val.id}>{val.name}</option>
                                ))}
                                
                                
                            </NativeSelect>
                            {this.state.hasSecError && <FormHelperText>Bạn phải chọn 1 lĩnh vực cha</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl style={{marginBottom:10,display:'inherit'}} error={this.state.hasCatError}>
                            <InputLabel htmlFor="category-native-helper">Lĩnh vực con*</InputLabel>
                            <NativeSelect
                                fullWidth
                                defaultValue=""
                                disabled = {this.state.dropCatShow}
                                onChange={this.handleChange}
                                input={<Input name="category" id="category-native-helper" />}
                            >
                                <option value="" />
                                {cat.map(val=>(
                                  <option value={val.id} key={val.id}>{val.name}</option>
                                ))}
                                
                            </NativeSelect>
                            {this.state.hasCatError && <FormHelperText>Bạn phải chọn 1 lĩnh vực con</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl style={{marginBottom:10,display:'inherit'}}>
                            <InputLabel htmlFor="classType-native-helper">Loại lớp học</InputLabel>
                            <NativeSelect
                                fullWidth
                                defaultValue="0"
                                onChange={this.handleChange}
                                input={<Input name="class_type" id="classType-native-helper" />}
                            >
                                <option value="0">Miễn phí</option>
                                <option value="1">Mất phí</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl style={{marginBottom:10,display:'inherit'}}>
                            <InputLabel htmlFor="classType-native-helper">Mức độ</InputLabel>
                            <NativeSelect
                                fullWidth
                                defaultValue=""
                                onChange={this.handleChange}
                                input={<Input name="class_type" id="classType-native-helper" />}
                            >
                                <option value="" />
                                <option value="0">Dễ</option>
                                <option value="1">Trung bình</option>
                                <option value="1">Khó</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Ảnh đại diện lớp học</Typography>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="class-image"
                        multiple
                        type="file"
                        name="class_image"
                        onChange={this.uploadChange}
                      />
                      <label htmlFor="class-image">
                      {this.state.imgPreview !== '' &&
                        <img src={this.state.imgPreview} alt="preview" style={{marginBottom:10,width:100}}/>
                        }
                        <br />
                        <Button variant="contained" color="secondary" size="small" component="span" className={classes.button} style={{marginBottom:20}}>
                          Upload
                          <CloudUploadIcon className={classes.rightIcon} />
                        </Button>
                      </label>
                      
                    </Grid>
                  </Grid>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        Quay lại
                      </Button>
                      <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                    > Tiếp <Icon className={classes.rightIcon}>send</Icon>
                  </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>

              <Step key={1}>
                <StepLabel>Mô tả lớp học</StepLabel>
                <StepContent>
                    <Editor
                        editorState={this.state.descClass}
                        onEditorStateChange={this.onDesClassChange}
                        ref="editor"
                        wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            toolbar={{
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                            }}
                        />
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        Quay lại
                      </Button>
                      <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                    > Tiếp <Icon className={classes.rightIcon}>send</Icon>
                  </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>

              <Step key={2}>
                <StepLabel>Mục tiêu lớp học</StepLabel>
                <StepContent>
                    <Editor
                        editorState={this.state.objClass}
                        onEditorStateChange={this.onObjClassChange}
                        ref="editor"
                        wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            toolbar={{
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                            }}
                        />
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        Quay lại
                      </Button>
                       <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                    > Tiếp <Icon className={classes.rightIcon}>send</Icon>
                  </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>

              <Step key={3}>
                <StepLabel>Dự án lớp học</StepLabel>
                <StepContent>
                    <Editor
                        editorState={this.state.projectClass}
                        onEditorStateChange={this.onProjectClassChange}
                        ref="editor"
                        wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            toolbar={{
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                            }}
                        />
                  <div className={classes.actionsContainer}>
                    <Grid container>
                    <Grid item xs={6}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        Quay lại
                      </Button>
                      </Grid>
                      <Grid item xs={6}>
                      <div className={classes.load}>
                        <div className={classes.wrapper}>
                          
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={this.props.adding}
                              className={classes.button}
                            >Hoàn thành 
                          </Button>
                          {this.props.adding && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </div>
                      </Grid>
                    </Grid>
                  </div>
                </StepContent>
              </Step>

            {/* ))} */}
          </Stepper>
          </form>
        </div>
      );
    }
  }
  
  VideoClassAdd.propTypes = {
    classes: PropTypes.object,
  };
  function mapStateToProps(state) {
    const { adding} = state.getClass;
    return {
        adding
    };
  }
  
  export default connect(mapStateToProps)(withStyles(styles)(VideoClassAdd));
export default [
	{
		title: 'Học trực tuyến miễn phí',
		description:
			'Hàng nghìn khóa học hoàn thiện kỹ năng cho bạn đã sẵn sàng trong hệ thống.',
		button: 'Học ngay hôm nay',
		url: '/lop-hoc',
		image: 'images/slide_1.jpg',
	},
	{
		title: 'Dạy học trên LearnSkill',
		description:
			'Chia sẻ kiến thức chuyên môn của bạn, xây dựng cộng đồng giáo dục.',
		button: 'Trở thành giáo viên',
		url: '/day-hoc',
		image: 'images/slide_2.jpg',
	},
	{
		title: 'Cộng đồng dạy và học trực tuyến',
		description:
			'Chia sẻ các kiến thức, mẹo, thủ thuật trên cộng đồng.',
		button: 'Chia sẻ kiến thức',
		url: '/tips',
		image: 'images/slide_3.jpg',
	}
];
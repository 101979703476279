import React from "react";

class Dashboard extends React.Component{
  render(){
    return(
      <React.Fragment>

        <h1>Dashboard</h1>

      </React.Fragment>
    )
  }
}

export default Dashboard;
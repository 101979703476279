import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button,  ListItem, 
  ListItemText,  List, CircularProgress     
} from '@material-ui/core';
import MetaTags from 'react-meta-tags';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import ArrowForward from '@material-ui/icons/ArrowForward';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

const styles = theme => ({
    root: {
    },
    listContent:{
      paddingTop:3,
      paddingBottom:3,
    },
      linkLesson: {
        color: '#777',
        textDecoration: 'none',
        fontSize:11,
        paddingTop:3,
      paddingBottom:3,
        '&:hover': {
          textDecoration: 'underline',
        },
    },
  });

class ClassTextInfo extends React.Component{
    state = {
        // value: 0,
        loading: true,
        slug: '',
        classContent: [],
        mobileOpen: false,
        desc: EditorState.createEmpty(),
      };
      handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
      };
      componentDidMount(){
        axios.get(config.get("apiDomain")+'class/class-info/'+this.props.match.params.lessonSlug)
        .then(res => {
          this.setState({slug:res.data.slug})
            if(res.data.description !== ''){
              let convert = convertFromRaw(JSON.parse(res.data.description));
              let editor = EditorState.createWithContent(convert);
              this.setState({desc: editor})
            }
          });
        axios.get(config.get("apiDomain")+'class/content/'+this.props.match.params.lessonSlug)
        .then(res => {
          this.setState({
            classContent: res.data,
            loading: false
          })
        });
    }
    
      render() {
        const { classes  } = this.props;
        const { loading, slug, classContent, desc } = this.state;
       return (
          <div className={classes.root}>
            <MetaTags>
            <title>{'Giới thiệu lớp học văn bản '+this.props.match.params.lessonSlug+' - LearnSkill'}</title>
              <meta name="description" content={'Giới thiệu lớp học '+this.props.match.params.lessonSlug+' trên hệ thống học trực tuyến learnskill'} />
              <meta name="keywords" content="học, dạy học, lớp học, dạy học, giáo dục, học trực tuyến, học online, video, lớp học video, các lớp học video" />
              <meta property="og:title" content={'Giới thiệu lớp học văn bản '+this.props.match.params.lessonSlug+' - LearnSkill'} />
              <meta property="og:description" content={'Giới thiệu lớp học '+this.props.match.params.lessonSlug+' trên hệ thống học trực tuyến learnskill'} />
              <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
              <meta property="og:url" content={config.get('domain')} />
              <meta name="twitter:card" content="summary_large_image" />
            </MetaTags>
            {loading ? (
                <CircularProgress className={classes.progress} color="secondary" style={{position:'absolute',top:'48%',left:'48%'}} />
            ) : (
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12} md={9}>
                <Typography variant="h6">Giới thiệu</Typography>
                {/* {
                  description.map((val, index)=>(
                    <div key={index} style={{marginBottom:15}}>{val.getText()}</div>
                  ))
                } */}
                <Editor editorState={desc} readOnly={true} toolbarHidden />
                <div align="right" className="margin-top-30">
                  <Button component={Link} to={'/lesson/'+slug+'/muc-tieu'} variant="outlined" color="primary" className={classes.button}>
                    Mục tiêu 
                    <ArrowForward />
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
              <List>
                 <ListItem>
                    <ListItemText primary={<Typography variant="h6">Nội dung</Typography>} />
                  </ListItem>
                  <ListItem component={NavLink} activeStyle={{fontWeight: 450,color: "#1e88e5"}} to={'/lesson/'+slug+'/gioi-thieu'} selected style={{backgroundColor:'initial'}} className={classes.listContent}>
                    <ListItemText primary={<Typography variant="subtitle2">Giới thiệu</Typography>}/>
                  </ListItem>
                  <ListItem component={NavLink} activeStyle={{fontWeight: 450,color: "#1e88e5"}} to={'/lesson/'+slug+'/muc-tieu'} className={classes.listContent}>
                    <ListItemText primary={<Typography variant="body2">Mục tiêu</Typography>}/>
                  </ListItem>
                  {classContent.map(value => (
                    <ListItem key={value.id} component={NavLink} to={'/lesson/'+slug+'/'+value.url}  className={classes.linkLesson}>
                      <ListItemText primary={<Typography variant="body2">{value.name}</Typography>}/>
                    </ListItem>
                    ))}
              </List>
              </Grid>
            </Grid>
            )}
          </div>
        );
      }
}
export default withStyles(styles, { withTheme: true })(ClassTextInfo);
export const tipsConstants = {
    ADMINGETALL_REQUEST: 'ADMIN_GETALLTIPS_REQUEST',
    ADMINGETALL_SUCCESS: 'ADMIN_GETALLTIPS_SUCCESS',
    ADMINGETALL_FAILURE: 'ADMIN_GETALLTIPS_FAILURE', 

    ADMINADDTIPS_REQUEST: 'TIPS_ADMINADD_REQUEST',
    ADMINADDTIPS_SUCCESS: 'TIPS_ADMINADD_SUCCESS',
    ADMINADDTIPS_FAILURE: 'TIPS_ADMINADD_FAILURE',

    DELTIPS_REQUEST: 'ADMIN_DELTIPS_REQUEST',
    DELTIPS_SUCCESS: 'ADMIN_DELTIPS_SUCCESS',
    DELTIPS_FAILURE: 'ADMIN_DELTIPS_FAILURE', 

    ADMINEDITTIPS_REQUEST: 'TIPS_ADMINEDIT_REQUEST',
    ADMINEDITTIPS_SUCCESS: 'TIPS_ADMINEDIT_SUCCESS',
    ADMINEDITTIPS_FAILURE: 'TIPS_ADMINEDIT_FAILURE',

    TIPDELETED_REQUEST: 'ADMIN_DELETEDTIPS_REQUEST',
    TIPDELETED_SUCCESS: 'ADMIN_DELETEDTIPS_SUCCESS',
    TIPDELETED_FAILURE: 'ADMIN_DELETEDTIPS_FAILURE', 

    RESTORE_REQUEST: 'TIPS_RESTORE_REQUEST',
    RESTORE_SUCCESS: 'TIPS_RESTORE_SUCCESS',
    RESTORE_FAILURE: 'TIPS_RESTORE_FAILURE',

    FORCEDEL_REQUEST: 'TIPS_FORCEDEL_REQUEST',
    FORCEDEL_SUCCESS: 'TIPS_FORCEDEL_SUCCESS',
    FORCEDEL_FAILURE: 'TIPS_FORCEDEL_FAILURE',

}
import { searchConstants } from '../_constants/search.constant';
var items = [];
export function search(state = {items}, action) {
  switch (action.type) {
    case searchConstants.SEARCH_CLASS_REQUEST:
    return {
      loading: true,
      items: items
    };
  case searchConstants.SEARCH_CLASS_SUCCESS:
    return {
      items: action.classData
    };
  case searchConstants.SEARCH_CLASS_FAILURE:
    return { 
      error: action.error
    };

    default:
    return state
}
}
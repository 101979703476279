export const menuConstants = {
    GETALL_REQUEST: 'MENU_GETALL_REQUEST',
    GETALL_SUCCESS: 'MENU_GETALL_SUCCESS',
    GETALL_FAILURE: 'MENU_GETALL_FAILURE',

    GETALLADMIN_REQUEST: 'MENU_GETALLADMIN_REQUEST',
    GETALLADMIN_SUCCESS: 'MENU_GETALLADMIN_SUCCESS',
    GETALLADMIN_FAILURE: 'MENU_GETALLADMIN_FAILURE',

    ADMINADDMENU_REQUEST: 'MENU_ADMINADD_REQUEST',
    ADMINADDMENU_SUCCESS: 'MENU_ADMINADD_SUCCESS',
    ADMINADDMENU_FAILURE: 'MENU_ADMINADD_FAILURE',

    ADMINEDITMENU_REQUEST: 'MENU_ADMINEDIT_REQUEST',
    ADMINEDITMENU_SUCCESS: 'MENU_ADMINEDIT_SUCCESS',
    ADMINEDITMENU_FAILURE: 'MENU_ADMINEDIT_FAILURE',

    DELETE_REQUEST: 'MENU_DELETE_REQUEST',
    DELETE_SUCCESS: 'MENU_DELETE_SUCCESS',
    DELETE_FAILURE: 'MENU_DELETE_FAILURE' ,
    
    GETALLDELETED_REQUEST: 'ADMIN_GETALLDELETED_REQUEST',
    GETALLDELETED_SUCCESS: 'ADMIN_GETALLDELETED_SUCCESS',
    GETALLDELETED_FAILURE: 'ADMIN_GETALLDELETED_FAILURE',

    RESTORE_REQUEST: 'MENU_RESTORE_REQUEST',
    RESTORE_SUCCESS: 'MENU_RESTORE_SUCCESS',
    RESTORE_FAILURE: 'MENU_RESTORE_FAILURE',

    FORCEDEL_REQUEST: 'MENU_FORCEDEL_REQUEST',
    FORCEDEL_SUCCESS: 'MENU_FORCEDEL_SUCCESS',
    FORCEDEL_FAILURE: 'MENU_FORCEDEL_FAILURE',
};
import React from 'react';

class Teach extends React.Component{
    render(){
        return (
            <h1 style={{marginTop:100}}>Chức năng đang được xây dựng</h1>
        )
    }
}

export default Teach;
import { classConstants } from '../_constants/class.constants';
import axios from 'axios';
import config from "react-global-configuration";
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux'
import { authHeader } from '../_helpers/auth-header';
import { alertActions } from './alert.actions';
import { history } from '../_helpers/history';

export const classActions = {
    getAll, delete:_delete, editClass, editClassText,  getAllVideo, adminAddClass, getAllText, find, adminAddLessonText,
    allClassText, delLesson, editLessonText, classTextDeleted, restore, forceDel, classVideoDeleted, adminAddLessonVideo,
    allClassVideo, editVideo, delVideo, allClassVideoAdmin, allClassTextAdmin
};

function getAll() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"class/all/home")
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu lớp học'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request() { return { type: classConstants.GETALL_REQUEST } }
    function success(getClass) { return { type: classConstants.GETALL_SUCCESS, getClass } }
    function failure(error) { return { type: classConstants.GETALL_FAILURE, error } }
}
function classTextDeleted() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"admin/text-class/deleted",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu lớp học'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request() { return { type: classConstants.GETTEXTDELETED_REQUEST } }
    function success(textDeleted) { return { type: classConstants.GETTEXTDELETED_SUCCESS, textDeleted } }
    function failure(error) { return { type: classConstants.GETTEXTDELETED_FAILURE, error } }
}
function classVideoDeleted() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"admin/video-class/deleted",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu lớp học'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request() { return { type: classConstants.GETVIDEODELETED_REQUEST } }
    function success(videoDeleted) { return { type: classConstants.GETVIDEODELETED_SUCCESS, videoDeleted } }
    function failure(error) { return { type: classConstants.GETVIDEODELETED_FAILURE, error } }
}
function allClassText(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+"class/content/"+id)
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu bài học học'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request() { return { type: classConstants.GETALLLESSONBYCLASS_REQUEST } }
    function success(getLesson) { return { type: classConstants.GETALLLESSONBYCLASS_SUCCESS, getLesson } }
    function failure(error) { return { type: classConstants.GETALLLESSONBYCLASS_FAILURE, error } }
}

function allClassTextAdmin(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+"admin/content-class/"+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu bài học học'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request() { return { type: classConstants.GETALLLESSONADMINBYCLASS_REQUEST } }
    function success(getLesson) { return { type: classConstants.GETALLLESSONADMINBYCLASS_SUCCESS, getLesson } }
    function failure(error) { return { type: classConstants.GETALLLESSONADMINBYCLASS_FAILURE, error } }
}

function allClassVideo(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+"class/content-video/"+id)
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu bài học học'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request() { return { type: classConstants.GETALLLVIDEOBYCLASS_REQUEST } }
    function success(getVideo) { return { type: classConstants.GETALLLVIDEOBYCLASS_SUCCESS, getVideo } }
    function failure(error) { return { type: classConstants.GETALLLVIDEOBYCLASS_FAILURE, error } }
}
function allClassVideoAdmin(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+"admin/admin-all-video/"+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu video'))
                dispatch(snackbar.show({
                    message: 'Lỗi kết nối server',
                  }))
            })
    };

    function request() { return { type: classConstants.GETALLLVIDEOADMINBYCLASS_REQUEST } }
    function success(getVideo) { return { type: classConstants.GETALLLVIDEOADMINBYCLASS_SUCCESS, getVideo } }
    function failure(error) { return { type: classConstants.GETALLLVIDEOADMINBYCLASS_FAILURE, error } }
}



function find(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+"class/find/"+id)
        .then(res => {
            dispatch(success(res.data))
        })
        .catch(error => {
            dispatch(failure('lỗi nạp dữ liệu lớp học'))
            dispatch(snackbar.show({
                message: 'Lỗi kết nối server',
              }))
        })
    };

    function request() { return { type: classConstants.GETCLASSBYID_REQUEST } }
    function success(idClass) { return { type: classConstants.GETCLASSBYID_SUCCESS, idClass } }
    function failure(error) { return { type: classConstants.GETCLASSBYID_FAILURE, error } }
}

function getAllVideo() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"admin/class-all-video",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu lớp học'))
            })
    };

    function request() { return { type: classConstants.ADMINGETALL_REQUEST } }
    function success(getClassAdmin) { return { type: classConstants.ADMINGETALL_SUCCESS, getClassAdmin } }
    function failure(error) { return { type: classConstants.ADMINGETALL_FAILURE, error } }
}

function getAllText() {
    return dispatch => {
        dispatch(request());

        axios.get(config.get("apiDomain")+"admin/class-all-text",
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
            .then(res => {
                dispatch(success(res.data))
            })
            .catch(error => {
                dispatch(failure('lỗi nạp dữ liệu lớp học'))
            })
    };

    function request() { return { type: classConstants.ADMINGETALLCLASSTEXT_REQUEST } }
    function success(getClassAdmin) { return { type: classConstants.ADMINGETALLCLASSTEXT_SUCCESS, getClassAdmin } }
    function failure(error) { return { type: classConstants.ADMINGETALLCLASSTEXT_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        axios.delete(config.get("apiDomain")+'admin/delete-class/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa lớp học thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa lớp học',
        }))
    });
}

    function request(id) { return { type: classConstants.DELCLASS_REQUEST, id } }
    function success(id) { return { type: classConstants.DELCLASS_SUCCESS, id } }
    function failure(id, error) { return { type: classConstants.DELCLASS_FAILURE, id, error } }
}

//delete lesson
function delLesson(id) {
    return dispatch => {
        dispatch(request(id));

        axios.delete(config.get("apiDomain")+'admin/lesson/del/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa bài học thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa bài học',
        }))
    });
}

    function request(id) { return { type: classConstants.DELLESSON_REQUEST, id } }
    function success(id) { return { type: classConstants.DELLESSON_SUCCESS, id } }
    function failure(id, error) { return { type: classConstants.DELLESSON_FAILURE, id, error } }
}

//delete video
function delVideo(id) {
    return dispatch => {
        dispatch(request(id));

        axios.delete(config.get("apiDomain")+'admin/video/del/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa video thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa video',
        }))
    });
}

    function request(id) { return { type: classConstants.DELVIDEO_REQUEST, id } }
    function success(id) { return { type: classConstants.DELVIDEO_SUCCESS, id } }
    function failure(id, error) { return { type: classConstants.DELVIDEO_FAILURE, id, error } }
}


function editClass(id, eClass) {
    return dispatch => {
        dispatch(request(eClass));
        
        axios.put(config.get("apiDomain")+'admin/update-class/'+id, eClass,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/video-class');
                dispatch(snackbar.show({
                    message: 'Sửa lớp học thành công',
                  })) 
            
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi kết nối server'))
        })
}

    function request(eClass) { return { type: classConstants.ADMINEDITCLASS_REQUEST, eClass } }
    function success(eClass) { return { type: classConstants.ADMINEDITCLASS_SUCCESS, eClass } }
    function failure(error) { return { type: classConstants.ADMINEDITCLASS_FAILURE, error } }
}

function editClassText(id, eClass, quiz) {
    return dispatch => {
        dispatch(request(eClass));
        
        axios.put(config.get("apiDomain")+'admin/update-class/'+id, eClass,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/text-class');
            if(quiz === false){
                dispatch(snackbar.show({
                    message: 'Sửa lớp học thành công',
                  })) 
            }else{
                dispatch(snackbar.show({
                    message: 'Thêm bài trắc nghiệm thành công',
                  })) 
            }
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(snackbar.show({
                message: 'Lỗi kết nối server',
              })) 
        })
}

    function request(eClass) { return { type: classConstants.ADMINEDITCLASS_REQUEST, eClass } }
    function success(eClass) { return { type: classConstants.ADMINEDITCLASS_SUCCESS, eClass } }
    function failure(error) { return { type: classConstants.ADMINEDITCLASS_FAILURE, error } }
}

function editLessonText(id, lesson, idClass) {
    return dispatch => {
        dispatch(request(lesson));
        
        axios.put(config.get("apiDomain")+'admin/update-lesson/'+id, lesson,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/text-class/'+idClass);
            dispatch(snackbar.show({
                message: 'Sửa bài học thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(snackbar.show({
                message: 'Lỗi kết nối server',
              })) 
        })
}

    function request(lesson) { return { type: classConstants.ADMINEDITLESSON_REQUEST, lesson } }
    function success(lesson) { return { type: classConstants.ADMINEDITLESSON_SUCCESS, lesson } }
    function failure(error) { return { type: classConstants.ADMINEDITLESSON_FAILURE, error } }
}

function editVideo(id, lesson, idClass) {
    return dispatch => {
        dispatch(request(lesson));
        
        axios.put(config.get("apiDomain")+'admin/update-video/'+id, lesson,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/video-class/'+idClass);
            dispatch(snackbar.show({
                message: 'Sửa video thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(snackbar.show({
                message: 'Lỗi kết nối server',
              })) 
        })
}

    function request(lesson) { return { type: classConstants.ADMINEDITVIDEO_REQUEST, lesson } }
    function success(lesson) { return { type: classConstants.ADMINEDITVIDEO_SUCCESS, lesson } }
    function failure(error) { return { type: classConstants.ADMINEDITVIDEO_FAILURE, error } }
}

//admin add class text
function adminAddClass(classData) {
    return dispatch => {
        dispatch(request(classData));
        axios.post(config.get("apiDomain")+'admin/add-class', classData,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            if(res.data.isVideo === 0){
                history.push('/dashboard/text-class/'+res.data.id);
            }else{
                history.push('/dashboard/video-class/'+res.data.id);
            }
            
            dispatch(snackbar.show({
                message: 'Thêm lớp học thành công',
            })) 
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình thêm lớp học'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình thêm lớp học',
        }))
    });
}

    function request(addClass) { return { type: classConstants.ADMINADDCLASS_REQUEST, addClass } }
    function success(addClass) { return { type: classConstants.ADMINADDCLASS_SUCCESS, addClass } }
    function failure(error) { return { type: classConstants.ADMINADDCLASS_FAILURE, error } }
}

//admin add lesson text
function adminAddLessonText(lesson) {
    return dispatch => {
        dispatch(request(lesson));
        axios.post(config.get("apiDomain")+'admin/add-lesson-text', lesson,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/text-class/'+lesson.class_id);
            dispatch(snackbar.show({
                message: 'Thêm bài học thành công',
            })) 
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình thêm bài học'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình thêm bài học',
        }))
    });
}

    function request(lesson) { return { type: classConstants.ADMINADDLESSONTEXT_REQUEST, lesson } }
    function success(lesson) { return { type: classConstants.ADMINADDLESSONTEXT_SUCCESS, lesson } }
    function failure(error) { return { type: classConstants.ADMINADDLESSONTEXT_FAILURE, error } }
}

//admin add lesson video
function adminAddLessonVideo(video) {
    return dispatch => {
        dispatch(request(video));
        axios.post(config.get("apiDomain")+'admin/add-lesson-video', video,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(res.data));
            history.push('/dashboard/video-class/'+video.class_id);
            dispatch(snackbar.show({
                message: 'Thêm video thành công',
            })) 
        })
        .catch(error => {
            dispatch(failure('loi'));
            dispatch(alertActions.error('Lỗi trong quá trình thêm video'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình thêm video',
        }))
    });
}

    function request(video) { return { type: classConstants.ADMINADDLESSONVIDEO_REQUEST, video } }
    function success(video) { return { type: classConstants.ADMINADDLESSONVIDEO_SUCCESS, video } }
    function failure(error) { return { type: classConstants.ADMINADDLESSONVIDEO_FAILURE, error } }
}

function restore(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+'admin/restore-class/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Khôi phục lớp học thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình khôi phục lớp học người dùng',
        }))
    });
}

    function request(id) { return { type: classConstants.RESTORE_REQUEST, id } }
    function success(id) { return { type: classConstants.RESTORE_SUCCESS, id } }
    function failure(id, error) { return { type: classConstants.RESTORE_FAILURE, id, error } }
}

function forceDel(id) {
    return dispatch => {
        dispatch(request(id));

        axios.get(config.get("apiDomain")+'admin/force-del-class/'+id,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
            dispatch(success(id));
            dispatch(snackbar.show({
                message: 'Xóa vĩnh viễn lớp học thành công',
              })) 
        })
        .catch(error => {
            dispatch(failure(id, 'loi'));
            dispatch(snackbar.show({
                message: 'Lỗi trong quá trình xóa vĩnh viễn lớp học',
        }))
    });
}

    function request(id) { return { type: classConstants.FORCEDEL_REQUEST, id } }
    function success(id) { return { type: classConstants.FORCEDEL_SUCCESS, id } }
    function failure(id, error) { return { type: classConstants.FORCEDEL_FAILURE, id, error } }
}


import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography,  Card, CardActionArea, CardActions, Chip,
    CircularProgress, Fab 
} from '@material-ui/core';
// import Icon from '@material-ui/core/Icon';
import { PlayCircleOutline } from '@material-ui/icons';
import UserAvatar from 'react-user-avatar';
import { convertFromRaw, EditorState, Editor } from 'draft-js';
import Tooltip from '@material-ui/core/Tooltip';
import Timestamp from 'react-timestamp';

const styles = theme => ({
    root: {
      flexGrow: 1,
      width: '100%',
      marginTop: 100,
      marginBottom: 50,
    },
    bigAvatar: {
        marginBottom: 10,
        width: 120,
        height: 120,
        marginLeft:'auto',
        marginRight: 'auto',
      },
      media: {
        height: 120,
        objectFit: 'cover',
        width: '100%',
      },
      card: {
        width: '100%',
      },
      caption:{
          color: '#777',
      },
      iconTime: {
        fontSize: 13,
        position: 'relative',
        bottom: -2,
      },
      btnPlay: {
        position: 'absolute',
        top: '30%',
        left: '40%',
        fontSize: 50,
        '&:hover':{
            color: '#D50000',
        }
    },
    videoThumbnail: {
      '&:hover':{
          btnPlay:{
              color: '#D50000',
          } 
      }
    },
    titleClass:{
        color: '#444',
        fontWeight:450,
        textDecoration: 'none',
        marginBottom: 30,
        '&:hover':{
            color: '#1e88e5',
        }
    },
    chip: {
        fontSize:12,
        height:20,
        borderRadius: 3,
        
    },
})

class PublicUser extends React.Component{
    state = {
        loading: false,
        uInfo: [],
        uClass: [],
        relatedSkill: [],
        info: EditorState.createEmpty(),
      };
    
    componentWillMount(){
        this.setState({loading: true})
        axios.get(config.get("apiDomain")+'userFrontend/info/'+this.props.match.params.id)
        .then(res => {
            this.setState({
                uInfo: res.data,
            });
            if(res.data.skill_related !== null){
                this.setState({
                    relatedSkill: JSON.parse(res.data.skill_related)
                })
            }
            if(res.data.info !== null){
                let convert = convertFromRaw(JSON.parse(res.data.info));
                let editor = EditorState.createWithContent(convert);
                this.setState({info: editor})
              }
        });

        axios.get(config.get("apiDomain")+'class/user/'+this.props.match.params.id)
        .then(res => {
            this.setState({uClass: res.data});
            this.setState({loading: false})
        });

    }

    render(){
        const { classes } = this.props;
        const { uInfo, loading, uClass, relatedSkill, info } = this.state;
        return(
            <div className={classes.root}>
            <MetaTags>
                <title>{uInfo.name+' - LearnSkill'}</title>
                <meta name="description" content={'Thông tin '+uInfo.name+' người dùng hệ thống học trực tuyến learnskill'} />
                <meta property="og:title" content={uInfo.name+' - LearnSkill'} />
                <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
                <meta property="og:url" content={config.get('domain')} />
                <meta name="twitter:card" content="summary_large_image" />
            </MetaTags>
            {loading ? (
                <CircularProgress className={classes.progress} color="secondary" style={{position:'absolute',top:'48%',left:'48%'}} />
            ) : (
            <Grid container style={{padding:'0 10px'}}>
                
                <Grid item md={1}></Grid>
                <Grid item xs={12} sm={12} md={10}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={4} align="center">
                            {uInfo.name !== undefined &&
                                <UserAvatar size="120" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar}/>
                            }
                            <Typography variant="body1">{uInfo.name}</Typography>
                            {uInfo.isTeacher === 1 ? (
                                <Chip
                                    label="Giáo viên"
                                    className={classes.chip}
                                    color="secondary"
                                />
                            ) : (
                                <Chip
                                    label="Người học"
                                    className={classes.chip}
                                    color="primary"
                                />
                            )}
                            
                            <Typography variant="caption">
                                {relatedSkill !== null && relatedSkill.map((rs, i, arr)=>(
                                    <span key={i}>{rs.name}{arr.length-1 === i ? "": ", "}</span>
                                ))}
                            </Typography>
                            <Typography variant="caption" gutterBottom className="margin-top-20">
                            {uInfo.fb_url !== null &&
                                <Tooltip title="Facebook" aria-label="fb">
                                <Fab color="primary" size="small" href={uInfo.fb_url} target="_blank" style={{marginRight:15}}>f</Fab>
                                </Tooltip>
                            }
                            {uInfo.yt_url !== null &&
                                <Tooltip title="Youtube" aria-label="yt">
                                <Fab color="secondary" size="small" href={uInfo.yt_url} target="_blank" style={{marginRight:15}}>YT</Fab>
                                </Tooltip>
                            }
                            {uInfo.gh_url !== null &&
                                <Tooltip title="Github" aria-label="gh">
                                <Fab color="primary" size="small" href={uInfo.gh_url} target="_blank">GH</Fab>
                                </Tooltip>
                            }
                            </Typography>
                            <Typography variant="body1" className="margin-top-20">
                                {uInfo.web_url}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                        <Typography variant="h6" style={{marginBottom:20}}>
                            Lớp học đang dạy
                        </Typography>
                        <Grid container spacing={16}>
                            {uClass.map((item, index)=>(
                                <Grid item xs={6} sm={6} md={4} key={index}>
                                <Card>
                                    <CardActionArea className={classes.videoThumbnail}>
                                        <Link to={item.isVideo === 1 ? ("/video-course/")+item.slug : ("/text-course/") +item.slug}>
                                            <PlayCircleOutline color="primary" className={classes.btnPlay} />
                                            <img src={(item.class_image !== null) ? config.get('apiImg')+item.class_image : "/no-preview.jpg"} className={classes.media} alt={item.title}/>
                                        </Link>
                                    </CardActionArea>
                                    <CardActions style={ {padding:'2px'} }>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography className={classes.caption} variant="caption" align="left">
                                                {item.countViews} lượt xem
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography className={classes.caption} variant="caption" align="right">
                                                    <Timestamp date={new Date(item.created_at)} relative autoUpdate />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{paddingBottom:10}}>
                                                <Typography variant="body1" gutterBottom>
                                                    {item.isVideo === 1 ? (
                                                        <Link to={"/video-course/"+item.slug} className={classes.titleClass}>{item.title}</Link>
                                                    ) : (
                                                        <Link to={"/text-course/"+item.slug} className={classes.titleClass}>{item.title}</Link>
                                                    )}
                                                    
                                                </Typography>
                                                <span style={{color:'#888',fontSize:13}}>Loại lớp học:</span> <Chip
                                                    label={item.isVideo === 1 ? 'Video' : 'Văn bản'}
                                                    className={classes.chip}
                                                    color={item.isVideo === 1 ? 'secondary' : 'primary'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>
                            ))}
                        </Grid>
                        <Typography variant="h6" style={{marginTop:50}}>
                            Giới thiệu
                        </Typography>
                        <Editor toolbarHidden editorState={info} readOnly={true} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>
            )}
            </div>
        )
    }
}
export default withStyles(styles)(PublicUser);
import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import config from "react-global-configuration";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Fab
} from '@material-ui/core';
import { HeadPrivate, FooterPrivate } from '../Layout';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserAvatar from 'react-user-avatar';
import { convertFromRaw, EditorState } from 'draft-js';
import axios from 'axios';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux'
import { authHeader } from '../_helpers/auth-header';
import '../editor.css';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop:100,
      },
      bigAvatar: {
        marginBottom: 10,
        width: 100,
        height: 100,
        marginLeft:'auto',
        marginRight: 'auto',
      },
      fb:{
        color: '#777',
        textDecoration: 'none',
        fontSize: 25,
        fontWeight: 500,
        '&:hover':{
          color: '#4267b2',
        }
      },
      web:{
        color: '#777',
        textDecoration: 'none',
        fontWeight: 500,
        '&:hover':{
          color: '#ff2200',
        }
      }
  })

  class Profile extends React.Component {
    state = {
      uInfo: {name:'u'},
      content: EditorState.createEmpty(),
      loading: false,
      info: EditorState.createEmpty(),
    };
    componentDidMount() {
    this.setState({loading: true})
        let user = JSON.parse(localStorage.getItem('user'));
        axios.get(config.get("apiDomain")+'user/info/'+user.id,
        {
            headers: authHeader()
        })
        .then(res => {
            this.setState({
                uInfo: res.data,
                loading: false
              })
              if(res.data.info !== null){
                let convert = convertFromRaw(JSON.parse(res.data.info));
                let editor = EditorState.createWithContent(convert);
                this.setState({info: editor})
              }
        })
        .catch(error => {
            this.props.dispatch(snackbar.show({
                message: 'Lỗi nạp dữ liệu',
              })) 
        })
        
    }
    render(){
      const { classes } = this.props;
      const { uInfo, info, loading } = this.state;
      var a = info.getCurrentContent().getBlocksAsArray();
      
      return(
        <div className={classes.root}>
        <MetaTags>
          <title>Thông tin thành viên - {uInfo.name}</title>
          <meta name="description" content="Thông tin thành viên hệ thống học kỹ năng trực tuyến Learn Skills" />
        </MetaTags>
        <HeadPrivate />
          <Grid container style={{padding:'0 10px'}}>
            <Grid item md={1}></Grid>
            <Grid item xs={12} sm={12} md={10}>
            {loading ? (
              <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
            ) : (
              <Grid container spacing={16}>
                <Grid item xs={12} align="right">
                  <Button size="small" variant="outlined" component={Link} to="/u/edit-profile" style={{textTransform:'initial'}}>
                    Sửa thông tin
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={4} align="center">
                {uInfo.avatar !== "" ?(
                  <UserAvatar size="120" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar}/>
                ) : (
                  <UserAvatar size="120" name={uInfo.name} />
                )}
                <div style={{textAlign: 'center'}}><b>{uInfo.name}</b></div>
                <div style={{textAlign: 'center',marginTop:20}}>
                  <Typography variant="caption" gutterBottom className="margin-top-20">
                  {uInfo.fb_url !== null &&
                    <Tooltip title="Facebook" aria-label="fb">
                      <Fab color="primary" size="small" href={uInfo.fb_url} target="_blank" style={{marginRight:15}}>f</Fab>
                    </Tooltip>
                  }
                  {uInfo.yt_url !== null &&
                    <Tooltip title="Youtube" aria-label="yt">
                      <Fab color="secondary" size="small" href={uInfo.yt_url} target="_blank" style={{marginRight:15}}>YT</Fab>
                    </Tooltip>
                  }
                  {uInfo.gh_url !== null &&
                    <Tooltip title="Github" aria-label="gh">
                      <Fab color="primary" size="small" href={uInfo.gh_url} target="_blank">GH</Fab>
                    </Tooltip>
                  }
                  </Typography>
                </div>
                <Typography variant="body1" className="margin-top-20">
                    {uInfo.web_url}
                </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography variant="h6" gutterBottom>
                    Giới thiệu
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Xin chào, tôi là {uInfo.name}
                  </Typography>
                  {
                    a.map((val,index)=>(
                      <div key={index} style={{marginBottom:15,textAlign:'justify'}}>{val.getText()}</div>
                    ))
                  }
                </Grid>
              </Grid>
            )}
              
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>

          <FooterPrivate />
        </div>
      )
    }
  }
  function mapStateToProps(state) {
    const { users } = state;
    return {
      users
    };
}
  export default connect(mapStateToProps)(withStyles(styles)(Profile));


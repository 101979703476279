import React from 'react';
import MetaTags from 'react-meta-tags';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import { connect } from 'react-redux';
import { userActions } from '../_actions/user.actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HeadPrivate, FooterPrivate } from '../Layout';
import red from '@material-ui/core/colors/red';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import UserAvatar from 'react-user-avatar';
import { convertFromRaw, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import axios from 'axios';
import config from "react-global-configuration";
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux'
import { authHeader } from '../_helpers/auth-header';
import '../editor.css';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Avatar from 'react-avatar-edit';
import PhotoCamera from '@material-ui/icons/PhotoCamera';


const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop:80,
      },
      flex: {
        flex: 1,
      },
      bigAvatar: {
        marginBottom: 10,
        width: 150,
        height: 150,
        marginLeft:'auto',
        marginRight: 'auto',
      },
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      margin: {
          marginTop:20,
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
    //   buttonSuccess: {
    //     backgroundColor: green[500],
    //     '&:hover': {
    //       backgroundColor: green[700],
    //     },
    //   },
      fabProgress: {
        color: red[500],
        position: 'absolute',
        top: -4,
        left: -4,
        zIndex: 1,
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      input: {
        display: 'none',
      },
      appBar: {
        position: 'relative',
      },
      
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
  })

  function Transition(props) {
    return <Slide direction="up" {...props} />;
  }

  class EditProfile extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          open: false,
            user: [],
            noChange: true,
            info: EditorState.createEmpty(),
            uInfo: {
              name: 'a',
              avatar: 'a'
            },
            loading: false,
            avatar: '',
            avatarPre:[],
            defaultPreview: null,
            src: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSaveAvatar = this.handleSaveAvatar.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onCropDefault = this.onCropDefault.bind(this)
        this.onCloseDefault = this.onCloseDefault.bind(this)
    }
    onCropDefault(preview) {
      this.setState({defaultPreview: preview})
    }
  
    onCloseDefault() {
      this.setState({defaultPreview: null})
    }
    handleClickOpen = () => {
      this.setState({ open: true });
    };
  
    handleClose = () => {
      this.setState({ open: false });
    };
    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({noChange:false})
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    onEditorStateChange = (info) => {
        this.setState({info, noChange:false});
        const { user } = this.state;
        let infoConvert = info.getCurrentContent();
        this.setState({
            user: {
                ...user,
                info: JSON.stringify(convertToRaw(infoConvert), null, 4)
            }
        });
      };
    
      handleSubmit(event) {
        event.preventDefault();
        let userInfo = JSON.parse(localStorage.getItem('user'));
         const { user } = this.state;
            const { dispatch } = this.props;
            dispatch(userActions.editProfile(userInfo.id, user))
      };
      handleSaveAvatar(event) {
        event.preventDefault();
        this.setState({loading:true})
        let userInfo = JSON.parse(localStorage.getItem('user'));
        
        const user = {avatar: this.state.defaultPreview};
        axios.put(config.get("apiDomain")+'user/edit/'+userInfo.id, user,
        {
            'Content-Type': 'application/json',
            headers: authHeader()
        })
        .then(res => {
          // console.log(user)
            this.setState({ 
              open: false, 
              loading:false,
              avatar: {
                name: userInfo.name,
                img: user.avatar
              } 
            });
            this.props.dispatch(snackbar.show({
                message: 'Thay ảnh đại diện thành công thành công',
              })) 
        })
        .catch(error => {
          this.setState({loading:false });
          this.props.dispatch(snackbar.show({
            message: 'Lỗi trong quá trình thay ảnh đại diện',
          })) 
          
      })
      
      };
    componentDidMount() {
        this.setState({loading: true})
        let user = JSON.parse(localStorage.getItem('user'));
        axios.get(config.get("apiDomain")+'user/info/'+user.id,
        {
            headers: authHeader()
        })
        .then(res => {
            this.setState({
                uInfo: res.data,
                loading: false,
              })
              if(res.data.info !== null){
                let convert = convertFromRaw(JSON.parse(res.data.info));
                let editor = EditorState.createWithContent(convert);
                this.setState({info: editor})
              }
        })
        .catch(error => {
            this.props.dispatch(snackbar.show({
                message: 'Lỗi nạp dữ liệu',
              })) 
        })
        
    }
   
    render(){
        const { user, info, uInfo, loading } = this.state;
        const { classes, updating } = this.props;
      return(
        <div className={classes.root}>
            <HeadPrivate />
            <MetaTags>
                <title>Sửa thông tin - LearnSkill</title>
             </MetaTags>
            <ValidatorForm className={classes.form} onSubmit={this.handleSubmit} >
                <Grid container style={{padding:'0 10px'}}>
                    <Grid item md={2}></Grid>
                    <Grid item xs={12} sm={12} md={8}>
                    <Grid container>
                        {loading ? (
                        <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                        ) : (
                        <React.Fragment>
                        <Grid item xs={12} align="right">
                            <div className={classes.load}>
                                <div className={classes.wrapper}>
                                <Button
                                    variant="outlined" 
                                    color="primary"
                                    disabled={updating || this.state.noChange}
                                    type="submit"
                                    style={{textTransform:'initial'}}
                                >
                                    Lưu thiết lập
                                </Button>
                                {updating && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </div>
                        </Grid>
                        
                        <Grid item xs={12} sm={4} md={4}>
                        <div align="center">
                        {uInfo.avatar !== "" ?(
                          <UserAvatar size="120" name={uInfo.name} src={config.get('apiImg')+uInfo.avatar}/>
                        ) : (
                          <UserAvatar size="120" name={uInfo.name} />
                        )}
                        </div>
                        <div align="center">
                          <Button variant="outlined" size="small" style={{textTransform:'initial'}} className={classes.button} onClick={this.handleClickOpen}>
                          <PhotoCamera className={classNames(classes.leftIcon, classes.iconSmall)} />
                            Thay ảnh
                          </Button>
                        </div>
                        <FormControl className={classNames(classes.margin, classes.textField)} style={{display:'inherit'}}>
                        <TextField
                            fullWidth
                            className={classes.margin}
                            label="Họ và tên"
                            name="name"
                            defaultValue={uInfo.name}
                            onChange={this.handleChange}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <AccountCircle />
                                </InputAdornment>
                            ),
                            }}         
                        />
                        </FormControl>
                        <FormControl className={classNames(classes.margin, classes.textField)} style={{display:'inherit'}}>
                        <TextField
                            fullWidth
                            className={classes.margin}
                            id="fb"
                            label="Địa chỉ Facebook"
                            name="fb_url"
                            defaultValue={uInfo.fb_url}
                            onChange={this.handleChange}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <AccountCircle />
                                </InputAdornment>
                            ),
                            }}
                        />
                        </FormControl>
                       
                        <FormControl className={classNames(classes.margin, classes.textField)} style={{display:'inherit'}}>
                        <TextField
                            fullWidth
                            className={classes.margin}
                            id="input-with-icon-textfield"
                            label="Địa chỉ Youtube"
                            name="yt_url"
                            defaultValue={uInfo.yt_url}
                            onChange={this.handleChange}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <AccountCircle />
                                </InputAdornment>
                            ),
                            }}
                        />
                        </FormControl>
                        <FormControl className={classNames(classes.margin, classes.textField)} style={{display:'inherit'}}>
                        <TextField
                            fullWidth
                            className={classes.margin}
                            id="input-with-icon-textfield"
                            label="Github"
                            name="gh_url"
                            defaultValue={uInfo.gh_url}
                            onChange={this.handleChange}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <AccountCircle />
                                </InputAdornment>
                            ),
                            }}
                        />
                        </FormControl>
                        <FormControl className={classNames(classes.margin, classes.textField)} style={{display:'inherit'}}>
                        <TextField
                            fullWidth
                            className={classes.margin}
                            id="input-with-icon-textfield"
                            label="Địa chỉ website cá nhân"
                            name="web_url"
                            defaultValue={uInfo.web_url}
                            onChange={this.handleChange}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <AccountCircle />
                                </InputAdornment>
                            ),
                            }}
                        />
                        
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} style={{paddingLeft:16}}>
                        <Typography variant="h6" gutterBottom>
                            Giới thiệu
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Xin chào, tôi là {user.name === undefined ? uInfo.name: user.name}
                        </Typography>
                        <Editor
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          toolbarOnFocus
                          toolbarClassName="toolbar-class"
                          editorState={info}
                          onEditorStateChange={this.onEditorStateChange}
                          toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
                            inline: {
                              options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                              bold: { className: 'bordered-option-classname' },
                              italic: { className: 'bordered-option-classname' },
                              underline: { className: 'bordered-option-classname' },
                              strikethrough: { className: 'bordered-option-classname' },
                              code: { className: 'bordered-option-classname' },
                            },
                            blockType: {
                              className: 'bordered-option-classname',
                            },
                            fontSize: {
                              className: 'bordered-option-classname',
                            },
                            fontFamily: {
                              className: 'bordered-option-classname',
                            },
                          }}
                        />
                        </Grid>
                        </React.Fragment>
                        )}
                    </Grid>
                        
                    </Grid>
                    <Grid item md={2}></Grid>
                </Grid>
            </ValidatorForm>
            <FooterPrivate />

            <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Thay ảnh đại diện
              </Typography>
              <div className={classes.load}>
                      <div className={classes.wrapper}>
              <Button color="secondary" disabled={loading} variant="contained" style={{textTransform:'initial'}} onClick={this.handleSaveAvatar}>
                Lưu thay đổi
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
              </div>
            </Toolbar>
          </AppBar>
          <Grid container style={{padding:20}}>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={6}>
              <h5>Chọn ảnh đại diện</h5>
              <Avatar
                width={390}
                height={295}
                onCrop={this.onCropDefault}
                onClose={this.onCloseDefault}
                // src={this.state.src}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <h5>Xem trước</h5>
              <img alt="" style={{width: '150px', height: '150px'}} src={this.state.defaultPreview}/>
            </Grid>
          </Grid>
        </Dialog>

        </div>
      )
    }
  }
  function mapStateToProps(state) {
    const { users } = state;
    const { updating } = state.editProfile;
    return {
      users, updating
    };
}
  export default connect(mapStateToProps)(withStyles(styles)(EditProfile));


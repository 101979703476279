import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotFound from './images/notfound.png';

const styles = theme => ({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    content: {
        marginTop: theme.spacing.unit * 20,
    }
});
class Error extends React.Component{
    render(){
        const { classes } = this.props;
        return(
            <Grid container className={classes.content}>
                <Grid item xs={12} sm={12} md={2}></Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Grid container>
                        <Grid item xs={5}>
                            <img src={NotFound} alt="Trang không tồn tại" />
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="h5">
                                Rất tiếc! Dường như trang bạn mở không tồn tại.
                            </Typography><br />
                            <Typography>
                                Trang bạn mở không tồn tại hoặc đã bị xóa bỏ khỏi hệ thống.
                            </Typography><br />
                            <Typography>
                                Vui lòng kiểm tra lại đường dẫn trang bạn đã nhập. Nếu địa chỉ trang chính xác nhưng vẫn không mở được. Vui lòng liên hệ với người quản trị hệ thống.
                            </Typography><br />
                            <Button variant="contained" color="primary" href="/">Trở về trang chủ</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={2}></Grid>
            </Grid>
        )
    }
}

Error.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withStyles(styles)(Error);
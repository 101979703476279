import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { blue, red } from "@material-ui/core/colors";
import "./config";
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'material-ui-snackbar-redux'
import { store } from './_helpers/store';
// // setup fake backend
// import { configureFakeBackend } from './_helpers';
// configureFakeBackend();
// import ReactGA from 'react-ga';
// const trackingId = "UA-142570305-1"; // Replace with your Google Analytics tracking ID
// ReactGA.initialize(trackingId);

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
      },
    palette: {
        primary: {
            light: blue[300],
            main: blue[600],
            dark: blue[800],
          },
          secondary: {
            light: red[400],
            main: red[500],
            dark: red.A700,
          }
    }
});

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <SnackbarProvider SnackbarProps={{ autoHideDuration: 3500 }}>
                <App />
            </SnackbarProvider>
        </MuiThemeProvider>
    </Provider>,
document.getElementById('root'));
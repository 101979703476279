import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import validate from './validate';
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  load: {
    display: 'flex',
    alignItems: 'center',
    flexDirection:'row-reverse',
  },
  wrapper: {
    margin: 5,
    position: 'relative',
  },
});

class QuizForm extends Component {

  renderInputField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
      <label>{label}</label>
      <TextField
        fullWidth
        error={touched && error ? true : false}
          {...input} 
          type={type}
          label={label}
          margin="normal"
          variant="outlined"
          style={{marginTop:5,marginBottom:15}}
          helperText={touched && error && error}
        />
    </div>
  );

  renderTextareaField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
      <label>{label}</label>
      <TextField
        fullWidth
        error={touched && error ? true : false}
          {...input} 
          type={type}
          label={label}
          margin="normal"
          variant="outlined"
          style={{marginTop:5,marginBottom:15}}
          multiline
          rows={5}
          helperText={touched && error && error}
        />
    </div>
  );

   renderSelectField = ({ input, label, type, meta: { touched, error }, children }) => (
    <div>
      <label>{label}</label>
      <FormControl error={touched && error ? true : false}>
          <NativeSelect
            {...input}
          >
            {children}
          </NativeSelect>
          {touched && error && <FormHelperText>{error}</FormHelperText>}
          
        </FormControl>
    </div>
  );
  
   renderSelectQuestionTypeField = ({ input, label, type, meta: { touched, error }, children }) => (
    <div>
      <label>{label}</label>
      <FormControl error={touched && error ? true : false}>
          <NativeSelect
            {...input}
          >
            {children}
          </NativeSelect>
          {touched && error && <FormHelperText>{error}</FormHelperText>}
          
        </FormControl>
    </div>
  );
  
   renderTextAnswers = ({ fields, question, meta: { error } }) => (
    <ul>
      <li>
        <Button variant="contained" color="primary" style={{textTransform:'initial'}} size="small" onClick={() => fields.push()}>Thêm phương án trả lời</Button>
      </li>
      {fields.map((answer, index) => (
        <li key={index}>
          <Button variant="contained" size="small" color="secondary"  onClick={() => fields.remove(index)}>
            <DeleteIcon/>
          </Button>
          <Field
            name={answer}
            type="text"
            component={this.renderInputField}
            label={`Trả lời #${index + 1}`}
          />
        </li>
      ))}
          <li>
           <Field
            name={`${question}.correctAnswer`}
            component={this.renderSelectField}
            label="Chọn câu trả lời chính xác"
          >
            <option value="">Please select correct answer</option>
             {fields.map((answer, index) => (
               <option key={index+1} value={index+1}>{`Trả lời #${index + 1}`}</option>
             ))}
          </Field>
          </li>
    
      {error && <li className="error">{error}</li>}
    </ul>
  );
  
  
   renderQuestions = ({ fields, meta: { touched, error, submitFailed } }) => (
    <ul style={{listStyle:'none',paddingLeft:0}}>
      <li>
      <Button variant="contained" color="secondary"  onClick={() => fields.push({})} style={{textTransform:'initial'}}>
        <AddIcon style={{marginRight: 10}} />
        Thêm câu hỏi
      </Button>
      {(touched || submitFailed) && error && <span>{error}</span>}
      </li>
      {fields.map((question, index) => (
        <li key={index}>
        <div  style={{marginTop:10,padding:'10px',border: '1px dashed #999'}}>
          <div align="right">
            <Button variant="contained" color="secondary" onClick={() => fields.remove(index)}>
              <DeleteIcon />
            </Button>
          </div>
          <h4>Câu hỏi #{index + 1}</h4>
          <Field
            name={`${question}.question`}
            type="text"
            component={this.renderInputField}
            label="Tiêu đề câu hỏi"
          />

          <Field
            name={`${question}.questionType`}
            component={this.renderSelectQuestionTypeField}
            label="Chọn loại câu hỏi"
          >
            <option value="">Chọn loại câu hỏi</option>
            <option value="text">Văn bản</option>
            <option value="photo">Ảnh</option>
          </Field>
          <FieldArray name={`${question}.answers`} component={this.renderTextAnswers} question={question} />
          <Field
            name={`${question}.messageForCorrectAnswer`}
            type="text"
            component={this.renderTextareaField}
            label="Thông điệp trả lời chính xác"
          />
          <Field
            name={`${question}.messageForInorrectAnswer`}
            type="text"
            component={this.renderTextareaField}
            label="Thông điệp trả lời không chính xác"
          />
          <Field
            name={`${question}.explanation`}
            type="text"
            component={this.renderTextareaField}
            label="Gợi ý thêm"
          />
        </div>
          
        </li>
      ))}
    </ul>
  );

  render() {

  const { handleSubmit, pristine, reset, submitting, classes } = this.props;

    return (
      <div className={classes.root}>
      <Grid container>
        <Grid item md={1}></Grid>
        <Grid item md={10}>
          <form name="quiz-form" onSubmit={handleSubmit}>
          <Field
            name="quizTitle"
            type="text"
            component={this.renderInputField}
            label="Tiêu đề bài trắc nghiệm"
          />
          <Field
            name="quizSynopsis"
            type="text"
            component={this.renderTextareaField}
            label="Mô tả bài trắc nghiệm"
          />
          <FieldArray name="questions" component={this.renderQuestions} />
          <div align="right">
            <Button disabled={pristine || submitting} onClick={reset} variant="contained" color="default" style={{marginRight:10,textTransform:'initial'}}>
              Làm lại
            </Button>
                <Button type="submit" disabled={submitting} variant="contained" color="primary" style={{textTransform:'initial'}}>
                  Tạo bài trắc nghiệm
                </Button>
                {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
            
          </div>
        </form>
      </Grid>
      </Grid>
      </div>
    );
  }
}

QuizForm = reduxForm({
  form: 'quizForm',
  validate
})(QuizForm);

const selector = formValueSelector('quizForm');

QuizForm = connect(
  state => {
    const questions = selector(state, 'questions');
    const questionType = questions && questions.map(question => question.questionType);

    return { questionType: questionType }
  }
)(QuizForm)


export default withStyles(styles)(QuizForm);
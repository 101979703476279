import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Card, 
    CardActionArea,  CardActions, Paper,CircularProgress 
} from '@material-ui/core';
import { PlayCircleOutline } from '@material-ui/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import UserAvatar from 'react-user-avatar';
import { history } from '../_helpers/history';
import Timestamp from 'react-timestamp';

const styles = theme => ({
    root: {
      flexGrow: 1,
      marginBottom: 50,
    },
    section:{
        marginBottom: 20,
    },
    media: {
        objectFit: 'cover',
        width: '100%',
        height: 150,
      },
      bigMedia: {
          objectFit: 'cover',
          width: '100%',
          height: 250,
        },
    card: {
      width: '100%',
    },
    chip: {
        width:'100%',
        color: '#777',
        borderRadius: 0,
      },
    caption:{
        color: '#777',
    },
   
      teacherName: {
          color: '#777',
          textDecoration: 'none',
          fontWeight: 450,
          cursor: 'pointer',
          '&:hover': {
              color: 'red',
              textDecoration: 'none',
          },
      },
     
    iconTime: {
      fontSize: 13,
      position: 'relative',
      bottom: -2,
    },
    popover: {
      margin: theme.spacing.unit * 2,
      pointerEvents: 'none',
      top:-25,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    button: {
      margin: theme.spacing.unit,
    },
    center:{
        textAlign: 'center',
    },
    menuClass:{
        textAlign: 'right',
        paddingRight: 20,
    },
    btnPlay: {
        position: 'absolute',
        top: '40%',
        left: '45%',
        fontSize: 60,
        '&:hover':{
            color: '#D50000',
        }
    },
    videoThumbnail: {
        display:'inline-flex',
      '&:hover':{
          btnPlay:{
              color: '#D50000',
          } 
      }
      },
      whyChoose: {
          background: '#E3F2FD',
          marginTop: 50,
          paddingBottom: 50,
      },
      mobile:{
          background: '#282c34 url("images/mobile.jpg")',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          height: 420,
          color: '#fff',
          padding: '80px 0 50px 0',
      },
      amazing:{
          background: '#fff url("images/desktop-2-rows.png")',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          height: 350,
          margin: '80px 0 50px 0',
      },
      listTeacher:{
          background: '#282c34 url("images/teachers-hero.jpg")',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          height: 420,
          margin: '30px 0 20px 0',
          padding: '100px 0',
          color: '#fff',
      },
      gray:{
          color: '#666',
      },
      linkMenuLeft: {
        color: '#777',
        textDecoration: 'none',
        '&:hover':{
            color: '#1e88e5',
        }
    },
      linkListTeacher: {
          color: '#1565C0',
          textDecoration: 'none',
          marginLeft: 20,
          fontWeight: 'normal',
          '&:hover':{
              color: '#d50000',
          }
      },
      ListItem:{
          listStyleType: 'none',
          margin: 0,
          padding: 0
      },
      listItemText:{
          margin: '7px 0',
      },
      linkSection:{
          color: '#666',
          textDecoration: 'none',
          '&:hover':{
              color: '#d50000',
          }
      },
      inline: {
        display: 'inline',
      },
      box:{
          padding: 50,
          border: '1px dashed #999',
      },
      titleClass:{
        color: '#444',
        fontSize:'1.1em',
        fontWeight:450,
        textDecoration: 'none',
        marginBottom: 30,
        '&:hover':{
            color: '#1e88e5',
        }
       },
       avatarTeacher:{
           height:60,
           width:60
       }
  });

  
class EClass extends React.Component {
    state = {
        value: 0,
        loading: true,
        theFirstClass: [],
        classFeature: [],
        classPopular:[],
        classTrending: [],
        catName: [],
        relatedSkill: [],
      };
    
      handleChange = (event, value) => {
        this.setState({ value });
      };
      componentDidMount(){
        this.setState({loading: true})
        axios.get(config.get("apiDomain")+'menu/findName/'+this.props.match.params.slug)
        .then(res => {
            if(res.data.length === 0){
                history.push('/error')
            }
            this.setState({catName: res.data});
        });
    
        axios.get(config.get("apiDomain")+'class/the-first-section/'+this.props.match.params.slug+'/1')
        .then(res => {
            this.setState({theFirst: res.data})
        })
        axios.get(config.get("apiDomain")+'class/feature-section/'+this.props.match.params.slug+'/1')
        .then(res => {
            this.setState({classFeature: res.data})
        })
        axios.get(config.get("apiDomain")+'class/popular-section/'+this.props.match.params.slug+'/1')
        .then(res => {
            this.setState({classPopular: res.data})
        })
        axios.get(config.get("apiDomain")+'class/trending-section/'+this.props.match.params.slug+'/1')
        .then(res => {
            this.setState({classTrending: res.data, loading:false})
        })
        
    }
    
      render() {
          const { classes } = this.props;
          const { catName, theFirst, classFeature, classTrending, classPopular, loading } = this.state;
          
        return(
            <div className={classes.root}>
                <MetaTags>
                    <title>{'Các lớp học video '+catName}</title>
                    <meta name="description" content={'Các lớp học video '+catName+' trên hệ thống học tập trực tuyến learnskill'} />
                    <meta property="og:title" content={'Các lớp học video '+catName} />
                    <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
                    <meta property="og:url" content={config.get('domain')} />
                    <meta name="twitter:card" content="summary_large_image" />
                </MetaTags>
                {loading ? (
                <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5" color="primary" className={classes.section}>
                            Tất cả lớp học {catName}
                        </Typography>
                        {theFirst.map(val=>(
                            <Paper key={val.id}>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <Card style={{boxShadow: 'none',borderRadius:0}}>
                                        <CardActionArea className={classes.videoThumbnail} style={{display:'initial'}}>
                                            <Link to={"/video-course/"+val.slug}>
                                                <PlayCircleOutline color="primary" className={classes.btnPlay} />
                                                <img src={(val.class_image !== null) ? config.get('apiImg')+val.class_image : "/no-preview.jpg"} className={classes.bigMedia} alt={val.title}/>
                                            </Link>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} style={{paddingLeft: 20, position:'relative'}}>
                                    <Typography variant="h6" gutterBottom align="left" style={{marginTop:10}}>
                                        <Link to={"/video-course/"+val.slug} className={classes.titleClass}>{val.title}</Link>
                                    </Typography>
                                    <List>
                                        <ListItem style={{paddingLeft:0,paddingTop:20}}>
                                            <ListItemAvatar component={Link} to={"/user/"+val.user.id+'/'+val.user.name.replace(/\s+/g, '-')} className={classes.teacherName} title={val.user.name}>
                                            {val.user.avatar !== '' ?(
                                                        <UserAvatar size="60" name={val.user.name} src={config.get('apiImg')+val.user.avatar}/>
                                                        ) : (
                                                        <UserAvatar size="60" name={val.user.name} />
                                                        )}
                                            </ListItemAvatar>
                                                <ListItemText
                                                    primary={<Link to={"/user/"+val.user.id+'/'+val.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{val.user.name}</Link>}
                                                    secondary={
                                                    <React.Fragment>
                                                        <Typography component="span" className={classes.caption} color="textPrimary">
                                                            {val.user.skill_related !== null && JSON.parse(val.user.skill_related).map((rs, i, arr)=>(
                                                                <span key={i}>{rs.name}{arr.length-1 === i ? "": ", "}</span>
                                                            ))}
                                                            </Typography>
                                                            <Typography component="span" className={classes.caption} color="textPrimary">
                                                            {val.countViews} người học
                                                            </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        
                                        </List>
                                        <Typography className={classes.caption} style={{position:'absolute',bottom:0}} variant="caption">
                                            <Timestamp date={new Date(val.created_at)} options={{ includeDay: true, twentyFourHour: true }} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                                                

                        <Grid item xs={12} style={{marginTop:30}}>
                            <Typography variant="h5" color="primary" className={classes.section}>
                                Các lớp học {catName} mới nhất
                            </Typography>
                        </Grid>
                        <Grid container spacing={16}>
                        {classFeature.map((cf,index) =>(
                            <Grid item xs={12} sm={4} md={4} key={index}>
                            <Card>
                                <CardActionArea className={classes.videoThumbnail}>
                                    <Link to={"/video-course/"+cf.slug} title={cf.title}>
                                        <PlayCircleOutline color="primary" className={classes.btnPlay} />
                                        <img src={(cf.class_image !== null) ? config.get('apiImg')+cf.class_image : "/no-preview.jpg"} className={classes.media} alt={cf.title}/>
                                    </Link>
                                </CardActionArea>
                                <CardActions style={ {padding:'2px'} }>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography className={classes.caption} variant="caption" align="left">
                                            {cf.countViews} người học
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.caption} variant="caption" align="right">
                                                <Timestamp date={new Date(cf.created_at)} relative autoUpdate />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom>
                                                <Link to={"/video-course/"+cf.slug} className={classes.titleClass}>{cf.title}</Link>
                                            </Typography>
                                            <Typography className="margin-top-20">
                                                <Link to={"/user/"+cf.user.id+'/'+cf.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{cf.user.name}</Link> 
                                            </Typography>
                                            
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                        ))}
                        </Grid>

              
                        <Grid container spacing={8} style={{marginTop:30}}>
                            <Grid item xs={12} sm={6} >
                                <Typography variant="h5" color="primary" className={classes.section}>
                                    Các lớp học {catName} xu hướng
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} align="right">
                                <Button component={Link} to={"/video/"+this.props.match.params.slug+"/2"} variant="outlined" size="small" color="primary" className={classes.button} style={{marginTop:0,textTransform:'initial'}}>
                                    Xem tất cả
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={16}>
                        {classTrending.map((ct,index) =>(
                            <Grid item xs={12} sm={4} md={4} key={index}>
                            <Card>
                                <CardActionArea className={classes.videoThumbnail}>
                                    <Link to={"/video-course/"+ct.slug} title={ct.title}>
                                        <PlayCircleOutline color="primary" className={classes.btnPlay} />
                                        <img src={(ct.class_image !== null) ? config.get('apiImg')+ct.class_image : "/no-preview.jpg"} className={classes.media} alt={ct.title}/>
                                    </Link>
                                </CardActionArea>
                                <CardActions style={ {padding:'2px'} }>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography className={classes.caption} variant="caption" align="left">
                                            {ct.countViews} người học
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.caption} variant="caption" align="right">
                                                <Timestamp date={new Date(ct.created_at)} relative autoUpdate />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom>
                                                <Link to={"/video-course/"+ct.slug} className={classes.titleClass}>{ct.title}</Link>
                                            </Typography>
                                            <Typography className="margin-top-20">
                                                <Link to={"/user/"+ct.user.id+'/'+ct.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{ct.user.name}</Link> 
                                            </Typography>
                                            
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                        ))}  
                        </Grid>

                     
                        <Grid container spacing={8} style={{marginTop:30}}>
                            <Grid item xs={12} sm={6} >
                                <Typography variant="h5" color="primary" className={classes.section}>
                                    Các lớp học {catName} phổ biến
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} align="right">
                                <Button component={Link} to={"/video/"+this.props.match.params.slug+"/3"} variant="outlined" size="small" color="primary" className={classes.button} style={{marginTop:0,textTransform:'initial'}}>
                                    Xem tất cả
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={16}>
                        {classPopular.map((cp,index) =>(
                            <Grid item xs={12} sm={4} md={4} key={index}>
                            <Card>
                                <CardActionArea className={classes.videoThumbnail}>
                                    <Link to={"/video-course/"+cp.slug} title={cp.title}>
                                        <PlayCircleOutline color="primary" className={classes.btnPlay} />
                                        <img src={(cp.class_image !== null) ? config.get('apiImg')+cp.class_image : "/no-preview.jpg"} className={classes.media} alt={cp.title}/>
                                    </Link>
                                </CardActionArea>
                                <CardActions style={ {padding:'2px'} }>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography className={classes.caption} variant="caption" align="left">
                                            {cp.countViews} người học
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.caption} variant="caption" align="right">
                                                <Timestamp date={new Date(cp.created_at)} relative autoUpdate />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom>
                                                <Link to={"/video-course/"+cp.slug} className={classes.titleClass}>{cp.title}</Link>
                                            </Typography>
                                            <Typography className="margin-top-20">
                                                <Link to={"/user/"+cp.user.id+'/'+cp.user.name.replace(/\s+/g, '-')} className={classes.teacherName}>{cp.user.name}</Link> 
                                            </Typography>
                                            
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                        ))}
                    </Grid> 
                    </Grid>
                </Grid>
                )}
            </div>
        )
    }
}

export default withStyles(styles)(EClass);
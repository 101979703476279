import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {  Typography,  Grid, CircularProgress
  } from '@material-ui/core';
import axios from 'axios';
import config from "react-global-configuration";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import UserAvatar from 'react-user-avatar';

const styles = theme => ({
    root: {
      flexGrow: 1,
      marginBottom: 50,
      marginTop:100,
    },
    gray:{
        color: '#666',
    },
    linkListTeacher:{
        color: '#1565C0',
        textDecoration: 'none',
        marginLeft: 20,
        fontWeight: 'normal',
        '&:hover':{
            color: '#d50000',
        }
    },
    teacher:{
        color: '#555',
        textDecoration: 'none',
        fontWeight: 450,
        '&:hover':{
            color: '#d50000',
        }
    },
})

class TeacherList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            allTeacher: [],
          }
      }

      componentDidMount(){
        if(this.props.match.params.keyword === undefined){
            axios.get(config.get("apiDomain")+"userFrontend/teacher/all")
            .then(res => {
                this.setState({allTeacher: res.data, loading:false});
            })
        }else{
            axios.get(config.get("apiDomain")+"userFrontend/search-teacher/"+this.props.match.params.keyword)
            .then(res => {
                this.setState({allTeacher: res.data, loading:false});
            })
        }
        
    }
    componentWillReceiveProps(nextProps) {
            const kw = this.props.match.params.keyword;

            const newKw = nextProps.match.params.keyword;
            if(kw !== newKw){
                this.setState({loading:true});
                axios.get(config.get("apiDomain")+"userFrontend/search-teacher/"+newKw)
                .then(res => {
                    this.setState({allTeacher: res.data, loading:false});
                })
            }
    }
    render(){
        const { classes } = this.props;
        const { allTeacher, loading } = this.state;
        
        return(
            <div className={classes.root}>
                <MetaTags>
                <title>Danh sách giáo viên LearnSkill</title>
                <meta name="description" content='Danh sách giáo viên tham gia giảng dạy trên hệ thống học kỹ năng trực tuyến learnskill' />
              <meta name="keywords" content="học, dạy học, lớp học, dạy học, giáo dục, học trực tuyến, học online, video, lớp học video, các lớp học video" />
              <meta property="og:title" content="Tìm kiếm lớp học LearnSkill" />
              <meta property="og:description" content='Danh sách giáo viên tham gia giảng dạy trên hệ thống học kỹ năng trực tuyến learnskill' />
              <meta property="og:image" content={config.get('domain')+'img-share.jpg'} />
              <meta property="og:url" content={config.get('domain')} />
              <meta name="twitter:card" content="summary_large_image" />
                </MetaTags>
                <Grid container style={{padding: '0 10px'}}>
                    <Grid item md={1}></Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <Typography variant="h2" style={{fontSize:30}}>
                            Danh sách giáo viên
                        </Typography>
                        <Typography variant="h6" className={classes.gray} style={{wordWrap: 'break-word',marginTop:30,textAlign:'center'}} >
                            <Link to="/danh-sach-giao-vien/A" title="A" className={classes.linkListTeacher}>A</Link> 
                            <Link to="/danh-sach-giao-vien/B" title="B" className={classes.linkListTeacher}>B</Link>
                            <Link to="/danh-sach-giao-vien/C" title="C" className={classes.linkListTeacher}>C</Link>
                            <Link to="/danh-sach-giao-vien/D" title="D" className={classes.linkListTeacher}>D</Link>
                            <Link to="/danh-sach-giao-vien/E" title="E" className={classes.linkListTeacher}>E</Link>
                            <Link to="/danh-sach-giao-vien/G" title="G" className={classes.linkListTeacher}>G</Link>
                            <Link to="/danh-sach-giao-vien/H" title="H" className={classes.linkListTeacher}>H</Link>
                            <Link to="/danh-sach-giao-vien/K" title="K" className={classes.linkListTeacher}>K</Link>
                            <Link to="/danh-sach-giao-vien/L" title="L" className={classes.linkListTeacher}>L</Link>
                            <Link to="/danh-sach-giao-vien/M" title="M" className={classes.linkListTeacher}>M</Link>
                            <Link to="/danh-sach-giao-vien/N" title="N" className={classes.linkListTeacher}>N</Link>
                            <Link to="/danh-sach-giao-vien/O" title="O" className={classes.linkListTeacher}>O</Link>
                            <Link to="/danh-sach-giao-vien/P" title="P" className={classes.linkListTeacher}>P</Link>
                            <Link to="/danh-sach-giao-vien/Q" title="Q" className={classes.linkListTeacher}>Q</Link>
                            <Link to="/danh-sach-giao-vien/R" title="R" className={classes.linkListTeacher}>R</Link>
                            <Link to="/danh-sach-giao-vien/S" title="S" className={classes.linkListTeacher}>S</Link>
                            <Link to="/danh-sach-giao-vien/T" title="T" className={classes.linkListTeacher}>T</Link>
                            <Link to="/danh-sach-giao-vien/U" title="U" className={classes.linkListTeacher}>U</Link>
                            <Link to="/danh-sach-giao-vien/V" title="V" className={classes.linkListTeacher}>V</Link>
                            <Link to="/danh-sach-giao-vien/X" title="X" className={classes.linkListTeacher}>X</Link>
                            <Link to="/danh-sach-giao-vien/Y" title="Y" className={classes.linkListTeacher}>Y</Link>                    
                        </Typography>
                        {loading ? (
                            <CircularProgress className={classes.progress} color="secondary" style={{position:'absolute',top:'48%',left:'48%'}} />
                        ) : (
                            <Grid container style={{marginTop:30}}>
                                
                                    {allTeacher.map(val => (
                                        <Grid item xs={12} sm={6} md={3} key={val.id}>
                                        {val.skill_related !== null ? (
                                            <List>
                                            <ListItem alignItems="flex-start" component={Link} to={'/user/'+val.id+'/'+val.name.replace(/\s+/g, '-')}>
                                                <ListItemAvatar>
                                                    <UserAvatar size="40" name={val.name} src={config.get('apiImg')+val.avatar} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    className={classes.teacher}
                                                    primary={val.name}
                                                    secondary={
                                                    JSON.parse(val.skill_related).map((rs, i, arr)=>(
                                                        <span key={i}>{rs.name}{arr.length-1 === i ? "": ", "}</span>
                                                    ))
                                                }
                                                />
                                            </ListItem>
                                            </List>
                                        ) : (
                                            <List>
                                            <ListItem alignItems="flex-start" component={Link} to={'/user/'+val.id+'/'+val.name.replace(/\s+/g, '-')}>
                                                <ListItemAvatar>
                                                    <UserAvatar size="40" name={val.name} src={val.avatar} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    className={classes.teacher}
                                                    primary={val.name}
                                                    style={{lineHeight:50}}
                                                />
                                            </ListItem>
                                            </List>
                                        )}
                                        
                                        </Grid>
                                    ))}
                                
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(TeacherList);
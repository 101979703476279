import * as React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import axios from 'axios';
import config from "react-global-configuration";
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop:100,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      load: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'row-reverse',
      },
      wrapper: {
        margin: 5,
        position: 'relative',
      },
      buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
    })


class SetRole extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        loading: true,
        loading2: false,
        checked: [0],
        userInfo: [],
        admin: false,
        teacher: false,
        student: false,
        user: true,
        noChange: true,
        updating: false
    }
  }
  componentDidMount(){
    let uInfo = JSON.parse(localStorage.getItem('user'))
    axios.get(config.get("apiDomain")+'admin/get-user/'+this.props.match.params.id,
        {
        headers: {
            'Authorization': 'Bearer '+uInfo.access_token,
        }
        }
    )
    .then(res => {
      this.setState({userInfo: res.data});
    });

    axios.get(config.get("apiDomain")+'auth/get-role/'+this.props.match.params.id,
        {
        headers: {
            'Authorization': 'Bearer '+uInfo.access_token,
        }
        }
    )
    .then(res => {
        let data = res.data;
        if(data.indexOf("admin") > -1){
            this.setState({admin: true});
        }
        if(data.indexOf("teacher") > -1){
            this.setState({teacher: true});
        }
        if(data.indexOf("student") > -1){
            this.setState({student: true});
        }
      
      this.setState({loading: false});
    });
   
  }

  handleChange = name => event => {
    let uInfo = JSON.parse(localStorage.getItem('user'));
    if(name === 'admin'){
      if(event.target.checked === true){
        this.setState({admin: event.target.checked, loading2:true});
        axios.get(config.get("apiDomain")+'auth/assign-role-admin/'+this.props.match.params.id,
          {
          headers: {
              'Authorization': 'Bearer '+uInfo.access_token,
          }
          }
      )
      .then(res => {
        this.setState({loading2:false});
      });
      }else{
        this.setState({admin: event.target.checked, loading2:true});
        axios.get(config.get("apiDomain")+'auth/remove-role/'+this.props.match.params.id+'/'+name,
          {
          headers: {
              'Authorization': 'Bearer '+uInfo.access_token,
          }
          }
      )
      .then(res => {
        this.setState({loading2:false}); 
      });
      }
  }
    if(name === 'teacher'){
        if(event.target.checked === true){
          this.setState({teacher: event.target.checked, loading2:true});
          axios.get(config.get("apiDomain")+'auth/assign-role-teacher/'+this.props.match.params.id,
            {
            headers: {
                'Authorization': 'Bearer '+uInfo.access_token,
            }
            }
        )
        .then(res => {
          this.setState({loading2:false});
        });
        }else{
          this.setState({teacher: event.target.checked, loading2:true});
          axios.get(config.get("apiDomain")+'auth/remove-role/'+this.props.match.params.id+'/'+name,
            {
            headers: {
                'Authorization': 'Bearer '+uInfo.access_token,
            }
            }
        )
        .then(res => {
          this.setState({loading2:false}); 
        });
        }
    }
    if(name === 'student'){
      if(event.target.checked === true){
        this.setState({student: event.target.checked, loading2:true});
        axios.get(config.get("apiDomain")+'auth/assign-role-student/'+this.props.match.params.id,
          {
          headers: {
              'Authorization': 'Bearer '+uInfo.access_token,
          }
          }
      )
      .then(res => {
        this.setState({loading2:false});
      });
      }else{
        this.setState({student: event.target.checked, loading2:true});
        axios.get(config.get("apiDomain")+'auth/remove-role/'+this.props.match.params.id+'/'+name,
          {
          headers: {
              'Authorization': 'Bearer '+uInfo.access_token,
          }
          }
      )
      .then(res => {
        this.setState({loading2:false}); 
      });
      }
  }
  };
 
  
  render() {
    const { loading, userInfo, admin, teacher, student, user} = this.state;
    const { classes } = this.props;
    // const error = [admin, teacher, student, user].filter(v => v).length < 1;
    const uInfo = JSON.parse(localStorage.getItem('user'))
    const disabled = (parseInt(this.props.match.params.id) === uInfo.id) ? true : false;
    return(
      <div>
        <MetaTags>
            <title>Quản lý người dùng - LearnSkill</title>
            <meta name="description" content="Ký tài khoản hệ thống LearnSkill để học các video trực tuyến miễn phí" />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
        {loading ? (
        <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
        ) : (
        <React.Fragment>
          {this.state.loading2 &&
          <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
          }
        <Button type="submit" size="small" variant="outlined" color="default" style={{textTransform:'initial',marginBottom:30}} component={Link} to="/dashboard/user">
            <KeyboardBackspace className={classes.leftIcon} style={{fontSize:18}}/>
                Quay lại quản lý người dùng
        </Button>
        <p></p>
        <form name="form" className={classes.form} onSubmit={this.handleSubmit}>
       
        <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Phân quyền cho người dùng <b>{userInfo.name}</b></FormLabel>
        {/* <FormHelperText>Bạn phải chọn tối thiểu 1 quyền cho người dùng</FormHelperText> */}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={admin} disabled = {disabled} onChange={this.handleChange('admin')} value="admin" />
              }
              label="Quyền Admin"
            />
            <FormControlLabel
              control={
                <Checkbox checked={teacher} onChange={this.handleChange('teacher')} value="teacher" />
              }
              label="Quyền giáo viên"
            />
            <FormControlLabel
              control={
                <Checkbox checked={student} onChange={this.handleChange('student')} value="student" />
              }
              label="Quyền sinh viên"
            />
            <FormControlLabel
              control={
                <Checkbox checked={user} disabled value="user" />
              }
              label="Người dùng thường"
            />
          </FormGroup>
        </FormControl>
        
        </form>
        </React.Fragment>
        )}
        </div>
  );
  }
}

  export default withStyles(styles)(SetRole);


import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { HeadPrivate, FooterPrivate } from '../Layout';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
// import config from "react-global-configuration";
import { userActions } from '../_actions/user.actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwipeableViews from 'react-swipeable-views';
import { AppBar, Tabs, Tab, Button, Grid, Typography, Dialog, TextField,
  CardMedia, CardContent, CardActions, Card, CardHeader, Avatar  } from '@material-ui/core';
  import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

function TabContainer({ children, dir }) {
    return (
      <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
        {children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
  };

const styles = theme => ({
  root: {
      flexGrow: 1,
      marginTop:100,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      marginTop: theme.spacing.unit * 2,
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing.unit,
    },
    
});

class MyClass extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        value: 0,
        open: false,
        fullWidth: true,
        maxWidth: 'sm',
        list: [],
        submitted: false,
      };
      this.handleDialogChange = this.handleDialogChange.bind(this);
      this.handleDialogSubmit = this.handleDialogSubmit.bind(this);
    }
    handleDialogChange(event) {
      const { name, value } = event.target;
      const { list } = this.state;
      this.setState({
          list: {
              ...list,
              [name]: value
          }
      });
  }
  
    handleDialogSubmit(event) {
      event.preventDefault();
      let userInfo = JSON.parse(localStorage.getItem('user'));
      this.setState({ submitted: true });
       const { list } = this.state;
          const { dispatch } = this.props;
          if (list.listName) {
            dispatch(userActions.addList(userInfo.id, list));
            this.setState({ open: false, list: []  });
          }
    };
      handleDialogOpen = () => {
        this.setState({
          open: true,
        });
      };
    
      handleDialogClose = () => {
        this.setState({ open: false, list: [] });
      };
      handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
      };
    
      handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
      };
    
      handleChange = (event, value) => {
        this.setState({ value });
      };
    
      handleChangeIndex = index => {
        this.setState({ value: index });
      };
  componentDidMount() {
    let userInfo = JSON.parse(localStorage.getItem('user'));
    this.props.dispatch(userActions.getInfo());
    this.props.dispatch(userActions.getList(userInfo.id));
  }

  render(){
    const { classes, theme, users } = this.props;
    const { list, submitted } = this.state;
    let uInfo = JSON.parse(localStorage.getItem('user'));
    // console.log(users.getList)
    return (
      <div className={classes.root}>
        <HeadPrivate />
        <MetaTags>
          <title>Lớp học của tôi - LearnSkill</title>
          <meta name="description" content="Some description." />
          <meta property="og:title" content="MyApp" />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
        <Grid container style={{padding:'0 10px'}}>
          <Grid item md={1}>&nbsp;</Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Typography variant="h2" gutterBottom style={{fontSize:30,marginBottom:30}}>
                Lớp học của tôi
            </Typography>
            <AppBar position="static" color="default" style={{backgroundColor:"transparent",boxShadow:'none'}}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            style={{paddingLeft:5,paddingRight:5}}
          >
            <Tab label="Danh sách của tôi" style={{textTransform:'initial'}}/>
            <Tab label="Các lớp học đã lưu" style={{textTransform:'initial'}}/>
            <Tab label="Lịch sử học" style={{textTransform:'initial'}}/>
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer dir={theme.direction}>
            <Grid container>
            {users.loading ? (
              <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
            ) : (
              <React.Fragment>
              <Grid item xs={12} align="right">
                <Button variant="contained" color="secondary" size="small" style={{textTransform:'initial', marginBottom:20}} onClick={this.handleDialogOpen}>
                  Tạo danh sách
                  <Icon className={classes.rightIcon}>send</Icon>
                </Button>
              </Grid>
              {users.items.length > 0 ? (
                <Grid container>
                  {users.items.map(v => (
                    <Grid item xs={12} sm={6} md={4} key={v.id}>
                    <Card className={classes.card}>
                    <CardHeader
                      avatar={
                        <Avatar 
                          alt={v.name}
                          src={uInfo.avatar} 
                          className={classes.avatar} 
                          component={Link}
                          to={"/u/"+uInfo.id+"/"+uInfo.name}
                        />
                      }
                      action={
                        <IconButton>
                          <MoreVertIcon />
                        </IconButton>
                      }
                      title={uInfo.name}
                      subheader={v.created_at}
                    />
                    <CardMedia
                      className={classes.media}
                      image="/static/images/cards/paella.jpg"
                      title="Paella dish"
                    />
                    <CardContent>
                      <Typography component="p">
                        {v.listName}
                      </Typography>
                    </CardContent>
                    <CardActions className={classes.actions} disableActionSpacing>
                      <Typography variant="caption">
                        0 lớp học
                      </Typography>
                    </CardActions>
                  </Card>
                  </Grid>
                  ))}
                </Grid>
              ) : (
                <div className="box">
                  <p>Bạn chưa tạo bất kỳ danh sách nào.</p>
                  <Button variant="contained" color="secondary" size="small" style={{textTransform:'initial', marginTop:30}} onClick={this.handleDialogOpen}>
                  Tạo danh sách
                  <Icon className={classes.rightIcon}>send</Icon>
                </Button>
                </div>
              )}
              </React.Fragment>
            )}
              

            </Grid>
          </TabContainer>
          <TabContainer dir={theme.direction}>Chức năng chưa sẵn có</TabContainer>
          <TabContainer dir={theme.direction}>Chức năng chưa sẵn có</TabContainer>
        </SwipeableViews>
          </Grid>
          <Grid item md={1}>&nbsp;</Grid>
        </Grid>

        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.open}
          onClose={this.handleDialogClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">Tạo danh sách</DialogTitle>
          <form className={classes.form} noValidate onSubmit={this.handleDialogSubmit}>
          <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                name="listName"
                label="Tên danh sách"
                type="text"
                defaultValue={list.listName}
                onChange={this.handleDialogChange}
                fullWidth
                helperText={submitted && !list.listName ? "Tên danh sách không được để trống": ""}
                error = {submitted && !list.listName ? true : false}
              />
              <TextField
                  fullWidth
                  label="Mô tả về bạn"
                  className={classes.textField}
                  name="info"
                  defaultValue={list.description}
                  onChange={this.handleDialogChange}
                  margin="normal"
                  variant="outlined"
                  multiline={true}
                  rows={2}
                  rowsMax={4}
              />
            
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary">
              Hủy
            </Button>
            <Button type="submit" variant="contained" color="secondary" size="small" style={{textTransform:'initial'}}>
              Tạo danh sách
            </Button>
          </DialogActions>
          </form>
        </Dialog>

        <FooterPrivate />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(MyClass));
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button,  ListItem, ListItemText,  List, CircularProgress, Divider, Paper, Card, CardHeader,
  CardContent, ListItemAvatar, CardActions, Fab
} from '@material-ui/core';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import { isLoged } from '../_services/auth';
import { authHeader } from '../_helpers/auth-header';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import StickyBox from "react-sticky-box";
import embed from "embed-video";
import '../editor.css';
import Timestamp from 'react-timestamp';
import UserAvatar from 'react-user-avatar';
import red from '@material-ui/core/colors/red';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

  
const styles = theme => ({
    root: {
    },
    listContent:{
      paddingTop:3,
      paddingBottom:3,
    },
      linkLesson: {
        color: '#777',
        textDecoration: 'none',
        fontSize:11,
        paddingTop:3,
      paddingBottom:3,
        '&:hover': {
          textDecoration: 'underline',
        },
    },
    figure:{
      margin:0,
    },
    load: {
      display: 'flex',
      alignItems: 'center',
      flexDirection:'row-reverse',
    },
    wrapper: {
      margin: 5,
      position: 'relative',
    },
    buttonProgress: {
      color: red[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    teacherName:{
      color: '#777',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover':{
          color: 'red',
          textDecoration: 'none',
      }
      
  },

  userComment:{
    color: '#1e88e5',
    textDecoration: 'none',
    fontWeight:500
},
  });

  function uploadImageCallBack(file) {
    return new Promise(
      (resolve, reject) => {
        const reader = new FileReader(); // eslint-disable-line no-undef
        reader.onload = e => resolve({ data: { link: e.target.result } });
        reader.onerror = e => reject(e);
        reader.readAsDataURL(file);
      });
  }

class ClassTextLesson extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        // value: 0,
        loading: true,
        introData: [],
        classContent: [],
        countStd: [],
        lessonContent: [],
        countLesson: 0,
        mobileOpen: false,
        content: EditorState.createEmpty(),
        contentComment: EditorState.createEmpty(),
        contentComment2: EditorState.createEmpty(),
        // contentReply: EditorState.createEmpty(),
        dataComment:[],
        dataComment2:[],
        allUser: [],
        comment:[],
      };
      this.onContentCommentChange = this.onContentCommentChange.bind(this);
        this.onContentCommentChange2 = this.onContentCommentChange2.bind(this);
        // this.onContentReplyChange = this.onContentReplyChange.bind(this);
        this.handleSubmitComment = this.handleSubmitComment.bind(this);
        this.handleSubmitComment2 = this.handleSubmitComment2.bind(this);
        // this.handleSubmitReply = this.handleSubmitReply.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
      this.setState((prev) => {
        return {visible: prev.visible + 5};
      });
    }

  onContentCommentChange = (contentComment) => {
      this.setState({contentComment});
      const { dataComment, introData } = this.state;
      let contentConvert = contentComment.getCurrentContent();
      let userInfo = JSON.parse(localStorage.getItem('user'));
      this.setState({
          noChange: false,
          dataComment: {
              ...dataComment,
              user_id: userInfo.id,
              class_id: introData.id,
              body: JSON.stringify(convertToRaw(contentConvert), null, 4)
          }
      }); 
  }
  onContentCommentChange2 = (contentComment2) => {
      this.setState({contentComment2});
      const { dataComment2, introData } = this.state;
      let contentConvert = contentComment2.getCurrentContent();
      let userInfo = JSON.parse(localStorage.getItem('user'));
      this.setState({
          noChange: false,
          dataComment2: {
              ...dataComment2,
              user_id: userInfo.id,
              class_id: introData.id,
              body: JSON.stringify(convertToRaw(contentConvert), null, 4)
          }
      }); 
  }
  // onContentReplyChange = (contentReply) => {
  //     this.setState({contentReply});
  //     const { dataReply, getClassName } = this.state;
  //     let contentConvert = contentReply.getCurrentContent();
  //     let userInfo = JSON.parse(localStorage.getItem('user'));
  //     this.setState({
  //         noChange: false,
  //         dataReply: {
  //             ...dataReply,
  //             user_id: userInfo.id,
  //             class_id: getClassName.id,
  //             body: JSON.stringify(convertToRaw(contentConvert), null, 4)
  //         }
  //     }); 
  // }
  handleSubmitComment(e){
      e.preventDefault();
      this.setState({ submitted: true });
      const { dataComment, contentComment } = this.state;
      if (contentComment.getCurrentContent().hasText() === true) {
          axios.post(config.get("apiDomain")+"user/comment/add", dataComment,
      {
          'Content-Type': 'application/json',
          headers: authHeader()
      })
          .then(res => {
              this.setState({
                  commentNotif: 'Bình luận của bạn đã được tạo và chờ kiểm duyệt từ hệ thống.',
                  contentComment: EditorState.createEmpty(),
                  submitted: false
              })
              this.turnOffRedTimeout = setTimeout(() => { 
                  this.setState(() => ({commentNotif: ''}))
                }, 5000);
          })
          .catch(error => {
              this.setState({
                  commentNotif: 'Lỗi tạo bình luận',
                  submitted: false
              })
          })
      }
    }

    handleSubmitComment2(e){
      e.preventDefault();
      this.setState({ submitted: true });
      const { dataComment2, contentComment2 } = this.state;
      if (contentComment2.getCurrentContent().hasText() === true) {
          axios.post(config.get("apiDomain")+"user/comment/add", dataComment2,
      {
          'Content-Type': 'application/json',
          headers: authHeader()
      })
          .then(res => {
              this.setState({
                  commentNotif: 'Bình luận của bạn đã được tạo và chờ kiểm duyệt từ hệ thống.',
                  contentComment2: EditorState.createEmpty(),
                  submitted: false
              })
              this.turnOffRedTimeout = setTimeout(() => { 
                  this.setState(() => ({commentNotif: ''}))
                }, 5000);
          })
          .catch(error => {
              this.setState({
                  commentNotif: 'Lỗi tạo bình luận',
                  submitted: false
              })
          })
      }
    }

      handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
      };
      componentDidMount(){
        axios.get(config.get("apiDomain")+'class/detail/'+this.props.match.params.slugClass)
        .then(res => {
            this.setState({
              introData: res.data,
            })
            axios.get(config.get("apiDomain")+"class/getComment-by-class/"+res.data.id+"/0")
            .then(res => {
                this.setState({
                    comment: res.data,
                });
            });
          });
        axios.get(config.get("apiDomain")+'class/content/'+this.props.match.params.slugClass)
        .then(res => {
          this.setState({
            classContent: res.data,
          })
        });
        axios.get(config.get("apiDomain")+'class/content/'+this.props.match.params.slugClass+'/'+this.props.match.params.slugLesson)
        .then(res => {
          this.setState({
            lessonContent: res.data,
          })
          if(res.data.content !== null){
            let convert = convertFromRaw(JSON.parse(res.data.content));
            let editor = EditorState.createWithContent(convert);
            this.setState({content: editor})
          }
        });
        axios.get(config.get("apiDomain")+'userFrontend/all-user')
        .then(res => {
            this.setState({
                allUser: res.data.map(val=>(
                    {'text': val.name, 'value':val.name, 'url':'/'+val.id+'/'+val.name.replace(/\s+/g, '-')}
                )),
                loading: false
            });
        });
        
    }
    componentWillReceiveProps(nextProps) {
      this.setState({loading:true})
      const slug = this.props.match.params.slugLesson;
      const newSlug = nextProps.match.params.slugLesson;
      if(slug !== newSlug){
        axios.get(config.get("apiDomain")+'class/content/'+this.props.match.params.slugClass+'/'+newSlug)
        .then(res => {
          this.setState({
            lessonContent: res.data,
            loading: false
          })
          if(res.data.content !== null){
            let convert = convertFromRaw(JSON.parse(res.data.content));
            let editor = EditorState.createWithContent(convert);
            this.setState({content: editor})
          }
        });
      }
  }
  
    getIndex(value, arr, prop) {
      for(var i = 0; i < arr.length; i++) {
          if(arr[i][prop] === value) {
              return i;
          }
      }
      return -1; //to handle the case where the value doesn't exist
  }
    getNext(){
      let allLesson = this.state.classContent;
      let Lesson = this.state.lessonContent;
      let idLesson = Lesson.id;
      let index = this.getIndex(idLesson, allLesson, 'id');
      if(allLesson.length >0 && (index+1)%allLesson.length !== 0){
        return allLesson[index+1].url;
      }else{
        return '';
      }
      
    }
    getPrevious(){
      let allLesson = this.state.classContent;
      let Lesson = this.state.lessonContent;
      let idLesson = Lesson.id;
      let index = this.getIndex(idLesson, allLesson, 'id');
      if(allLesson.length >0 && (index+1) > 1){
        return allLesson[index-1].url;
      }else{
        return '';
      }
      
    }
    setActive(url){
      if(this.props.match.params.slugLesson === url){
        return true;
      }else{
        return false;
      }
    }
    
      render() {
        const { classes  } = this.props;
        const { loading, lessonContent, introData, classContent, content, comment} = this.state;
        let userInfo = JSON.parse(localStorage.getItem('user'));
        
        return (
          <div className={classes.root}>
            <MetaTags>
              <title>{introData.title+' - LearnSkill'}</title>
              <meta name="description" content={introData.title+' - LearnSkill học kỹ năng trực tuyến trên nhiều lĩnh vực'} />
              <meta name="keywords" content="học, dạy học, lớp học, dạy học, giáo dục, học trực tuyến, học online, video, lớp học video, các lớp học video" />
              <meta property="og:title" content={introData.title+' - LearnSkill'} />
              <meta property="og:description" content={introData.title+' - LearnSkill học kỹ năng trực tuyến trên nhiều lĩnh vực'} />
              <meta property="og:image" content={config.get('apiImg')+introData.class_image} />
              <meta property="og:url" content={config.get('domain')} />
              <meta name="twitter:card" content="summary_large_image" />
            </MetaTags>
            <Grid container>
              <Grid item xs={12} sm={12} md={9}>
              <div>
                <Typography variant="h2" style={{fontSize:24,fontWeight:450}}>
                  {introData.title}
                  <span style={{marginLeft:20,marginRight:5,fontSize:16,fontWeight:'normal'}}>Chia sẻ:</span> 
                                                <Fab style={{color:'#888',height:'auto',width:'auto',minHeight:'auto'}} >
                                                <Tooltip title="Chia sẻ lên Facebook">
                                                    <FacebookShareButton
                                                        url={config.get('domain')+'text-course/'+this.props.match.params.slugClass}
                                                        quote={'Khóa học '+introData.title}
                                                        >
                                                        <FacebookIcon
                                                        size={32}
                                                        round />
                                                    </FacebookShareButton>
                                                </Tooltip>
                                                </Fab>
                                                <Fab style={{color:'#888',height:'auto',width:'auto',minHeight:'auto',marginLeft:5}} >
                                                <Tooltip title="Chia sẻ lên Twitter">
                                                <TwitterShareButton
                                                    url={config.get('domain')+'text-course/'+this.props.match.params.slugClass}
                                                    quote={'Khóa học '+introData.title}
                                                    >
                                                    <TwitterIcon
                                                    size={32}
                                                    round />
                                                </TwitterShareButton>
                                                </Tooltip>
                                                </Fab>
                </Typography> 
              <Divider style={{marginTop:10,marginBottom:20}} />
              {loading ? (
                <CircularProgress className={classes.progress} color="secondary" style={{position:'absolute',top:'48%',left:'48%'}} />
              ) : (
                <React.Fragment>
                  <Typography variant="h6">{lessonContent.name}</Typography>
                  <div className="unselectable">
                  <Editor editorState={content} readOnly={true} toolbarHidden style={{userSelect:'none'}}/>
                  </div>
                  <Divider style={{marginTop:20,marginBottom:10}}/>
                </React.Fragment>
                )}
                <Grid container spacing={32}>
                  
                  <Grid item xs={6} align="left">
                  {this.getPrevious() !== '' &&
                    <Button component={Link} to={'/lesson/'+introData.slug+'/'+this.getPrevious()} variant="outlined" size="small" style={{textTransform:'initial'}} color="primary" className={classes.button}>
                      <ArrowBack />
                      Bài trước 
                    </Button>
                  
                  }
                  </Grid>
                  <Grid item xs={6} align="right">
                  {this.getNext() !== '' ? (
                    <Button component={Link} to={'/lesson/'+introData.slug+'/'+this.getNext()} variant="outlined" size="small" style={{textTransform:'initial'}} color="primary" className={classes.button}>
                      Bài tiếp theo
                      <ArrowForward />
                    </Button>
                  ) : (
                    <Button component={Link} to={'/lesson/quiz/'+introData.slug+'/intro'} variant="outlined" size="small" style={{textTransform:'initial'}} color="primary" className={classes.button}>
                      Tiếp theo
                      <ArrowForward />
                    </Button>
                  )
                  }
                  </Grid>
                  
                </Grid>
                </div>
                
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
              <StickyBox offsetTop={80} offsetBottom={10}>
              <List>
                 <ListItem>
                    <ListItemText primary={<Typography variant="h6">Nội dung</Typography>} />
                  </ListItem>
                  <ListItem component={Link} to={'/lesson/'+introData.slug+'/gioi-thieu'} className={classes.listContent}>
                    <ListItemText primary={<Typography variant="body2">Giới thiệu</Typography>}/>
                  </ListItem>
                  <ListItem component={Link} to={'/lesson/'+introData.slug+'/muc-tieu'} className={classes.listContent}>
                    <ListItemText primary={<Typography variant="body2">Mục tiêu</Typography>}/>
                  </ListItem>
                  {classContent.map(value => (
                    <ListItem key={value.id} style={{backgroundColor:'initial'}} component={Link} to={'/lesson/'+introData.slug+'/'+value.url}  className={classes.linkLesson}>
                      <ListItemText primary={<Typography variant={this.setActive(value.url) ? 'subtitle2' : 'body2'}>{value.name}</Typography>}/>
                    </ListItem>
                    ))}
                    <ListItem component={Link} to={'/lesson/quiz/'+introData.slug+'/intro'} className={classes.listContent}>
                      <ListItemText primary={<Typography variant="body2">Kiểm tra</Typography>}/>
                    </ListItem>
              </List>
              </StickyBox>
              </Grid>
            </Grid>
            

            <Grid container spacing={24} style={{marginTop:20}}>
                                        <Grid item xs={12} sm={12} md={9}>
                                        {!isLoged() ? (
                                            <Paper style={{padding:"20px 10px"}}>
                                                Vui lòng <Link to = "/login" className={classes.userComment}>Đăng nhập</Link> hoặc <Link to = "/register" className={classes.userComment}>Đăng ký</Link> để bình luận
                                            </Paper>
                                        ) : (
                                            <React.Fragment>
                                                <Card className={classes.card} style={{overflow: 'initial'}}>
                                                    <CardHeader
                                                        style={{background:'#f5f6f7',padding:'3px 10px',borderBottom: '1px solid #dddfe2',borderRadius: '2px 2px 0 0'}}
                                                        title={<span style={{fontSize:17,fontWeight:500}}>Bình luận</span>}
                                                        subheader={<span style={{color:'red'}} className="fade-in">{this.state.commentNotif}</span>}
                                                    />
                                                    <CardContent style={{paddingBottom:0}}>
                                                        <List style={{paddingTop:0}}>
                                                            <ListItem style={{paddingLeft:0}}>
                                                                <ListItemAvatar className={classes.teacherName}>
                                                                    {userInfo.avatar !== '' ? (
                                                                        <UserAvatar size="50" name={userInfo.name} src={config.get('apiImg')+userInfo.avatar}/>
                                                                    ) : (
                                                                        <UserAvatar size="50" name={userInfo.name}/>
                                                                    )}
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    style={{position:'relative',top:-20}}
                                                                    primary={
                                                                        <React.Fragment>
                                                                        
                                                                        <Editor
                                                                            wrapperClassName="demo-wrapper"
                                                                            editorClassName="demo-editor"
                                                                            toolbarOnFocus
                                                                            toolbarClassName="toolbar-class"
                                                                            editorState={this.state.contentComment}
                                                                            onEditorStateChange={this.onContentCommentChange}
                                                                            placeholder="Viết trao đổi..."
                                                                            hashtag={{}}
                                                                            mention={{
                                                                                separator: ' ',
                                                                                trigger: '@',
                                                                                suggestions:this.state.allUser,
                                                                              }}
                                                                            toolbar={{
                                                                                options: ['image', 'embedded'],
                                                                                // emoji: {
                                                                                //     icon: emoji,
                                                                                //     className: undefined,
                                                                                //     component: undefined,
                                                                                //     popupClassName: undefined,
                                                                                //     emojis: [
                                                                                //       '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                                                //       '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                                                //       '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                                                //       '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                                                //       '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                                                //       '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                                                //       '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                                                //       '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                                                //       '✅', '❎', '💯',
                                                                                //     ],
                                                                                //   },
                                                                                  image: {
                                                                                    uploadCallback: uploadImageCallBack,
                                                                                    previewImage: true,
                                                                                  },
                                                                                  link: {
                                                                                    linkCallback: params => ({ ...params })
                                                                                  },
                                                                                  embedded: {
                                                                                    embedCallback: link => {
                                                                                      const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                                                                      return (detectedSrc && detectedSrc[1]) || link;
                                                                                    }
                                                                                  }
                                                                            }}
                                                                        />
                                                                        {this.state.submitted && this.state.contentComment.getCurrentContent().hasText() === false ? <Typography style={{color:'red'}}>Nội dung không được để trống</Typography> : ''}
                                                                        </React.Fragment>
                                                                    }
                                                                        
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </CardContent>
                                                    <CardActions style={{justifyContent: 'flex-end',borderTop: '1px solid #dddfe2'}}>
                                                        <div className={classes.load}>
                                                            <div className={classes.wrapper}>
                                                                <Button onClick = {this.handleSubmitComment} style={{textTransform:'initial'}} variant="contained" color="primary" size="small" disabled={this.state.submitted || this.state.noChange}>Đăng</Button>
                                                                {this.state.submitted && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                            </div>
                                                        </div>
                                                    </CardActions>
                                                </Card>
                                            </React.Fragment>
                                        )}
                                        {comment.slice(0, this.state.visible).map((val, index) =>(
                                            <React.Fragment key={index}>
                                            <List className="fade-in">
                                                
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                    <UserAvatar size="50" name={val.username} src={config.get('apiImg')+val.avatar}/>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Link to={"/user/"+val.userId+'/'+val.username.replace(/\s+/g, '-')} className={classes.userComment}>{val.username}</Link> 
                                                            <Editor editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(val.body)))} readOnly={true} toolbarHidden />
                                                            
                                                        </React.Fragment>}
                                                    secondary={
                                                        <React.Fragment>
                                                        {isLoged() ? (
                                                                <Typography component="span" style={{fontSize:13,color:'#888'}}>
                                                                {/* {val.clike} <Button onClick={this.updateLikeComment} style={{textTransform:'initial', color:'#888'}}>thích</Button>  */}
                                                                {/* <Link to="#" style={{color:'#888',textDecoration:'none',margin:'0 20px 0 0'}}>Trả lời</Link>  */}
                                                                <Timestamp date={new Date(val.created_at.date)} relative autoUpdate />
                                                                </Typography>
                                                            ) : (
                                                                <Timestamp date={new Date(val.created_at.date)} relative autoUpdate />
                                                            )}
                                                        </React.Fragment>
                                                    }
                                                    />
                                                </ListItem>
                                            </List>
                                            {/* {val.subcomment.map((v, idx)=>(
                                                <List key={idx} style={{marginLeft:50,borderLeft:'2px solid #1e88e5', padding:0}}>
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar>
                                                            <UserAvatar size="35" name={v.username} src={v.avatar}/>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                        primary={
                                                            <React.Fragment>
                                                                <Link to={"/user/"+v.userId+'/'+v.username.replace(/\s+/g, '-')} className={classes.userComment}>{v.username}</Link> 
                                                                <Editor editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(val.body)))} readOnly={true} toolbarHidden />
                                                            
                                                                </React.Fragment>}
                                                        secondary={
                                                            <React.Fragment>
                                                            {isLoged() ? (
                                                                <Typography component="span" style={{fontSize:13,color:'#888'}}>
                                                                {v.clike} <Link to="#" style={{color:'#888',textDecoration:'none'}}>thích</Link> 
                                                                <Link to="#" style={{color:'#888',textDecoration:'none',margin:'0 20px 0 0'}}>Trả lời</Link> 
                                                                <Timestamp date={new Date(val.created_at.date)} relative autoUpdate />
                                                                </Typography>
                                                            ) : (
                                                                <Timestamp date={new Date(val.created_at.date)} relative autoUpdate />
                                                            )}
                                                            </React.Fragment>
                                                        }
                                                        />
                                                    </ListItem>
                                                </List>

                                                
                                            ))} */}
                                            {/* <React.Fragment>
                                            <List style={{marginLeft:50,padding:0}}>
                                                            <ListItem style={{padding:0}}>
                                                                <ListItemAvatar className={classes.teacherName}>
                                                                    {userInfo.avatar !== '' ? (
                                                                        <UserAvatar size="35" name={userInfo.name} src={userInfo.avatar}/>
                                                                    ) : (
                                                                        <UserAvatar size="35" name={userInfo.name}/>
                                                                    )}
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    style={{position:'relative',top:-20,paddingRight:0}}
                                                                    primary={
                                                                        <React.Fragment>
                                                                        
                                                                        <Editor
                                                                            wrapperClassName="demo-wrapper"
                                                                            editorClassName="demo2-editor"
                                                                            toolbarOnFocus
                                                                            toolbarClassName="toolbar-class"
                                                                            editorState={this.state.contentReply}
                                                                            onEditorStateChange={this.onContentReplyChange}
                                                                            placeholder="Viết trả lời..."
                                                                            hashtag={{}}
                                                                            mention={{
                                                                                separator: ' ',
                                                                                trigger: '@',
                                                                                suggestions:this.state.allUser,
                                                                              }}
                                                                            toolbar={{
                                                                                options: ['image', 'embedded'],
                                                                                // emoji: {
                                                                                //     icon: emoji,
                                                                                //     className: undefined,
                                                                                //     component: undefined,
                                                                                //     popupClassName: undefined,
                                                                                //     emojis: [
                                                                                //       '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                                                //       '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                                                //       '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                                                //       '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                                                //       '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                                                //       '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                                                //       '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                                                //       '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                                                //       '✅', '❎', '💯',
                                                                                //     ],
                                                                                //   },
                                                                                  image: {
                                                                                    uploadCallback: uploadImageCallBack,
                                                                                    previewImage: true,
                                                                                  },
                                                                                  link: {
                                                                                    linkCallback: params => ({ ...params })
                                                                                  },
                                                                                  embedded: {
                                                                                    embedCallback: link => {
                                                                                      const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                                                                      return (detectedSrc && detectedSrc[1]) || link;
                                                                                    }
                                                                                  }
                                                                            }}
                                                                        />
                                                                        {this.state.submitted && this.state.contentComment2.getCurrentContent().hasText() === false ? <Typography style={{color:'red'}}>Nội dung không được để trống</Typography> : ''}
                                                                        </React.Fragment>
                                                                    }
                                                                        
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        <div align="right" style={{position:'relative',top:-38}}>
                                                            <p style={{color:'red'}}>{this.state.commentNotif}</p>
                                                            <div className={classes.load}>
                                                                <div className={classes.wrapper}>
                                                                    <Button id="1" onClick={e => this.handleSubmitReply(e.target.id)}style={{textTransform:'initial'}} variant="contained" color="primary" size="small" disabled={this.state.submitted || this.state.noChange}>Đăng</Button>
                                                                    {this.state.submitted && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </React.Fragment> */}
                                            </React.Fragment>
                                        ))}
                                        {this.state.visible < this.state.comment.length &&
                                            <Button onClick={this.loadMore} size="small" variant="outlined" color="primary" style={{textTransform:'initial'}}>Xem thêm</Button>
                                         }
                                         {((this.state.visible > this.state.comment.length) &&  (this.state.comment.length > 5)) &&
                                            
                                            <React.Fragment>
                                            <List style={{paddingTop:0,height:'auto'}}>
                                                            <ListItem style={{padding:0}}>
                                                                <ListItemAvatar className={classes.teacherName}>
                                                                    {userInfo.avatar !== '' ? (
                                                                        <UserAvatar size="50" name={userInfo.name} src={config.get('apiImg')+userInfo.avatar}/>
                                                                    ) : (
                                                                        <UserAvatar size="50" name={userInfo.name}/>
                                                                    )}
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    style={{position:'relative',top:-20,paddingRight:0}}
                                                                    primary={
                                                                        <React.Fragment>
                                                                        
                                                                        <Editor
                                                                            wrapperClassName="demo-wrapper"
                                                                            editorClassName="demo2-editor"
                                                                            toolbarOnFocus
                                                                            toolbarClassName="toolbar-class"
                                                                            editorState={this.state.contentComment2}
                                                                            onEditorStateChange={this.onContentCommentChange2}
                                                                            placeholder="Viết trao đổi..."
                                                                            hashtag={{}}
                                                                            mention={{
                                                                                separator: ' ',
                                                                                trigger: '@',
                                                                                suggestions:this.state.allUser,
                                                                              }}
                                                                            toolbar={{
                                                                                options: ['image', 'embedded'],
                                                                                // emoji: {
                                                                                //     icon: emoji,
                                                                                //     className: undefined,
                                                                                //     component: undefined,
                                                                                //     popupClassName: undefined,
                                                                                //     emojis: [
                                                                                //       '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                                                //       '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                                                //       '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                                                //       '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                                                //       '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                                                //       '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                                                //       '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                                                //       '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                                                //       '✅', '❎', '💯',
                                                                                //     ],
                                                                                //   },
                                                                                  image: {
                                                                                    uploadCallback: uploadImageCallBack,
                                                                                    previewImage: true,
                                                                                  },
                                                                                  link: {
                                                                                    linkCallback: params => ({ ...params })
                                                                                  },
                                                                                  embedded: {
                                                                                    embedCallback: link => {
                                                                                      const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                                                                      return (detectedSrc && detectedSrc[1]) || link;
                                                                                    }
                                                                                  }
                                                                            }}
                                                                        />
                                                                        {this.state.submitted && this.state.contentComment2.getCurrentContent().hasText() === false ? <Typography style={{color:'red'}}>Nội dung không được để trống</Typography> : ''}
                                                                        </React.Fragment>
                                                                    }
                                                                        
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        <div align="right" style={{position:'relative',top:-38}}>
                                                            <p style={{color:'red'}}>{this.state.commentNotif}</p>
                                                            <div className={classes.load}>
                                                                <div className={classes.wrapper}>
                                                                    <Button onClick = {this.handleSubmitComment2} style={{textTransform:'initial'}} variant="contained" color="primary" size="small" disabled={this.state.submitted || this.state.noChange}>Đăng</Button>
                                                                    {this.state.submitted && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </React.Fragment>
                                        }
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>

                                        </Grid>
                                    </Grid>
          </div>
        );
      }
}
export default withStyles(styles, { withTheme: true })(ClassTextLesson);
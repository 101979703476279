import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Tabs, Tab, ListItem,
  ListItemText, ListItemAvatar, List, ListItemSecondaryAction,CircularProgress, Fade     
} from '@material-ui/core';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from "react-global-configuration";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { connect } from 'react-redux';
// import { classActions } from '../_actions/class.actions';
import UserAvatar from 'react-user-avatar';
import '../editor.css';

function TabContainer(props) {
    return (
      <Typography component="div">
        {props.children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };
  
  const styles = theme => ({
    root: {
    },
    paper:{
      padding:10,
    },
    teacherName: {
      color: '#777',
      textDecoration: 'none',
      fontWeight: 450,
      cursor: 'pointer',
      '&:hover': {
          color: 'red',
          textDecoration: 'none',
      },
    },
    linkLesson: {
      color: '#777',
      textDecoration: 'none',
      '&:hover': {
          color: '#1e88e5',
      },
    },
  });

class ClassTextDetail extends React.Component{
    state = {
        value: 0,
        loading: true,
        introData: [],
        teacherInfo:
        {
            id:'',
            name: 'abc',
            avatar: '',
            skill_related: {"id":3,"name":"abc"}
        },
        relatedSkill: [],
        countStd: [],
        classContent: [],
        countViews: 0,
        desc: EditorState.createEmpty(),
        objective: EditorState.createEmpty(),
        project: EditorState.createEmpty(),
      };
      componentDidMount(){

        axios.get(config.get("apiDomain")+'class/detail/'+this.props.match.params.slug)
        .then(res => {
          if(res.data){
            this.setState({
                introData: res.data,
                teacherInfo:
                {
                    id:res.data.user.id,
                    name: res.data.user.name,
                    avatar: res.data.user.avatar,
                    skill_related: res.data.user.skill_related
                }
            });
              if(res.data.description !== null){
                  let convert = convertFromRaw(JSON.parse(res.data.description));
                  let editor = EditorState.createWithContent(convert);
                  this.setState({desc: editor})
              }
              if(res.data.objective !== null){
                  let convert = convertFromRaw(JSON.parse(res.data.objective));
                  let editor = EditorState.createWithContent(convert);
                  this.setState({objective: editor})
              }
              if(res.data.project !== null){
                  let convert = convertFromRaw(JSON.parse(res.data.project));
                  let editor = EditorState.createWithContent(convert);
                  this.setState({project: editor})
              }
          }else{
              this.setState({ introData: res.data });
          }
          
        });

        axios.get(config.get("apiDomain")+'class/content/'+this.props.match.params.slug)
        .then(res => {
          this.setState({
            classContent: res.data,
            loading: false
          })
        
        });
        axios.get(config.get("apiDomain")+'class/update-view/'+this.props.match.params.slug);
    }
    
      handleChange = (event, value) => {
        this.setState({ value });
      };
    
      render() {
        const { classes } = this.props;
        const { value, loading, introData, teacherInfo, relatedSkill, classContent, desc, objective, project } = this.state;
        
        return (
          <div className={classes.root}>
            <MetaTags>
              <title>{'Lớp học văn bản '+introData.title+' - LearnSkill'}</title>
              <meta name="description" content={'Học kỹ năng '+introData.title+' trên hệ thống học trực tuyến learnskill'} />
              <meta name="keywords" content="học, dạy học, lớp học, dạy học, giáo dục, học trực tuyến, học online, video, lớp học video, các lớp học video" />
              <meta property="og:title" content={'Lớp học văn bản '+introData.title+' - LearnSkill'} />
              <meta property="og:description" content={'Học kỹ năng '+introData.title+' trên hệ thống học trực tuyến learnskill'} />
              <meta property="og:image" content={introData.class_image} />
              <meta property="og:url" content={config.get('domain')} />
              <meta name="twitter:card" content="summary_large_image" />
            </MetaTags>
            {loading ? (
                    <CircularProgress className={classes.progress} color="secondary" style={{position:'relative',top:'48%',left:'48%'}} />
                ) : (
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={9}>
                <Typography variant="h5" color="primary">
                  {introData.title}
                </Typography>
                <List style={{paddingTop:0}}>
                <ListItem style={{paddingLeft:0}}>
                    <ListItemAvatar component={Link} to={"/user/"+teacherInfo.id+'/'+teacherInfo.name} className={classes.teacherName} title={teacherInfo.name}>
                        {teacherInfo.avatar !== '' ? (
                            <UserAvatar size="45" name={teacherInfo.name} src={config.get('apiImg')+teacherInfo.avatar}/>
                        ) : (
                          <UserAvatar size="45" name={teacherInfo.name}/>
                        )}
                      </ListItemAvatar>
                        <ListItemText
                            primary={<Link to={"/user/"+teacherInfo.id+'/'+teacherInfo.name} className={classes.teacherName}>{teacherInfo.name}</Link>}
                            secondary={
                            <React.Fragment>
                                <Typography component="span" className={classes.caption} color="textPrimary">
                                    {relatedSkill.map((rs, i, arr)=>(
                                        <span key={i}>{rs.name}{arr.length-1 === i ? "": ", "}</span>
                                    ))}
                                    </Typography>
                                    <Typography component="span" className={classes.caption} color="textPrimary">
                                    {introData.countViews} người học
                                    </Typography>
                            </React.Fragment>
                        }
                    />
                  </ListItem>
                
                </List>
                <Paper square>
                    <Tabs value={value} onChange={this.handleChange}>
                      <Tab label="Thông tin chung" />
                      <Tab label="Nội dung" />
                    </Tabs>
                  </Paper>
                  {value === 0 && <TabContainer>
                    <Typography variant="h6"  color="primary">
                      Mô tả khóa học
                    </Typography>
                    {desc.getCurrentContent().hasText() ? (
                      // description.map((val,index)=>(
                      //   <div key={index} style={{marginBottom:15,textAlign:'justify'}}>{val.getText()}</div>
                      // ))
                      <Editor editorState={desc} readOnly={true} toolbarHidden />
                    ) : (
                      <div className="box">
                        Không có mô tả cho lớp học này
                      </div>
                    )}
                    <Typography variant="h6"  color="primary" style={{marginTop:20}}>
                      Mục tiêu khóa học
                    </Typography>
                    {objective.getCurrentContent().hasText() ? (
                      <Typography component="div" className={classes.caption} color="textPrimary">
                        Sau khi học xong khóa học này, người học sẽ có thể:
                        {/* {
                        obj.map((val,index)=>(
                          <div key={index} style={{marginBottom:15,textAlign:'justify'}}>{val.getText()}</div>
                        ))
                        } */}
                        <Editor editorState={objective} readOnly={true} toolbarHidden />
                      </Typography>
                    ) : (
                      <div className="box">
                        Không có mục tiêu cho lớp học này
                      </div>
                    )}
                    <Typography variant="h6"  color="primary" style={{marginTop:20}}>
                      Dự án khóa học
                    </Typography>
                    {project.getCurrentContent().hasText() ? (
                        // proj.map((val,index)=>(
                        //   <div key={index} style={{marginBottom:15,textAlign:'justify'}}>{val.getText()}</div>
                        // ))
                        <Editor editorState={project} readOnly={true} toolbarHidden />
                    ) : (
                      <div className="box">
                        Không có dự án cho lớp học này
                      </div>
                    )}
                    
                  </TabContainer>}
                  {value === 1 && <TabContainer>
                    <List>
                    <ListItem button component={Link} to={'/lesson/'+introData.slug+'/gioi-thieu'}>
                        <ListItemText primary={`Giới thiệu `} />
                        <ListItemSecondaryAction>
                          <IconButton aria-label="chi tiết">
                            <KeyboardArrowRight />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem button component={Link} to={'/lesson/'+introData.slug+'/muc-tieu'} className={classes.linkLesson}>
                        <ListItemText primary={`Mục tiêu`} />
                        <ListItemSecondaryAction>
                          <IconButton aria-label="chi tiết">
                            <KeyboardArrowRight />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    {classContent.map(value => (
                      <ListItem key={value.id} button component={Link} to={'/lesson/'+introData.slug+'/'+value.url} className={classes.linkLesson}>
                        <ListItemText primary={value.name} />
                        <ListItemSecondaryAction>
                        <IconButton aria-label="chi tiết">
                            <KeyboardArrowRight />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                  </TabContainer>}
                  <div className="placeholder">
                                <Fade
                                    in={loading}
                                    style={{
                                    transitionDelay: loading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress color="secondary" />
                                </Fade>
                            </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                  {/* <Paper className={classes.paper}>
                  <Typography variant="h6">
                    Thông tin khóa học
                  </Typography>
                  <Divider />
                  </Paper> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
                )}
          </div>
        );
      }
}
function mapStateToProps(state) {
  const { getClass } = state;
  return {
      getClass
  };
}

export default connect(mapStateToProps)(withStyles(styles)(ClassTextDetail));